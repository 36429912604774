<template>
  <div class="footer-wrapper">
    <div class="d-flex justify-space-between">
      <div class="d-flex align-center">
        <help-juice />
        <hb-link v-if="step > 1" @click="$emit('handleResetModal')"
          >Reset Application</hb-link
        >
      </div>
      <div>
        <hb-link v-if="step > 1" class="mr-5" @click="$emit('back')"
          >Back</hb-link
        >
        <hb-btn
          v-if="step > 1"
          class="mr-5"
          color="secondary"
          :disabled="saveAndCloseLoading"
          :loading="saveAndCloseLoading"
          @click="$emit('saveAndClose')"
          >Save & Close</hb-btn
        >
        <hb-btn
          v-if="step >= 1 && step <= 4"
          color="primary"
          :disabled="continueLoading"
          :loading="continueLoading"
          @click="$emit('continue')"
          >Continue</hb-btn
        >
        <hb-btn
          v-else-if="step === 5"
          color="primary"
          :disabled="submitLoading"
          :loading="submitLoading"
          @click="$emit('submitApplication')"
          >Submit</hb-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    step: {
      type: Number,
      default: 1,
    },
    saveAndCloseLoading: {
      type: Boolean,
      default: false,
    },
    submitLoading: {
      type: Boolean,
      default: false,
    },
    continueLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.footer-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  padding: 12px 16px;
  box-shadow: inset 0px 1px 0px rgba(142, 142, 143, 0.15);
  width: 100%;
  border-top: 1px solid #dfe3e8;
  z-index: 1;
}
</style>
