<template>
  <v-expansion-panels class="pa-2" v-model="propertyInfoPanels" v-show="property" multiple>
    <hb-expansion-panel v-if="property">
      <template v-slot:title>
        Property Info
      </template>
      <template v-slot:content>
        <hb-form label="Name" class="hb-text-night">
          {{ property.name }}
        </hb-form>
        <hb-form label="Address" class="hb-text-night">
          <span v-if="property.Address">
            {{ property.Address.address }}<br>
            {{ property.Address | formatAddress }}
          </span>
        </hb-form>
        <hb-form label="Email" v-if="property?.Emails.length">
          <v-row class="total-text" no-gutters v-for="e in property?.Emails">
            {{ e.email }}
          </v-row>
        </hb-form>
        <hb-form label="Phone" v-if="property.Phones?.length">
          <v-row class="total-text" no-gutters v-for="p in property?.Phones">
            {{ p.phone | formatPhone }}
          </v-row>
        </hb-form>
        <hb-form label="Website" v-if="websiteInfo?.website_url && !isNational">
          <hb-link color="secondary" target='_blank' :href="websiteInfo?.website_url">{{ websiteInfo?.website_url
            }}</hb-link>
        </hb-form>
      </template>
    </hb-expansion-panel>
    <hb-expansion-panel v-if="websiteInfo?.hours && websiteInfo?.hours.length > 0 && !isNational">
      <template v-slot:title>
        Hours
      </template>
      <template v-slot:content>
        <hb-form label="Office Hours" class="hb-text-night">
          <div class="hours-row ma-0 pa-0" v-for="(obj, index) in officeHours" :key="index">
            <span class="hb-font-body-medium ma-0 pa-0">{{ obj.value }} </span> <span class="hb-font-body ma-0 pa-0">{{ obj.label }}</span>
          </div>
        </hb-form>
        <hb-form label="Gate Hours" class="hb-text-night">
          <div class="hours-row ma-0 pa-0" v-for="(obj, index) in gateHours" :key="index">
            <span class="hb-font-body-medium ma-0 pa-0">{{ obj.value }} </span> <span class="hb-font-body ma-0 pa-0">{{ obj.label }}</span>
          </div>
        </hb-form>
      </template>
    </hb-expansion-panel>
  </v-expansion-panels>
</template>


<script type="text/babel">
import api from '../../../assets/api.js';
import { charges } from '../../../mixins/leaseCharges.js'
import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
  name: "MoveInPropertyInfo",
  data() {
    return {
      showAside: true,
      propertyInfoPanels: [0],
      websiteInfo: {},
      officeHours: {},
      gateHours: {},
    }
  },

  components: {
  },
  async created() {
    this.showAside = this.show;
    await this.getWebsiteInfo();
  },
  computed: {
    ...mapGetters({
      properties: 'propertiesStore/filtered',
      property: 'onBoardingStore/property',
    }),
  },
  methods: {
    async getWebsiteInfo() {
      let r = await api.get(this, api.PROPERTIES + this.property.id + '/website-info/')
      this.websiteInfo = r.property;

      this.websiteInfo?.hours?.forEach(hourType => {
          switch(hourType.type) {
              case "gate_hours":
                  this.gateHours = this.compressScheduledHours(hourType.days);
                  break;
              case "office_hours":
                  this.officeHours = this.compressScheduledHours(hourType.days);
                  break;
              // Handle other hour types if needed
          }
      });
      this.hours = this.compressScheduledHours(this.websiteInfo?.hours[1].days)
    },
    compressScheduledHours(
      days,
      getDayOfWeekInFacilityTimezone
    ) {
      let sortedDays = this.sortDays(days)
      const groupedDays = []
      let lastEntry
      for (let i = 0; i < sortedDays.length; i++) {
        const dayObj = sortedDays[i]
        const hoursLabel = this.generateDayLabel(dayObj)
        if (
          lastEntry &&
          hoursLabel === lastEntry.hoursLabel &&
          i - lastEntry.index === 1
        ) {
          lastEntry.days.push(dayObj.day)
          lastEntry.index = i
        } else {
          lastEntry = {
            hoursLabel,
            days: [dayObj.day],
            index: i
          }
          groupedDays.push(lastEntry)
        }
      }
      return this.generateGroupLabels(groupedDays,getDayOfWeekInFacilityTimezone)
    },
    sortDays(day) {
      let days = [...day]
      const daysOfWeek = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']
      return days.sort(function (a, b) {
        return daysOfWeek.indexOf(a.day) - daysOfWeek.indexOf(b.day)
      })
    },
    generateDayLabel(dayObj) {
      let convertTime = time => {
        let meridium = time.split(':')
        return `${moment(time, 'HH:mm:ss').format('HH:mm')} ${meridium[meridium.length - 1].split(' ')[1]
          }`
      }
      let label
      if (dayObj.appointment_only) label = 'Appointment Only'
      else if (dayObj['24_hours']) label = 'Always Open'
      else if (dayObj.open_time === dayObj.close_time) label = 'Closed'
      else
        label = `${convertTime(dayObj.open_time)} - ${convertTime(
          dayObj.close_time
        )}`
      return label
    },
    generateGroupLabels(
      groupedDays = [],
      getDayOfWeekInFacilityTimezone = null
    ) {
      let DAY_NAME_MAPPING = {
        sun: 'Sun',
        mon: 'Mon',
        tue: 'Tue',
        wed: 'Wed',
        thu: 'Thu',
        fri: 'Fri',
        sat: 'Sat'
      }
      let getDay = new Date().getDay()
      if (getDayOfWeekInFacilityTimezone !== null)
        getDay = getDayOfWeekInFacilityTimezone
      let today = Object.keys(DAY_NAME_MAPPING)[getDay]
      return groupedDays.reduce((acc, curr, index) => {
        let tempObj = {}
        switch (curr.days.length) {
          case 1:
            tempObj = { value: DAY_NAME_MAPPING[curr.days[0]] }
            break
          case 2:
            tempObj = {
              value: `${curr.days[0]}, ${curr.days[1]}`
            }
            break
          case 7:
            tempObj = { value: 'Daily' }
            break
          default:
            tempObj = {
              value: `${curr.days[0]} - ${curr.days[curr.days.length - 1]}`
            }
            break
        }
        Object.assign(tempObj, {
          label: groupedDays[index].hoursLabel,
          includes_today: curr.days.includes(today)
        })
        acc.push(tempObj)
        return acc
      }, [])
    }
  },
  props: ['show','isNational'],
  watch: {
    show(val) {
      this.showAside = val;
    },
    async property(val) {
      await this.getWebsiteInfo();
    }
  }

}


</script>
<style scoped>
.total-text {
  color: #306FB6;
}
.hours-row{
  text-transform: capitalize;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
