<template>
    <div>

        <!-- <v-row no-gutters class="mt-n1 mb-4 pl-3">
            <div class="hb-font-header-2-medium">Select Service Charges</div>
        </v-row> -->
        <v-container class="insurance-container">
            <v-row class="merchandise-row hb-font-body-medium">
                <v-col style="flex: 0 1 50px;" md="1">
                    <label>&nbsp;</label>
                </v-col>
                <v-col style="flex: 2" md="3">
                    <label>Name</label>
                </v-col>
                <v-col style="flex: 1 0 100px" md="3">
                    <label>Service Start</label>
                </v-col>
                <v-col style="flex: 1 0 100px" md="3">
                    <label>Details</label>
                </v-col>
                <v-col style="flex: 0 1 100px;" md="2">
                    <label>Price</label>
                </v-col>
            </v-row>

            <v-row v-for="(product, index) in filtered_products" :key="product.id" class="merchandise-row">
                <v-col style="flex: 0 1 50px;" md="1">
                    <hb-checkbox @change="updateService(product)" v-model="product.activated"></hb-checkbox>
                </v-col>
                <v-col style="flex: 2" md="3">
                    <strong>{{product.name}}</strong><br />
                    <p>{{product.description}}</p>
                </v-col>
                <v-col style="flex: 1 0 100px" class="ml-n2 pa-0" md="3">
                    <div v-if="product.activated" style="margin-top:5px;">
                        <hb-date-picker dense v-model="product.start_date" @change="updateService(product)"></hb-date-picker>
                    </div>
                </v-col>
                <v-col style="flex: 1 0 100px" class="ml-2 pl-0" md="3">
                    <span v-if="product.activated">
                        <hb-checkbox v-model="product.recurring" label="Recurring" @change="updateService(product)"></hb-checkbox>
                    </span>
                </v-col>
                <v-col style="flex: 0 1 100px" class="ml-n1" md="2">
                    <v-text-field v-if="product.activated && isVariableRate(product.amount_type)"
                        dense
                        name="price"
                        v-model="product.service_price"
                        @change="updateService(product)"
                        label="Price"
                        v-validate="'decimal:2|max_value:9999|min_value:.01'"
                        data-vv-name="price"
                        :id="'price_' + product.id"
                        :name="'price_' + product.id"
                        outlined>
                    </v-text-field>
                    <span v-else>
                        <span v-if="product.amount_type === 'percentage' ">{{product.price | formatPercentage }}</span>
                        <span v-else>{{product.price | formatMoney }}</span>
                    </span>
                </v-col>
            </v-row>
        </v-container>

    </div>
</template>

<script type="text/babel">

    import api from '../../../assets/api.js';
    import HbDatePicker from '../../assets/HummingbirdDatepicker';
    import moment from 'moment';
    import { mapGetters } from 'vuex';
    import { EventBus } from '../../../EventBus.js';
	import { notificationMixin } from  '../../../mixins/notificationMixin.js';
	
    export default {
        name: "ConfigureMerchandise",
		mixins: [ notificationMixin ],
        data() {
            return {
                selected:'',
                products:[],
                defualt_products: [],
                product_search: '',
                currentRent: null,
                updated: false
            }
        },
        components:{
            HbDatePicker
        },

        created (){           
            this.fetchFees();
            EventBus.$on('remove_check', this.removeCheck);
        },
        filters:{
            lineTotal(c){
                return c.qty * c.cost;
            },
            getEndOfPeriod(date){
                return moment(date).add(1, 'month').subtract(1, 'day');
            }
        },
        computed: {
            ...mapGetters({
                property: 'onBoardingStore/property',
                lease: 'onBoardingStore/lease',
                services: 'onBoardingStore/services',
                insurance: 'onBoardingStore/insurance',
                template: 'onBoardingStore/template',
                fees: 'onBoardingStore/fees',
            }),

            filtered_products: {
                get(){
                    return this.products.filter(p => p.default_type === 'late')
                        .map(p => {
                            let service = this.services.find(s => s.product_id === p.id);
                            // p.Service = {};
                            //this.$set(p, 'Service', {});

                            this.$set(p, 'activated', !!service);
                            this.$set(p, 'qty', service ? 1 : null);
                            this.$set(p, 'service_price', service ? service.price : p.price);
                            this.$set(p, 'recurring', service ? service.recurring: null );
                            this.$set(p, 'prorate', service ? service.prorate: null );
                            this.$set(p, 'prorate_out', service ? service.prorate_out: null );
                            this.$set(p, 'start_date', service ? service.start_date: moment().format('YYYY-MM-DD') );
                            this.$set(p, 'Service', service || {});

                            // if(service){
                            //     this.$set(p, 'activated', true);
                            //
                            //     // p.Service.id = service.id;
                            //     // p.Service.name = service.name;
                            //     // p.Service.description = service.description;
                            //     // p.Service.qty = service.qty;
                            //     // p.Service.price = service.price;
                            //     // p.Service.recurring = service.recurring;
                            //     // p.Service.prorate = service.prorate;
                            //     // p.Service.start_date = service.start_date;
                            // } else {
                            //     this.$set(p.Service, 'name', p.name);
                            //     this.$set(p.Service, 'description', p.description);
                            //     this.$set(p.Service, 'qty', 1);
                            //     this.$set(p.Service, 'price', p.price || 0);
                            //     this.$set(p.Service, 'recurring', 0);
                            //     this.$set(p.Service, 'prorate', 0);
                            //     this.$set(p.Service, 'start_date',moment().format('YYYY-MM-DD'));
                            //
                            //     // p.Service.name = p.name;
                            //     // p.Service.description = p.description;
                            //     // p.Service.qty = 1;
                            //     // p.Service.price = p.price || 0;
                            //     // p.Service.recurring = 0;
                            //     // p.Service.prorate = 0;
                            //     // p.Service.start_date = moment().format('YYYY-MM-DD');
                            // }

                            return p;
                        })

                }


            },
            headers () {
                return [
                {
                    text:'',
                    value: 'activated'
                },
                {
                    text: 'Name',
                    align: 'start',
                    sortable: false,
                    value: 'name',
                },
                {
                    text: 'Service Start',
                    value: 'start_date',
                },
                { text: 'Details', value: 'recurring' },
                { text: 'Price', value: 'service_price' },
                ]
            },
        },
        methods: {
            async fetchFees(){
                let r = await api.get(this, api.PROPERTIES + this.property.id + '/products', {
                    type: 'late',
                    category_type: 'movein'
                });

                this.products = r.products;
                this.defualt_products = JSON.parse(JSON.stringify(r.products));

                console.log("PRODUCTS_BEFORE", this.defualt_products);
                 
                let existingServices = await api.get(this, api.LEASES + this.lease.id + '/services/' );

                this.currentRent = existingServices.services.find(service => {
                    if(service.Product.default_type === "rent") return service
                }) 

                

                this.products.forEach(p => {
                    console.log("CurrentProduct", p);
                    let service = existingServices.services.find((el,idx) => el.product_id === p.id);
                    if(service){
                        this.$set(p, 'activated', !!service);
                        this.$set(p, 'qty', service ? 1 : null);
                        this.$set(p, 'service_price', service ? service.price : p.price);
                        this.$set(p, 'recurring', service ? service.recurring: null );
                        this.$set(p, 'prorate', service ? service.prorate: null );
                        this.$set(p, 'prorate_out', service ? service.prorate_out: null );
                        this.$set(p, 'start_date', service ? service.start_date: moment().format('YYYY-MM-DD') );
                        this.$set(p, 'Service', service || {});
                    }
                });

                console.log("PRODUCTS_AFTER", this.products);
                
            },


            async updateService(product){
                // && !this.updated

              if(product.activated){
                  console.log("PRODUCT_SAVE", product );
                  await this.saveService(product)
              } else {
                  console.log("PRODUCT_DELETE", product );
                  await this.removeService(product)
              }
            },
            async saveService(product){
                let status =  await this.validate(this);
                if(!status) return;
                if(!product.price){                   
                    this.showMessageNotification({ type: 'error', description: 'Please enter a valid Price.' });
                    return;   
                };
                let r = await api.get(this, api.LEASES + this.lease.id + '/services/' );
                let service = r.services.find((el,idx) => el.product_id === product.id);
                let defualt_product = this.defualt_products.find(x=> x.id === product.id);
                var data = {
                    name: product.name,
                    price: product.service_price || 0,
                    qty: 1,
                    recurring: !!product.recurring,
                    prorate: !!(product.recurring && defualt_product.prorate),
                    prorate_out: !!(product.recurring && defualt_product.prorate_out),
                    description: product.description,
                    start_date: product.start_date,
                    end_date: null,
                    taxable:product.taxable || 0
                };
                //let r = {};

                // console.log("data", data);
                if(!service){
                    data.product_id = product.id;
                    await this.$store.dispatch('onBoardingStore/saveService', data);
                    EventBus.$emit('updatePaymentDetails');
                    EventBus.$emit('updatePartialPaymentAmount');
                } else {
                    await this.$store.dispatch('onBoardingStore/updateService', {data: data, service_id: service.id});
                    EventBus.$emit('updatePaymentDetails');
                    EventBus.$emit('updatePartialPaymentAmount');
                }
            },



            async removeService(product){

                //if(!product.Service.id) return;
                let r = await api.get(this, api.LEASES + this.lease.id + '/services/' );
                let service = r.services.find((el,idx) => el.product_id === product.id);
                if(!service && !service.id) return;
                product.activated = false;
                //await this.$store.dispatch('onBoardingStore/deleteService', {service_id: product.Service.id});
                await this.$store.dispatch('onBoardingStore/deleteService', {service_id: service.id});
                EventBus.$emit('updatePaymentDetails');
                EventBus.$emit('updatePartialPaymentAmount');
                // let r = await api.delete(this, api.SERVICES, product.Service.id);
                // await this.$store.dispatch('onBoardingStore/getServices');
                // await this.$store.dispatch('onBoardingStore/getEstimate');
            },
            async removeCheck(product){
                let service = this.filtered_products.find((el,idx) => el.id === product.Product.id);
                service.activated = false;
                if(service.recurring) service.recurring = false;
            },

            isVariableRate(rate_type) {
                return rate_type === 'variable';
            }
        },
        destroyed () {
            EventBus.$off('remove_check', this.removeCheck);
        }
    }
</script>

<style scoped>

    .insurance-container{
        background: white;
        border-radius: 4px;
        padding: 4px 18px;
    }

    .insurance-container .v-input--radio-group--column{
        margin: 0;
    }
    .insurance-container .insurance-row {
        margin-bottom: 10px;

    }
    .insurance-option-row.no-border{
        border-bottom: none;
    }
    .merchandise-row{
        border-bottom: 1px solid #C4CDD5;
    }
    .merchandise-row:last-child{
        border-bottom: none;
    }


    .insurance-container .v-input--selection-controls.v-input--checkbox {
        margin-top: 0;
    }



</style>
