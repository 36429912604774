<!-- PLEASE DO NOT EDIT THIS FILE -->
<!-- PLEASE DO NOT EDIT THIS FILE -->
<!-- PLEASE DO NOT EDIT THIS FILE -->
<!-- PLEASE DO NOT EDIT THIS FILE -->
<!-- PLEASE DO NOT EDIT THIS FILE -->
<!-- PLEASE DO NOT EDIT THIS FILE -->
<!-- PLEASE DO NOT EDIT THIS FILE -->
<!-- PLEASE DO NOT EDIT THIS FILE -->
<!-- PLEASE DO NOT EDIT THIS FILE -->
<!-- PLEASE DO NOT EDIT THIS FILE -->
<!-- PLEASE DO NOT EDIT THIS FILE -->
<!-- PLEASE DO NOT EDIT THIS FILE -->
<template>
    <div class="success-error-wrapper">
        <v-snackbar
            v-model="snackbar"
            light
            right
            top
            :color="backgroundColor"
            transition="slide-x-reverse-transition"
            :timeout="setTimeout"
            v-bind="$attrs"
            v-on="$listeners"
        >
            <div class="global-inner-wrapper" :class="setLoading ? 'pt-4' : 'py-4'">
                <v-row no-gutters class="mt-0 pb-3">
                    <template v-if="setTime">
                        <v-col cols="6" class="pl-4 d-flex align-center hb-text-truncate">
                            <hb-icon :color="iconColor">{{ setIcon }}</hb-icon>
                            <span class="success-error-title font-weight-medium pl-2 hb-text-truncate">{{ setTitle }}</span>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="4" class="my-n1 hb-text-truncate font-weight-medium pt-3" align="left">
                            {{ setTime | formatDateTimeNotifications }}
                        </v-col>
                    </template>
                    <template v-else>
                        <v-col cols="10" class="pl-4 d-flex align-center">
                            <hb-icon :color="iconColor">{{ setIcon }}</hb-icon>
                            <span class="success-error-title font-weight-medium pl-2">{{ setTitle }}</span>
                        </v-col>
                    </template>
                    <v-spacer></v-spacer>
                    <v-col cols="2" class="my-n1 pr-2" align="right">
                        <hb-btn icon tooltip="Close" @click="close" active-state-off :color="closeColor" :hover-background-color="closeHoverColor">mdi-close</hb-btn>
                    </v-col>
                </v-row>
                <v-divider v-if="dividerColor" :style="'border:1px solid' + dividerColor"></v-divider>
                <div class="px-4 success-error-content pt-4" @click="handleEventClick">
                    <div class="font-weight-medium hb-text-night hb-text-truncate mb-1" v-if="setSubject">
                        {{ setSubject }}
                    </div>
                    <div class="success-error-description">
                        {{ description }}
                    </div>
                    <ul style="list-style-type:disc;" v-if="list && list.length">
                        <li v-for="(e, i) in list" class="mt-1 mb-0" :key="'error' + i">{{ e.msg ? e.msg : e.message ? e.message : e }}</li>
                    </ul>
                    <div v-if="hasNoteSlot">
                        <slot name="note"></slot>
                    </div>
                </div>
            </div>
            <div v-if="hasFooterSlot">
                <slot name="footer"></slot>
            </div>
            <div v-if="hasProgressSlot">
                <slot name="progress"></slot>
            </div>
            <div v-else>
                <v-progress-linear v-if="setLoading" :active="setLoading" :indeterminate="true" color="primary" height="2" class="mt-2 mb-1" />
            </div>
        </v-snackbar>
    </div>
</template>
<script type="text/babel">

    import HbIcon from '../../aviary/HbIcon.vue';
    import HbBtn from '../../aviary/HbBtn.vue';
    
    export default {
        name: "GlobalNotification",
        data: function() {
            return {
                editing: false,
                hovering: false,
            };
        },
        components: {
            HbIcon,
            HbBtn
        },
        computed: {
            snackbar: {
                get () {
                    return this.value
                },
                set(value) {
                    if (!value) {
                        this.$emit('close');
                    }
                }
            },
            hasDefaultSlot(){
                return !!this.$slots['default'];
            },
            hasProgressSlot(){
                return !!this.$slots['progress']
            },
            hasFooterSlot(){
                return !!this.$slots['footer'];
            },
            hasNoteSlot() {
                return !!this.$slots['note'];
            },
            backgroundColor() {
                if(this.notification && this.notification.backgroundColor) {
                    return this.notification.backgroundColor;
                }
            },
            dividerColor() {
                if(this.notification && this.notification.dividerColor) {
                    return this.notification.dividerColor;
                }
            },
            iconColor() {
                if(this.notification && this.notification.iconColor) {
                    return this.notification.iconColor;
                }
            },
            closeHoverColor() {
                if(this.notification && this.notification.closeHoverColor) {
                    return this.notification.closeHoverColor;
                }
            },
            closeColor() {
                if(this.notification && this.notification.closeColor) {
                    return this.notification.closeColor;
                }
            },
            setTimeout() {
                if(this.notification && this.notification.persistent) {
                    return '-1';
                } else if(this.notification && this.notification.timeout) {
                    return this.notification.timeout;
                }
            },
            setIcon() {
                if(this.notification && this.notification.icon) {
                    return this.notification.icon;
                }
            },
            setTitle() {
                if(this.notification && this.notification.title) {
                    return this.notification.title;
                }
            },
            setTime() {
                if(this.notification && this.notification.time) {
                    return this.notification.time;
                }
            },
            setSubject() {
                if(this.notification && this.notification.subject) {
                    return this.notification.subject;
                }
            },
            setLoading() {
                if(this.notification && this.notification.loading) {
                    return this.notification.loading;
                }
                return false;
            }
        },
        methods: {
            close(){
                this.snackbar = false;
                this.$emit('close');
            },
            handleEventClick() {
                this.$emit('notificationClick');
            }
        },
        props: {
            value:{
                type: Boolean,
                default: false
            },
            type: {
                type: String,
                default: undefined
            },
            description: {
                type: String,
                default: undefined
            },
            list: {
                type: [Array, Object],
            },
            icon: {
                type: String,
                default: undefined
            },
            complete: {
                type: Boolean,
                default: false
            },
            notification: {
                type: Object
            }
        },
    }
</script>

<style scoped>

.success-error-wrapper >>> .v-snack__wrapper {
    box-shadow: 0px 2px 12px rgba(71, 84, 116, 0.2) !important;
}

.success-error-wrapper .v-snack__wrapper * {
    max-width: 328px !important;
    letter-spacing: normal !important;
    word-break: break-word !important;
}

.success-error-wrapper >>> .v-snack__wrapper {
    margin: 8px !important;
}

.success-error-title {
    font-size: 16px;
    color: #101318;
    line-height: 24px;
}

.success-error-description {
    font-size: 12px;
    line-height: 16px;
    color: #101318;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
}

.success-error-content {
    font-size: 12px;
    line-height: 16px;
    color: #101318;
    width: 328px !important;
    cursor: pointer;
}

.success-error-wrapper .global-inner-wrapper {
    width: 328px;
}
    
</style>

<style>

.success-error-wrapper .v-snack__wrapper {
    min-width: 328px !important;
    max-width: 328px !important;
}

.success-error-wrapper .v-sheet.v-snack__wrapper:not(.v-sheet--outlined) {
    top: 0 !important;
}

.success-error-notifications .success-error-wrapper .v-snack:not(.v-snack--absolute){
    justify-content: center !important;
}

</style>