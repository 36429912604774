<template>


    <div class="pa-0 d-flex" style="flex: 1; overflow: auto; flex-direction: column; align-items: stretch; width: 100%;" @scroll.self="searchNextList">
        <div v-if="tasksLoading && !tasks.length">
            <v-skeleton-loader
                v-for="i in 3"
                :key="i"
                type="list-item-avatar-two-line"
                class="mx-auto"
            ></v-skeleton-loader>
        </div>
        

        <div v-else-if="activeSection.name != 'all' && !subcategory && subcategories.length" >
            
            <v-row class="border-bottom ma-0 px-2"  v-for="s in subcategories" :key="s.id">
                <v-col cols="7"  class="pr-0 d-flex" style=" flex-direction: column;">
                    <div style="flex: 1">
                        <div class="col-12 pa-0">
                            <div class="list-title pb-1 d-flex align-center">
                                <hb-icon class="pr-2" :class="s.slug" color="#101318">{{ getIcon(s.icon) }}</hb-icon>{{ s.name }}
                            </div>
                            
                            <p class="text-subtitle-2 ellipse-text">You have {{count(s.slug)}} {{s.name}} tasks to complete</p>
                        </div>
                    </div>
                </v-col>    
                <v-col cols="5" class="text-right px-2 d-flex" style=" flex-direction: column;">
                    <div style="flex: 1" class="text-right">
                    </div>
                    <div style="flex: 0 1 " class="text-right">
                        <hb-btn color="secondary" small class="font-weight-regular" @click="selectSubcategory(s)">View Tasks</hb-btn>
                    </div>
                </v-col>
            </v-row>
        </div>

        <div v-else-if="!tasksLoading && !tasks.length && !filters.length && !numTasks" >
            <v-row class="mx-0 pa-3" >
                <v-col md="12" class="text-center">
                    <div class="no-task-message"><v-icon class="move-up-icon" size="30" color="#02AD0F">mdi-check</v-icon> Great Job! You’re all done</div>
                </v-col>
            </v-row>
        </div>    
        <div v-else-if="filters.length && !tasks.length && !tasksLoading">
            <v-row>
                <v-col md="12" class="text-center">
                    <div class="no-task-message">No Results Found</div>
                </v-col>
            </v-row>
        </div>

         <!-- <div :style="{ maxHeight: elHeight +'px', overflow: 'auto' }"> -->
        
        <div :style="{ maxHeight: scrollheightDynamic +'px' }" v-else-if="activeSection.name === 'all' || subcategory" >
            <template v-for="(task,index) in tasks" >
                    
                <task-item 
                    :task="task" 
                    :key="index" 
                    @markIncomplete="markIncomplete" 
                    @completeTask="markCompeleted" 
                    @dismissTask="markDismissed"
                ></task-item>
                </template>

                <div v-if="tasksLoading && tasks.length" class="text-center">
                    <v-progress-circular
                        indeterminate
                        color="primary"
                        ></v-progress-circular>
                        Loading...
                </div>
        </div>



                <!-- <v-row class="border-bottom-row ma-0" v-for="(task,index) in tasksList" @click.stop="task.event_type.can_dismiss ? markCompeleted(task) : ''" :key="index" :class="{'blurred-row' : (task.todo && task.todo.completed) || markedTasks.includes(task.todo.id), 'hand' : task.event_type.can_dismiss }">
                    <v-col md="2" class="check-item-outer d-flex justify-center mxw-83px">
                        <div class="check-item-circle mx-auto" :class="{'checked' : (task.todo && task.todo.completed) || markedTasks.includes(task.todo.id), 'dashed-border' : !task.event_type.can_dismiss }">
                            <v-icon v-if="task.event_type.can_dismiss" color="#fff">mdi-check</v-icon>
                            <v-tooltip bottom v-else>
                                <template v-slot:activator="{ on }">
                                    <span class="auto-complete-sign" v-on="on">A</span>
                                </template>
                                <span>Auto Complete</span>
                            </v-tooltip>
                        </div>
                    </v-col>
                    <v-col cols="10" class="pa-0">
                        <task-item :event="task.event_type" :todo="task.todo" :activeView.sync="activeView" :contact="task.contact" :unit="task.unit" :key="index" :index="index" :lease_id="task.lease_id" :created_date="task.todo && task.todo.original_date ? task.todo.original_date : ''" :group_id="task.todo.Event && task.todo.Event.group_id ? task.todo.Event.group_id : ''" @showList="showGroupedList" @HB-TaskItem--action="taskItemAction" :fetchTasks="fetchTasks"></task-item>
                    </v-col>
                </v-row> -->

                <!-- <v-row class="mx-0 border-bottom-row no-border-bottom" v-if="isLoading($options.name)">
                    <v-col md="12" class="text-center">
                        <v-progress-circular width="2" size="32" indeterminate color="primary" ></v-progress-circular>
                    </v-col>
                </v-row> -->

        <date-range @close="showDateRange = false" v-if="showDateRange" />
        <to-do v-model="showDialog" v-if="showDialog" @close="fetchTasks"></to-do>

        <hb-modal
            size="medium"
            :title="dismissType === 'dismiss' ?  'Dismiss Task' : 'Complete Task' "
            v-model="showDismissModal"
            confirmation
            show-help-link
        >
            <template v-slot:content>
                <div class="pa-5" v-show="dismissType === 'dismiss'">Are you sure you want to dismiss this task?</div>
                <div class="pa-5" v-show="dismissType === 'complete'">Are you sure you want to mark this task as complete?</div>
            </template>
            <template v-slot:actions>
                <hb-btn color="primary" @click="confirmComplete" v-show="dismissType === 'dismiss'">Dismiss</hb-btn>
                <hb-btn color="primary" @click="confirmComplete" v-show="dismissType === 'complete'">Complete</hb-btn>
            </template>
        </hb-modal>


    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import TaskItem from './TaskItem';
import ToDo from "../Todo.vue";
import DateRange from './DateRange.vue'
import api from '../../../assets/api.js'
import { EventBus } from '../../../EventBus.js';
import ViewPaymentContentVue from '../../payments/ViewPaymentContent.vue';
import moment from 'moment';

export default {
    name: "TaskList",
    components: { ToDo, TaskItem, DateRange },
    props: ['activeView', 'pendingMoveIn'],
    data() {
        return {
            showDialog: false,
            timeRange:[{
                title: "Yoday",
            },{
                title: "Yesterday"
            },{
                title: "This Week"
            },{
                title: "Last Month"
            },{
                title: "All Time"
            },{
                title: "Date Range"
            }],
            showDateRange: false,
            tempTask: [],
            isScrollLoading: false,
            filterEvents: [],

            tasksList: [],
            showCompleted: false,
            showLoader: false,
            isScrollRequest: false,
            tempFilters: [],
            allowScrollRequest: false,
            showCompleteAllModal: false,
            showDismissModal: false,
            dismissType: '',
            scrollheightDynamic:0,
            selectedTask: {}, 

        }
    },
    created() {
    
        this.tempFilters = this.filters;  
        // this.resetTasks();
        this.setMoveInTasks();
        this.scrollHeights();
        // this.getTaskData(true);
        //this.getEventTypes('task');
    },
    
    computed: {
        ...mapGetters({
            is_open:'taskCenterStore/tasksCenterOpen',
            tasks:'taskCenterStore/tasks',
            eventTypes: 'taskCenterStore/eventTypes', 
            filters: 'taskCenterStore/filters', 
            view: 'taskCenterStore/view',
            activeSection: 'taskCenterStore/activeSection', 
            tasksCount: 'taskCenterStore/tasksCount',
            subcategory: 'taskCenterStore/subcategory', 
            tasksLoading: 'taskCenterStore/tasksLoading',
            numTasks: 'taskCenterStore/numTasks',
            allowScroll:  'taskCenterStore/allowScroll',
            activeDrawerComponent: 'navigationStore/activeDrawerComponent'
        }),
        // numTasks(){
            
        //     if(this.filters.length){
        //         let count = this.filters.reduce((a,b) => {
        
        //             let event_type  = this.eventTypes.find(et => et.id === b); 
            
        //             if(!event_type || !this.tasksCount[event_type.slug]) return a; 
        
        //             return a + this.tasksCount[event_type.slug].count
        //         }, 0);    
        //         return count;
        //     }
                
        //     if(this.tasksCount && this.tasksCount[this.activeSection.name]){
        //         return this.tasksCount[this.activeSection.name].count; 
        //     }
        // },
        currentDate(){
            return  moment().format('M/DD/YYYY'); 
        },
        // elHeight(){
        //     let pHeight  = document.documentElement.clientHeight;
        //     let elHeight = pHeight - 265;
        //     return elHeight;
        // },
        subcategories(){
            if(!this.activeSection || !this.activeSection.task_categories) return [];
            return this.activeSection.task_categories.map(cat => this.eventTypes.find(et => et.slug === cat));
        }, 
        // formattedTasks(){
        //     this.tempTask = [...this.tempTask, ...this.tasks];
        //     let tasksArr = [];
        //     if (this.tempTask && this.tempTask.length) {
        //         this.tempTask.forEach( (sTask, index) => {
        //             let task = this.validateTask(sTask);
        //             if (task) {
        //                 tasksArr.push(task)
        //             }
        //         });
        //     }
        //     return tasksArr;
        // },
 
        // uniqueEvents(){
        //     let arr = [];
        //     this.eventTypes.forEach((el, index) => {
        //        let found = arr.find( a => a.name == el.name );
        //        if (!found) {
        //            arr.push( { id: el.id, name: el.name } )
        //        }
        //     });
        //     return arr.sort((a, b) => a.name.localeCompare(b.name));
        // },
        // isLockCategory(){
        //     if(!this.subcategory) return false;
        //     let et = this.eventTypes.find(et => et.id === this.subcategory);
        //    return et.slug === "overlock_space" || et.slug === "lock_removal"
        // },
        checkPendingMoveIns(){
            return this.pendingMoveIn && this.pendingMoveIn.length ? this.pendingMoveIn : [];
        }
    },
    methods: {
        ...mapActions({
            getTasks: 'taskCenterStore/getTasks',
            getGroupTasks: 'taskCenterStore/getGroupTasks',
            getEventTypes: 'taskCenterStore/getEventTypes',
            resetTasks: 'taskCenterStore/resetTasks', 
            setLoading: 'taskCenterStore/setLoading', 
            setSubcategory: 'taskCenterStore/setSubcategory',
            setFilters: 'taskCenterStore/setFilters',
            setTaskCounts: 'taskCenterStore/setTaskCounts', 
            markTask:  'taskCenterStore/markTask',
            setActiveDrawerComponent: 'navigationStore/setActiveDrawerComponent'
        }),
        selectSubcategory(event_type){
            console.log("event_type", event_type)
            this.setSubcategory(event_type.id)
            this.getTasks({
                reset: true,
                role_ids: this.rolesIDs
            });
            this.setActiveDrawerComponent({ type: 'show_task_center', ...this.activeDrawerComponent, sub_category: event_type.id })
            
        },
        // markAsRead(index){
        //     if (this.markedTasks.includes(index)) {
        //         this.markedTasks.splice(this.markedTasks.indexOf(index), 1);
        //     } else {
        //         this.markedTasks.push(index)
        //     }
        // },

        scrollHeights() {
            let documentHeight = document.documentElement.clientHeight; 
            if (documentHeight <= 768) { 
                this.scrollheightDynamic = documentHeight - 110;
            }else{ 
                this.scrollheightDynamic = documentHeight;
            }
        },
        downloadReport(){

        }, 
         count(key){
                
            let count = this.tasksCount[key] && this.tasksCount[key].count;
            return count || 0;

        },
        taskItemAction(action){
            console.log('Clicked',action);
        },
        getIcon(value){
                let icon = '';
                if (value && value.includes('Google Icon:')) {
                    icon = value.replace('Google Icon:', '')
                } else {
                    icon = value
                }
                return icon;
            },
        validateTask(taskData){
            if (taskData && taskData.task_type == 'moveIn') {
                let data = {
                    event_type: taskData,
                    contact: taskData.contact,
                    unit: taskData.unit,
                    todo: {
                        id: taskData.id
                    }
                }
                return data;
            } else {
                let taskObj = {
                    todo: taskData.task
                };
                taskObj.todo.count = taskData.count;
                let task    = taskData.task;

                if (taskData.type === 'group' && taskData.Tasks && taskData.Tasks.length) {
                    taskObj.grouped_tasks = taskData.Tasks;
                }

                if (
                    task
                    && Object.keys(task).length > 0
                    && task.Event
                    && Object.keys(task.Event).length
                    && task.Event.event_type
                    && Object.keys(task.Event.event_type).length) {
                        taskObj.event_type = task.Event.event_type;
                        if (task.Event.Contact && Object.keys(task.Event.Contact).length) {
                            taskObj.contact = task.Event.Contact;
                        }
                        if (task.Event.Lease) {
                            taskObj.unit = task.Event.Lease.Unit;
                            taskObj.lease_id = task.Event.Lease.id;
                            if (!taskObj.contact && task.Event.Lease.Tenants && task.Event.Lease.Tenants.length && task.Event.Lease.Tenants[0].Contact) {
                                taskObj.contact = task.Event.Lease.Tenants[0].Contact;
                            }
                        }
                }
                return ( !taskObj.grouped_tasks && Object.keys(taskObj).length > 0 ) || ( taskObj.grouped_tasks && Object.keys(taskObj).length > 1 ) ? taskObj : null;
            }
        },
        async showGroupedList(action){
            let data = {
                groupId : action.groupId,
                params: `?limit=20&offset=0`,
                role_ids: this.rolesIDs
            }

            await this.getGroupTasks(data).then(response => {
                this.$emit('setSeeListView', action.count);
            });
        },

        markCompeleted(todo){
            this.showDismissModal = true
            this.dismissType = 'complete'
            this.selectedTask = todo; 
        }, 

        markDismissed(task){
            this.showDismissModal = true
            this.dismissType = 'dismiss'
            this.selectedTask = task; 
        }, 

        async markIncomplete(task){
           let todo = task; 
           let r = await api.put(this, api.TODOS + 'mark-incomplete', { todos: [todo] });
            this.markTask({task_id: todo.id, complete: false});
            this.setTaskCounts({ role_ids: this.rolesIDs });
            this.selectedTask =  {};
            this.dismissType = ''; 
            this.showDismissModal = false;
            EventBus.$emit('reset_profile_tasks');
        }, 

        async confirmComplete(){
            
            let todo = this.selectedTask; 
            
            if (todo.completed) return; 
            
            // TODO replace when we have the ability to dismiss and complete tasks
            this.dismissType = 'dismiss';

            let r = await api.put(this, api.TODOS + this.dismissType, { todos: [todo] }, 'none'); 
            
            this.markTask({task_id: todo.id, complete: true});
            // if (this.markedTasks.includes(todo.id)) {
            //     this.markedTasks.splice(this.markedTasks.indexOf(todo.id), 1);
            // } else {
            //     this.markedTasks.push(todo.id)
            // }
            this.setTaskCounts({ role_ids: this.rolesIDs });
            this.selectedTask =  {};
            this.dismissType = ''; 
            this.showDismissModal = false;
            EventBus.$emit('reset_profile_tasks');

        
        },
        // markDismissed(todo){
            
        //     if (!todo.completed) {
        //         api.put(this, api.TODOS + 'dismiss', { todos: [todo] }, 'none').then( r => {
        //             if (this.markedTasks.includes(todo.id)) {
        //                 this.markedTasks.splice(this.markedTasks.indexOf(todo.id), 1);
        //             } else {
        //                 this.markedTasks.push(todo.id)
        //             }
        //             this.$emit('fetchCounts');
        //         })
        //     }
        // },

        searchNextList({ target: { scrollTop, clientHeight, scrollHeight }}){
            
            if (scrollTop + clientHeight >= scrollHeight) {
                console.log("his.allowScroll", this.allowScroll)
                if(this.tasksLoading || !this.allowScroll) return;
                
                let total  = this.pendingMoveIn ? parseInt(this.tasks.length - this.pendingMoveIn.length) : this.tasks.length;
                
                console.log("searchNextList total", total)

                let data = {
                    reset: false, 
                    offset: total, 
                    limit: 20,
                    role_ids: this.rolesIDs
                }
                this.getTasks(data); 
            }
        },
        
        // searchNextList({ target: { scrollTop, clientHeight, scrollHeight }}){
 
        //     if (scrollTop + clientHeight >= scrollHeight) {
        //         let offset = 0;
        //         let total  = this.pendingMoveIn ? parseInt(this.totalTasks - this.pendingMoveIn.length) : this.totalTasks;
        //         if (this.filters.length) {
        //             this.isScrollRequest = true;
        //             offset = (this.tasksList.length%20);
        //         } else {
        //             this.isScrollRequest = false;
        //             offset = ((this.tasksList.length - this.pendingMoveIn.length)%20);
        //         }
        //         if (offset === 0 && !this.isLoading(this.$options.name) && total > 0 && this.allowScrollRequest) {
        //             this.showLoader = true;
        //             this.getTaskData();
        //         }
        //     }
        // },
        async getTaskData(fetchCounts){
              
            let offset = 0;
            
            // if (!this.isScrollRequest) {
            //     this.resetLocalTasks();
            // }

            if (!this.filters.length) {
                // if no filters, include move in tasks
                this.setMoveInTasks();
            }

            // if (fetchCounts) {
            //     this.$emit('fetchCounts', this.filters);
            // }
        
            // if (this.filters.length && this.isScrollRequest) {
            //     offset = (this.tasksList.length);
            // } else {
            //     offset = (this.tasksList.length - this.pendingMoveIn.length);
            // }
            // let data = {
            //     component: this,
            //     params: `?limit=20&offset=${ offset > 0 ? offset : 0 }`
            // }
            
            try {
                // if(!this.isScrollRequest){
                //     this.resetTasks();
                // } else {
        
                // }
                console.log(this.tasks.length);
                await this.getTasks({
                    reset: !this.isScrollRequest,
                    role_ids: this.rolesIDs
                });

                // if (this.tasks.length == 0 && this.totalTasks === 0) {
                //     this.showCompleted = true;
                // } else {
                //     this.showCompleted = false;
                // }

                // if (r.tasks.length == 0) {
                //     this.allowScrollRequest = false;
                // } else {
                //     this.allowScrollRequest = true;
                // }
                // this.showLoader = false;
                // this.tempTask = [...this.tempTask, ...r.tasks];
                // if (this.tempTask && this.tempTask.length) {
                //     this.tempTask.forEach( (sTask, index) => {
                //         let task = this.validateTask(sTask);
                //         if (task) {
                //             this.tasksList.push(task);
                //         }
                //     });
                // }
                // this.tempTask = [];
                
                
            } catch(err){
                  console.log("err",err)
            }

        },
        // applyFilters(fetchCounts = false){
        //      console.log("this.tempFilters", this.tempFilters)
        //     this.showFilters = false;
        //     this.setFilters(this.tempFilters);
        //     this.getTaskData(fetchCounts);
        // },
        // getEventName(id){
        //     return this.uniqueEvents.find(e => e.id == id).name;
        // },
        refetchData(id){
            
            this.setFilters(this.filters.filter(f => f !== id));
            this.isScrollRequest = false;
            this.getTaskData(true); 
        },



        setMoveInTasks(){
            let pending = this.pendingMoveIn && this.pendingMoveIn.length ? this.pendingMoveIn : [];
            if (pending.length) {
                this.tempTask = this.tempTask.concat(pending);
            }
        },
        fetchTasks(flag = true){
            this.showDialog = false;
            this.isScrollRequest = false;
            if(flag)  {
                this.getEventTypes('task');
            }
            this.getTaskData();
            this.$emit('fetchCounts');
        },
        resetLocalTasks(){
            this.tempTask  = [];
            this.tasksList = [];
        },
        // closeFilter(){
        //     this.showFilters = false;
        //     this.tempFilters = [];
        // },
        showTaskDialog(){
            this.closeFilter();
            setTimeout(() => {
                this.showDialog = true;
            }, 20);
        }
    },
    watch: {
        // 'pendingMoveIn'(value){
        //     let pending = value && value.length ? value : [];
        //     if (pending.length) {
        //         this.setMoveInTasks();
        //         if (!this.isLoading(this.$options.name)) {
        //             this.getTaskData();
        //         }
        //     }
        // },
        // tasks(){
        //     this.localTasks.tasks = [];
        //     this.$set(this.localTasks, 'tasks', []); 
        //     this.$set(this.localTasks, 'tasks', this.tasks); 
        // }
    }
}
</script>

<style lang="scss" scoped>
    .border-bottom-row {
        &:not(:first-child) {
            border-top: 1px solid #DFE3E8;
        }
        &:last-child{
            border-bottom: 1px solid #DFE3E8;
        }
    }
    // .tasks-counter{
    //     background-color: #F26500;

    //     > div{
    //         font-style: normal;
    //         font-weight: 500;
    //         font-size: 28px;
    //         line-height: 14px;
    //         color: #fff;

    //         small {
    //             font-style: normal;
    //             font-size: 10px;
    //             line-height: 16px;
    //             font-weight: 500;
    //         }
    //     }
    // }
    // .task-calender{
    //     font-weight: 500;
    //     font-size: 20px;
    //     line-height: 24px;
    //     color: #101318;
    // }
    .check-item-outer{
        border-right: 1px solid #C4CDD5;
    }
    .check-item-circle{
        width: 32px;
        height: 32px;
        left: 27px;
        top: 16px;
        border: 1px solid #C4CDD5;
        box-sizing: border-box;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .check-item-circle.checked{
        background-color: #02AD0F;
        z-index: 11;
    }
    .list-title{
        font-weight: 500;
        font-size: 14px;
        color: #101318;
        line-height: 18px;
    }
    .list-title:first-of-type + p {
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: #637381;
        margin-bottom: 0;
        padding-bottom: 5px;
    }
    .follow-timer{
        font-size: 12px;
        line-height: 16px;
        text-align: right;
        color: #02AD0F;
    }
    .list-links a{
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #306FB6;
    }
    .move-up-icon{
        position: relative;
        top: -3px;
    }
    .move-out-date{
        font-size: 12px;
        line-height: 16px;
        color: #637381;
    }
    .min-h-35px{
        min-height: 35px;
    }
    .blurred-row{
        position: relative;
        z-index: 1000;
        &::after{
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background: #E6F7E7;
            opacity: 0.6;
        }
    }
    .no-task-message{
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #101318;
    }
    .no-border-bottom{
        border-bottom: 0px solid !important;
    }
    .mxw-83px{
        max-width: 83px;
    }
    .auto-complete-sign{
        font-size: medium;
        font-weight: 500;
        line-height: 0px;
        color: #C4CDD5;
    }
    .dashed-border{
        border: 1px dashed #C4CDD5 !important;
    }

    .list_icon{
        margin-top: 0px;
        vertical-align: middle;
    }

</style>
