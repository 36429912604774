<template>
  <div class="tpa-wrapper">
    <hb-blade-header
      title="Accounting Template"
      back-button-off
      @close="discardSetup = true"
    >
    </hb-blade-header>
    <div class="tpa-stepper">
      <hb-stepper v-model="step">
        <hb-stepper-header>
          <hb-stepper-step step="1" @click="goToStep(1)">
            General
          </hb-stepper-step>
          <v-divider></v-divider>
          <hb-stepper-step step="2" @click="goToStep(2)">
            Default COA
          </hb-stepper-step>
          <v-divider></v-divider>
          <hb-stepper-step step="3" @click="goToStep(3)">
            Journal Templates
          </hb-stepper-step>
        </hb-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1" class="pb-14">
            <v-expansion-panels v-model="panel" multiple class="mb-6">
              <hb-expansion-panel :topMargin="false">
                <template v-slot:title>General</template>
                <template v-slot:content>
                  <hb-form label="Template Name" required large>
                    <div class="fields-wrapper">
                      <v-row>
                        <v-col class="py-0 my-0">
                          <HbTextField
                            v-model="form.name"
                            v-validate.disable="'required|max:45'"
                            placeholder="Template Name"
                            data-vv-name="template_name"
                            data-vv-as="Template Name"
                            data-vv-scope="setup"
                            :error="
                              errors.collect('setup.template_name').length > 0
                            "
                          />
                        </v-col>
                      </v-row>
                    </div>
                  </hb-form>
                  <hb-form
                    label="Accounting Type"
                    required
                    large
                    description="Select the type of accounting your company uses"
                  >
                    <div class="fields-wrapper">
                      <v-row>
                        <v-col class="py-0 my-0">
                          <HbSelect
                            v-model="form.AccountingSetup.book_id"
                            :items="bookTypes"
                            item-text="name"
                            item-value="id"
                            v-validate.disable="'required'"
                            placeholder="Accounting Type"
                            data-vv-name="accounting_type"
                            data-vv-as="Accounting Type"
                            data-vv-scope="setup"
                            :clearable="false"
                            :disabled="hasPermission('select_accounting_book') ? false : true"
                            :error="
                              errors.collect('setup.accounting_type').length > 0
                            "
                          />
                        </v-col>
                      </v-row>
                    </div>
                  </hb-form>
                  <enable-properties-field
                    @selectedProperties="selectedProperties"
                    :selectedPropertiesList.sync="selectedPropertiesList"
                  />
                </template>
              </hb-expansion-panel>
            </v-expansion-panels>
          </v-stepper-content>
          <v-stepper-content step="2">
            <DefaultCOA ref="defaultCOARef"></DefaultCOA>
          </v-stepper-content>
          <v-stepper-content step="3">
            <v-expansion-panels v-model="panel1" multiple>
              <hb-expansion-panel class="mb-1" key="journal_template">
                <template v-slot:title> Journal Templates </template>
                <template v-slot:content>
                  <journal-template
                    class="mx-6 mb-10"
                  ></journal-template>
                </template>
              </hb-expansion-panel>
            </v-expansion-panels>
          </v-stepper-content>
        </v-stepper-items>
        <div class="footer-wrapper">
          <help-juice />
          <div class="d-flex justify-space-between float-right">
            <div>
              <hb-link v-if="step > 1" class="mr-5" @click="step--"
                >Back</hb-link
              >
              <hb-btn v-if="step == 1" color="primary" :disabled="isSaveAndContinue" :loading="isSaveAndContinue" @click="saveAndContinue"
                >Save & Continue</hb-btn
              >
              <hb-btn v-if="step == 2" color="primary" @click="goToStep(3)"
                >Next</hb-btn
              >
              <hb-btn v-if="step == 3" color="secondary" @click="$emit('close')"
                >Close</hb-btn
              >
            </div>
          </div>
        </div>
      </hb-stepper>
    </div>
    <DiscardSetup v-model="discardSetup" v-if="discardSetup" @close="confirmDiscardSetup"></DiscardSetup>
  </div>
</template>

<script type="text/babel">
import EnablePropertiesField from "../Common/EnablePropertiesField";
import DefaultCOA from "./DefaultCOA";
import JournalTemplate from "../Accounting/JournalTemplate.vue";
import DiscardSetup from "../../assets/DiscardSetup.vue";
import { notificationMixin } from "@/mixins/notificationMixin.js";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "AccountingTemplatesDrawer",
  data() {
    return {
      step: 1,
      panel: [0],
      panel1: [0],
      form: {
        name: null,
        AccountingSetup: {
          book_id: null,
        },
        Properties: [],
      },
      selectedPropertiesList: [],
      isSaveAndContinue: false,
      discardSetup: false,
      bookTypes: null
    };
  },
  mixins: [notificationMixin],
  props: ["selected"],
  components: {
    EnablePropertiesField,
    DefaultCOA,
    JournalTemplate,
    DiscardSetup
  },
  created() {
    if (this.isEditAccountingTemplate) {
      this.form = Object.assign({}, JSON.parse(JSON.stringify(this.selected)));
      if (this.selected.Properties.length) {
        this.transformSelectedProperties();
      }
    }
    this.setSelectedTemplate(this.selected);
    this.bookTypes = Object.values(this.accountingTypes);
    // this.$store.dispatch("navigationStore/setSettingsNavigationOnly", false);
  },
  destroyed() {
    this.setSelectedTemplate({});
    // this.$store.dispatch("navigationStore/setSettingsNavigationOnly", true);
  },
  computed: {
    ...mapGetters({
      selectedTemplate: "accountingStore/selectedTemplate",
      hasPermission: 'authenticationStore/rolePermission',
      accountingTypes: "accountingStore/accountingTypes"
    }),
    isEditAccountingTemplate() {
      return this.selected && this.selected.id ? true : false;
    },
  },
  methods: {
    ...mapActions({
      saveTemplate: "accountingStore/saveTemplate",
      setSelectedTemplate: "accountingStore/setSelectedTemplate"
    }),
    confirmDiscardSetup() {
      this.discardSetup = false;
      this.$emit('close');
    },
    async goToStep(val) {
      const valid = await this.$validator.validateAll("setup");
      if (!valid || this.step == val) return;
      if(!this.selectedTemplate?.id && !this.isEditAccountingTemplate) {
        this.showMessageNotification({
          description: "Save template to continue"
        });
        return;
      }
      if(val == 3 && this.step != 1) {
        await this.$refs.defaultCOARef.setEditMode();
        const valid = await this.$refs.defaultCOARef.$validator.validateAll("default_coa");
        if(!valid) return;
      } else {
        await this.$refs.defaultCOARef.$validator.reset();
      }
      this.step = val;
    },
    selectedProperties(properties) {
      this.form.Properties = properties.map((property_id) => {
        return {
          id: property_id,
        };
      });
    },
    transformSelectedProperties() {
      this.selectedPropertiesList = this.selected.Properties.map((p) => {
        return p.id;
      });
    },
    async saveAndContinue() {
      
      const valid = await this.$validator.validateAll("setup");
      if (!valid) return;
      this.isSaveAndContinue = true;
      try {
        let notification = this.selectedTemplate?.id ? "Template Updated Successfully" : "Template Created Successfully";
        await this.saveTemplate({
          id: this.selectedTemplate?.id ? this.selectedTemplate.id : null,
          name: this.form.name,
          AccountingSetup: { book_id: this.form.AccountingSetup.book_id },
          Properties: this.form.Properties,
        });
        this.showMessageNotification({
          type: "success",
          description: notification,
        });
        this.step = 2;
      } catch (error) {
        this.showMessageNotification({
          description: error,
        });
      }
      this.isSaveAndContinue = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.tpa-wrapper {
  position: relative;
  background-color: #f9fafb;
  height: 100%;

  .tpa-stepper {
    .v-stepper {
      background-color: #f9fafb;
      ::v-deep .v-expansion-panel {
        border: 1px solid #dfe3e8;
      }
    }
    .v-stepper__header {
      background-color: #ffffff;
    }
  }
  .tpa-stepper-content {
    background-color: #f9fafb;
    margin-bottom: 60px;
  }
}
.footer-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  padding: 12px 16px;
  box-shadow: inset 0px 1px 0px rgba(142, 142, 143, 0.15);
  width: 100%;
  border-top: 1px solid #dfe3e8;
  z-index: 1;
}
</style>
