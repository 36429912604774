<template>
  <hb-modal
    v-model="dialog"
    v-if="dialog"
    size="medium"
    :title="isPropertyClosed ? 'Undo Close of Day' : 'Close of Day'"
    confirmation
    show-help-link
  >
    <template v-slot:content>
      <v-row class="ma-0 pa-0">
        <v-col class="ma-0 pa-0 pt-4 px-6">
          <v-autocomplete
            id="facility"
            rounded
            :items="facilityList"
            item-text="name"
            item-value="id"
            v-validate="'required'"
            v-model.lazy="property_id"
            data-vv-name="facility"
            data-vv-as="Property"
            outlined
            dense
            single-line
            background-color="white"
            label="Select Property"
						:disabled="isPropertySet"
            :error="errors.has('facility')"
            :hide-details="!errors.collect('facility').length"
          ></v-autocomplete>
        </v-col>
      </v-row>
			<div class="mb-16 px-6 py-4" v-if="isPropertyClosed">
				You are about to undo the close of day. All transactions will be marked for today until you close the day again.
			</div>
      <div class="mb-16 px-6 py-4" v-else>
        You are about to close the day. Any new transactions made after
        completing this action will count towards the next day. Would you like
        to proceed?
      </div>
    </template>
    <template v-slot:actions>
			<hb-btn color="primary" @click="undoCloseOfDay" :disabled="!isPropertySet" v-if="isPropertyClosed">Undo</hb-btn>
      <hb-btn color="primary" @click="closeTheDay" :disabled="!isPropertySet" v-else>Close the Day</hb-btn>
    </template>
  </hb-modal>
</template>

<script type="text/babel">
import api from "../../../assets/api";
import { mapGetters, mapActions } from "vuex";
import { EventBus } from '../../../EventBus.js';
import { notificationMixin } from  '../../../mixins/notificationMixin.js';

export default {
  name: "CloseOfDay",
  mixins: [notificationMixin],
  data() {
    return {
      property_id: "",
    };
  },
  props: ["value"],
	created() {
		this.property_id = this.facilityList.length === 1 ? this.facilityList[0].id : ""
	},
	destroyed() {
		this.closeMessageNotification();
  },
  computed: {
    ...mapGetters({
      facilityList: "propertiesStore/filtered",
    }),
    isPropertySet() {
      return this.property_id == "" ? false : true;
    },
		isPropertyClosed() {
			return this.facilityList.length === 1 ? this.facilityList[0].is_day_closed : null;
		},
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
		...mapActions({
			setProperties: "propertiesStore/setProperties"
		}),
    async closeTheDay() {
			try {
				let r = await api.post(
				  this,
				  api.PROPERTIES + this.property_id + "/closing-day"
				);
				if(r.id) {
					this.setProperties({all: true});
					this.$emit('close');
          this.showMessageNotification({ type: 'success', description: 'The close of day has been done.' });
				}
			} catch(err) {
        this.showMessageNotification({ list: err });
			}
    },
		async undoCloseOfDay() {
			try {
				let r = await api.put(
					this,
					api.PROPERTIES + this.property_id + "/inactive-closing-day"
				);
				this.setProperties({all: true});
					this.$emit('close');
          this.showMessageNotification({ type: 'success', description: 'The close of day has been undone.' });
			} catch(err) {
        this.showMessageNotification({ list: err });
			}
		}

  },
};
</script>
