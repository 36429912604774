<template>
  <div class="new-tenant-walkthrough">
    <div class="new-tenant-walkthrough-container">
      <hb-blade-header
        title="Review Rent Change Notifications"
        title-icon="mdi-account-box"
        @back="$emit('close')"
        @close="$emit('close')"
      >
      </hb-blade-header>


<!--      <v-list-item dense class="nav-drawer-header">-->
<!--        <v-btn icon @click="$emit('close')" >-->
<!--          <v-icon>mdi-chevron-left</v-icon>-->
<!--        </v-btn>-->
<!--        <v-list-item-title class="section-header title py-0" style="height: 17px;">-->
<!--          Review Rent Change Notifications-->
<!--        </v-list-item-title>-->
<!--        <v-btn class="ml-2" icon @click.stop="$emit('close')">-->
<!--            <v-icon>mdi-close</v-icon>-->
<!--        </v-btn>-->
<!--      </v-list-item>-->
      <div class="new-tenant-walkthrough-content-section">
        <div class="onboarding-container">
            <div class="walkthrough-body">
                <div class="pt-4 pb-2 px-0">
                  <v-row class="mx-3">
                    <v-col>
                      <rate-change-item :data="$props.rateItem" :editOnly="true" :flagTaskCenter="fromTaskCenterFlag" @edit-rate-change-item="editRateChangeItem"/>
                    </v-col>
                  </v-row>
                  <v-row class="d-flex justify-center">
                    <v-col cols="6">
                      <hb-notification v-model="notification" v-if="notification" type="warning"> {{message}} </hb-notification>
                    </v-col>
                  </v-row>
                  <v-row class="mx-0 pb-12">
                    <tenant-list
                      v-if="conditions.filters.rate_change_id"
                      @hideList="$emit('close')"
                      @selctedRows="selectedTenant"
                      :conditions="conditions"
                    />
                  </v-row>
                </div>
            </div>
        </div>
      </div>
      <div class="new-tenant-walkthrough-footer" >
        <v-row class="px-4 py-auto">
          <v-col cols="12" class="text-right">
            <hb-link text class="mr-2" @click="$emit('close')">Cancel</hb-link>
            <hb-btn color="secondary" class="mr-2" @click="generateLetterAction(true)">Skip Notification</hb-btn>
            <hb-btn color="primary" class="mr-1" @click="generateLetterAction(false)">Approve & Generate Notification Letter</hb-btn>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>

import RateChangeItem from './RateChangeItem.vue'
import TenantList from './TenantList.vue'
import { mapActions, mapGetters } from 'vuex'
import { EventBus } from '../../EventBus'

export default {
  name: 'ReviewRateChange',
  props: ['rateItem', 'fromTaskCenter'],
  data() {
    return {
      rateData: {
        pastRate: false,
        isDocumentGenerated: false,
        isEmailGenerated: false,
        isRateApplied: false,
        isAutomated: true,
        isSkiped: false,
        isCanceled: false,
        reviewBtn: false,
        skipBtn: false,
        canceledBtn: false,
        isCompleted: false,
        editBtn: true,
        title: 'Rent Raise CA - 90 Days'
      },
      selectedTenantList: [],
      notification: false,
      message: '',
      conditions: {
          filters : {
              property_id: '',
              rate_change_id: ''
          },
          show_count_tenant : true,
          report_name: ''
      }
    }
  },
  components: {
    RateChangeItem,
    TenantList
  },
  created () {
      this.conditions.filters.property_id = this.rateItem.property_id;
      this.conditions.filters.rate_change_id = this.rateItem.id;
      this.conditions.report_name = 'rate_change_selected_tenants';
  },
    destroyed(){
        this.clearTenantList();
    },
  computed:{
    ...mapGetters({
        getSelectedEntries: 'reportStore/get_selected_entries',
    }),
    tenantList(){
        return this.getSelectedEntries({
            report_id: null,
            report_template: "rate_change_selected_tenants",
        });
    },
    fromTaskCenterFlag() {
      return this.$props.fromTaskCenter ? true : false;
    }
  },
  methods: {
    ...mapActions({
      rateChangesReviewApprove: 'rateManagementStore/rateChangesReviewApprove',
      clearTenantList: 'reportStore/clear_selected_entries'
    }),
    selectedTenant(data) {
      this.selectedTenantList = data
    },

      clearTenantList(){
          this.$store.commit('reportStore/resetReport', {
              report_id: null,
              report_template: "rate_change_selected_tenants",
          })
      },

    async generateLetterAction(skipped){

      this.selectedTenantList = this.tenantList;

      let data = {
        path : 'rate-changes/' + this.rateItem.id + '/reviewed',
        type: 'reviewed',
        skipped: skipped,
        leases: this.tenantList.map(x => {
            return {
                id: x.lease_id
            }
        })
      }

      if(!data.leases.length) {
          this.notification = true;
          this.message = "Please select some tenants.";
          return;
      }

      await this.rateChangesReviewApprove(data);
      this.clearTenantList();
      this.$emit('close');

    },
    editRateChangeItem(params) {
      EventBus.$emit('rate_change', {rateChangeType: 'add-rate-change', rateChangeEditType: params.type, rateChangeId: params.rateChangeID, editRateChange: true, rateChangePropertyID: this.rateItem.property_id});
    }
  }
}
</script>

<style scoped>

  .new-tenant-walkthrough-footer{
    padding: 10px 30px;
    padding: 0 !important;
    background: #FFFFFF;
    border-top: 1px solid #e2e2e2;
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 100;
    left: 0;
    right: 0;
  }
  .new-tenant-walkthrough-container{
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: stretch;
      height: 100%;
      flex: 1 1;
      position: relative;
      overflow: hidden;
      /* margin-bottom: 10px; */
  }
  .new-tenant-walkthrough-content-section{
      align-items:stretch;
      display: flex;
      overflow: hidden;
      /* padding-bottom: 50px; */
      height: 100%;
  }
  .new-tenant-walkthrough{
      background: #F9FAFB;
      width: 100%;
      overflow: hidden;
  }
  .walkthrough-body{
      /* flex: 1; */
      overflow-y: scroll;
      height: 85vh;
  }
  .nav-drawer-header{
      flex: 0 1 auto;
      min-height: 60px;
      background: #FFFFFF;
  }
  .onboarding-container{
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: stretch;
      flex: 1;
  }

  @media (min-height: 700px) {
    .walkthrough-body{
      height: 82vh;
    }
  }

  @media (min-height: 800px) {
    .walkthrough-body{
      height: 84vh;
    }
  }

  @media (min-height: 900px) {
    .walkthrough-body{
      height: 86vh;
    }
  }

  @media (min-height: 1025px) {
    .walkthrough-body{
      height: 88vh;
    }
  }

</style>
