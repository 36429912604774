<template>
  <div id="journals--table">
    <hb-data-table
      :headers="headers"
      :items="events"
      disable-pagination
      hide-default-footer
      @click:row="editEvent"
    >
      <template v-slot:header.event_name="{ header }">
        <span>{{header.text}}</span>
      </template>
      <template v-slot:header.debit_account_id="{ header }">
        <span>{{header.text}}</span>
        <span class="pl-2">({{header.sub_text}})</span>
      </template>
      <template v-slot:header.credit_account_id="{ header }">
        <span>{{header.text}}</span>
        <span class="pl-2">({{header.sub_text}})</span>
      </template>

      <template v-slot:item.event_name="{ item }">
        <div class="d-flex align-center">
          {{item.event_name}}
          <hb-tooltip v-if="getEventTooltip(item.event_name) != ''">
            <template slot="body">
              <div style="width: 247px;"> {{ getEventTooltip(item.event_name) }} </div>
            </template>
          </hb-tooltip>
        </div>
      </template>

      <template v-slot:item.debit_account_id="{ item }">
        <hb-tooltip disable-flexbox>
          <template slot="item">
            <v-row no-gutters>
              <v-col cols="3">
                <span v-if="item.debit_account_active == 0" class="pr-1">
                  <hb-icon color="#fb4c4c" small>mdi-alert</hb-icon>
                </span>
                <span class="text-capitalize" :class="{'hb-text-error' : item.debit_account_active == 0}">{{item.debit_account_code}}</span>
              </v-col>
              <v-col cols="9" class="pl-1">
                <span v-if="item.debit_account_active == 0" class="pr-1">
                  <hb-icon color="#fb4c4c" small>mdi-alert</hb-icon>
                </span>
                <span :class="{'hb-text-error' : item.debit_account_active == 0}">{{item.debit_account_name}}</span>
              </v-col>
            </v-row>
          </template>

          <template slot="body">
            <div class="d-flex flex-column">
              <span>{{item.event_name}}</span>
              <span>  {{item.debit_account_name}} </span>
              <span> {{item.credit_account_name}}</span>
              <span class="mt-2" v-if="item.Overrides">There {{item.Overrides.length == 1 ? 'is' : 'are'}} {{item.Overrides.length}} Override{{item.Overrides.length == 1 ? '' : 's'}} Added</span>
            </div>
          </template>

        </hb-tooltip>
      </template>

      <template v-slot:item.credit_account_id="{ item }">
        <hb-tooltip disable-flexbox>
          <template slot="item">
            <v-row no-gutters>
              <v-col cols="3">
                <span v-if="item.credit_account_active == 0" class="pr-1">
                  <hb-icon color="#fb4c4c" small>mdi-alert</hb-icon>
                </span>
                <span class="text-capitalize" :class="{'hb-text-error' : item.credit_account_active == 0}">{{item.credit_account_code}}</span>
              </v-col>
              <v-col cols="9" class="pl-1">
                <span v-if="item.credit_account_active == 0" class="pr-1">
                  <hb-icon color="#fb4c4c" small>mdi-alert</hb-icon>
                </span>
                <span :class="{'hb-text-error' : item.credit_account_active == 0}">{{item.credit_account_name}}</span>
              </v-col>
            </v-row>
          </template>

          <template slot="body">
            <div class="d-flex flex-column">
              <span>{{item.event_name}}</span>
              <span>  {{item.debit_account_name}} </span>
              <span> {{item.credit_account_name}}</span>
              <span class="mt-2" v-if="item.Overrides">There {{item.Overrides.length == 1 ? 'is' : 'are'}} {{item.Overrides.length}} Override{{item.Overrides.length == 1 ? '' : 's'}} Added</span>
            </div>
          </template>

        </hb-tooltip>

      </template>


      <template v-slot:no-data>
        <v-row class="d-flex flex-column my-4" no-gutters>
          <v-col>
            <span class="font-weight-regular hb-text-night">No Information</span>
          </v-col>
        </v-row>
      </template>

      <template v-slot:item.actions="{ item }" class="pa-0" v-if="hasPermission('edit_journal_templates') && isEditable">
        <hb-menu
          options
          align-right
        >
          <v-list>
            <template v-if="checkAccountIDs(item)">
              <v-list-item class="list-item-height" @click="$emit('addEvent',item)">
                <v-list-item-title>Add</v-list-item-title>
              </v-list-item>
            </template>
            <template v-else>
              <v-list-item class="list-item-height" @click="$emit('editEvent',item)">
                <v-list-item-title>View/Edit</v-list-item-title>
              </v-list-item>
              <v-list-item class="list-item-height" @click="$emit('clearEvent',item)">
                <v-list-item-title>Clear</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </hb-menu>
      </template>

    </hb-data-table>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";

  export default {
    name: 'Journals',
    data() {
      return {
        headers: [
          { text: "Event", value: "event_name"},
          { text: "Debit", sub_text: "GL Code / GL Name", value: "debit_account_id"},
          { text: "Credit", sub_text: "GL Code / GL Name", value: "credit_account_id"},
          { text: "", value: "actions", align: "center", sortable: false}
        ],
        EVENT_TOOLTIP_LIST: [
          { tooltip: "Automatic or Manual Generation of Invoice", event: "Generating an Invoice"},
          { tooltip: "Voiding an Invoice for deleting or waiving off a charge", event: "Voiding an Invoice"},
          { tooltip: "Payments made on website, in-store or at call centers for due and past due charges", event: "Posting a Payment"},
          { tooltip: "Acknowledgement of payments made in advance on the due date of the charge", event: "Liability Recognition"},
          { tooltip: "Refunding or Reversing a payment", event: "Refunds"},
          { tooltip: "Writing off any type of invoice", event: "Write-Off"},
          { tooltip: "Issuing store/manager credits to a tenant", event: "Allowance"},
          { tooltip: "Payments made using store/manager credits", event: "Payment with Credits"},
          { tooltip: "Payments made on website, in-store or at call centers for upcoming charges", event: "Posting Excess Payment"},
          { tooltip: "Payments made for Auction Cleaning Deposit", event: "Cleaning Deposit Payment"},
          { tooltip: "Refunding Auction Cleaning Deposit after the Clean-out Period has passed", event: "Cleaning Deposit Refund after Clean-out Period"},
          { tooltip: "Refunding Auction Cleaning Deposit within the Clean-out Period", event: "Cleaning Deposit Refund within Clean-out Period"},
          { tooltip: "Auction Cleaning Deposit has not been refunded yet and the clean-out period has passed", event: "No Cleaning Deposit Refund within Clean-out Period"},
          { tooltip: "Payments made using store/manager credits for upcoming charges", event: "Excess Payment with Credits"},
          { tooltip: "Refund payments made for upcoming charges", event: "Refund Excess Payment"},
          { tooltip: "Refund payments made using store/manager credits for upcoming charges", event: "Refund Excess Payment with Credits"},
          { tooltip: "Refund payments of a property made from different property as a source", event: "Inter Property Payment Refund"},
        ]
      }
    },
    props: {
      events: {
        type: Array,
        default: []
      },
      isEditable: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      ...mapGetters({
        hasPermission: 'authenticationStore/rolePermission'
      }),
    },
    methods: {
      getEventTooltip(name) {
        return this.EVENT_TOOLTIP_LIST.find(t => t.event == name) ? this.EVENT_TOOLTIP_LIST.find(t => t.event == name).tooltip : "";
      },
      checkAccountIDs(item) {
        return !item.credit_account_id && !item.debit_account_id;
      },
      editEvent(item) {
        if (
          this.hasPermission("edit_journal_templates") &&
          !this.checkAccountIDs(item)
        ) {
          this.$emit("editEvent", item);
        }
      },
    },
    created () {
      ;
    },

  }
</script>

<style lang="scss">
  #journals--table {
    table {
      thead {
        tr {
          th:first-child {
            width: 35% !important;
            padding-left: 18px;
          }
          th:last-child {
            width: 0% !important;
          }
          th{
            width: 35% !important;
            padding-left: 8px;
          }

        }
      }
      tbody {
        tr {
          td:last-child {
            padding: 0px !important;
            border-right: none;
          }
          td {
            border-right: 1px solid #D6D8DA;
            border-bottom: 1px solid #D6D8DA;
            padding-right: 4px;
            padding-left: 8px;
          }
          td:first-child {
            padding-left: 18px
          }
          td:nth-child(3){
            background-color: #dfe3e8;
          }
          td:nth-child(2){
            background-color:  #F9FAFB;
          }
          td:nth-child(1){
            background-color:  #fff;
          }
          td:nth-child(4){
            background-color:  #fff;
          }

        }
      }
    }
  }
</style>
