<template>
	<v-menu v-model="todo_menu" offset-y max-width="500" min-width="500" width="100%" nudge-left :close-on-content-click="false">
		<template v-slot:activator="{ on }">
			<span v-on="on">
				<hb-btn icon tooltip="Create Task" :active="todo_menu">mdi-calendar-today</hb-btn>
			</span>
		</template>
		<v-card class="modal-width">
			<todo @close="todo_menu = false"></todo>
		</v-card>
	</v-menu>
</template>
<script>
import Todo from '../Todo.vue';

export default {
	data() {
		return {
			todo_menu: false
		}
	},
	components: {
		Todo
	}
}
</script>
<style lang="scss">
.modal-width{
	width: 850px;
}
</style>