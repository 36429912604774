<template>
    <v-row class="ma-0">
        <v-col cols="12 pa-0 text-center task-box-title">
            <v-icon class="float-left" size="26" v-if="activeView !== 'taskCenter'" @click.stop="$emit('update:activeView', 'taskCenter')">mdi-chevron-left</v-icon>                    
            <div class="d-inline-block">
                <span class="relative-box" :class="{ 'mr-1' : header.title == 'Move-Out'}" v-if="header.svg">
                    <span class="counter-absolute v-badge__badge red" :class="{'green' : count === 0}">{{ count }}</span>
                    <svg v-if="header.svg"
                        :class="header.svg.class" 
                        :width="header.svg.width" 
                        :height="header.svg.height" 
                        :viewBox="header.svg.viewBox"
                        :fill="header.svg.fill" 
                        xmlns="http://www.w3.org/2000/svg" 
                        style="position: relative;top: 3px;"
                    >
                        <path 
                            :fill-rule="header.svg.path.fillRule" 
                            :clip-rule="header.svg.path.clipRule" 
                            :d="header.svg.path.d"
                            :fill="header.svg.path.fill"
                        />
                    </svg>
                </span>
                <span class="relative-box" v-if="header.icon">
                    <span class="counter-absolute v-badge__badge red" :class="{'green' : count === 0}" v-if="activeView !== 'taskCenter'">{{ count }}</span>
                    <v-icon 
                        :size="header.icon.size" 
                        :class="header.icon.class"
                        :color="header.icon.color">{{ header.icon.mdi }}</v-icon>
                </span>
                {{ header.title }}                        
            </div>

            <v-icon class="float-right" @click.stop="$emit('close')">mdi-close</v-icon>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "Header",
    props: [
        "activeView", 
        "header",
        "count"
    ]
}
</script>

<style scoped lang="scss">
    .task-box-title{
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        .relative-box{
            position: relative;

            span.counter-absolute{
                bottom: calc(100% - 10px); 
                left: calc(100% - 10px); 
                right: auto; top: auto;
                font-weight: 500;
                font-size: small;
                min-width: 18px;
                height: 15px;
                line-height: 0;
                padding: 9px 5px;
                z-index: 1;
            }
        }
    }
    
</style>