<template>

    <div class="mb-n4" style="max-height: 400px; overflow-y: auto; padding: 0 24px 16px" @scroll.self="fetchOnScroll">

        <v-row class="yellow-row px-2" v-for="(note, index) in notes" :key="index">
            <v-col md="8" class="py-2 d-flex align-center">
                <div class="d-flex align-center">
                    <v-icon class="pr-1" color="primary" size="25">mdi-bookmark-outline</v-icon>
                    <div v-html="$options.filters.nl2br(note.content)"></div>
                </div>
            </v-col>
            <v-col md="4" class="text-right py-2">
                <div>
                    {{note.created | formatLocalShortDate }} @ {{ note.created | formatLocalTime }}<br/> 
                    <span v-if="note.EnteredBy">By: {{note.EnteredBy.first}} {{note.EnteredBy.middle}} {{note.EnteredBy.last}}</span><span v-if="note.EnteredBy && note.EnteredBy.Role">({{note.EnteredBy.Role.name}})</span>
                </div>
            </v-col>
        </v-row>
        <v-row class="mx-0" v-if="!notes.length && !isLoading($options.name)">
            <v-col md="12" class="text-center">
                No interactions found
            </v-col>
        </v-row>
        <v-row class="mx-0" v-if="isLoading($options.name)">
            <v-col md="12" class="text-center">
                <v-progress-circular width="2" size="32" indeterminate color="primary" ></v-progress-circular>
            </v-col>
        </v-row>

        <hb-modal v-model="showAddNote" v-if="showAddNote" size="large" title="Add Note" @close="onClose" :footerCancelOption="true" show-help-link>
            <template v-slot:content>
                <v-row class="ma-0 pa-0">
                    <v-col md="12" class="pa-3 pl-6 pr-6">
                        <div v-if="showAddNote" class="form-input-container">
                            <v-textarea
                                rows="2"  
                                placeholder="Enter Description"
                                v-validate="'required'"
                                v-model="content"
                                id="notes"
                                name="notes"
                                class="pa-0 mt-0"
                                data-vv-as="description"
                                :hide-details="!errors.collect('notes').length"
                                :error-messages="errors.collect('notes')"
                                :height="150"
                                solo
                                dense
                                flat
                            > </v-textarea>
                        </div>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:right-actions>
                <hb-btn color="primary" :disabled="isLoading($options.name)" @click="saveNote()">Save</hb-btn>
                <span v-show="isLoading($options.name)">
                    <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
                </span>
            </template>
        </hb-modal>

    </div>
</template>

<script>
    import api from '../../../../assets/api.js';
    import Loader from '../../../assets/CircleSpinner.vue'
    import { EventBus } from '../../../../EventBus.js';
    export default {
        name: "Notes",
        components: {Loader},
        props: {
            showNoteModal: {
                type: Boolean,
                default: false,
                content: ''
            },
            contact_id: {
                type: String,
                default: ''
            },
            lease_id: {
                type: String,
                default: ''
            },
            unit_number:{
                type : String,
            }
        },
        data() {
            return {
                notes : [],
                showAddNote: false,
                isAllRecordsLoaded: false,
                content: ''
            }
        },
        watch: {
            showNoteModal(value){
                this.showAddNote = value;
                if (!value) {
                    this.content = ''
                }
            }
        },
        created() {
            this.getNotesList();
        },
        methods: {
            fetchOnScroll ({ target: { scrollTop, clientHeight, scrollHeight }}) {
                if (scrollTop + clientHeight >= scrollHeight) {
                    if ((this.notes.length%20) === 0 && !this.isLoading(this.$options.name) && !this.isAllRecordsLoaded) {
                        this.getNotesList();
                    }
                }
            },
            getNotesList(refresh){
                let offset = this.notes.length;
                if(refresh) {
                    this.notes = [];
                    offset = 0;
                }
                let path = `/contact-interactions?limit=${20}&offset=${offset}&context=moveout`;
                const notesList = this.notes;
                api.get(this, api.CONTACTS + this.contact_id + path).then(r => {
                // api.get(this, api.CONTACTS + 'YgBdxQlBwy/contact-interactions?context=moveout').then(r => {
                    this.notes = [...notesList, ...r.Interactions];
                    if(r.Interactions.length) {
                        this.isAllRecordsLoaded = false;
                    } else {
                        this.isAllRecordsLoaded = true;
                    }
                })
            },
            saveNote(){
                this.$validator.validateAll().then((status) => {
                    if(!status) throw "error";
                    
                    var data = {
                        context: 'moveout',
                        content: this.content,
                        method: 'note',
                        created: new Date(),
                        pinned: false,
                        ref_object_type: 'lease',
                        ref_object_id: this.lease_id,
                        space: this.unit_number
                    }
                    api.post(this, api.CONTACTS + this.contact_id + '/interaction', data)
                    // api.post(this, api.CONTACTS + 'YgBdxQlBwy/interaction', data)
                    .then( () => {
                        this.getNotesList(true);
                        EventBus.$emit('communication::refresh', true);
                        this.showAddNote = false;
                        this.$emit('closeNotesModal', this.showAddNote);
                    })
                })
            },
            onClose(){
                this.showAddNote = false;
                this.$emit('closeNotesModal', this.showAddNote);
            }
        },
    }
</script>

<style scoped>
    .yellow-row{
        margin-left: -24px;
        margin-right: -24px;
        background: #FFF9DA;
        box-shadow: 0px -1px 0px rgba(11, 18, 29, 0.1);
    }
</style>