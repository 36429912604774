<template>

  <v-card>
    <v-card-title class="title">Send E-Sign Link</v-card-title>
    <v-divider></v-divider>
    <v-card-text class="pb-0">

      <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
      <status @resetStatus="successClear($options.name)" v-if="successHas($options.name)" :message="successGet($options.name)" status="success"></status>

      <v-container class="py-0">
        <v-row class="py-2">
          <v-col align-self="center" class="row-checkbox-col">

            <v-checkbox class="mt-0" name="send_sms" id="send_sms" color="primary" hide-details dense v-model="signer.send_sms"></v-checkbox>
          </v-col>
          <v-col align-self="center" style="flex: 0 0 100px;" >
            <label for="send_sms">SMS</label>
          </v-col>

          <v-col align-self="center" class="flex: 1" v-show="signer.send_sms">
            <v-text-field hide-details outlined placeholder="(xxx) xxx-xxxx" dense v-model="signer.sms_number"></v-text-field>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="py-2">
          <v-col align-self="center" class="row-checkbox-col">
            <v-checkbox class="mt-0" name="send_email" id="send_email" color="primary" hide-details dense  v-model="signer.send_email"></v-checkbox>
          </v-col>
          <v-col align-self="center" style="flex: 0 0 100px;"><label for="send_email">Email</label></v-col>
          <v-col align-self="center" v-show="signer.send_email">
            <v-text-field hide-details outlined dense placeholder="Email Address" v-model="signer.email_address"></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-container>
        <v-row class="justify-end">
          <v-btn color="secondary" @click="$emit('close')" class="mr-3">
            Cancel
          </v-btn>
          <v-btn color="primary" @click="sendSignLink(signer)">Send Documents</v-btn>
        </v-row>
      </v-container>
    </v-card-actions>
  </v-card>


</template>


<script type="text/babel">


    import api from '../../assets/api.js';
    import Status from '../includes/Messages.vue';
    import Loader from '../assets/CircleSpinner.vue';

    export default {
        name: "SendForSignature",
        data() {
            return {}
        },
        components:{
            Status,
            Loader
        },
        props: ['signer', 'lease_id', 'property_id'],
        methods:{
            async sendSignLink(signer){

                var data = {
                    signers: [signer],
                    upload_id: signer.upload_id,
                    lease_id: this.lease_id,
                    property_id: this.property_id
                };

                await api.post(this, api.SEND_SIGN_LINK, data);
                this.successSet(this.$options.name, "An email with instructions has been sent to this user.");


            },
        }
    }

</script>
