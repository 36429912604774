<template>
    <div style="width: 100%;">
        <div class="timer-container">
            <div>
                <span class="hb-font-header-2-medium">{{ leadType }}</span>
                <span class="hb-font-header-2" v-if="active_reservation?.reservation_id && active_reservation?.code">{{ ' (' + active_reservation?.code + ')' }}</span>
            </div>
            
            <span class="hb-font-header-2-medium" :style="time_color" v-if="leadType != 'Lead'">{{ duration }}</span>
        </div>
        <hb-expansion-panel >
            <template v-slot:title>
                Lead Follow-Up
            </template>
            <template v-slot:content>
                <v-row no-gutters :class="[computedHeaderBackgroundColor]" v-if="active_reservation?.reservation_id">
                        <HbMiniProfile
                            class="px-4 pt-4 pb-3"
                            type="space"
                            :status="active_reservation.Unit.state ? active_reservation.Unit.state : undefined"
                            :title="active_reservation.Unit.number ? active_reservation.Unit.number : undefined"
                            :address="computedPropertyInfo"
                            :space-type="active_reservation.Unit.type"
                            :space-category="active_reservation.Unit.label"
                            :space-property-info="computedPropertyInfo"
                            @emitType="emitType"
                        />
                    <!-- <v-col cols="12">
                        <span class="font-weight-medium title mb-2">This timer started with the reservation</span>
                        <span  class="float-right timer mr-1" :style="time_color" v-if="active_reservation" >{{ duration }}</span>
                        <br>
                        <ol class="text-body-2 font-weight-medium pl-4">
                        <li class="py-1">
                            <span>The faster you follow up the more rentals you get!</span>
                        </li>
                        <li class="py-1">
                            <span>Every Reservation needs our service</span>
                        </li>
                        <li class="py-1">
                            <span>Smile and be friendly</span>
                        </li>
                        </ol>
                    </v-col> -->
                </v-row>
                <v-row v-if="active_reservation.id && !active_reservation?.reservation_id" no-gutters :class="[computedHeaderBackgroundColor]">
                        <HbMiniProfile
                            class="px-4 pt-4 pb-3"
                            type="space"
                            :status="active_reservation?.state ? active_reservation.state : undefined"
                            :title="active_reservation?.number ? active_reservation.number : undefined"
                            :address="active_reservation?.Address | formatAddress"
                            :space-type="active_reservation?.type"
                            :space-category="active_reservation.label"
                            :space-property-info="active_reservation.Address | formatAddress"
                            @emitType="emitType"
                        />
                </v-row>
                <hb-form label="Interactions">
                    <v-row no-gutters>Log all lead interactions during the follow-up</v-row>
                    <v-row no-gutters>
                        <hb-btn class="pr-4" @click="sendCommunicationType('phone_log')" color="secondary">Log Phone</hb-btn>
                        <hb-btn class="pr-4" @click="sendCommunicationType('email')" color="secondary">Email</hb-btn>
                        <hb-btn class="pr-4" @click="sendCommunicationType('text')" color="secondary">Text</hb-btn>
                        <hb-btn class="pr-4" @click="sendCommunicationType('note')" color="secondary">Note</hb-btn>
                    </v-row>
                    <v-row no-gutters>What was the outcome of the interaction?</v-row>
                    <v-row no-gutters>
                        <v-col cols="12">
                            <hb-select :items="answerList" v-model="tenantAnswer" placeholder="Select Outcome"
                                name="answer" v-validate="'required'" data-vv-as="Call Answer"
                                :error="errors.has('answer')" full></hb-select>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>Provide notes about the interaction</v-row>
                    <v-row full no-gutters>
                        <v-col cols="12">
                            <hb-textarea v-model="noteContent" name="note_content" v-validate="'required|max:1000'"
                                data-vv-as="Note" :error="errors.has('note_content')" placeholder="Enter Notes"
                                placeholder-fix></hb-textarea>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <hb-btn @click="sendNote" color="primary"> Save </hb-btn>
                        <hb-link class="ma-2" @click="">Cancel</hb-link>
                    </v-row>
                </hb-form>
            </template>
        </hb-expansion-panel>
    </div>
</template>

<script>
import moment from 'moment'
import { EventBus } from '../../../EventBus';
import { notificationMixin } from  '../../../mixins/notificationMixin.js';
import api from '../../../assets/api.js'

export default {
    name: 'LeadOnBoard',
    mixins: [ notificationMixin ],
    data: () => {
        return {
            duration: '',
            leadCriteria: {
                acceptable: '#02AD0F',
                overDue: '#ffa300',
                expired: '#FB4C4C',
                none: '#101318'
            },
            time_color: '',
            interval_id: null,
            leadType:'Reservation',
            answerList: [
                { text: 'Call answered', value: 'Call answered' },
                { text: 'Call answered but tenant not available', value: 'Call answered but tenant not available' },
                { text: 'No answer left message', value: 'No answer left message' },
                { text: 'No answer left message on voicemail', value: 'No answer left message on voicemail' },
                { text: 'No answer voicemail full', value: 'No answer voicemail full' },
                { text: 'No answer', value: 'No answer' },
                { text: 'Number no longer in service', value: 'Number no longer in service' },
                { text: 'Number is temporarily disconnected', value: 'Number is temporarily disconnected' },
                { text: 'Number belongs to someone else', value: 'Number belongs to someone else' }
            ],
            noteContent: '',
            tenantAnswer: '',
            emittedType: '',
        }
    },
    props: ['active_reservation','lead'],
    created() {
        if(this.active_reservation?.reservation_id){
            this.interval_id = window.setInterval(() => this.updateTimer(), 1000);
        }
        else if(this.lead){
            this.leadType = this.lead?.lead_type || "Lead";
            this.interval_id = window.setInterval(() => this.updateTimer(), 1000);
        }
    },
    beforeDestroy () {
      window.clearInterval(this.interval_id);
    },
    computed: {
        computedPropertyInfo() {
            if (this.active_reservation && this.active_reservation.Lease && this.active_reservation.Lease.Unit && this.active_reservation.Lease.Unit.Address) {
                return this.$options.filters.formatAddress(this.active_reservation.Lease.Unit.Address, '^', true);
            } else {
                return undefined;
            }
        },
        webLeadResEvent() {
            if (this.active_reservation && this.active_reservation.event) {
                return this.active_reservation.event;
            }
            return ''
        },
        computedHeaderBackgroundColor(){
            if(this.emittedType && this.emittedType == 'error'){
            return 'hb-error-shade';
            } else if(this.emittedType && this.emittedType == 'caution'){
            return 'hb-caution-shade';
            } else if(this.emittedType && this.emittedType == 'success'){
            return 'hb-success-shade';
            } else if(this.emittedType && this.emittedType == 'guidance'){
            return 'hb-guidance-shade';
            } else {
            return '';
            }
        },
    },
    methods: {
        updateTimer() {
            if (!this.active_reservation || !this.leadType) return;
            let time = '';
            if (this.hasReservation(this.active_reservation) && this.webLeadResEvent) {
                time = this.webLeadResEvent.time || this.active_reservation.reservation_time;
            } else if (this.hasReservation(this.active_reservation)) {
                time = this.active_reservation.reservation_time;
            } else if(this.leadType){
                time = this.lead?.modified || this.lead?.created
            }
            else {
                time = this.active_reservation.lead_time;
            }
            this.duration = this.calculateTimer(time)
            this.time_color = this.checkLeadCriteria(this.duration)

        },
        calculateTimer(value) {

            if (!value) return '00:00'
            let startDate = moment.utc(value).local()
            let endDate = moment()
            let diffInMilliSeconds = Math.abs(endDate - startDate) / 1000;

            const days = Math.floor(diffInMilliSeconds / 86400);
            diffInMilliSeconds -= days * 86400;

            let hours = (Math.floor(diffInMilliSeconds / 3600) % 24)
            diffInMilliSeconds -= hours * 3600;

            const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
            diffInMilliSeconds -= minutes * 60;

            const seconds = Math.floor(diffInMilliSeconds) % 60;

            let difference = '';
            // if (days > 0) {
            //   difference += days.toString().padStart(2, '0') + ':'
            // }
            hours += 24 * days;
            difference += hours.toString().padStart(2, '0') + ':'
            difference += minutes.toString().padStart(2, '0') + ':'
            difference += seconds.toString().padStart(2, '0')

            return difference

        },
        checkLeadCriteria(value) {
            let timeArray = value.split(':')
            let hours = timeArray.length > 2 ? parseInt(timeArray[0]) : 0
            let minutes = timeArray.length > 2 ? parseInt(timeArray[1]) : parseInt(timeArray[0])

            if (hours >= 48) {
                return {
                    color: this.leadCriteria.expired
                }
            } else if (hours > 0 || minutes >= 5) {
                return {
                    color: this.leadCriteria.overDue
                }
            } else if (minutes <= 5 && minutes >= 0) {
                return {
                    color: this.leadCriteria.acceptable
                }
            } else {
                return {
                    color: this.leadCriteria.none
                }
            }
        },
        hasReservation(lead) {
            return lead && lead.reservation_id && lead.expires ? true : false;
        },
        sendCommunicationType(type) {
            EventBus.$emit('lead-follow-up-interaction', type);
        },
        sendNote () {
            this.validate(this).then(status => {
                if (!status) {
                return
                }
                var data = {
                content: this.noteContent,
                method: 'phone',
                created: new Date(),
                pinned: 0,
                context: 'leadFollowUp',
                tenant_answer: this.tenantAnswer,
                space : this.active_reservation.id && !this.active_reservation?.reservation_id ? this.active_reservation.number : this.active_reservation?.Lease?.Unit?.number,
                lead_id: this.lead.id
                }
                api.post(this, api.CONTACTS + this.lead.contact_id + '/interaction', data).then(note =>{
                    this.noteContent = ''
                    this.tenantAnswer = null
                    EventBus.$emit('communication::refresh');
                    this.showMessageNotification({ type: 'success', description: 'Call record saved successfully.' });
                    if (this.webLeadResEvent) {
                    window.clearInterval(this.interval_id);
                    this.dismissTask(this.webLeadResEvent.id);
                    }
                })

            })
        },
        emitType(type){
            this.emittedType = type;
        } 
    }
}
</script>
<style scoped>
.timer-container {
    display: flex;
    justify-content: space-between;
}

</style>
