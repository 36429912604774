<template>
  <div>
    <v-card class="mx-auto" max-width="500">
      <v-list>
        <v-list-item-group>
          <v-list-item 
            v-for="(item, i) in items" 
            :key="i" 
            @click="triggerEvent(item.event, parentEvent)"
          >
            <v-list-item-content>
              <v-list-item-title
                v-text="item.text"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
  </div>
</template>

<script type="text/babel">
import { EventBus } from "../../../EventBus.js";

export default {
  name: "RentKebabView",
  props: {
    items: {
      type: Array,
      required: false,
      default: () => [],
    },
    parentEvent: {
      type: String,
      required: true,
      default: "",
    },
    paramsData: {
      type: Object,
      required: true,
      default: () => [],
    }
  },
  data() {
    return {
      kebabItems: []
    }
  },
  methods: {
    triggerEvent(itemEvent, parentEvent) {
      EventBus.$emit(parentEvent, {
        event: itemEvent,
        data: this.paramsData,
      });
    },
  },
};
</script>
