<template>
    <div class="pt-3" :class="moveout_prepare_space ? 'col' : ''">
        <div>
            <!-- <div class="font-big pb-4">Please complete the following tasks.</div> -->
            <v-row class="ma-0 pa-0">
                <!-- <v-col md="6" class="pt-0">
                    <v-checkbox
                        hide-details
                        label="Floor is clean"
                        class="mt-0 pt-0"
                    ></v-checkbox>
                </v-col>
                <v-col md="6" class="pt-0">
                    <v-checkbox
                        hide-details
                        label="Hasp works"
                        class="mt-0 pt-0"
                    ></v-checkbox>
                </v-col>

                <v-col md="6" class="pt-0">
                    <v-checkbox
                        hide-details
                        label="Wall is clean"
                        class="mt-0 pt-0"
                    ></v-checkbox>
                </v-col>

                <v-col md="6" class="pt-0">
                    <v-checkbox
                        hide-details
                        label="Lighting works"
                        class="mt-0 pt-0"
                    ></v-checkbox>
                </v-col>

                <v-col md="6" class="pt-0">
                    <v-checkbox
                        hide-details
                        label="Door is Clean and Works"
                        class="mt-0 pt-0"
                    ></v-checkbox>
                </v-col> -->
                <v-col class="pb-4" md="12">
                    <hb-btn color="secondary" @click="addReminder = true">Schedule Unfinished Task(s)</hb-btn>
                </v-col>
            </v-row>
        </div>
        <div v-if="products.length > 0">
            <v-row class="pt-2 pb-3 mrl--24px">
                <v-divider></v-divider>
            </v-row>
            <div class="col-md-11 py-0">
                <div v-if="!moveout_prepare_space" class="font-big">The space needs maintenance.</div>
                <div v-if="moveout_prepare_space" class="font-big pb-2">Schedule maintenance and/or apply fees.</div>
                <p v-if="!moveout_prepare_space" class="mb-2">Please schedule a maintenance request and select a fee <span class="font-big">IF APPLICABLE</span> (Tenant generated Fee)</p>
                <!-- <v-col v-if="moveout_prepare_space" md="12" class="px-0 pb-4">
                    <button @click="addReminder = true" class="btn-update-req px-8 py-3">Schedule Maintenance Request</button>
                </v-col> -->
                <div>

                    <v-row v-for="(product, index) in products" :key="index">
                        <v-col md="7" class="pt-0">
                            <v-checkbox
                                hide-details
                                v-model="selectedProducts"
                                :label="product.name"
                                :value="product.id"
                                class="mt-0 pt-0"
                            ></v-checkbox>
                        </v-col>
                        <v-col md="5" class="text-right pt-0">
                            {{product.price | formatMoney}}
                        </v-col>
                    </v-row>
                </div>
            </div>
            <v-row class="pt-2 pb-3 mrl--24px">
                <v-divider></v-divider>
            </v-row>
            <v-row>
                <v-col cols="8" class="pa-0 mt-n3">
                    <status
                        @resetStatus="successClear($options.name)"
                        v-if="successHas($options.name)"
                        :message="successGet($options.name)"
                        status="success"
                    ></status>
                </v-col>
                <v-col class="text-right">
                    <hb-btn v-if="!moveout_prepare_space" :disabled="selectedProducts.length <= 0" @click="generateInvoice()" color="primary">Update</hb-btn>
                    <hb-btn v-if="moveout_prepare_space" :disabled="selectedProducts.length <= 0" @click="generateInvoice()" color="primary">Generate Invoice</hb-btn>
                </v-col>
            </v-row>

        </div>
        <!-- <v-row>
            <v-col md="7" class="pt-0">
                <v-checkbox
                    hide-details
                    v-model="selectedProducts"
                    label="Dumpster Fee"
                    value="220.00"
                    class="mt-0 pt-0"
                ></v-checkbox>
            </v-col>
            <v-col md="5" class="text-right pt-0">
                $220.00
            </v-col>
        </v-row> -->

        <add-todo v-model="addReminder" v-if="!moveout_prepare_space" :lease_id="lease.id" :contact_id="contact.id" event_type="clean_&_prepare_space" @close="addReminder = false"></add-todo>
        <add-todo v-model="addReminder" v-if="moveout_prepare_space" :lease="lease" :contact="contact" :lease_id="lease.id" :contact_id="contact.id" event_type="clean_&_prepare_space" :moveout_prepare_space="true" @close="addReminder = false"></add-todo>

    </div>
</template>

<script>
    import Status from "../../../includes/Messages.vue";
    import AddTodo from '../../Todo.vue'
    import api from '../../../../assets/api.js';

    export default {
        name: "IncompletePrep",
        props: ['property_id', 'lease_id', "lease", "contact", "moveout_prepare_space"],
        components: { AddTodo, Status },
        data() {
            return {
                selected: [],
                addReminder: false,
                products: [],
                selectedProducts: []
            }
        },
        created() {
            this.getFees();
        },
        methods: {
            getFees(){
                api.get(this, api.PROPERTIES  + this.property_id + '/products?type=late&category_type=moveout' ).then(r => {
                    this.products = r.products;
                });
            },
            generateInvoice(){
                let data = {
                    Lines: this.selectedProducts.map( p => {
                        return {
                            product_id: p,
                            qty: 1
                        }
                    })
                }
                api.post(this, api.LEASES  + this.lease_id + '/invoices', data ).then(r => {
                    this.products = r;
                    this.$emit('openBalance');
                    this.successSet(this.$options.name, "Invoice Generated Successfully!");
                    this.getFees();
                });
            }
        },
        watch: {
            // selectedProducts(value){
            //     let sum = 0;
            //     value.forEach( p_id => {
            //         sum = sum + parseFloat(this.products.find( p => p.id === p_id).price);
            //     });
            //     sum = Math.round(sum * 1e2)/1e2;
            //     this.$emit('openBalance', sum);
            // }
        },
    }
</script>

<style scoped>
    .font-big{
        font-size: 15px;
        font-weight: 500;
    }
    .mrl--24px{
        margin-left: -24px;
        margin-right: -24px;
    }
    .btn-update-req{
        background: linear-gradient(180deg, #FFFFFF 0%, #F9FAFB 100%);
        border: 1px solid #C4CDD5;
        box-sizing: border-box;
        box-shadow: 0px 1px 0px rgba(22, 29, 37, 0.05);
        border-radius: 4px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #101318;
    }
    .dis-click{
        pointer-events:none
    }
</style>