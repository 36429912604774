<template>
    <div class="pt-3">
        <v-row v-if="overpayments">
            <v-col md="5" class="pr-0">Overpayment Amount: </v-col>
            <v-col md="5" class="pr-0">{{overpayments | formatMoney}}</v-col>
        </v-row>

        <v-row v-if="account_balance">
            <v-col md="5" class="pr-0">Rent Adjustment: </v-col>
            <v-col md="5" class="pr-0">{{account_balance | formatMoney}}</v-col>
        </v-row>

        <v-row>
            <v-col md="5" class="pr-0"><strong>Refund to Process:</strong></v-col>
            <v-col md="5" class="pr-0"><strong>{{amt_to_refund | formatMoney}}</strong></v-col>
        </v-row>

        
        <v-row class="pt-2 pb-3 mrl--24px" v-if="false">
            <v-divider></v-divider>
        </v-row>


        <v-row class="pt-2 pb-3 mrl--24px">
            <v-divider></v-divider>
        </v-row>

        <div class="ref-amount">
            <div class="font-big">Refund Amount will be issued to the tenant.</div>
        </div>

        <v-row class="pt-4 pb-3 mrl--24px">
            <v-divider></v-divider>
        </v-row>

        <v-row>
                <v-col cols="8" class="pa-0 mt-n3">
                    <!-- <status
                        @resetStatus="successClear('refund')"
                        v-if="successHas('refund')"
                        :message="successGet('refund')"
                        status="success"
                    ></status> -->
                </v-col>
                <v-col class="text-right">
                    
                    <hb-btn 
                        :disabled="!amt_to_refund || processingRefund"
                        :loading="processingRefund" 
                        @click="processOverpayment" 
                        color="secondary" 
                    >Process Refund</hb-btn>
                    <span v-show="isLoading('refund')">
                        <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
                    </span>
                </v-col>
            </v-row>

    </div>
</template>

<script>
    
    import Status from '../../../includes/Messages.vue'
    import Loader from '../../../assets/CircleSpinner.vue'
    import moment from 'moment';
    import api from '../../../../assets/api.js';
    import { notificationMixin } from  '../../../../mixins/notificationMixin.js';


    export default {
        name: "RefundRequest",
		mixins: [ notificationMixin ],
        props: ['move_out_date', 'current_refund', 'lease_id', 'contact_id', 'amt_to_refund', 'overpayments', 'account_balance'],
        components: { Status, Loader },
        data() {
            return {
                refund: 0,
                processingRefund: false,
                refundOpt: {
                    manager : '',
                    district: '',
                    owner   : ''
                },
                refundObj : {}
            }
        },
        created() {
            this.refund = this.amt_to_refund;
            // this.refundObj = this.current_refund ? JSON.parse(JSON.stringify(Object.assign({}, this.current_refund))) : {};
            // if (this.refundObj.balnce && this.refundObj.balnce > 0) {
            //     this.refundObj.credit = this.refundObj.balnce;
            // }
            //this.populateRefundAmount();
        },
        methods: {
            // populateRefundAmount() {
            //     if(this.refundObj.Payments){
            //         let refundCredit = ~this.refundObj.credit;
            //         this.refundObj.Payments.map(p => {
            //             if(refundCredit > 0) {
            //                 if(refundCredit < p.amount) {
            //                     p.credit = refundCredit;
            //                     refundCredit = 0;  
            //                 }
            //                 else {
            //                     refundCredit = refundCredit - p.amount;
            //                     p.credit = refundCredit > 0 ? p.amount : 0;
            //                 }
            //             }
            //         });
            //     }
            // },
            async processOverpayment(){
                try{

                    let status = await this.$validator.validateAll(); 
                    if(!status) throw "error";

                    let data = {
                        moved_out: moment(this.move_out_date).startOf('day').format('YYYY-MM-DD'),
                        confirm: true
                    }
                    this.processingRefund = true;
                    let r = await api.get(this, api.LEASES + this.lease_id + '/over-payments', data); 
					this.showMessageNotification({ type: 'success', description: 'Refund Processed Successfully' });
                    this.$emit('fetchPayments');
                } catch(err){
					this.showMessageNotification({ type: 'error', description: err });
                } finally{
                    this.processingRefund = false;
                }

            },
        },
        watch:{
            "current_refund": async function(){
                this.refundObj = this.current_refund ? JSON.parse(JSON.stringify(Object.assign({}, this.current_refund))) : {};
            },
        },
    }
</script>

<style scoped>
    .font-big{
        font-size: 15px;
        font-weight: 500;
    }
    .mrl--24px{
        margin-left: -24px;
        margin-right: -24px;
    }
    .btn-update-req{
        background: linear-gradient(180deg, #FFFFFF 0%, #F9FAFB 100%);
        border: 1px solid #C4CDD5;
        box-sizing: border-box;
        box-shadow: 0px 1px 0px rgba(22, 29, 37, 0.05);
        border-radius: 4px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #101318;
    }
    .approve-ref-btn{
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: #919EAB;
        background: #F9FAFB;
        border: 1px solid #DFE3E8;
        box-sizing: border-box;
        border-radius: 4px;
    }
    .font-15px{
        font-size: 15px;
    }
    .width-25px{
        width:25px;
    }
</style>