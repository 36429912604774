<template>
  <div class="pa-6" :class="{ hide: !showAside }">
    <div v-if="isFoundLoading" style="height: 200px; display: flex; align-items: center; justify-content: center;"
      class="px-6 py-4 pb-2">
      <v-progress-circular width="2" size="32" indeterminate color="primary"></v-progress-circular>
    </div>
    <div v-else>
      <div class="aside-header">
        <div>
          <h2 v-if="found.length === 0" class="hb-font-header-2-medium hb-text-night">
            No Similar Contacts Found
          </h2>
          <h2 v-else class="hb-font-header-2-medium hb-text-night">Similar Contacts Found</h2>
        </div>
      </div>

      <div class="aside-summary-section">
        <div>
          <p v-if="found.length > 0" class="hb-body hb-text-night">
            There are similar contacts found. Do you want to use of these
            contacts?
          </p>

          <!-- <hb-btn color="secondary" @click="$emit('hide')">Hide</hb-btn> -->
        </div>
      </div>

      <div v-for="(contact, i) in found" :key="i">
        <hb-header one-action>
          <template v-if="contact?.id" v-slot:left>
            <mini-profile-view class="pa-0 mini-profile-view" :contact-id="contact.id" :use-contact="true"
              :contact-data="contact" @click.native="setContact(contact)" />
          </template>
        </hb-header>
        <!-- <hb-btn @click="setContact(contact)" color="primary"
        >Use This Contact</hb-btn
      > -->
      </div>

    </div>
  </div>
</template>

<script type="text/babel">
import Status from "../../includes/Messages.vue";
import Loader from "../../assets/CircleSpinner.vue";
import api from "../../../assets/api.js";
import moment from "moment";
import { charges } from "../../../mixins/leaseCharges.js";
import { parsePhoneNumber } from "libphonenumber-js";
import { mapGetters } from "vuex";
import MiniProfileView from "./MiniProfileView.vue";
export default {
  name: "FoundContacts",
  data() {
    return {
      showAside: true,
      found: [],
    };
  },
  components: {
    MiniProfileView
  },
  props: ["show", "contacts",'isFoundLoading'],
  created() {
    this.found = this.contacts;
    this.showAside = this.show;
  },
  computed: {
    ...mapGetters({
      selectedProperties: 'propertiesStore/filtered'
    })
  },
  methods: {
    async setContact(contact) {
      this.$emit("selected", contact);
    },
  },
  watch: {
    show(val) {
      this.showAside = val;
    },
    contacts(val) {
      this.found = this.contacts;
    },
  },
};
</script>
<style scoped>
.mini-profile-view{
  cursor: pointer;
}
</style>
