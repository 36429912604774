<template>
  <div>
    <hb-form label="Enable Properties" large>
      <HbCheckbox
        v-model="select_all"
        label="Select All"
        id="select_all"
        name="select_all"
        :indeterminate.sync="propertiesIndeterminate"
      />
      <div
        v-for="(property, propertyIndex) in properties"
        :key="propertyIndex"
        class="mt-4"
        :class="{ 'mb-1' : propertyIndex === properties.length - 1 }"
      >
        <v-checkbox
          v-model="properties_selected"
          :value="property.id"
          :label="property.name"
          hide-details
          @change="$emit('selectedProperties', properties_selected)"
        />
      </div>
    </hb-form>
  </div>
</template>

<script type="text/babel">
import { mapGetters } from 'vuex';
export default {
  name: "EnablePropertiesField",
  props: ['selectedPropertiesList'],
  data() {
    return {
      select_all: false,
      properties_selected: []
    };
  },
  created() {
    if(this.selectedPropertiesList.length) {
      this.properties_selected = this.selectedPropertiesList;
    }
  },
  computed: {
    ...mapGetters({
      properties: "propertiesStore/filtered"
    }),
    propertiesIndeterminate: {
      get() {
        return (
          this.properties_selected.length > 0 &&
          this.properties_selected.length != this.properties.length
        );
      },
      set(value) {},
    },
  },
  methods: {
  },
  watch: {
    select_all(val) {
      if (val) {
        this.properties_selected = this.properties.map((p) => p.id);
      } else {
        this.properties_selected = [];
      }
      this.$emit('selectedProperties', this.properties_selected);
    }
  }
}
</script>

<style lang="scss" scoped></style>