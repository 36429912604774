<template>
  <div>
    <v-container>
      <v-row>
        <v-col md="8" class="pa-0">
          <h2 class="headline mb-0">Finalize Transfer</h2>
          <span class="subtitle-1 font-weight-medium">Transfer Date: <span v-if="transfer != null">{{transfer.date | formatDateTimeCustom('MMM DD, yy')}}</span><span v-else>{{ new Date() | formatDateTimeCustom('MMM DD, yy')}}</span> by <span v-if="transfer != null">{{transfer.TransferBy.first + ' ' + transfer.TransferBy.last}}</span><span v-else>{{getUserData.first + ' ' + getUserData.last}}</span></span>
        </v-col>
      </v-row>
    </v-container>

    <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>

    <!-- <hb-notification type="caution" title="Warning:" :notDismissable="true">
      <template > 
        Transferred out space is going to be moved out and put back in inventory immediately. Please make sure the space is clean and ready for move in.
      </template>
    </hb-notification> -->

    <v-expansion-panels v-model="panels" multiple elevation="0" class="hb-expansion-panel mt-5"  >

      <v-expansion-panel elevation="0" v-if="signature_items.length">
        <v-expansion-panel-header  :class="['hb-default-font-size pt-3 pb-4 px-6']">
          <v-col cols="12" class="pa-0 ma-0">
            <span class="text-h6">Document Signing</span>
          </v-col>
        </v-expansion-panel-header>
        <v-divider></v-divider>

        <v-expansion-panel-content class="px-0 py-4 ma-0 ma-0" elevation="0" >
          <v-row class="pa-0 ma-0">
            <v-col class="py-0 px-6 ma-0">
              <div class="mb-6">Please select which document(s) need to be signed to finalize the transfer.</div>
              <v-container class="px-0 py-0">
                <v-row no-gutters v-for="item in signature_items" :key="item.id"  >
                  <v-col class="py-0 px-1 col mb-6" v-if="status_rank(item) === 1 || status_rank(item) === 2">
                    <v-progress-circular
                      indeterminate
                      class="pa-0 ma-0 mr-2"
                      size="16"
                      width="2"
                      color="primary"
                    ></v-progress-circular>
                    {{ item.name }}
                  </v-col >

                  <v-col class="pa-0" v-else>
                    <v-checkbox v-if="status_rank(item) <= 5 && !item.completed"  class="ma-0 pa-0" :label="item.name" :value="item.id" color="primary" v-model="docs_to_sign"></v-checkbox>
                    <v-checkbox v-else disabled class="ma-0 pa-0" :label="item.name" color="primary" v-model="item.completed"></v-checkbox>
                  </v-col >

                  <v-col class="text-right pa-2" :class="{'hb-text-accept': item.status === 'complete' || item.status === 'finished'}" >
                    <hb-icon v-if="item.status === 'complete' || item.status === 'finished'" color="#02AD0F">mdi-check</hb-icon>
                    {{status_message(item)}}
                  </v-col>
                  <v-col class="text-right flex-shrink" style="flex: 0 1" >
                    <hb-menu options>
                      <v-list>
                        <v-list-item v-if="(item.completed  && item.Upload.src) || status_rank(item) > 4 || (status_rank(item) >= 3 && item.Upload.src)" :ripple="false">
                          <v-list-item-title>
                            <a :href="item.Upload.src" target="_blank" style="text-decoration: none;">Download/Print</a>
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item v-show="status_rank(item) < 5 && !item.completed" @click="upload(item)" :ripple="false">
                          <v-list-item-title>Upload Document</v-list-item-title>
                        </v-list-item>

                        <v-list-item @click="remove(item)" :ripple="false">
                          <v-list-item-title>Discard and Start Over</v-list-item-title>
                        </v-list-item>

                        <v-list-item @click="fetchData" :ripple="false">
                          <v-list-item-title>Refresh</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </hb-menu>

                  </v-col>
                </v-row>
              </v-container>
              <v-container class="px-0 py-0">
                <hb-btn :disabled="!ready_to_sign" @click="sign_documents" color="primary" class="mr-3">Sign on This Device</hb-btn>
                <hb-btn :disabled="!ready_to_sign" color="secondary" @click="openSendDocument" class="mr-3">Send for Signing</hb-btn>
                <hb-btn :disabled="!ready_to_sign" color="secondary" @click="getSignLink" class="mr-3">Get Link</hb-btn>
              </v-container>

            </v-col>
          </v-row>
        </v-expansion-panel-content>

      </v-expansion-panel>

    </v-expansion-panels>

    <sign
      @close="sign_process = false"
      @completed="closeSignProcess"
      :property_id="property.id"
      v-if="uploads_to_sign.length && sign_process"
      :uploads="uploads_to_sign"
      :signer_id="signer_id"
    ></sign>

    <v-dialog v-model="send_docs" width="500">
      <send-for-signature
        :signer="to_send"
        :lease_id="lease.id"
        @close="send_docs = false">
      </send-for-signature>
    </v-dialog>

    <v-dialog
      v-model="show_link"
      width="500"
    >
      <v-card>
        <v-card-title primary-title>Signing Link</v-card-title>

        <v-card-text>
          You can text or email this link to be able to sign the document:
          <br />
          <br />
          {{link.shortUrl}}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="show_link = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <send-document
      v-model="email_doc_copy"
      v-if="email_doc_copy"
      :upload_id="file.upload_id"
      :lease_id="lease.id"
      @close="email_doc_copy = false"
    ></send-document>

    <upload-file
      v-model="upload_document"
      v-if="upload_document"
      type="file"
      model="leases"
      :foreign_id="lease.id"
      size="lg"
      slot="body"
      @close="upload_document=false"
      @save="completeUpload">
    </upload-file>

    <hb-modal v-model="sendDocument" v-if="sendDocument" size="medium" title="Send for Signing" show-help-link>
      <template v-slot:content>
        <div class="pa-6">
          <status class="mr-5 mt-2" @resetStatus="errorClear('sendSignLink')" v-if="errorHas('sendSignLink')" :message="errorGet('sendSignLink')" status="error"></status>
          <status class="mr-5 mt-2" @resetStatus="successClear('sendSignLink')" v-if="successHas('sendSignLink')" :message="successGet('sendSignLink')" status="success"></status>
          <v-card elevation="0" class="ti-widget">
            <div class="v-card__content ti-widget__content">
              <v-divider></v-divider>
              <v-row class="ma-0 pa-0">
                <v-col cols="4" class="row-title pl-6 lease-column-1">
                  Num. of Documents
                </v-col>
                <v-col cols="8" class="pl-5 gray-color">
                  {{send_doc_data.docs.length}}
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row class="ma-0 pa-0">
                <v-col cols="4" class="row-title pl-6 lease-column-1">
                  Message
                </v-col>
                <v-col cols="8" class="pl-5 gray-color">
                  <v-textarea
                    dense
                    v-model="send_doc_data.message"
                  >
                  </v-textarea>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row class="ma-0 pa-0">
                <v-col cols="4" class="row-title pl-6 lease-column-1">
                  Method
                </v-col>
                <v-col cols="8" class="pl-5 gray-color">
                  <v-radio-group v-model="send_doc_data.method" dense>
                    <v-radio
                      dense
                      v-if="send_doc_data.contact.email"
                      key="Email"
                      :label="'Email ' + send_doc_data.contact.email"
                      value="email"
                    ></v-radio>
                    <v-radio
                      dense
                      v-for="(phone, i) in send_doc_data.contact.Phones"
                      :key="i"
                      :label="'Text ' + $options.filters.formatPhone(phone.phone)"
                      :value="phone.id"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </div>
          </v-card>
        </div>
      </template>
      <template v-slot:actions>
        <hb-btn color="primary" :disabled="isLoading('send_sign')" @click="sendSignLink">Send Link</hb-btn>
        <span v-show="isLoading('send_sign')" >
                  <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
              </span>
      </template>
    </hb-modal>

    <div class="new-tenant-walkthrough-footer">
        <v-row class="ml-0">
            <v-col class="pt-4">
              <help-juice />
            </v-col>
            <v-col cols="9" class="d-flex align-center justify-end pr-sm-7">
                <hb-link class="mr-2" @click="$emit('close')"
                    >Cancel</hb-link
                >
                <hb-btn
                    color="primary"
                    :disabled="isLoading($options.name) || !!incomplete_items.length || fetchingAccess"
                    :loading="fetchingAccess"
                    @click="confirmFinalize"
                    >Finalize Transfer</hb-btn
                >
            </v-col>
        </v-row>
    </div>

    <ConfirmAccessHours
      v-model="confirmHoursVisible"
      @confirm-change="finalize"
      :area-name="configuration_unit.area_name"
      :hours-name="selectedHours.name"
      :units-in-area="formattedUnitsInArea"
    />

  </div>
</template>

<script type="text/babel" >


import api from '../../../assets/api.js';
import moment from 'moment';
import { mapGetters } from 'vuex';
import { EventBus } from '../../../EventBus.js';
import { notificationMixin } from '../../../mixins/notificationMixin.js';

import Status from '../../includes/Messages.vue';
import Loader from '../../assets/CircleSpinner.vue';

import Sign from '../../includes/SignDocuments.vue';
import SendForSignature from '../../includes/SendForEsign.vue';
import SendDocument from '../../includes/SendDocument.vue';
import UploadFile from '../../assets/UploadFile.vue';

import ConfirmAccessHours from '../../access_hour_selection/ConfirmAccessHours.vue';

export default {
  name: 'SignLease',
  mixins: [ notificationMixin ],
  data() {
    return {
        fetchingAccess: false,
        confirmHoursVisible: false,
        standard_item_checkboxes: [],
        initial: false,
        upload_document : false,
        send_docs : false,
        email_doc_copy : false,
        file: {},
        all_documents: 'all',
        to_send_sms: false,
        documents_loaded: false,
        identity_verification: 'manual',
        docs_to_sign: [],
        sign_method: '',
        to_send: {},
        printLoading: false,
        documents_to_sign: [],
        sign_process: false,
        uploads_to_sign:[],
        signer_id: null,
        active_item: {},
        link:'',
        show_link:false,
        saved: false,
        panels: [0,1,2],
        selected_upload: {},
        statuses: [
            'error',
            'not_processed',
            'loading',
            'ready',
            'loaded',
            'initiated',
            'complete',
            'finished',
        ],
        sendDocument: false,
        send_doc_data: {
            message: '',
            method: '',
            contact: {},
            docs: []
        },
    }
  },
  props: ['transfer', 'selectedHours', "accessAreasEnabled"],
  components:{
    Status,
    Loader,
    Sign,
    SendForSignature,
    SendDocument,
    UploadFile,
    ConfirmAccessHours
  },
  computed: {
    ...mapGetters({
      properties: 'propertiesStore/filtered',
      property: 'onBoardingStore/property',
      lease: 'onBoardingStore/lease',
      template: 'onBoardingStore/template',
      paymentMethod: 'onBoardingStore/paymentMethod',
      billed_months: 'onBoardingStore/billed_months',
      items: 'onBoardingStore/items',
      contact: 'onBoardingStore/contact',
      getUserData: 'authenticationStore/getUserData',
      configuration_unit: 'onBoardingStore/configuration_unit',
      unitsInArea: 'onBoardingStore/units_in_area'
    }),
    // standard_items(){
    //   return this.items.filter(c => !c.document_id);
    // },
    signature_items(){
      return this.items.filter(c => c.document_id);
    },
    // documents(){
    //   return this.items.filter(c => c.document_id)
    // },
    incomplete_items(){
      return this.items.filter(c => !c.completed && c.document_id);
    },

    ready_to_sign(){
      return !this.signature_items.filter(item => !item.completed && this.status_rank(item) < 3).length &&
              this.signature_items.filter(item => !item.completed).length && this.docs_to_sign.filter(id => {
                return !this.signature_items.find(item => item.id === id).completed;}).length;
    },
    formattedUnitsInArea(){
      return this.unitsInArea.join(", ");
    }

  },
  async created (){
    this.$emit('update:loading', false)
    await this.fetchData();

    EventBus.$on('pandadoc_generation_update', this.updateSignature);
    this.docs_to_sign = this.signature_items.map(si => si.id);    
  },
  beforeDestroy(){
    EventBus.$off('pandadoc_generation_update', this.updateSignature);
  },
  methods: {
    openSendDocument(item){
      let docs = [];
      if(!this.docs_to_sign || !this.docs_to_sign.length) this.errorSet(this.$options.name, "Please Choose documents to sign.");
      if(!Array.isArray(this.docs_to_sign)){
        this.docs_to_sign = [this.docs_to_sign];
      }
      for(let i = 0; i < this.docs_to_sign.length; i++){
        let doc = this.signature_items.find(si => si.id === this.docs_to_sign[i]);
        if(!doc.completed){
            docs.push(doc);
        }
      }

      if(!docs.length || !docs[0].Upload || !docs[0].Upload.signers || !docs[0].Upload.signers.length || docs[0].Upload.signers.Contact ){
        return;
      }
      this.send_doc_data.docs = docs;
      this.send_doc_data.contact = this.contact;
      this.errorClear('sendSignLink')
      this.successClear('sendSignLink')

      if(this.contact.Phones && this.contact.Phones.length){
        this.send_doc_data.method = this.contact.Phones[0].id;
      } else {
        this.send_doc_data.method = 'email';
      }
      this.send_doc_data.message = "Please click the link to sign your documents";
      this.sendDocument = true;
    },

    async sendSignLink(){
      var data = {
        docs: this.send_doc_data.docs,
        contact_id: this.contact.id,
        property_id: this.property.id,
        method: this.send_doc_data.method,
        message: this.send_doc_data.message
      };

      let response = await api.post(this, api.SEND_SIGN_LINK, data, "sendSignLink");
      if(this.send_doc_data.method === 'email'){
        this.successSet("sendSignLink", "An email with instructions has been sent to this customer.");
      } else {
        this.successSet("sendSignLink", "A text with instructions has been sent to this customer.");
      }
    },

    status_message(item){
      switch(item.status){
        case 'error':
          return "An error occured, please try again";
          break;
        case 'not_processed':
        case 'loading':
          return "loading";
          break;
        case 'ready':
        case 'loaded':
          return "Ready to sign";
          break;
        case 'initiated':
          return "Signing Started";
          break;
        case 'complete':
        case 'finished':
          if(item.Upload.signers && item.Upload.signers.length && item.Upload.signers[0].signed){
            return item.Upload.signers[0].Contact.first + ' ' + item.Upload.signers[0].Contact.last + ' | ' + this.$options.filters.formatLocalDateTime(item.Upload.signers[0].signed);
          } else {
            return  "Signing complete. Finalizing document";
          }
          break;
        default:
          break;
      }
    },

    async getSignLink(item){
      let docs = [];
      if(!this.docs_to_sign || !this.docs_to_sign.length) this.errorSet(this.$options.name, "Please Choose documents to sign.");

      if(!Array.isArray(this.docs_to_sign)){
        this.docs_to_sign = [this.docs_to_sign];
      }
      for(let i = 0; i < this.docs_to_sign.length; i++){
        let doc = this.signature_items.find(si => si.id === this.docs_to_sign[i]);
        if(!doc.completed){
          docs.push(doc);
        }
      }

      if(!docs.length || !docs[0].Upload || !docs[0].Upload.signers || !docs[0].Upload.signers.length || docs[0].Upload.signers.Contact ){
        return;
      }
      var data = {
        docs: docs,
        contact_id: docs[0].Upload.signers[0].Contact.id,
        property_id: this.property.id
      };
      let response = await api.post(this, api.GET_SIGN_LINK, data);
      this.link = response.link;
      this.show_link = true;
    },

    updateSignature(payload){
      this.$store.commit('onBoardingStore/updateChecklistItem', payload);
    },

    status_rank(item){
      return this.statuses.indexOf(item.status);
    },

    item_url(item){
      let slug = this.$options.filters.slugify(item.Upload.name);
      return process.env.VUE_APP_API_PROTOCOL + '://' + process.env.VUE_APP_API_SUBDOMAIN + '.' + process.env.VUE_APP_DOMAIN + ':' + process.env.VUE_APP_API_PORT + '/v1/uploads/panda-docs/' + item.Upload.id + '/' + slug + '.pdf'
    },

    async fetchData(){
      await this.$store.dispatch('onBoardingStore/loadChecklistItems');
      await this.$store.dispatch("onBoardingStore/loadDocuments");
    },

    sign_documents(){
      let docs = [];
      if(!this.docs_to_sign || !this.docs_to_sign.length) this.errorSet(this.$options.name, "Please Choose documents to sign.");

      if(!Array.isArray(this.docs_to_sign)){
        this.docs_to_sign = [this.docs_to_sign];
      }

      for(let i = 0; i < this.docs_to_sign.length; i++){
        let doc = this.signature_items.find(si => si.id === this.docs_to_sign[i]);
        if(!doc.completed){
          docs.push(doc.Upload);
        }
      }

      if(!docs.length){
        return;
      }

      this.uploads_to_sign = docs;
      this.sign_process = true;
    },

    async updateChecklist(item){
      this.$store.dispatch('onBoardingStore/saveChecklistItemCompleted', {
        item,
        completed: !!item.completed
      });
    },

    async completeUpload(params){
      if(params.uploads && params.uploads.length) {
        this.selected_upload.completed = true;
        this.selected_upload.upload_id = params.uploads[0].id;
        this.selected_upload.Upload = params.uploads[0];
        await this.$store.dispatch('onBoardingStore/saveChecklistItemCompleted', {
          item: this.selected_upload,
          completed: true
        });

        this.upload_document = false;
        this.selected_upload = {};
      }
    },

    async closeSignProcess(upload_id){
      this.sign_process = false;
      this.signer_id = null;
      this.uploads_to_sign = [];
      this.active_item = {};
    },

    async print(){
      let location =  await api.get(this, api.LEASES + this.lease.id + '/documents/', this.signature_items[0].document_id + '/generate', {}, 'print_loading');
    },

    closeSendWindow(){
      this.send_docs=false;
      this.to_send = {};
    },

    send_documents(item, signer){
      this.send_docs = true;
      let phone =  signer.Contact && signer.Contact.Phones && signer.Contact.Phones.length ? signer.Contact.Phones.find(p => p.sms) : null;
      this.$set(signer, 'send_sms', !!phone);
      this.$set(signer, 'sms_number', phone? phone.phone : '');
      this.$set(signer, 'send_email', !!signer.Contact.email);
      this.$set(signer, 'email_address', signer.Contact.email);
      this.$set(signer, 'upload_id', item.Upload.id);
      this.$set(signer, 'lease_id', this.lease.id );
      this.$set(signer, 'item_id', item.id );
      this.$set(signer, 'property_id', this.property.id );
      this.to_send = signer;
    },

    upload(item){
      this.selected_upload = JSON.parse(JSON.stringify(item));
      this.upload_document = true;
    },

    async remove(item){
      let index = this.docs_to_sign.findIndex(d => d.id === item.id);
      if(index >= 0){
          this.docs_to_sign.splice(index, 1);
      }
      this.$store.dispatch('onBoardingStore/regenerateItem', item);
    },

    email_copy(item){
      this.email_doc_copy = true;
      this.file = item;
    },

    async download_panda_doc(item){
      this.$http.get(
        api.UPLOADS + 'panda-docs/' + item.Upload.id + '/download',
        null,
        { responseType: 'arraybuffer' }
      ).then(function (response) {
        var blob = new Blob([response.body], {type:response.headers.get('content-type')});
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = item.Upload.name;
        link.click();
      });
    },
    async confirmFinalize(){
      if (this.accessAreasEnabled) {
        this.fetchingAccess = true;
        try {
          await api.get(this, api.PROPERTIES + this.property.id + '/access')
          await this.$store.dispatch("onBoardingStore/getUnitsInArea", {area_id: this.configuration_unit.area_id});
          if (this.unitsInArea.length > 1 && this.selectedHours?.vendor_access_level_id) {
            this.confirmHoursVisible = true;
            return;
          }
          if (!this.selectedHours?.vendor_access_level_id) {
            this.showMessageNotification({ type: 'error', description: 'The tenant has been transferred and needs access. Please configure and assign their access hours.' });                                                                                                                
          }
        } catch(err) {
          this.showMessageNotification({ type: 'error', description: 'The tenant requires access to the facility. Please configure Gate Access in the settings.' });
        } finally {
          this.fetchingAccess = false;
        }
      }
      this.finalize();
    },
    async finalize(){
        let data = {
            billed_months: this.billed_months
        };
        data.payment = {};
        data.vendor_access_level_id = this.selectedHours?.vendor_access_level_id;
        let r = await api.post(this, api.LEASES + this.lease.id + '/finalize', data)
        // data.payment.id = r.payment_id;
        // data.payment.transaction_id = r.transaction_id;
        // data.payment.amount = r.amount;
        // this.$store.commit('onBoardingStore/setPayment', data.payment);
        EventBus.$emit('contact_updated_transfer');
        EventBus.$emit('reservation_made');
        EventBus.$emit('unit_available_changed');
        this.$store.commit('onBoardingStore/resetContact');
        this.$emit('saved');
        this.$emit("next");
    },

    goToLease(){
      this.$router.push('/contacts/' + this.contact.id);
      this.$emit('close', true);
    },
  }
}
</script>

<style scoped>

</style>