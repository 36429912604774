<template>
    <div class="filter-dropdown-menu">
        <v-row no-gutters>
            <v-col cols="4">
                <HbAutocomplete
                    rounded
                    v-model="selectedSpaceStatus"
                    :items="['Available']"
                    v-validate="'required|max:45'"
                    data-vv-scope="default"
                    :clearable="location === 'TRANSFER' || isNational ? false : true"
                    data-vv-name="autocomplete-rounded"
                    data-vv-as="Autocomplete Rounded"
                    placeholder="All Spaces"
                    @change="$emit('spaceStatusChange', selectedSpaceStatus)"
                />
            </v-col>
            <v-col class="ml-4" cols="4">
                <v-text-field
                outlined
                style="background-color: white;"
                hide-details
                dense
                @keyup.enter.prevent="setSearchParams(null)"
                v-model.lazy="search.search"
                prepend-inner-icon="mdi-magnify"
                clearable
                clear-icon="mdi-close-circle"
                label="Search Spaces"
                class="hb-text-field-custom vuetify-input-fieldset-fix"
                ></v-text-field>
            </v-col>
            <v-col v-if="location == 'LEAD_ON_BOARDING' && tab == 'map'" class="ml-4" cols="2">
                <hb-btn @click="$emit('showMapView')">
                    Full Screen Map
            </hb-btn>
            </v-col>
        </v-row>
        <v-row no-gutters class="ml-n2 mt-2">
            <v-col md-2>

                <v-menu offset-y
                    v-model="mi.open"
                    v-for="mi in filter_data"
                    :key="mi.label"
                    :close-on-click="true"
                    :close-on-content-click="false"
                    min-width="200"
                    max-width="300"
                    max-height="450"
                >
                
                <template v-slot:activator="{ on, attrs }">
                    <span v-on="on" 
                    >
                        <!-- :class="!search[mi.label.toLowerCase()].length ? '': 'activeFilterStatus'" -->
                    <hb-btn
                        v-if=" (!tab || tab == 'spaces') || (tab == 'map' && (mi.label == 'Size' || mi.label == 'Floor' || mi.label == 'Type'))"
                        @click="mi.data = search[mi.label.toLowerCase()]"
                        v-bind="attrs"
                        :color="!search[mi.label.toLowerCase()].length ? 'secondary': 'primary'"
                        class="ml-2 filters-margin-st"
                        v-on="on"
                        append-icon="mdi-menu-down"
                        >{{ mi.label }}
                        <span v-show="search[mi.label.toLowerCase()].length">: {{search[mi.label.toLowerCase()].length }}</span>
                    </hb-btn>
                    </span>
                </template>

                <v-list class="pa-0" max-height="390">
                    <v-list-item
                    class="py-0 my-0"
                    v-for="(item, index) in mi.items"
                    :key="index"
                    style="background-color: white;"
                    >
                    <v-list-item-content class="py-0 my-0">
                        <v-list-item-title class="py-0 my-0">
                            <v-checkbox class="mt-0 pt-0" v-if="mi.label != 'Type'" hide-details dense v-model="mi.data" :label="item" :value="item"></v-checkbox>
                            <v-checkbox class="mt-0 pt-0"v-else hide-details dense v-model="mi.data" :label="item.display_name" :value="item.unit_type_id"></v-checkbox>
                        </v-list-item-title>
                    </v-list-item-content>
                    </v-list-item>
                    <div style="position: sticky; bottom: 0; background-color:white">
                        <v-divider></v-divider>
                        <div class="pa-2 mr-2">
                            <hb-btn
                                color="primary"
                                @click="setValues(mi)"
                                >Apply
                            </hb-btn>
                        </div>
                    </div>
                </v-list>
                
                </v-menu>
                <hb-link v-if="reset == true" class="ml-3" @click="resetFilters">Reset Filters</hb-link>
            </v-col>
        </v-row>
    </div>
</template>
<script type="text/babel">
import { EventBus } from '../EventBus';

export default {
    name: "FilterDropdownMenu",
    props: ['filter_data','search', 'reset','location','isNational','tab'],
    data() {
        return {
            selectedSpaceStatus: 'Available'
        }
    },
    mounted() {
        EventBus.$on('resetMoveInFilters', this.resetFilters)
        EventBus.$on('applyMoveInFilters', this.removeSelectedFilters)
    },
    destroyed() {
        EventBus.$off('resetMoveInFilters')
        EventBus.$off('applyMoveInFilters')
    },
    methods:{
        setValues(mi){
                this.$emit('setValues', mi)
        },
        setSearchParams(){

        },
        resetFilters() {
            this.$emit('resetFilters')
        },
        removeSelectedFilters(filter) {
            switch(filter.colId) {
                case 'unit_size':
                    filter.colId = 'Size'
                    break;
                case 'unit_type':
                    filter.colId = 'Type'
                    break;
                case 'unit_status':
                    filter.colId = 'Status'
                    break;
                case 'unit_amenities':
                    filter.colId = 'Amenities'
                    break;
                case 'unit_price':
                    filter.colId = 'Price'
                    break;
                case 'unit_floor':
                    filter.colId = 'Floor'
                    break;
            }
            if(filter.colId == 'Status' && filter.data == 'Available'){
                this.selectedSpaceStatus = '';
                this.$emit('spaceStatusChange', this.selectedSpaceStatus)
            }else{
                let mi = null;

                const updatedItems = this.filter_data?.map(item => {
                    if (item.label === filter.colId) {
                        mi = { ...item, data: item.data.filter(dataItem => dataItem !== filter.key) };
                        return mi;
                    }
                    return item;
                });

                this.filter_data = updatedItems;
                this.$emit('setValues', mi)
            }
        }
    },
    watch:{
        'search.status'() {
            this.selectedSpaceStatus = this.search?.status[0];
        },
        'tab'() {
            this.$emit('spaceStatusChange', this.selectedSpaceStatus)
            this.$emit('setValues', mi)
        }
    }

}
</script>

<style scoped>
.activeFilterStatus >>> button{
    border:1px solid #00848E;
}
.filters-margin-st >>> button{
    margin-bottom: 8px;
}
</style>