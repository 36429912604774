<template>
  <div id="charm-call" v-if="isCharmCallToastOpen" :style="getInSnappedZone ? '' : 'height: 0;'">
    <v-slide-x-reverse-transition>
      <hb-toast
        :addAsAdditionalContactButton="false"
        :id="notification.id"
        :type="type"
        :view.sync="view"
        :confirmation="confirmation"
        :loading.sync="notification.loading"
        :active-call-id="active_call_id"
        :active-call-contact-name="active_call_contact_name"
        :status="notification.status"
        :phone-number="notification.phone_number | formatPhone"
        :contact-unknown="notification.contact_unknown"
        :communication-type="notification.communication_type"
        :communication-time=" notification.communication_time | formatCharmDateTime('MMM DD, YYYY, hh:mma', 'hh:mma') "
        :property="notification.communication_property"
        :source="communicationSource"
        :contact-id="notification.contact_id"
        :contact-name="notification.contact_name"
        :contact-status="notification.contact_status"
        :contact-phone="notification.contact_phone | formatPhone"
        :contact-email="notification.contact_email"
        :contact-address="notification.contact_address"
        :contact-access-codes="notification.contact_access_codes"
        :contact-units="notification.contact_units"
        :selected-contact-id="notification.selected_contact_id"
        :selected-contact-name="notification.selected_contact_name"
        :selected-contact-status="notification.selected_contact_status"
        :selected-contact-phone=" notification.selected_contact_phone | formatPhone "
        :selected-contact-email="notification.selected_contact_email"
        :selected-contact-address="notification.selected_contact_address"
        :selected-contact-access-codes=" notification.selected_contact_access_codes "
        :selected-contact-units="notification.selected_contact_units"
        :call-duration-time="notification.call_duration_time"
        :hold-duration-time="notification.hold_duration_time"
        :hold-duration-time-color="holdDurationTimeColor"
        :call-back-time="notification.call_back_time"
        :reservation-id="notification.reservation_id"
        :reservation-space-information=" notification.reservation_space_information "
        :reservation-move-in-date=" notification.reservation_move_in_date | formatDateTimeCustom('MMM DD, YYYY') "
        :reservation-code="notification.reservation_code"
        :reservation-in-store-price="notification.reservation_in_store_price"
        :reservation-web-only-price="notification.reservation_web_only_price"
        :add-as-additional-phone-number-button="!notification.phone_number_added_to_selected_contact"
        :add-as-additional-contact-button="!notification.contact_added_to_selected_contact"
        :switching-in-progress="notification.switching_in_progress"
        :playback-url="notification.playback_url"
        :voicemail-url="notification.voicemail_url"
        :block-caller-button="false"
        :email-button-disabled="emailButtonDisabled"
        :profile-button-disabled="profileButtonDisabled"
        @remove-selected-contact="removeSelectedContact()"
        @answer-call-button-clicked=" answerCallClicked()"
        @lead-button-clicked="handleActionPre(notification, 'Lead')"
        @profile-button-clicked="handleActionPre(notification, 'Profile')"
        @move-in-button-clicked="handleActionPre(notification, 'MoveIn')"
        @move-out-button-clicked="handleActionPre(notification, 'MoveOut')"
        @payment-button-clicked="handleActionPre(notification, 'Payment')"
        @transfer-button-clicked="handleActionPre(notification, 'Transfer') "
        @end-call-button-clicked=" endCallClicked(), resetDurationTimer() "
        @close="handleClose($event)"
        @dismiss="handleDismiss($event)"
        @send-to-voicemail-button-clicked=" sendToVoicemailClicked() "
        @call-back-button-clicked=" (type = 'mini-charm'), callBackClicked() "
        @put-on-hold-button-clicked=" holdButtonClicked() "
        @take-off-hold-button-clicked=" unholdButtonClicked() "
        @call-notes-button-clicked="callWrapRoute('call-notes')"
        @create-task-button-clicked="callWrapRoute('create-task')"
        @email-button-clicked="callWrapRoute('email')"
        @text-button-clicked="callWrapRoute('text')"
        @add-as-additional-contact="addAsAdditionalContact()"
        @add-as-additional-phone-number="addAsAdditionalPhoneNumber()"
        @voicemail-played="voicemailPlayed()"
        @confirmed="afterConfirmation()"
        @update:confirmation="updateConfirmation('')"
        @drag-stopped="handleDismissMiniCharmNotifications"
        @in-snapped-zone="handleInSnappedZone"
        @click="handleToastInteraction"
        @update:loading="updateLoading"
        class="charm-call-toast"
        :class="{ 'right-margin': isCharmNotificationToastOpen }"
      >
        <template v-slot:toastItems>
          <hb-toast-item
            v-show="incoming_notifications.length"
            v-for="(item, i) in incoming_notifications"
            :key="'incoming-notification-' + i"
            :id="item.id"
            :status="item.status"
            :contact-unknown="item.contact_unknown"
            :phone-number="item.phone_number"
            :contact-id="item.contact_id"
            :contact-name="item.contact_name"
            :contact-status="item.contact_status"
            :time="
              item.status == 'call in' || item.status == 'call out'
                ? ''
                : item.status == 'active'
                ? item.call_duration_time
                : item.call_back_time
            "
            mini-charm
            @click=" toastItemClickedFun(item, i) "
            :adding-in-progress="item.adding_in_progress"
            :switching-in-progress="item.switching_in_progress"
          >
          </hb-toast-item>
        </template>
        <template v-slot:searchContacts>
          <call-toast-auto-search
            :contact_address="notification.contact_address"
            :isActiveCall="true"
          >
          </call-toast-auto-search>
        </template>
      </hb-toast>
    </v-slide-x-reverse-transition>
    <space-selector
      v-if="showSpaceSelector"
      :contactUnits=" path == 'MoveIn' ? reservedContactUnits : leasedContactUnits"
      :showSpaceSelector="showSpaceSelector"
      :action="path"
      :contactId="contactId"
      :propertyId="notification.property_id"
      :tenantContact="tenantContact"
      :onModalClose="onSpaceSelectorClosed"
      :isActiveLeadWithoutContactUnits="isActiveLeadWithoutContactUnits"
      :isUnknownCaller="isUnknownCaller"
      :unknownCallerNumber="isUnknownCaller && notification.phone_number || ''"
      :properties="properties"
    >
    </space-selector>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { notificationMixin } from "@/mixins/notificationMixin.js";
import { EventBus } from "../../EventBus.js";
import api from "../../assets/api.js";
import SpaceSelector from "./SpaceSelectorModal.vue";
import CallToastAutoSearch from "./CallToastAutoSearch.vue";

export default {
  data() {
    return {
      holdDurationTimeColor: "success",
      holdIntervalCall: null,
      showSpaceSelector: false,
      callDetails: {},
      path: "",
      contactId: "",
      tenantContact: null,
      isSelectedContactFetched: false,
      answered: false,
      holdApiCallInProgress: false
    };
  },
  components: {
    SpaceSelector,
    CallToastAutoSearch
  },
  mixins: [notificationMixin],
  computed: {
    ...mapGetters({
      charmSubscribedAndFilteredProperties: 'charmSubscriptionStore/charmSubscribedAndFilteredProperties',
      isCharmNotificationToastOpen: "charmNotificationStore/isCharmNotificationToastOpen",
      twilioCallDetailsToUnhold: "charmCallStore/twilioCallDetailsToUnhold",
      activeCallTwilioDetails: 'charmCallStore/activeCallTwilioDetails',
      active_call_contact_name: "charmCallStore/activeCallContactName",
      searchedContactDetails:"charmCallStore/searchedContactDetails",
      incoming_notifications: "charmCallStore/incomingNotifications",
      isCharmCallToastOpen: "charmCallStore/isCharmCallToastOpen",
      outgoingPhoneNumber: 'charmCallStore/outgoingPhoneNumber',
      communication_title: "charmCallStore/communicationTitle",
      communication_icon: "charmCallStore/communicationIcon",
      searchedContact: "charmCallStore/searchedContact",
      loggedInUser: "authenticationStore/getUserData",
      active_call_id: "charmCallStore/activeCallId",
      notification: "charmCallStore/notification",
      confirmation: "charmCallStore/confirmation",
      properties: "propertiesStore/filtered",
      title: "charmCallStore/title",
      type: "charmCallStore/type",
      view: "charmCallStore/view",
      getInSnappedZone: "globalNotificationsStore/getInSnappedZone",
    }),
    isUnknownCaller(){
      if(this.searchedContact?.id){
        return false;
      }
      return this.notification.contact_unknown;
    },
    communicationSource(){
     if(this.notification.communication_source == "ppc_ad_campaign")
      return "Google Ad";
     return this.notification.communication_source;
    },
    leasedContactUnits(){
      if(this.searchedContact?.id){
        return this.searchedContactDetails?.contact_units?.filter(unit => unit.status !== "reserved") || [];
      }
      if (this.notification?.contact_units?.length) {
        return this.notification?.contact_units?.filter(unit => unit.status !== "reserved") || [];
      }
      return [];
    },
    reservedContactUnits() {
      if(this.searchedContact?.id){
        return this.searchedContactDetails?.contact_units?.filter(unit => unit.status === "reserved") || [];
      }
      if (this.notification?.contact_units?.length) {
        return this.notification?.contact_units?.filter(unit => unit.status === "reserved") || [];
      }
      return [];
    },
    emailButtonDisabled(){
      if(this.searchedContact?.id){
        return !this.searchedContactDetails?.contact_email;
      }
      return !this.notification.contact_email;
    },
    profileButtonDisabled() {
      if(this.notification?.selected_contact_id){
        return !this.notification?.selected_contact_status;
      }
      return !this.notification.contact_status;
    },
  },
  methods: {
    ...mapActions({
      readReservationNotifications: "charmNotificationStore/readReservationNotifications",
      readMissedCallsNotifications: "charmNotificationStore/readMissedCallsNotifications",
      readVoicemailNotifications: "charmNotificationStore/readVoicemailNotifications",
      checkIfAlreadyOnAnotherCall:'charmCallStore/checkIfAlreadyOnAnotherCall',
      updateNotificationStatus: "charmCallStore/updateNotificationStatus",
      notificationStatusActive: 'charmCallStore/notificationStatusActive',
      addIncomingNotification: "charmCallStore/addIncomingNotification",
      updateCommunicationType: "charmCallStore/updateCommunicationType",
      notificationStatusHold: 'charmCallStore/notificationStatusHold',
      interactNotification: 'charmCallStore/notificationInteraction',
      cancelSelectedContact: "charmCallStore/cancelSelectedContact",
      addAdditionalContact: 'charmCallStore/addAdditionalContact',
      setCharmNotification: 'charmCallStore/setCharmNotification',
      stopLoadingAnimation: 'charmCallStore/stopLoadingAnimation',
      setOutgoingCallData: "charmCallStore/setOutgoingCallData",
      addAdditionalPhone: 'charmCallStore/addAdditionalPhone',
      resetDurationTimer: "charmCallStore/resetDurationTimer",
      updateConfirmation:'charmCallStore/updateConfirmation',
      emptyNotification: "charmCallStore/emptyNotification",
      toastItemClicked: "charmCallStore/toastItemClicked",
      makeOutgoingCall: 'charmCallStore/makeOutgoingCall',
      setLoadingAction: 'charmCallStore/setLoadingAction',
      setCallWrapAction:'charmCallStore/setDatacallWrap',
      dismissCharmToast: "charmCallStore/handleDismiss",
      startHoldTimer: "charmCallStore/startHoldTimer",
      resetHoldTimer: "charmCallStore/resetHoldTimer",
      CloseCharmToast: "charmCallStore/handleClose",
      callWrapData:'charmCallStore/setDatacallWrap',
      updateTitle: "charmCallStore/updateTitle",
      updateType: "charmCallStore/updateType",
      updateView: "charmCallStore/updateView",
      endCall: "charmCallStore/endCall",
    }),
    async removeSelectedContact(){
      try{
       await this.cancelSelectedContact();
       this.isSelectedContactFetched = false;
       this.tenantContact = null;
      }catch(error){
        this.showMessageNotification({ type: 'error', description: error });
      }
    },
    isActiveLeadWithoutContactUnits() {
      if(this.searchedContact?.id){
        return (['active', 'active lead', 'retired', 'retired lead'].includes(this.searchedContactDetails?.contact_status?.toLowerCase()) && 
             (!this.searchedContactDetails.contact_units || this.searchedContactDetails.contact_units.length === 0));
      }
     return (['active', 'active lead', 'retired', 'retired lead'].includes(this.notification?.contact_status?.toLowerCase()) && 
             (!this.notification.contact_units || this.notification.contact_units.length === 0));
    },
    async answerCallClicked() {
      if (!this.answered) {
        this.answered = true;
        await this.checkIfAlreadyOnAnotherCall('answer-call-button-clicked');
        if (this.confirmation === '') {
          this.acceptTwilioCall(this.notification);
        }
      }
      setTimeout(() => { this.answered = false }, 2000);
    },
    async afterConfirmation(){
      if (this.confirmation === 'answer') {
        await this.holdApiCall();
        await this.notificationStatusHold();
        this.acceptTwilioCall(this.notification);
      }
      if (this.confirmation === 'take off hold') {
        await this.holdApiCall();
        await this.notificationStatusHold();
        await this.unholdApiCall();
        this.notificationStatusActive();
      }
      this.updateConfirmation('');      
    },
    endCallClicked() {
      this.hangUpTwilioCall(this.notification);
    },
    onSpaceSelectorClosed() {
      this.showSpaceSelector = false;
      this.path = "";
    },
    async setSelectSpaceModal() {
      if(this.searchedContact?.id && !this.isSelectedContactFetched ){
            try{
             const { contact } = await api.get(this,`${api.CONTACTS}${this.searchedContact.id}`);
             this.tenantContact = contact;
             this.isSelectedContactFetched = true;
            }catch(error){
              this.showMessageNotification({ type: 'error', description: error });
            }
          }
      else{
        if(this.contactId && (!this.tenantContact || this.tenantContact.id !== this.contactId)) {
          try {
          const { contact } = await api.get(this,`${api.CONTACTS}${this.contactId}`);
          this.tenantContact = contact;
          } catch (error) {
            this.showMessageNotification({ type: 'error', description: error });
          }
        }
      }
      this.showSpaceSelector = this.tenantContact ? true : false;
    },
    closeBlades(){
      EventBus.$emit("closeMoveOut");
      EventBus.$emit("closeTransfer");
      EventBus.$emit("closeMakePayment");
      EventBus.$emit("closeLeadIntake");
      EventBus.$emit("closeCreateTask");
    },
    async handleActionPre(callDetails, path){
      this.setCallWrapAction("");
      await this.closeBlades();
      this.interactNotification();
      if(this.searchedContact?.id){
        this.handleAction(this.searchedContactDetails,path); 
      }else{
        this.handleAction(callDetails, path);
      }
    },
    updateLoading(event) {
      this.setLoadingAction({"status": true, "eventStatus":event});
    },
    handleAction(callDetails, path) {
      this.path = path;
      this.callDetails = callDetails;
      let routePath = "";
      const { contact_id } = callDetails;
      this.contactId = contact_id;
      switch (path) {
        case "Lead":
          this.openLead();
          break;
        case "Profile":
          routePath = `/contacts/${this.contactId}`;
          if (this.$route.path !== routePath) {
            this.$router.push(routePath);
          } else {
            this.setLoadingAction({"status": false, "eventStatus": 'profile-button-clicked'});
          }
          break;
        case "Payment":
          EventBus.$emit("make_payment", {
            property_id: this.notification.property_id,
            contact_id: this.contactId
          });
          break;
        default:
          this.setSelectSpaceModal();
          break;
      }
    },
    async openLead() {
      if (this.contactId) {
        await this.$store.dispatch("onBoardingStore/getContactInfo", {contact_id: this.contactId});
        }
        let data={
          'charm_property_id':this.notification.property_id
        }
        EventBus.$emit("new_lead",data);
    },
    handleClose() {
      this.CloseCharmToast();
      this.setCallWrapAction("");
    },
    handleDismiss() {
      this.readNotification();
      this.dismissCharmToast(this.notification);
      this.setCallWrapAction("");
      
    },

    async holdButtonClicked() {
      this.holdApiCall();
      await this.notificationStatusHold();
    },

    async unholdButtonClicked() {
      await this.checkIfAlreadyOnAnotherCall('take-off-hold-button-clicked');
      if (this.confirmation === '') {
        await this.unholdApiCall();
        this.notificationStatusActive();
      }
    },

    async unholdApiCall() {
      await api.post(this, api.CHARM_CALL_HOLD_UNHOLD, {
        call_sid: this.twilioCallDetailsToUnhold?.data?.call,
        status: false,
        agent_id: this.loggedInUser.id,
        conf_name: this.twilioCallDetailsToUnhold?.data?.conference_name || '',
        facility_id: this.twilioCallDetailsToUnhold?.data?.facility,
        from: this.twilioCallDetailsToUnhold?.data?.from_phone,
        via: this.twilioCallDetailsToUnhold?.data?.via_phone,
        to: this.twilioCallDetailsToUnhold?.data?.to_phone,
        direction: this.twilioCallDetailsToUnhold?.data?.direction || ''
      })
      .then(r => { })
      .catch((error) => {
        this.showMessageNotification({ type: 'error', description: error });
      });
    },

    async holdApiCall() {
      if(this.holdApiCallInProgress){
        return
      }       
      this.holdApiCallInProgress = true
      await api.post(this, api.CHARM_CALL_HOLD_UNHOLD, {
        call_sid: this.activeCallTwilioDetails?.data?.call,
        status: true,
        agent_id: this.loggedInUser.id,
        conf_name: this.activeCallTwilioDetails?.data?.conference_name || '',
        facility_id: this.activeCallTwilioDetails?.data?.facility,
        from: this.activeCallTwilioDetails?.data?.from_phone,
        via: this.activeCallTwilioDetails?.data?.via_phone,
        to: this.activeCallTwilioDetails?.data?.to_phone,
        direction: this.activeCallTwilioDetails?.data?.direction || ''
      })
      .then(r => { })
      .catch((error) => {
        this.showMessageNotification({ type: 'error', description: error });
      })
      .finally(()=>{
        this.holdApiCallInProgress = false
      })
    },

    toastItemClickedFun(notification, i) {
      let payload = {
        notification: notification,
        i: i
      };
      this.toastItemClicked(payload);
    },

    startHoldTimerFun() {
      this.holdIntervalCall = setInterval(() => {
        this.startHoldTimer();
      }, 1000);
    },
    resetHoldTimerFun() {
      clearInterval(this.holdIntervalCall);
      this.resetHoldTimer();
    },
    navigateToTenantScreen(sub, contactId) {
        let route = "";
        this.setCallWrapAction("");
        switch (sub) {
            case "call-notes":
                this.setCallWrapAction("onNoteShow");
                route = `/contacts/${contactId}`;
                break;

            case "email":
                this.setCallWrapAction("onEmailShow");
                route = `/contacts/${contactId}`;
                break;

            case "text":
                this.setCallWrapAction("onSmsShow");
                route = `/contacts/${contactId}`;
                break;

            case "profile":
                route = `/contacts/${contactId}`;
                break;

            case "create-task":
                EventBus.$emit("createTask");
                break;
        }
        return route;
    },
    naviagteToLeadsScreen(sub, contactId) {
      let route = "";
      this.setCallWrapAction("");
      switch (sub) {
        case "call-notes":
            route = `/leads`;
            setTimeout(() => {
                EventBus.$emit("handleClick", contactId);
            }, 3000);
            setTimeout(() => {
                EventBus.$emit("setView", "interactions");
                this.setCallWrapAction("onNoteShow");
            }, 5000);
            break;

        case "email":
            route = `/leads`;
            setTimeout(() => {
                EventBus.$emit("handleClick", contactId);
            }, 3000);
            setTimeout(() => {
                EventBus.$emit("setView", "interactions");
                this.setCallWrapAction("onEmailShow");
            }, 5000);
            break;

        case "text":
            route = `/leads`;
            setTimeout(() => {
                EventBus.$emit("handleClick", contactId);
            }, 3000);
            setTimeout(() => {
                EventBus.$emit("setView", "interactions");
                this.setCallWrapAction("onSmsShow");
            }, 5000);
            break;

        case "create-task":
            EventBus.$emit("createTask");
            break;
      }
      return route;
    },
    callWrapRoute(sub) {
      this.closeBlades();
      let routePath = "";
      let isContactUnknown = this.notification.contact_unknown;
      let contactId = this.notification.contact_id;
      let phoneNumber = this.notification.phone_number;
      let caller = this.checkTenantOrLead(this.notification.contact_status);
      if (this.searchedContact?.id) {
          isContactUnknown = false;
          contactId = this.searchedContactDetails.contact_id;
          phoneNumber = this.searchedContactDetails.phone_number;
          caller = this.checkTenantOrLead(this.searchedContactDetails.contact_status)
      }
      if (!isContactUnknown && caller === 'Tenant') {
          routePath = this.navigateToTenantScreen(sub, contactId);
      } else if (!isContactUnknown && caller === 'Lead') {
          routePath = this.naviagteToLeadsScreen(sub, contactId);
      } else {
          let data = {};
          switch (sub) {
              case "call-notes":
                  data = {
                    id: contactId,
                    contact_id: contactId,
                    phoneNumber: phoneNumber,
                    action: 'onNoteShow',
                  };
                  EventBus.$emit("HB-Navigation:CommunicationCenter", data);
                  break;

              case "text":
                  data = {
                    id: contactId,
                    contact_id: contactId,
                    phoneNumber: phoneNumber,
                    action: 'onSmsShow',
                  };
                  EventBus.$emit("HB-Navigation:CommunicationCenter", data);
                  break;

              case "create-task":
                  setTimeout(() => {
                      EventBus.$emit("createTask");
                  }, 2000);
                  break;
              case "email":
                  data = {
                        id: contactId,
                        contact_id: contactId,
                        phoneNumber: phoneNumber,
                        action: 'onEmailShow',
                      };
                  EventBus.$emit("HB-Navigation:CommunicationCenter", data);
                 
          }
      }

      if (routePath && (this.$route.path !== routePath)) {
          this.$router.push(routePath);
      }
    },
    checkTenantOrLead(contact_status) {
      if (contact_status && ['active', 'active lead', 'retired', 'retired lead'].includes(contact_status.toLowerCase())) {
        return 'Lead';
      } else if (contact_status){
        return 'Tenant';
      }
      return '';
    },
    sendToVoicemailClicked() {
      api.post(this, api.CHARM_CALL_SEND_TO_VOICEMAIL, {
          id: this.notification.id,
          call_sid: this.notification?.twilioDetails?.call,
          agent_id: this.loggedInUser?.id,
          agent: this.notification?.twilioDetails?.agent,
          from: this.notification?.twilioDetails?.from_phone,
          via: this.notification?.twilioDetails?.via_phone,
          to: this.notification?.twilioDetails?.to_phone,
          property_id: this.notification.property_id,
          conf_name: this.notification?.twilioDetails?.conference_name || '',
          facility_id: this.notification?.twilioDetails?.facility,
          direction: this.notification?.twilioDetails?.direction || ''
      }).then(r => { })
      .catch((e) => {
        console.log(e)
      });
      this.handleDismiss();
    },

    async callBackClicked() {
      this.readNotification();
      this.interactNotification();
      this.checkIfAlreadyOnAnotherCall('call-back-button-clicked');
      await this.setCharmNotification(this.notification);
      await this.setOutgoingCallData();
      if (this.outgoingPhoneNumber && this.notification?.property_id) {
        let data = {
          To: this.outgoingPhoneNumber,
          agent_id: this.loggedInUser?.id,
          property_id: this.notification?.property_id
        }
        this.makeOutgoingCall(data);
      }

    },
    readNotification() {
      if (this.notification.status == "reservation") {
        this.readReservationNotifications({ notification: this.notification, properties: this.charmSubscribedAndFilteredProperties });
      } else if (this.notification.status  == "missed") {
        this.readMissedCallsNotifications({ notification: this.notification, properties: this.charmSubscribedAndFilteredProperties });
      } else if (this.notification.status  == "voicemail") {
        this.readVoicemailNotifications({ notification: this.notification, properties: this.charmSubscribedAndFilteredProperties });
      }
    },
    async addAsAdditionalContact() {
      let payload = {
        layout: 'charmCall',
        caller: this.notification,
        searchedContact: this.searchedContact
      };
      let notify = await this.addAdditionalContact(payload);
      this.showMessageNotification({ type: notify.type, description: notify.msg });
    },
    async addAsAdditionalPhoneNumber() {
      let payload = {
        layout: 'charmCall',
        caller: this.notification,
        searchedContact: this.searchedContact,
        formatted_phone_number: this.$options.filters.formatPhone(this.notification.phone_number)
      };
      let notify = await this.addAdditionalPhone(payload);
      this.showMessageNotification({ type: notify.type, description: notify.msg });
    },
    voicemailPlayed() {
      this.interactNotification();
    },
    handleToastInteraction(data) {
      this.interactNotification();
    }
  },
  watch: {
    "notification.status"() {
      if (this.notification.status === "hold") {
        this.startHoldTimerFun();
      }
      if (this.notification.status === "active"){
        this.resetHoldTimerFun();
      }
    },
    view() {
      this.updateTitle("");
    },
    'notification.loading'() {
      if (this.notification.loading) {
        setTimeout(() => {
          this.stopLoadingAnimation()
        }, 15000);
      }
    }

  }
};
</script>

<style lang="scss" scoped>
#charm-call {
  .charm-call-toast {
    height: min-content !important;
  }

  .right-margin {
    right: 360px !important;
  }
}
</style>
