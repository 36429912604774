<template>
    <v-col class="px-4 py-5">
        <v-row class="ma-0">
            <v-col
                :class="button.class" 
                v-for="(button, index) in buttons" 
                :key="index"
            >
            <div height="100%" :disabled="tasksLoading" width="100%" :class="{'disable-buttons' : tasksLoading}" @click="setSection(button)" class="task-manage-btn btn-style secondary-button px-2 pt-3 pb-3 relative-box">
                <span class="counter-absolute v-badge__badge hb-dot-task" v-if="count(button) > 0">{{ count(button) }}</span>                            
                <hb-icon icon color="#101318">
                    {{ button.icon.mdi }}
                </hb-icon>

                <p class="mb-0 button-title">{{ button.title}}</p>
            </div>
            </v-col>
        </v-row>
    </v-col>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: "Buttons",
        computed:{
            ...mapGetters({
                sections: 'taskCenterStore/sections',
                tasksCount: 'taskCenterStore/tasksCount',
                buttons: 'taskCenterStore/buttons',
                tasksLoading: 'taskCenterStore/tasksLoading'
            }),
        },
        methods:{
        ...mapActions({
                setActiveSection: 'taskCenterStore/setActiveSection',
                setSubcategory: 'taskCenterStore/setSubcategory',
                setActiveDrawerComponent: 'navigationStore/setActiveDrawerComponent'
            }),

            count(button){
                let tc = this.sections.find(s =>s.name === button.action).task_categories; 
                if(!tc) return 0;
                let total = tc.reduce((a,b) => {
                    if(!this.tasksCount[b]) return a; 
                    return a + this.tasksCount[b].count; 
                }, 0); 
                return total || 0

            },
            setSection(button){
                if(this.tasksLoading) return;
                
                this.setActiveSection(button.action);
                this.setSubcategory(null);
                this.setActiveDrawerComponent({ type: 'show_task_center', active_section:button.action })
            }
        },
        props: ["counts", "disableButtons"]
    }
</script>

<style scoped lang="scss">
    .task-manage-btn{
        min-height: 85px;
        box-sizing: border-box;
        border-radius: 4px;
        cursor: pointer;
    }
    .button-title{
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 115%;
        margin-top: 5px;
        text-align: center;
        color: #101318;
    }
    .relative-box{
        position: relative;

        span.counter-absolute{
                bottom: calc(100% - 12px); 
                left: calc(100% - 11px); 
                right: auto; top: auto;
                font-weight: 500;
                font-size: small;
                z-index: 1;
        }
    }
    .disable-buttons{
        pointer-events: none;
        cursor: not-allowed;
        opacity: 0.65;
        box-shadow: none;
    }
    
</style>