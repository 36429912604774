<template>
    <v-menu>
        <template v-slot:activator="{ on }">
            <span v-on="on">
                <v-icon>mdi-dots-horizontal</v-icon>
            </span>
        </template>
        <v-list>
            <v-list-item v-for="(option, index) in options" :key="index" @click.prevent="optionClicked(option)">
                <v-list-item-title>{{ option.title }}</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>
<script>
export default {
    data() {
        return {

        }
    },
    props: ['options'],
    methods: {
        optionClicked (option) {
            this.$emit('option-clicked', option)
        }
    }
}
</script>
<style>
.hb-context-menu {
    position: relative;
}

.hb-context-menu--list {
    display:none;
    position: absolute;
    top: 1px;
    left: 1px;
    margin: 0;
    list-style: none;
}
.hb-context-menu:hover .hb-context-menu--list {
    display: block;
}
</style>