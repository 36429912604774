<template>
    <v-flex>
    <div v-show="cmsLoader && !isHBUser && componentCreated" class="cover-spinner">
        <AsyncLoader></AsyncLoader>
    </div>
    <div v-show="!cmsLoader || isHBUser">
        <audio ref="audio_receive" src="/audio/chat_alert_2.mp3"></audio>
        <app-header v-on:toggle-drawer="drawer = !drawer" :drawer="isHBUser ? drawer : true" :nav_drawer="isHBUser ? nav_drawer : true"/>
        <navigation v-if="isAdmin && isHBUser" :drawer.sync="drawer"  @close="(value) => {
            drawer = value
        }" />
        <app-navigation v-if="isAdmin" :drawer.sync="drawer" @settingsStatus="setCmsLoader"/>
        <notifications v-if="isAdmin"></notifications>
        <GlobalNotification></GlobalNotification>
        <v-flex class="hb-flex platform-page" :class="{'has-drawer': drawer && $vuetify.breakpoint.width > 1259}">
            <slot v-if="isLoaded" />
            <div v-else class="loading"></div>
        </v-flex>

        <v-navigation-drawer
            v-if="isAdmin"
            v-model="nav_drawer"
            :class="[ drawer_type, zIndex, 'side-drawer-margin' ]"
            fixed
            :width="drawer_width"
            :stateless="!$vuetify.breakpoint.xs"
            :temporary="temporary"
        >
            <quick-actions
                :open_panel="open_panel"
                v-if="quick_actions"
                @pending="setPending"
                @new_lead="setNewLead"
                @new_payment="setNewPayment"
                @sell_merchandise="sellMerchandise"
                @close="closeQuickActions"
            >
            </quick-actions>

            <lead-intake
                v-if="showLeadIntake"
                :property_id="property_id"
                :contact_id="contact_id"
                :unit_id.sync="unit_id"
                :location="location"
                :showSpacesList="showSpacesList"
                :unKnownNumber="unKnownNumber"
                :charm_property_id="charm_property_id"
                :selectedLeadId="selectedLeadId"
                @close="closeLeadIntake"
                @close_then_go_to_reservation="closeThenGoToReservation"
                style="overflow: hidden"
            >
            </lead-intake>

            <make-payment
                v-if="showMakePayment"
                :key="payment_key"
                :property_id="property_id"
                :contact_id="contact_id"
                @close="closeMakePayment"
                @reload="payment_key++"
                style="overflow: hidden"
            >
            </make-payment>

            <sell-merchandise
                v-if="showSellMerchandise"
                :key="merchadise_key"
                :property_id="property_id"
                :selected_contact="contact"
                :lease_id="lease_id"
                @close="closeSellMerchandise"
                @reload="payment_key++"
                style="overflow: hidden"
                :active-tab="activeMerchandiseTab"
            >
            </sell-merchandise>

            <omni-search
                :class="{ 'z-250' : showOnlySettingsNavigation }"
                v-if="show_omni_Search"
                @close="hideOmniSeacrh"
            >
            </omni-search>

            <move-out
                v-if="showMoveOut"
                :property_id="property_id"
                :contact="contact"
                :lease_id="lease_id"
                :unit_id="unit_id"
                :moveOutDate="move_out_date"
                @close="closeMoveOut"
                style="overflow: hidden"
            >
            </move-out>

            <transfer
                v-if="showTransfer"
                :property_id="property_id"
                :contact="contact"
                :lease_id="lease_id"
                :unit_id="unit_id"
                :access="access"
                :space-access="spaceAccess"
                :is-space-access-codes-vendor="isSpaceAccessCodesVendor"
                :transfer="transferData"
                @close="closeTransfer"
                style="overflow: hidden"
            >
            </transfer>

            <component
                :is="rateChangeComponent"
                :rateItem="rateChangeData"
                :rate_config_id="rateChangeID"
                :type="rateChangeEditType"
                :fromTaskCenter="true"
                :edit_configuration="false"
                :edit_rate_change="rateChangeEdit"
                :rate_change_property_id="rateChangePropertyID"
                @close="closeRateChange"
                style="overflow: hidden"
            />

            <delinquency-center
            v-if="show_delinquency_center"
            :contact="delinquencyData"
            @close="hideDelinquencyCenter"
            >
            </delinquency-center>

            <lead-follow-up
            :reservation="reservation"
            v-if="show_lead_follow_up"
            @close="hideLeadFollowUp"
            >
            </lead-follow-up>

            <task-center
                v-if="show_task_center"
                @close="closeTaskCenter"
            />
            <review-rent-change
                v-if="review_rent_change"
                :rent-queue-data="rentQueueData.data"
                :rm-permissions="rentQueueData.rent_management_Permissions"
                @close="closeTaskCenter"
            />
            <selected-rent-plan-defaults
                v-if="show_selected_rent_defaults"
                :space-data="rentDefaultSelectedData"
                @close="closeTaskCenter"
            />
            <communication-center
                v-if="show_communication_center"
                :contact_id="contact_id"
                :blade_data="blade_data"
                @close="closeCommuncationCenter"
            />
            
            <TenantPaymentsApplication
                v-if="showTenantPaymentsApplication"
                @close="closeTenantPaymentsApplication"
            />
            <TemplateManager
                    v-if="showCommunicationTemplate"
                    :currentItem="communicationTemplateData"
                    @close ="closecommunicationTemplate"
                />

            <MoveIn
                v-if="showMoveIn"
                :contact="MovinData.contact"
                :property_id="MovinData.property"
                :contact_id="contact_id"
                :unit_id.sync="unit_id"
                @close="closeMovin"
            />
                            <!--  // Restricted Permission warning popup -->
                            <hb-modal
                            v-model="showNoPermissionDialogNationalAccountMoveIn"
                            size="medium"
                            title="You Don’t Have Permission"
                            :footerOff="true"
                            confirmation
                            @close="showNoPermissionDialogNationalAccountMoveIn = false"
                            show-help-link
                        >
                            <template v-slot:content>
                            <div class="px-6 py-4">
                                <div class="pb-2">
                                    You do not have the required permission to perform this action.
                                </div>
                                <div>
                                    Required Permission: 
                                    <span class="hb-font-body-medium">
                                        Move-In National Account
                                    </span>
                                </div>
                            </div>
                            </template>
                            <!-- move out permission national account -->
                            </hb-modal>
                            <hb-modal
                            v-model="showNoPermissionDialogNationalAccountMoveOut"
                            size="medium"
                            title="You Don’t Have Permission"
                            :footerOff="true"
                            confirmation
                            @close="showNoPermissionDialogNationalAccountMoveOut = false"
                            show-help-link
                        >
                            <template v-slot:content>
                            <div class="px-6 py-4">
                                <div class="pb-2">
                                    You do not have the required permission to perform this action.
                                </div>
                                <div>
                                    Required Permission: 
                                    <span class="hb-font-body-medium">
                                        Move-Out National Account
                                    </span>
                                </div>
                            </div>
                            </template>
                            </hb-modal>

            <RecordPayment
                v-if="showRecordPayments"
                :contact="MovinData"
                :property_id="property_id"
                :contact_id="contact_id"
                :unit_id.sync="unit_id"
                @close="closeRecordPayment"
            />

            <AccountingTemplates
                v-if="showAccountingTemplates"
                @close="closeAccountingTemplates"
                :selected="accountingTemplateData"
            />

            <AuctionManager
                v-if="show_auction_manager"  
                :auction-lease.sync="auctionLease"
                :property_id="auctionLease.Unit.property_id ? auctionLease.Unit.property_id : ''"
                :contact_id="auctionContactId"
                @close="hideDrawer"
                :start-on-step="auctionStep ? auctionStep : null"
            />
        </v-navigation-drawer>
        <modal v-if="hasSidebar" allow-click-away="true" :hideHeader="true" :noPadding="true" :size="[slideOutProps.invoice_id ? 'lg':'']" :style="slideOutProps?.unit_id ? 'z-index:999;' : ''">
            <div slot="body">
            <div class="master-modal-content-wrapper" :class="[slideOutProps.contact_id || slideOutProps.payment_id || slideOutProps.invoice_id ? '':'pb-3']">
                <component
                :is="slideOutComponent"
                :contact_id="slideOutProps.contact_id"
                :invoice_id="slideOutProps.invoice_id"
                :unit_id="slideOutProps.unit_id"
                :view="slideOutProps.view"
                :property_id="slideOutProps.property_id"
                :selected="slideOutProps.property"
                :maintenance_id="slideOutProps.maintenance_id"
                :payment_id="slideOutProps.payment_id"
                :show="slideOutProps.tab"
                :fromMasterVueFile="true"
                @close="$store.dispatch('navigationStore/closeSlideOut')"
                />
            </div>
            </div>
        </modal>

        <auction-dialog 
            v-if="show_auction_model" 
            v-model="show_auction_model" 
            :auctionLease="auctionLease"
            :property_id="auctionLease.Unit.property_id ? auctionLease.Unit.property_id : ''"
            :contact_id="auctionLease.Tenants[0].contact_id ? auctionLease.Tenants[0].contact_id : ''"
            @close="closeAuctionModal">
        </auction-dialog>

        <CloseOfDay 
            v-if="showCloseOfDay" 
            v-model="showCloseOfDay" 
            @close="hideCloseOfDayModal">
        </CloseOfDay>
        <payments-notification-modal
            v-model="notificationModal"
            :type="notificationType"
        >
        </payments-notification-modal>
        </div>
    </v-flex>
</template>

<script>
  import Vue from 'vue';
import AppHeader from '../components/includes/AppHeader/index.vue';
import AppNavigation from '../components/includes/AppNavigation/index.vue';
import Navigation from '../components/includes/Navigation.vue';
import { mapGetters, mapActions, mapMutations } from 'vuex'; 
import { EventBus } from '../EventBus.js';
import store from '../store';
import Modal from '../components/assets/Modal.vue';

import Notifications from '../components/assets/Notifications.vue';
import QuickActions from '../components/QuickActions.vue';
import LeadIntake from '../components/includes/LeadIntake/Index.vue';
import MakePayment from '../components/includes/MakePayment/Index.vue';
import SellMerchandise from '../components/includes/Merchandise/Index.vue';
import OmniSearch from '../components/assets/OmniSearch.vue';
import MoveOut from '../components/includes/MoveOut/index.vue';
import Transfer from '../components/includes/Transfer/Index.vue';
import DelinquencyCenter from '../components/includes/DelinquencyCenter/Index.vue'
import LeadFollowUp from '../components/includes/LeadIntake/LeadFollowUp.vue'
import TaskCenter from '../components/includes/TaskCenter/Index.vue';
import ReviewRentChange from '../components/revenue_management/rent_management/ReviewRentChange.vue';
import SelectedRentPlanDefaults from '../components/revenue_management/rent_management/SelectedRentPlanDefaults.vue';
import AuctionDialog from '../components/leases/AuctionDialog.vue';
import CloseOfDay from '../components/settings/Accounting/CloseOfDay.vue';
import CommunicationCenter from '../components/includes/CommunicationCenter/Index.vue';
import TenantPaymentsApplication from '../components/settings/PaymentConfiguration/TenantPaymentsApplication/Index.vue';
import TemplateManager from '../components/settings/CommunicationTemplates/TemplateManager/Index.vue' 
import MoveIn from '../components/contacts/NationalAccount/MoveIn/Index.vue'
import RecordPayment from '../components/contacts/NationalAccount/ApplyPayments/Index.vue'
import AccountingTemplates from '../components/settings/AccountingTemplates/AccountingTemplatesDrawer.vue';
import AsyncLoader from '../components/includes/Loading.vue';
import { notificationMixin } from "../mixins/notificationMixin.js";
import { webNotificationMixin } from "../mixins/webNotificationMixin.js";
import GlobalNotification from '../components/global_notification/Index.vue';

// import DisplayInvoice from '../components/invoices/DisplayInvoice.vue';
// Vue.component('invoice', DisplayInvoice);

import SlideOutWindow from '../components/includes/SlideOutWindow.vue';

import api from '../assets/api.js';

import ReviewRateChange from '../components/rate_management/ReviewRentChanges.vue';
import FinalizeRateChange from '../components/rate_management/FinalizeRentChanges.vue';
import AddRateChange from '../components/rate_management/AddRateChange.vue';
import PaymentsNotificationModal from '../components/payments/PaymentsNotificationModal.vue';

//BCT: Mini-charm
import CharmCall from '../components/charm/CharmCall.vue';
import moment from "moment";

import AuctionManager from '../components/leases/AuctionManager.vue';

export default {
    name: 'master-layout',
    data() {
        return {
            showNoPermissionDialogNationalAccountMoveIn:false,
            showNoPermissionDialogNationalAccountMoveOut:false,
            deviceSetup:false,
            cmsLoader: false,
            componentCreated: false,
            drawer: true,
            socket: {},
            socketEvents: {},
            open_panel: '',
            payment_key:0,
            merchadise_key: 0,
            drawer2: true,
            nav_drawer: false,
            drawer_type: false,
            drawer_width: 0,
            showMakePayment: false,
            showSellMerchandise: false,
            quick_actions: false,
            property_id: null,
            contact_id: null,
            contact: null,
            unit_id: null,
            spaceAccess: [],
            isSpaceAccessCodesVendor: false,
            lease_id: null,
            temporary: false,
            location: '',
            show_bulk_edit_unit_details: false,
            show_bulk_edit_unit_details_units: [],
            show_omni_Search: false,
            show_delinquency_center: false,
            show_lead_follow_up: false,
            showMoveOut: false,
            showTransfer: false,
            transferData: {},
            rateChangeComponent: '',
            rateChangeData: null,
            rateChangeID: null,
            rateChangeEditType: 'manual',
            rateChangeEdit: false,
            rateChangePropertyID: '',
            show_task_center: false,
            review_rent_change:false,
            show_selected_rent_defaults: false,
            show_communication_center: false,
            showTenantPaymentsApplication: false,
            showCommunicationTemplate: false,
            showMoveIn: false,
            showRecordPayments: false,
            showAccountingTemplates: false,
            accountingTemplateData: {},
            delinquencyData:null,
            auctionLease: {},
            show_auction_model: false,
            showCloseOfDay: false,
            successError: false,
            successErrorType: '',
            successErrorDescription: '',
            successErrorList: [],
            successErrorTitle: '',
            successErrorIcon: '',
            successErrorComplete: false,
            successErrorContent: '',
            successErrorFooter: '',
            successErrorPersistent: false,
            successErrorTimeout: '',
            rentQueueData: {},
            rentDefaultSelectedData: {},
            notificationModal: false,
            notificationType: '',
            charm_property_id:'',
            blade_data: {},
            communicationTemplateData: '',
            MovinData:'',
            selectedLeadId:'',
            show_auction_manager: false,
            auctionContactId: '',
            auctionStep: null,
        }
    },
  mixins: [notificationMixin,webNotificationMixin],

    components: {
        AppHeader,
        AppNavigation,
        AsyncLoader,
        Navigation,

        Notifications,

        QuickActions,
        LeadIntake,
        MakePayment,
        SellMerchandise,
        OmniSearch,
        MoveOut,
        Transfer,
        DelinquencyCenter,
        LeadFollowUp,
        TaskCenter,
        CommunicationCenter,
        TenantPaymentsApplication,
        TemplateManager,
        MoveIn,
        RecordPayment,
        AccountingTemplates,

        SlideOutWindow,
        Modal,
        ReviewRateChange,
        ReviewRentChange,
        SelectedRentPlanDefaults,
        FinalizeRateChange,
        AddRateChange,
        GlobalNotification,
        AuctionDialog,
        CloseOfDay,
        PaymentsNotificationModal,
        CharmCall,
        AuctionManager,
    },
    computed:{
        ...mapGetters({
            charmSubscribedAndFilteredProperties: "charmSubscriptionStore/charmSubscribedAndFilteredProperties",
            hasCharmSubscribedPropertySelected: 'charmSubscriptionStore/hasCharmSubscribedPropertySelected',
            isCharmSubscribedProperty: 'charmSubscriptionStore/isCharmSubscribedProperty',
            reservationsNotifications:'charmNotificationStore/reservationsNotifications',
            showOnlySettingsNavigation: 'navigationStore/showSettingsNavigationOnly', 
            isMissedCallToastAvailable: 'charmCallStore/isMissedCallToastAvailable',
            isVoicemailToastAvailable: 'charmCallStore/isVoicemailToastAvailable',
            isNotificationInteracted: 'charmCallStore/isNotificationInteracted',
            isPropertySelected: 'charmSubscriptionStore/isPropertySelected',
            activeDrawerComponent: 'navigationStore/activeDrawerComponent',
            isCallToastAvailable: 'charmCallStore/isCallToastAvailable',
            isNotificationActive: 'charmCallStore/isNotificationActive',
            isCharmCallToastOpen:'charmCallStore/isCharmCallToastOpen',
            getNotificationId: 'reportLibraryStore/getNotificationId',
            isSameNotification: 'charmCallStore/isSameNotification',
            slideOutComponent: 'navigationStore/slideOutComponent',
            hasPermission: 'authenticationStore/rolePermission',
            taskCenterOpen: 'taskCenterStore/tasksCenterOpen',
            showLeadIntake: 'navigationStore/hasLeadIntake',
            slideOutProps: 'navigationStore/slideOutProps',
            userData: 'authenticationStore/getUserData',
            notification: "charmCallStore/notification",
            bulkEditMode: 'bulkEditStore/bulkEditMode',
            isHBUser: 'authenticationStore/isHBUser',
            isLoaded: 'authenticationStore/isLoaded',
            properties: 'propertiesStore/filtered',
            isAdmin: 'authenticationStore/isAdmin',
            activeMerchandiseTab: 'navigationStore/activeMerchandiseTab',

        }),
        hasSidebar(){
            return !!this.slideOutComponent;
        },
		hasMiniCharmPermission(){
            return this.hasPermission('mini_charm');
        },
        zIndex() {
            //Handled three layer z-index value for drawers and modals
            //to handle setting drawer active state
            if(this.showOnlySettingsNavigation && this.activeDrawerComponent) return 'navigation-drawer-on-settings-z-203';
            //to handle accounting add journal template modal when setting drawer active
            // if(this.activeDrawerComponent && this.activeDrawerComponent.type == 'showAccountingTemplates') return 'accounting-template-z-203';
            //to handle task center create task modal when setting drawer active
            // if(this.activeDrawerComponent && this.activeDrawerComponent.type == 'show_task_center' && this.showOnlySettingsNavigation) return 'show-task-center-z-204';
            //to handle task center create task modal when setting drawer is inactive
            // if(this.activeDrawerComponent && this.activeDrawerComponent.type == 'show_task_center' && !this.showOnlySettingsNavigation) return 'show-task-center-z-202';
        }
    },
    mounted(){
        EventBus.$on('HB-Navigation:QuickLinks', this.showQuickActions);
        EventBus.$on('HB-Navigation:Search', this.showOmniSearch);
        EventBus.$on('HB-Navigation:TaskCenter', this.showTaskCenter);
        // this.$root.$on('HB::Overlock:Unlock', (obj)=>{
        //   console.log("HB::Overlock:Unlock", obj)
        // });
        this.loadActiveDrawer();
        window.onbeforeunload = (e) => {
            this.destroyWebsockets()
            if(this.hasPermission('mini_charm')){
            let data = {
                agent_id: this.userData.id,
                // availability: 'unavailable',
                logged_in: false,
                from: 'close-browser',
                facilities: {
                    available: [],
                    unavailable: []
                },
                storedBrowserID : localStorage.getItem('browserID')            
            }
            
                this.$store.dispatch('charmSubscriptionStore/setAvailableAgent', data);
                this.deviceSetup = false
                this.unregisterTwilioDevice();
                this.unregisterTwilioDevice2()
                sessionStorage.removeItem('charm_subscribed_properties'); 
            }          
            
        }
    },
    created(){
        this.drawer = this.$vuetify.breakpoint.width > 1259;
        this.componentCreated = true;
        if(this.properties.length === 0){
            // this.setProperties({all: true});
        }
        this.setSocketEvents();
        EventBus.$on('sell_merchandise', this.sellMerchandise);
        EventBus.$on('make_payment', this.setNewPayment);
        EventBus.$on('new_lead', this.setNewLead);
        EventBus.$on('bulk_edit_unit_details', this.bulkEditUnitDetails);
        EventBus.$on('move_out', this.setMoveOut);
        EventBus.$on('transfer', this.setTransfer);
        EventBus.$on('HB-Navigation:DelinquencyCenter', this.showDelinquencyCenter);
        EventBus.$on('HB-Navigation:CommunicationCenter', this.showCommunicationCenter);
        EventBus.$on('HB-Navigation:LeadFollowUp', this.showLeadFollowUp);
        EventBus.$on('HB-Navigation:TenantPaymentsApplication', this.tenantPaymentsApplication);
        EventBus.$on('HB-Navigation:CommunicationTemplate',this.CommunicationTemplate)
        EventBus.$on('HB-Navigation:NationalAccount:Movin',this.nationalAccountMovin)
        EventBus.$on('HB-Navigation:NationalAccount:RecordPayment',this.nationalAccountRecordPayments)
        EventBus.$on('HB-Navigation:AccountingTemplates', this.accountingTemplates);
        EventBus.$on('rate_change', this.setRateChange);
        EventBus.$on('schedule_auction', this.showAuctionModal);
        EventBus.$on('download_center', this.showDownloadCenter);
        EventBus.$on('HB-Navigation:CloseOfDay', this.showCloseOfDayModal);
        EventBus.$on('show-prop-selector', this.showPropSelector);
        EventBus.$on('show-rent-review', this.showRentChange);
        EventBus.$on('show-rent-plan-defaults', this.showSelectedRentDefaults);
        // BCT: CHR - 165
        EventBus.$on('closeMoveOut', this.closeMoveOut);
        EventBus.$on('closeTransfer', this.closeTransfer);
        EventBus.$on('closeMakePayment', this.closeMakePayment);
        EventBus.$on('closeLeadIntake', this.closeLeadIntake);
        EventBus.$on('destroyWebsockets', this.destroyWebsockets);
        EventBus.$on('updateBrowserIdentifier', this.updateBrowserIdentifier);
        EventBus.$on('hideNavigationDrawer', this.hideNavigationDrawer);
        EventBus.$on('HB-Navigation:AuctionManager', this.showAuctionManager);
        this.setUpWebsockets();
        
        // BCT: charm
        if (this.hasPermission('mini_charm') && this.hasCharmSubscribedPropertySelected && !this.deviceSetup) {
            this.setUpTwilioClient();
            this.getCharmNotifications();
            this.deviceSetup = true
            this.$store.dispatch('charmCallStore/initilizeCharmCallState');
        }
    },
    destroyed(){
        EventBus.$off('HB-Navigation:Search', this.dispatchOmniSearch);
        EventBus.$off('HB-Navigation:QuickLinks', this.showQuickActions);
        EventBus.$off('HB-Navigation:TaskCenter', this.showTaskCenter);
        EventBus.$off('sell_merchandise', this.sellMerchandise);
        EventBus.$off('make_payment', this.setNewPayment);
        EventBus.$off('new_lead', this.setNewLead);
        EventBus.$off('bulk_edit_unit_details', this.bulkEditUnitDetails);
        EventBus.$off('move_out', this.setMoveOut);
        EventBus.$off('transfer', this.setTransfer);
        EventBus.$off('HB-Navigation:DelinquencyCenter', this.showDelinquencyCenter);
        EventBus.$off('HB-Navigation:CommunicationCenter', this.showCommunicationCenter);
        EventBus.$off('HB-Navigation:LeadFollowUp', this.showLeadFollowUp);
        EventBus.$off('HB-Navigation:TenantPaymentsApplication', this.tenantPaymentsApplication);
        EventBus.$off('HB-Navigation:CommunicationTemplate', this.CommunicationTemplate);
        EventBus.$off('HB-Navigation:NationalAccount:Movin',this.nationalAccountMovin)
        EventBus.$off('HB-Navigation:NationalAccount:RecordPayment',this.nationalAccountRecordPayments)
        EventBus.$off('HB-Navigation:AccountingTemplates', this.accountingTemplates);
        EventBus.$off('rate_change', this.setRateChange);
        EventBus.$off('schedule_auction', this.showAuctionModal);
        EventBus.$off('download_center', this.showDownloadCenter);
        EventBus.$off('HB-Navigation:CloseOfDay', this.showCloseOfDayModal);
        EventBus.$off('show-prop-selector', this.showPropSelector);
        EventBus.$off('show-rent-review', this.showRentChange);
        EventBus.$off('show-rent-plan-defaults', this.showSelectedRentDefaults);
        EventBus.$off('hideNavigation', this.hideNavigationDrawer);
        // BCT: CHR - 165
        EventBus.$off('closeMoveOut', this.closeMoveOut);
        EventBus.$off('closeTransfer', this.closeTransfer);
        EventBus.$off('closeMakePayment', this.closeMakePayment);
        EventBus.$off('closeLeadIntake', this.closeLeadIntake);
        EventBus.$off('destroyWebsockets', this.destroyWebsockets);
        EventBus.$off('updateBrowserIdentifier', this.updateBrowserIdentifier);
        EventBus.$off('hideNavigationDrawer', this.hideNavigationDrawer);
        EventBus.$off('HB-Navigation:AuctionManager', this.showAuctionManager);
        //Added by BCT : Mini-charm
        this.$store.dispatch('charmCallStore/initilizeCharmCallState');
        // this.destroyWebsockets();
    },

    methods:{
        ...mapActions({
            setProperties: 'propertiesStore/setProperties',
            setLeadIntake:'navigationStore/setLeadIntake',
            setTaskCenterOpen: 'taskCenterStore/setTaskCenterOpen',
            setActiveDrawerComponent:'navigationStore/setActiveDrawerComponent',
            setActiveSection: 'taskCenterStore/setActiveSection',
            setTaskFilters: 'taskCenterStore/setFilters',
            getTasks: 'taskCenterStore/getTasks',
            setLeases: 'paymentsStore/setLeases',
            setSubcategory: 'taskCenterStore/setSubcategory',
            removeGlobalNotificationByType: "globalNotificationsStore/removeGlobalNotificationByType",
            setActiveMerchandiseTab: 'navigationStore/SET_ACTIVE_MERCHANDISE_TAB'
        }),
        ...mapMutations({
            setTasks: 'taskCenterStore/SET_TASKS'
        }),
        updateBrowserIdentifier(count){
            console.log("update browser identifier ",count)
            this.socket.emit('browserIdentifier', { browserID: localStorage.getItem('browserID'), contact_id: this.userData.id , callCount : count});
        },
        generateBrowserIdentifier() {
                      return Date.now().toString(36);
        },
        hideNavigationDrawer(value) {
            this.drawer = value;
        },
        watchWebsocketEvents(){
            if(this.userData.id) {
             
            const storedBrowserID = localStorage.getItem('browserID');
            if (storedBrowserID == 'undefined' || !storedBrowserID) {
                const newBrowserID = this.generateBrowserIdentifier();
                localStorage.setItem('browserID', newBrowserID);
                this.socket.emit('browserIdentifier', { browserID: newBrowserID, contact_id: this.userData.id ,callCount : 0  });
            } else {
                const existingBrowserID = storedBrowserID;
                this.socket.emit('browserIdentifier', { browserID: existingBrowserID, contact_id: this.userData.id ,callCount : 0  });
            }  
                this.socket.emit('watch', this.userData.id);
            }
        },
        setCmsLoader(value) {
            this.cmsLoader = value
        },
        showAuctionModal(lease) {
            this.auctionLease = lease;
            this.show_auction_model = true;
        },

        showCloseOfDayModal() {
            this.hideDrawer();
            this.showCloseOfDay = true;
        },
        hideNavigationDrawer(value) {
            this.drawer = value;
        },
        hideCloseOfDayModal() {
            this.showCloseOfDay = false;
        },
        setSocketEvents(){
            this.socketEvents = {
                'test': (payload) => {
                    alert(payload.message)
                },

                'pdf_generated': (payload) => {
                    this.removeGlobalNotificationByType({ type: 'document_download' })
                    let data = {}
                    if(payload.success) {
                        data.type = 'document_download';
                        data.file = payload.data;
                        data.content_type = payload.content_type;
                        data.filename = payload.filename;
                        data.title = 'Your download is ready!';
                        data.persistent = true;
                        data.backgroundColor = '#00848e';
                        data.dividerColor = '#00848e';
                        data.iconColor = '#FFFFFF';
                        data.titleColor = '#FFFFFF';
                        data.closeColor = '#FFFFFF';
                        data.closeHoverColor = "#4d4d4d";
                        data.footerBackgroundColor = "#FFFFFF";
                        data.action = {
                            name: 'download_report',
                            text: 'Download Report'
                        }
                    } else {
                        data.type = 'error';
                        data.description = payload.message;
                    }
                    Vue.nextTick(() => {
                        this.showMessageNotification(data);
                    });
                },
                "pdf_progress": (payload) => {
                    payload.property = 'percentage';
                    payload.value = payload.percentage;
                    this.updateMessageNotification(payload);
                },
                'alert': (payload) => {
                    this.$store.commit('notificationsStore/setAlert', payload);
                },
                'document_generation_failed': (payload) => {

                    //EventBus.$emit('document_generation_failed', payload);
                    this.$store.commit('onBoardingStore/updateChecklistItem', payload);

                    payload.title = 'Document Error!';
                    payload.id = payload.checklist_id;
                    payload.text = 'An error occurred while generating a document: ' + payload.msg;
                    // payload.status = 'error';
                    this.$store.commit('notificationsStore/setAlert', payload);


                },
                'document_generation_completed': (payload) => {
                    //EventBus.$emit('document_generation_failed', payload);
                    this.$store.commit('onBoardingStore/updateChecklistItem', payload);
                },
                'connect': () => {
                    this.watchWebsocketEvents(); 
                },
                'error': (err) => {
                    console.log('socket error', err);
                },
                'pandadoc_generation_update': (payload) => {
                    EventBus.$emit('pandadoc_generation_update', payload);
                },
                'reversal_process_update': (payload) => {
                    EventBus.$emit('reversal_process_update', payload);
                },
                // added by BCT
                'space_generation_update': (payload) => {
                    EventBus.$emit('space_generation_update', payload);
                },
                "update_property_amenities": (payload) => {
                    EventBus.$emit('update_property_amenities', payload);
                },
                "refresh_batches": (payload) => {
                    EventBus.$emit('refresh_batches', payload);
                },
                "custom_amenity_creation": (payload) => {
                    EventBus.$emit('custom_amenity_creation', payload);
                },
                "property_amenities_deletion": (payload) => {
                    EventBus.$emit('property_amenities_deletion', payload);
                },
                // 'document_downloaded': (payload) => {
                //     EventBus.$emit('document_downloaded', payload);
                // },
                'todo': (payload) => {
                    this.fetchTodo('socket');
                },
                'lead': (payload) => {
                    this.fetchTodo('socket');
                },
                'notification': payload => {
                    this.fetchData('socket');
                },
                'new_lease': payload => {
                    // if(this.properties.find(p => payload.property_id === p.id)){
                    //     this.$store.commit('notificationsStore/newLease', payload);
                    // }
                }, 
                'new_reservation': payload => {
                    // if(this.properties.find(p => payload.property_id === p.id)){
                    //     this.$store.commit('notificationsStore/newReservation', payload);
                    // }
                }, 
                'new_web_lead': payload => {
                    
                    // if(this.properties.find(p => payload.property_id === p.id)){
                    //     this.$store.commit('notificationsStore/newLead', payload);
                    // }
                }, 
                'new_lead': payload => {
                    
                    // if(this.properties.find(p => payload.property_id === p.id)){
                    //     this.$store.commit('notificationsStore/newLead', payload);
                    // }
                }, 
                'message_sent_to_contact': () => {
                    EventBus.$emit('message_sent_to_contact');
                },
                'confirmation': (message) => {
                    this.$store.dispatch('confirmationStore/setUpConfirmation', message);
                },
                'callStatusUpdate': (payload) => {
                    this.$store.commit('notificationsStore/updateIncomingCall', payload);
                },
                'brivoConnection': (message) => {
                    EventBus.$emit('brivoConnection');
                },
                'paymentReader': (message) => {
                    EventBus.$emit('paymentReader', message);
                },
                'incomingCall': (payload) => {
                    // this.$store.dispatch('callStore/addCall', message);
                    // this.$store.commit('notificationsStore/addIncomingCall', payload);
                },
                'incomingText': (payload) => {
                    if(this.properties.find(p => payload.property_id === p.id)){
                        this.$store.commit('notificationsStore/addIncomingText', payload);
                    }
                },
                "incomingEmail": (payload) => {
                    if(this.properties.find(p => payload.property_id === p.id)){
                        // If we are not in the communication center otherwise notify
                        EventBus.$emit('communication::refresh', payload);
                        if(this.$route.name !== 'communicationCenter') { 
                            this.showMessageNotification({
                                type: "email",
                                title: (payload?.contact?.first && payload?.contact?.last) ? `${payload.contact.first} ${payload.contact.last}` : 'Incoming Email',
                                time: payload.time,
                                subject: payload.subject,
                                description: payload.message,
                                persistent: this.activeDrawerComponent.type == 'show_communication_center' ? false : true,
                                contact_id: payload.contact_id
                            });
                        }
                    }
                    
                },
                "incomingSMS": (payload) => {
                     
                    if(this.properties.find(p => payload.property_id === p.id)){
                        EventBus.$emit('communication::refresh', payload);
                        if(this.$route.name !== 'communicationCenter') { 
                            this.showMessageNotification({
                                type: "sms",
                                title: (payload?.contact?.first && payload?.contact?.last) ? `${payload.contact.first} ${payload.contact.last}` : 'Incoming SMS',
                                time: payload.time,
                                description: payload.message,
                                persistent: this.activeDrawerComponent.type == 'show_communication_center' ? false : true,
                                contact_id: payload.contact_id
                            });
                        }
                    }
                    EventBus.$emit('communication::refresh');
                },
                "taskCreated": (payload) => {
                    EventBus.$emit('HB:Navigation-tasks-indicator', payload);
                },
                // BCT: Bulk edit - Features and Amenities
                "space_amenities_update": (payload) => {
                    EventBus.$emit('space_amenities_update', payload);
                },
                
                'share_reports_update': (payload) => {
                    console.log(payload, "socket....", this.getNotificationId);
                    if(this.getNotificationId.includes(payload.id)) {
                        const name = payload.reports.length > 1 ? 'reports' : 'report';
                        const capsName = payload.reports.length > 1 ? 'Reports' : 'Report'
                    if(payload.status == 'success') {
                        this.showMessageNotification({
                            type: "success",
                            description: `The ${name} have been successfully shared.`
                        });
                    } else {
                             this.showMessageNotification({
                            type: "error",
                            description: `The Share ${capsName} process has failed.`
                            });
                        }
                    }
                },
              'document_retry_update': (payload) => {
                this.removeGlobalNotificationByType({ type: 'document_retry_update' })
                if(payload.status == 'success') {
                  this.showMessageNotification({
                    type: "success",
                    description: `The document retry process has been successfully completed.`
                  });
                } else {
                  this.showMessageNotification({
                    type: "error",
                    description: `The Document Retry process has failed.`
                  });
                }

              },
              'gate_access_sync': (payload) => {
                this.removeGlobalNotificationByType({ type: 'gate_access_sync' })
                if(payload.status == 'success') {
                  this.showMessageNotification({
                    type: "success",
                    description: `Tenant data for ${payload.property_name} has been successfully updated in the Access Control System.`
                  });
                } else {
                  this.showMessageNotification({
                    type: "error",
                    description: `An error occurred while syncing tenants for ${payload.property_name} to the Access Control System.`
                  });
                }

              },
              'bulk_space_area_change': (payload) => {
                this.removeGlobalNotificationByType({ type: 'bulk_space_area_change' })
                if(payload.status == 'success') {
                  this.showMessageNotification({
                    type: "success",
                    description: `Spaces successfully updated.`
                    
                  });
                } else {
                  this.showMessageNotification({
                    type: "error",
                    description: `An error occurred while updating the spaces.`
                  });
                }
                EventBus.$emit('unit_edited');
              },
				//BCT - Charm
                'charm_notify_reservation' : (payload) => {
                    if (this.hasPermission('mini_charm') && this.isPropertySelected(payload?.data?.property_id) &&
                        this.isCharmSubscribedProperty(payload.data.property_id) && !this.isSameNotification(payload.data)) {
                        this.$store.dispatch('charmNotificationStore/getReservationNotifications', this.charmSubscribedAndFilteredProperties);
                        // Additional check bcz getting multiple events for single reservation.
                        if (payload.data.showToast) {
                            this.showNotifyToast(payload.data);
                        }
                    }
                },
                'update_charm_status':(payload)=>{
                     EventBus.$emit('update-agentAvailable', payload);
                },
                'charm_user_logout':()=>{
                    console.log("logout socket")
                    setTimeout(() => {
                        if(!localStorage.getItem('auth_token')){                           
                            this.destroyWebsockets()
                            this.deviceSetup = false
                            this.unregisterTwilioDevice()
                            this.unregisterTwilioDevice2()
                            this.$router.push('/logout')
                        }
                    }, 2000);
                     
                },
                'charm_incoming_call': (payload) => {
                    console.log('web socket charm_incoming_call:', payload?.callStatus, payload)
                    let checkInterval  = 0
                    const checkCallDetails = () => {
                    if (payload.twilioDetails.data.from_phone === this.twilioCallFrom || payload.twilioDetails.data.from_phone === this.twilioCall2From) {
                        clearInterval(checkInterval);
                        if (this.hasPermission('mini_charm') && !this.isSameNotification(payload?.callerDetails) && payload?.callStatus === 'ringing' && this.isPropertySelected(payload.callerDetails.property_id)) {
                            this.$store.dispatch('charmCallStore/setTwilioCallDetails', {...payload.twilioDetails, id: payload.callerDetails.id});
                            if (this.isCharmCallToastOpen || this.isNotificationActive) {
                                this.$store.dispatch('charmCallStore/addIncomingNotification', {...payload.callerDetails, twilioDetails: payload.twilioDetails.data});
                                this.notificationTimeout = setTimeout(() => { 
                                    this.$store.dispatch('charmCallStore/handleDismissIncomingCall', payload.callerDetails);
                                }, 90000);
                            } else {
                                this.$store.dispatch('charmCallStore/setCharmNotification', {...payload.callerDetails, twilioDetails: payload.twilioDetails.data});
                                this.$store.dispatch('charmCallStore/showCharmCallToast', true); 
                                this.notificationTimeout = setTimeout(() => { 
                                    this.$store.dispatch('charmCallStore/handleDismissIncomingCall', payload.callerDetails);
                                }, 90000);
                            }
                            this.showNotification({
                                title: 'Charm! Incoming Call...',
                                dir: 'auto',
                                lang: 'en',
                                renotify: true,
                                tag: Date.now(),
                                icon: "/img/icons/favicon-32x32.png"
                            });
                        }
                    } else {
                        console.log('Awaiting Call from Twilio');
                    }
                };
                checkInterval = setInterval(() => {
                    checkCallDetails();
                }, 500);


                setTimeout(() => {
                    clearInterval(checkInterval);
                }, 60000);

                },

                'charm_call_status' : async (payload) => {
                    console.log('web socket charm_call_status:', payload?.callStatus, payload )
                    if((payload.callStatus == 'no-answer') && (payload?.twilioDetails?.data?.agent != this.userData.id)) return
                    if (this.hasPermission('mini_charm') && this.hasCharmSubscribedPropertySelected) {
                        // Getting this event to agents who not answered the call.
                        if (payload.callStatus === 'in-progress' && payload?.twilioDetails?.data?.agent) {
                            // Dismiss call toast As call is answered by Other agent.
                            this.$store.dispatch('charmCallStore/handleDismiss', payload.callerDetails);
                            this.$store.dispatch('charmCallStore/removeTwilioDetails', payload.callerDetails);
                        }
                        if (payload?.callStatus === 'completed' && payload?.twilioDetails?.data?.agent === this.userData.id) {
                            if (payload?.callerDetails && !payload?.recordingStatus) {
                                this.$store.dispatch('charmCallStore/setCallCompleted', payload.callerDetails);
                                this.$store.dispatch('charmCallStore/removeTwilioDetails', payload.callerDetails);
                            }
                            // Set Call recording url
                            if (payload?.recordingStatus === 'completed') {
                                this.$store.dispatch('charmCallStore/setCallRecordingUrl', payload.callerDetails);
                            }
                        }
                        if (payload?.callerDetails?.id && ['no-answer', 'canceled','cancelled'].includes(payload.callStatus) && this.isPropertySelected(payload.callerDetails.property_id) && this.isCharmSubscribedProperty(payload.callerDetails.property_id)) {
                            this.$store.dispatch('charmCallStore/removeTwilioDetails', payload.callerDetails);
                            if (this.isCallToastAvailable(payload.callerDetails)) {
                                this.$store.dispatch('charmCallStore/incomingCallToMissedCall', {id: payload.callerDetails.id, status: 'missed', comm_type: 'missed'});
                                let interval = setInterval(() => this.$store.dispatch('charmCallStore/setCallBackTimer'), 1000);
                                this.$store.dispatch('charmNotificationStore/getMissedCallsNotifications', this.charmSubscribedAndFilteredProperties);
                                this.notificationTimeout = setTimeout(() => { 
                                    this.$store.dispatch('charmCallStore/handleDismiss', payload.callerDetails);
                                    clearInterval(interval);
                                },10000)
                            } else if (!this.isMissedCallToastAvailable(payload.callerDetails)) {
                                this.showNotifyToast(payload.callerDetails);
                                this.$store.dispatch('charmNotificationStore/getMissedCallsNotifications', this.charmSubscribedAndFilteredProperties);
                            }
                        }
                        if (payload.callStatus === 'failed') {
                            this.$store.dispatch('charmCallStore/endCall', '+' + payload?.callerDetails?.phone_number);
                        }
                    }
                },

                'charm_notify_voicemail': async (payload) => {
                    console.log('web socket charm_notify_voicemail:', payload)
                    if (this.hasPermission('mini_charm') && this.isPropertySelected(payload.data.property_id) && this.isCharmSubscribedProperty(payload.data.property_id)) {
                        if (this.isMissedCallToastAvailable(payload.data)) {
                            this.$store.dispatch('charmCallStore/missedCallToVoicemail', payload.data);
                            let interval = setInterval(() => this.$store.dispatch('charmCallStore/setCallBackTimer'), 1000);
                            this.notificationTimeout = setTimeout(() => { 
                                this.$store.dispatch('charmCallStore/handleDismiss', payload.data);
                                clearInterval(interval);
                            },10000)
                            this.$store.dispatch('charmNotificationStore/getVoicemailNotifications', this.charmSubscribedAndFilteredProperties);
                            this.$store.dispatch('charmNotificationStore/getMissedCallsNotifications', this.charmSubscribedAndFilteredProperties);
                        } else if (!this.isVoicemailToastAvailable(payload.data)) {
                            this.showNotifyToast(payload.data);
                            this.$store.dispatch('charmNotificationStore/getVoicemailNotifications', this.charmSubscribedAndFilteredProperties);
                            this.$store.dispatch('charmNotificationStore/getMissedCallsNotifications', this.charmSubscribedAndFilteredProperties);
                        }
                    }
                },

                'charm_send_to_voicemail' : (payload) => {
                    if (this.hasPermission('mini_charm') && this.isPropertySelected(payload.property_id) && this.isCharmSubscribedProperty(payload.property_id)) {
                        this.$store.dispatch('charmCallStore/removeTwilioDetails', payload);
                        this.$store.dispatch('charmCallStore/handleDismiss', payload);
                    }
                },

                'charm_outgoing_status' : (payload) => {
                    console.log('web socket charm_outgoing_status:', payload?.callStatus, payload)
                    if (this.hasPermission('mini_charm') && this.hasCharmSubscribedPropertySelected) {
                        if (payload?.callStatus === 'in-progress' && payload?.twilioDetails?.data?.participant?.includes('customer')) {
                            this.$store.dispatch('charmCallStore/setTwilioCallDetails', {...payload.twilioDetails, id: payload.callerDetails.id});
                            this.$store.dispatch('charmCallStore/setCharmNotification', {...payload.callerDetails, twilioDetails: payload.twilioDetails.data});
                            this.acceptTwilioCall({...payload.callerDetails, twilioDetails: payload?.twilioDetails?.data});
                        }
                        if (['completed', 'no-answer', 'cancelled', 'canceled', 'busy'].includes(payload.callStatus)) {
                            this.hangUpTwilioCall({...payload.callerDetails, twilioDetails: payload?.twilioDetails?.data});
                            this.$store.dispatch('charmCallStore/removeTwilioDetails', payload?.callerDetails);
                        }
                    }
                },

                'combine_errors_update': (payload) => {
                    console.log("combining_error_docs");
                    EventBus.$emit('combine_errors_update', payload);
                },

                'delinquency_actions_update': (payload) => {
                    EventBus.$emit('delinquency_actions_update', payload);
                },

                'upload_rent_change_update': (payload) => {
                    EventBus.$emit('upload_rent_change_update', payload);
                },
                'payment_session_update':(payload)=>{
                        EventBus.$emit('collect-card-info-ivr', payload);
                },
                'auto_pay_enrolment': (payload) => {
                    EventBus.$emit('auto_pay_enrolment', payload);
                }

            }
        },

        setUpWebsockets(){
            this.socket = io(process.env.VUE_APP_WEBSOCKET_SERVER_APP_ENDPOINT, {
                transports: ['websocket'] 
            });           
            
            for(const socketEvent in this.socketEvents) {
                this.socket.on(socketEvent, this.socketEvents[socketEvent]);
            }
        },

        destroyWebsockets() {
            for(const socketEvent in this.socketEvents) {
                this.socket.off(socketEvent, this.socketEvents[socketEvent]);
            }
            this.socket.disconnect()
        },

        bulkEditUnitDetails(data){
            // show modal
            this.show_bulk_edit_unit_details = true;

            this.show_bulk_edit_unit_details_units = data;

        },

        dispatchOmniSearch(){
            this.$store.dispatch('navigationStore/setOmniSearch', true);
        },
        getMakePaymentDetails(data){

            let r = api.get(this, api.LEASES + data.lease_id + '/property');
            this.setNewPayment({
                property_id:  r.property.id,
                contact_id:  data.contact_id
            });
        },
        showQuickActions(open_panel){
            this.showDrawer('quick_actions', true, !this.$vuetify.breakpoint.xs ? 444 : "100%", '');
            this.open_panel = open_panel;
        },
        closeQuickActions(){
            this.hideDrawer();
        },
        closeLeadIntake(){
            this.property_id = null;
            this.contact_id = null;
            this.unit_id = null;
            this.setLeadIntake(false);
            this.hideDrawer();
        },
        closeThenGoToReservation(newReservationData){
            this.closeLeadIntake();
            if(newReservationData.contact_id){
                api.get(this, api.CONTACTS + newReservationData.contact_id).then(r => {
                    var contact = r.contact;
                    var data = {
                        contact_id: contact.id,
                        lead_first: contact.first,
                        lead_last: contact.last
                    }

                    if(contact.Lead){
                        data.lead_time = contact.Lead.created;
                        data.id = contact.Lead.id;
                    }

                    if(newReservationData.unit_id && contact.Reservations){
                        contact.Reservations.forEach( reservation => {
                            if (reservation.Lease && reservation.Lease.Unit && reservation.Lease.Unit.id == newReservationData.unit_id) {
                                data.expires = reservation.expires;
                                data.lease_id = reservation.Lease.id;
                                data.reservation_id = reservation.id;
                                data.reservation_time = reservation.created;
                                data.unit_id = reservation.Lease.Unit.id;
                            }
                        });
                    }

                    data.event = event;
                    
                    this.showLeadFollowUp(data);
                });
            }
        },
        closeMakePayment(){
            if(this.taskCenterOpen){
                this.showTaskCenter()
            } else {
                this.hideDrawer();
            }
        },
        closeSellMerchandise(){
           if(this.taskCenterOpen){
                this.showTaskCenter()
            } else {
                this.hideDrawer();
            }
        },

        setNewLead(data = {}){
            this.hideDrawer();
            this.location = data.location;
            this.showSpacesList = data.showSpacesList;
            this.unKnownNumber = data.unKnownNumber || '';
            this.selectedLeadId = data.selectedLeadId
            this.setLeadIntake(true);
            this.showDrawer('show_lead_intake', true, '1300px', 'new_lead');
            this.charm_property_id = data.charm_property_id;
            
        },

        setPending(data){
            this.hideDrawer();
            this.unit_id = data.unit_id;
            this.drawer_type = 'new_lead';
            this.quick_actions = false;
            this.setLeadIntake(true);
            this.drawer_width = 1200;
            this.temporary = true;
        },

        setNewPayment(data){
            this.hideDrawer();
            this.property_id = data.property_id;
            this.contact_id = data.contact_id;
            this.showDrawer('showMakePayment', true, 880, 'new_payment');
            this.setActiveDrawerComponent({type:'showMakePayment', ...data  });
            // this.setActiveDrawerComponent({type:'showMakePayment', property_id: data.property_id, contact_id: data.contact_id  });
        },
        sellMerchandise(data){
            this.hideDrawer();
            this.property_id = data.property_id;
            this.lease_id = data.lease_id;
            this.contact = data.contact;
            if (data.tab_type) {
                this.setActiveMerchandiseTab(data.tab_type)
            } else {
                this.setActiveMerchandiseTab(this.activeMerchandiseTab);
            }
            let isQuickAction = data && (data === 'merchandise' || data.quick_action) ? true : false;

            let merchandise_data = {
                type: 'showSellMerchandise',
                ...(data.contact && { contact: data.contact }),
                ...(data.invoice && { invoice: data.invoice }),
                ...(data.services && { services: data.services }),
                ...(data.payment_id && { payment_id: data.payment_id }),
                ...(data.property_id && { property_id: data.property_id }),
                ...(data.lease_id && { lease_id: data.lease_id }),
                step: data.step || 1,
                payment_type: data.payment_type,
                alt_payment_type: data.alt_payment_type,
                quick_action: isQuickAction
            }
            this.showDrawer('showSellMerchandise', true, 1300, 'merchandise');
            this.setActiveDrawerComponent({...merchandise_data});
        },
        showOmniSearch(){
            this.showDrawer('show_omni_Search', true, 600, 'showOmniSearch');
        },
        hideOmniSeacrh(){
            this.hideDrawer();
        },
        setMoveOut(data){
            if(data.contact?.national_account_id){
                if(! this.hasPermission('national_accounts_move_out')){
                    this.showNoPermissionDialogNationalAccountMoveOut = true
                    return
                }
            }
            this.showDrawer('showMoveOut', true, 1200, 'move_out');

            this.property_id = data.property_id;
            this.lease_id = data.lease_id;
            this.unit_id = data.unit_id;
            this.contact = data.contact;
            this.move_out_date = data.move_out_date;

            this.setActiveDrawerComponent({
                type:'showMoveOut', 
                property_id: data.property_id,
                contact: data.contact,
                lease_id: data.lease_id,
                unit_id: data.unit_id,
                move_out_date: data.move_out_date,
                paymentSection: data.paymentSection || false,
                step: data.step || 1,
                readyToMoveIn: data.readyToMoveIn
            });
        },
        closeMoveOut(){
            if(this.taskCenterOpen){
                this.showTaskCenter()
            } else {
                this.hideDrawer();
            }
        },
        setTransfer(data) {
            this.showDrawer('showTransfer', true, 1200, 'transfer');
            this.setActiveDrawerComponent({ type:'showTransfer', ...data })
            this.property_id = data.property_id;
            this.lease_id = data.lease_id;
            this.unit_id = data.unit_id;
            this.contact = data.contact;
            this.access = data.access;
            this.spaceAccess = data.space_access || [];
            this.isSpaceAccessCodesVendor = data.is_space_access_codes_vendor;
            this.transferData = data.transfer;
        },
        closeTransfer(){
            this.hideDrawer();
        },
        setRateChange(data){
            this.showDrawer('showRateChange', true, 1200, 'rate_change');
            this.rateChangeComponent = data.rateChangeType
            this.rateChangeData = data.rateChangeItem;
            this.rateChangeID = data.rateChangeId;
            this.rateChangeEditType = data.rateChangeEditType;
            this.rateChangeEdit = data.editRateChange;
            this.rateChangePropertyID = data.rateChangePropertyID;

            this.setActiveDrawerComponent({
                type:'showRateChange',
                rateChangeType: data.rateChangeType,
                rateChangeItem: data.rateChangeItem,
                rateChangeId: data.rateChangeId,
                rateChangeEditType: data.rateChangeEditType,
                editRateChange: data.editRateChange,
                rateChangePropertyID: data.rateChangePropertyID
            });
        },
        closeRateChange() {
            this.rateChangeComponent = '';
            this.rateChangeData = null;
            this.rateChangeID = null;
            this.rateChangeEditType = 'manual';
            this.hideDrawer();
        },
        showDelinquencyCenter(data = null){
            this.delinquencyData = data;
            this.showDrawer('show_delinquency_center', true, 1200, '');
            this.setActiveDrawerComponent({ type:'show_delinquency_center', ...data });
        },
        hideDelinquencyCenter(){
            this.delinquencyData = null;
            
            if(this.taskCenterOpen){
                this.showTaskCenter()
            } else {
                this.hideDrawer();
            }

        },
        async showLeadFollowUp(data){
            this.reservation = data;
            await this.$store.dispatch('onBoardingStore/getContactInfo', {contact_id: data.contact_id});
            this.setNewLead({location:"move_in", selectedLeadId: data.selectedLeadId})
            // this.showDrawer('show_lead_follow_up', true, 1200);
            // this.setActiveDrawerComponent({ type:'show_lead_follow_up', data });
        },
        hideLeadFollowUp(){
            if(this.taskCenterOpen){
                this.showTaskCenter()
            } else {
                this.hideDrawer();
            }
            
        },
        showTaskCenter(open_panel){
            this.showDrawer('show_task_center', true, 500, '');
 
        },
        showRentChange(data = {}) {
            this.rentQueueData = data
            if (this.rentQueueData?.data) {
                if (Object.keys(this.rentQueueData.data).length)
                    this.showDrawer('review_rent_change', true, 5200, '')
                else this.closeTaskCenter()
            }
        },
        showSelectedRentDefaults(selectedDefaultData = {}){
            if(Object.keys(selectedDefaultData).length) {
                this.rentDefaultSelectedData = selectedDefaultData
                this.showDrawer('show_selected_rent_defaults', true, 1296, '');
            } else this.closeTaskCenter();
            

        },

        showCommunicationCenter(data) {
            this.contact_id = data && data.contact_id
            this.showDrawer('show_communication_center', true, 1196, '')
            this.setActiveDrawerComponent({ type:'show_communication_center', ...data });
            this.blade_data = data;
        },
        tenantPaymentsApplication() {
            this.showDrawer('showTenantPaymentsApplication', true, 1196, '')
        },
        closeTenantPaymentsApplication() {
            this.hideDrawer();
        },
        CommunicationTemplate(data) {
            if (data) this.communicationTemplateData = data
            else this.communicationTemplateData = null
            this.showDrawer('showCommunicationTemplate', true, 5200, '')
        },
        closecommunicationTemplate() {
            this.hideDrawer();
        },
        nationalAccountMovin(data){
            if(! this.hasPermission('national_accounts_move_in')){
                this.showNoPermissionDialogNationalAccountMoveIn = true              
                return
            }
            if (data) this.MovinData = data
            else this.MovinData = null
            this.showDrawer('showMoveIn', true, '75%', '')
        },
        nationalAccountRecordPayments(data){
            if (data) this.MovinData = data
            else this.MovinData = null
            this.showDrawer('showRecordPayments', true, 880, '')
        },
        closeMovin(){
            this.hideDrawer();
        },
        closeRecordPayment(){
            this.hideDrawer();
        },
        accountingTemplates(data = {}) {
            this.accountingTemplateData = data;
            this.showDrawer('showAccountingTemplates', true, 1196, '');
        },
        closeAccountingTemplates() {
            this.hideDrawer();
        },
        closeTaskCenter(){
            
            this.hideDrawer();
        },
        closeCommuncationCenter() {
            this.hideDrawer()
        },
        closeAuctionModal() {
            this.show_auction_model = false;
            // this.closeTaskCenter();
        },
        showDownloadCenter(data = {}){
            this.hideDrawer();
            this.$router.push('/download-center').catch(()=>{});
        },
        showDrawer(drawerToShow = '', temporary = false, drawer_width = '', drawer_type = ''){
            EventBus.$emit("closeCreateTask");
            this.nav_drawer = true;
            this.drawer_type = '';
            if (temporary) {
                this.temporary = true;
            }
            if (drawer_type) {
                this.drawer_type = drawer_type;
            }

            let vmods = ['quick_actions', 'showSellMerchandise', 'showMakePayment', 'show_omni_Search', 'showMoveOut', 'showTransfer', 'show_delinquency_center', 'show_lead_follow_up', 'show_task_center', 'showRateChange', 'show_communication_center', 'showLeadIntake', 'showTenantPaymentsApplication', 'showAccountingTemplates', 'review_rent_change', 'show_selected_rent_defaults','showCommunicationTemplate','showMoveIn', 'showRecordPayments', 'show_auction_manager'];
            vmods.forEach( model => {
                this[model] = false 
            });
            if (drawerToShow) {
                this[drawerToShow] = true;
                if(!this.activeDrawerComponent.type || this.activeDrawerComponent.type !== drawerToShow){
                    this.setActiveDrawerComponent({type:drawerToShow});
                }
            }
            if (drawer_width) {
                this.drawer_width = drawer_width;
            }
            
        },
        hideDrawer(){
            this.drawer_type = '';
            this.nav_drawer = false;
            this.drawer_width = 0;
            this.temporary = false;
            let vmods = ['quick_actions', 'showSellMerchandise', 'showMakePayment', 'show_omni_Search', 'showMoveOut', 'showTransfer', 'show_delinquency_center', 'show_lead_follow_up', 'show_task_center', 'showRateChange', 'showLeadIntake', 'showTenantPaymentsApplication', 'showAccountingTemplates', 'review_rent_change', 'show_selected_rent_defaults','showCommunicationTemplate','showMoveIn', 'showRecordPayments', 'show_auction_manager'];
            vmods.forEach( model => {
                this[model] = false 
            });
            this.setActiveDrawerComponent({});
        },
        loadActiveDrawer(){
            if(this.activeDrawerComponent && this.activeDrawerComponent.type){
                let property_id = this.activeDrawerComponent.property_id;

                switch(this.activeDrawerComponent.type) {
                    case 'show_omni_Search':
                        this.showOmniSearch();
                        break;
                    case 'quick_actions':
                        this.showQuickActions();
                        break;
                    case 'show_task_center':
                        this.showTaskCenter();
                        if(this.activeDrawerComponent.active_section)  this.setActiveSection(this.activeDrawerComponent.active_section || 'all');
                        if(this.activeDrawerComponent.sub_category) {
                            this.setSubcategory(this.activeDrawerComponent.sub_category);
                            this.getTasks({ reset: true, role_ids: this.rolesIDs });
                        }
                        if(this.activeDrawerComponent.filters && this.activeDrawerComponent.filters.length) {
                            this.setTaskFilters(this.activeDrawerComponent.filters);
                            this.getTasks({ reset: true, role_ids: this.rolesIDs });
                        }
                        break;
                    case 'show_communication_center':
                        this.showCommunicationCenter();
                        break;
                    case 'showMakePayment':
                        if(this.properties.find(p => p.id === property_id) || this.properties.length === 0){                            
                            this.setNewPayment({ ...this.activeDrawerComponent });
                            this.activeDrawerComponent.leases && this.setLeases(this.activeDrawerComponent.leases);
                        } else {
                            this.showQuickActions();
                        }
                        break;
                    case 'showSellMerchandise':
                        if(!property_id || this.properties.find(p => p.id === property_id) || this.properties.length === 0){
                            this.sellMerchandise({ ...this.activeDrawerComponent });
                        } else {
                            this.showQuickActions();
                        }
                        break;
                    case 'showMoveOut':
                        if(this.properties.find(p => p.id === property_id) || this.properties.length === 0){
                            this.setMoveOut({ ...this.activeDrawerComponent });
                        } else {
                            this.showQuickActions();
                        }
                        break;
                    case 'show_lead_intake':
                        this.setNewLead();
                        break;
                    case 'show_lead_follow_up':
                        api.get(this, api.CONTACTS + this.activeDrawerComponent.contact_id).then(c =>{
                            if(this.activeDrawerComponent.reservation_id) {
                                let activeReservation  = c.contact.Reservations && c.contact.Reservations.filter(r=> r.id === this.activeDrawerComponent.reservation_id);
                                property_id = activeReservation && activeReservation.length > 0 ? activeReservation[0].Lease?.Unit?.property_id: null;
                            } else if(this.activeDrawerComponent.id) {
                                property_id = c.contact.Lead?.property_id;
                            }

                            if(this.properties.find(p => p.id === property_id) || this.properties.length === 0){
                                this.showLeadFollowUp({ ...this.activeDrawerComponent,property_id });
                            } else {
                                this.showTaskCenter();
                            }
                        });
                        break;
                    case 'show_delinquency_center':
                        if(this.properties.find(p => p.id === property_id) || this.properties.length === 0){
                            this.showDelinquencyCenter({ ...this.activeDrawerComponent });
                        } else {
                            this.showTaskCenter();
                        }
                        break;
                    case 'showRateChange':
                        if(this.properties.find(p => p.id === this.activeDrawerComponent.rateChangeItem?.property_id) || this.properties.length === 0){
                            this.setRateChange({ ...this.activeDrawerComponent });
                        } else {
                            this.showTaskCenter();
                        }
                        break;
                    case 'showTransfer':
                        let is_same_prop = !!this.properties.find(p => p.id === property_id)
                        if(!is_same_prop) return

                        this.setTransfer({ ...this.activeDrawerComponent })
                        break;
                    case 'review_rent_change':
                        this.showRentChange()
                        break;
                    case 'show_selected_rent_defaults':
                        this.showSelectedRentDefaults()
                        break;
                    default:
                        
                }
            }
        },
        showPropSelector(value) {
            this.nav_drawer = value;
        },

        // BCT: charm
        setUpTwilioClient(){
            this.connectClientWithUsername();
        },
        getCharmNotifications(){
            this.$store.dispatch('charmNotificationStore/getMissedCallsNotifications', this.charmSubscribedAndFilteredProperties);
            this.$store.dispatch('charmNotificationStore/getReservationNotifications', this.charmSubscribedAndFilteredProperties);
            this.$store.dispatch('charmNotificationStore/getVoicemailNotifications', this.charmSubscribedAndFilteredProperties);
        },
        showNotifyToast(data) {
          // Set current time.
          data.time = moment().utc().local().format('YYYY-MM-DD HH:mm:ss');
          // Every sec
          let callBackInterval = setInterval(() => this.$store.dispatch('charmCallStore/setCallBackTimer'), 1000);
          if (this.isCharmCallToastOpen || this.isNotificationActive) {
            this.$store.dispatch("charmCallStore/addIncomingNotification", data);
          } else {
            this.$store.dispatch("charmCallStore/setCharmNotification", data);
            this.$store.dispatch('charmCallStore/showCharmCallToast', true); 
          }
          this.notificationTimeout = setTimeout(() => {
                clearInterval(callBackInterval);
                this.$store.dispatch('charmCallStore/handleDismiss', data); 
          },10000)
        },
        showAuctionManager(lease, contact_id, step) {
            this.auctionLease = lease;
            this.auctionContactId = contact_id;
            this.hideDrawer();
            this.showDrawer('show_auction_manager', true, 990, '');
            if(step){
                this.auctionStep = step;
            }
        },
    },
    watch: {
        // BCT: Charm
        hasCharmSubscribedPropertySelected() {
            if (this.hasCharmSubscribedPropertySelected) {
                this.getCharmNotifications();
                if (!this.deviceSetup) {
                    this.setUpTwilioClient();
                    this.deviceSetup = true;
                }
            } else {
                this.deviceSetup = false
                this.unregisterTwilioDevice();
                this.unregisterTwilioDevice2();
            }
        },
        hasMiniCharmPermission(isMiniCharm) {
            if (isMiniCharm) {
                this.getCharmNotifications();
                if (!this.deviceSetup) {
                    this.setUpTwilioClient();
                    this.deviceSetup = true;
                }
            } else {
                this.deviceSetup = false
                this.unregisterTwilioDevice();
                this.unregisterTwilioDevice2();
            }
        },
        isNotificationInteracted() {
            if (this.isNotificationInteracted) {
                clearTimeout(this.notificationTimeout);
                this.notificationTimeout = null;
            }
        },
        show_task_center(params) {
            if (!params) {
                this.setTasks({reset: true});
            }
        },
        nav_drawer(newVal) {
            if(!newVal) {
                this.hideDrawer();
            }
        },
    }


}
</script>
<style lang="scss" scoped>
.cover-spinner {
  height: 100vh;
}

.platform-page {
    padding: 60px 0 0 70px;
    background: #F9FAFB;
    min-height: 100vh;
}

.platform-page:not(.has-drawer) .section-content {
    margin-left: 0px;
    padding: 0px 16px 0px 10px!important;
}

.platform-page .section-content {
 padding: 0px 16px 10px 0px;
}



.has-drawer{
    padding-left: 280px;
}

.hb-flex{
    display: flex;
}
.loading {
    height: 100vh;
    vertical-align: middle;
    margin: 0 auto;
    .v-progress-circular {
        position: relative;
        top: 40%;
        left: 40%;
    }
}
.side-drawer-margin {
    margin-top: 60px;
    height: calc(100% - 64px) !important;
}
.master-modal-content-wrapper {
    margin-top: 20%;
    position: relative;
    z-index: 999999 !important;
}
@media (max-width: 480px){
    .side-drawer-margin {
        margin-top: 54px;
        height: calc(100% - 54px) !important;
    }

    .platform-page {
        padding-left: 0!important;
    }
}
</style>
