<template>
    <div>
<!--      <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>-->

<!--      <v-row v-if="properties.length > 1">-->
<!--        <v-col class="mr-auto mt-0 pt-0" style="flex: 0 1 300px;">-->
<!--          <v-autocomplete-->
<!--            v-if="filters"-->
<!--            :key="report_key"-->
<!--            dense-->
<!--            hide-details-->
<!--            rounded-->
<!--            :items="properties"-->
<!--            item-text="name"-->
<!--            item-value="id"-->
<!--            label="Select A Facility"-->
<!--            v-model="property_id"-->
<!--            background-color="white"-->
<!--            singleLine-->
<!--            outlined-->
<!--            prepend-inner-icon="mdi-magnify"-->
<!--            placeholder="Select A Facility"-->
<!--            @change="updateChart"-->
<!--          ></v-autocomplete>-->
<!--        </v-col>-->
<!--      </v-row>-->

      <v-expansion-panels v-model="panel" class="mt-0">
          <v-expansion-panel class="unit-category-panel elevation-0" v-for="(category, i) in categories" :key="category.id" >
              <v-expansion-panel-header>
                <v-row>
                  <v-col>
                    {{category.name}}
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col style="flex: 0 1 100px;" class="align-right">
                    {{category.filterVacant.unit_count || 0}} / {{category.Units.unit_count || 0}}
                  </v-col>
                  <v-col style="flex: 0 1 175px;" class="align-right mr-5">
                    {{category.filterVacant.min_price || 0 | formatMoney}} - {{category.filterVacant.max_price || 0 | formatMoney }}
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pa-0 ma-0 category-holder">
                <div :style="{ height: reportHeight + 'px'}" v-resize="setHeight">
                  <hb-report
                    v-if="panel === i"
                    :key="report_key"
                    report_type="move_in"
                    :report_filters="filters"
                    row_click
                    show_filters
                    @rowClicked="setUnit"
                    @report_data="setReportData"
                  ></hb-report>
                </div>
              </v-expansion-panel-content>
          </v-expansion-panel>
      </v-expansion-panels>

      <div v-if="!categories.length" class="pa-5" style="background:white; border: 1px solid #E1E6EA">
        No Categories found at this property
      </div>

    </div>
</template>


<script type="text/babel">
    import api from '../../../assets/api.js';
    import HbReport from '../../assets/HummingbirdReportViewer.vue';
    // import status from '../../assets/HummingbirdReportViewer.vue';
    import { mapGetters } from 'vuex';
    export default {
        name: 'CategorySelector',
        components: {
            HbReport
        },
        data: () => ({
            report_key:0,
            panel: null,
            reportHeight: 0,
            categories: [],
            filters: {
                search: {
                    unit_category_id: [],
                    property_id: [],
                    unit_status: ['Available'],
                },
                columns: [
                    {
                        "label": "Space Number",
                        "key": "unit_number",
                        "sortable": true,
                        "width": 150,
                        "column_type": "string",
                        "agg_method": "Count",
                        "format": "",
                        "fixed": false,
                        "group": "unit"
                    },
                    {
                        "label": "Space Category",
                        "key": "unit_category",
                        "sortable": true,
                        "width": 150,
                        "column_type": "string",
                        "agg_method": "Count",
                        "format": "",
                        "fixed": false,
                        "group": "unit"
                    },
                    {
                        "label": "Space Status",
                        "key": "unit_status",
                        "sortable": true,
                        "width": 150,
                        "column_type": "string",
                        "agg_method": "Count",
                        "format": "",
                        "fixed": false,
                        "group": "unit",
                        "input": "multi-select",
                        "options": [
                            "Available",
                            "Leased",
                            "Pending",
                            "On Hold",
                            "Unavailable",
                            "Offline",
                            "Reserved"
                        ]
                    },
                    {
                        "label": "Space Price",
                        "key": "unit_price",
                        "sortable": true,
                        "width": 150,
                        "column_type": "money",
                        "agg_method": "Count",
                        "format": "",
                        "fixed": false,
                        "group": "unit",
                        "input": "comparison"
                    }
                ],
                "sort": {
                    "field": "unit_number",
                    "sort": "ASC"
                },
                "pivot_mode": {
                    "enabled": false,
                    "column": {},
                    "metric": {
                        "field": {},
                        "method": ""
                    },
                    "row": {}
                },
                "groups": [],
                "limit": 50,
                "page": 1,
                "offset": 0
            }
        }),
        async created(){

            this.property_id = this.property.id;
            if(this.property.id){
                this.filters.search.property_id = [this.property_id];
                this.report_key++;
            }
            await this.getCategories();

            this.$emit('loading:update', false);
        },
        computed:{
            ...mapGetters({
                properties: 'propertiesStore/filtered',
                property: 'onBoardingStore/property',
            }),
        },
        props: ['search', 'props'],
        methods:{
            async updateChart(){
                this.filters.search.property_id = [this.property_id];
                await this.$store.dispatch('onBoardingStore/getPropertyInfo', this.properties.find(p => p.id === this.property_id));
                await this.getCategories();
                this.report_key++;
            },
            async getCategories(){
                let r = await api.get(this, api.PROPERTIES + this.property_id + '/categories');
                this.categories = r.categories.map(c=>{
                    return {
                        ...c,
                        filterVacant: {
                            unit_count: c.Vacant.unit_count,
                            max_price: c.Vacant.max_price,
                            min_price: c.Vacant.min_price
                        }
                    }
                });
            },
            setHeight(){
                let header = 48;
                let stepper = 100;
                let tabs = 0; // 48
                let footer = 91;
                let heading = 61;
                let padding = 40;
                this.reportHeight = window.innerHeight - header - stepper - tabs -  heading - footer - padding;
            },
            filterCategories(category_id){
                let filters = JSON.parse(JSON.stringify(this.filters));
                filters.search.unit_category_id = [category_id];
                return filters;
            },
            async setUnit(data){

                //this.setPropertyInfo(data.property_id);
                // If there's a reservation, load reservation info. but set new unit
                // If there's a pending lease, load pending info.
                this.$emit('setUnit', data.unit_id);
            },

            setSearchParams(value) {

                if(this.panel === null || this.panel === undefined) return;

                let filters = JSON.parse(JSON.stringify(this.filters));
                value = value || this.search;

                filters.search.search = value.search || '';
                filters.search.unit_floor = value.access;
                filters.search.unit_size = value.size;
                filters.search.unit_type = value.type;
                if(value.price && value.price.length){
                    filters.search.unit_price = []
                    value.price.forEach((price)=>{
                        switch(price){
                            case '< $50':
                                filters.search.unit_price.push( { max: "", operator: "<", value: "50"})
                                break;
                            case '$50 - $100':
                                filters.search.unit_price.push( { max: "100", operator: "range", value: "50"})
                                break;

                            case '$100 - $150':
                                filters.search.unit_price.push( { max: "150", operator: "range", value: "100"})
                                break;

                            case '$150 - $300':
                                filters.search.unit_price.push( { max: "300", operator: "range", value: "150"})
                                break;

                            case '> $300':
                                filters.search.unit_price.push( { max: "", operator: ">", value: "300"})
                                break;

                        }
                    })
                } else {
                    filters.search.unit_price = value.price
                }

                filters.search.unit_amenities = value.amenities;
                let category = this.categories[this.panel];
                filters.search.unit_category_id = [category.id];
                this.filters = filters;
                this.report_key++;
            },

            setReportData(data,filters) {
                if(filters && filters.search && filters.search.unit_category_id && filters.search.unit_category_id.length) {
                    let index = this.categories.findIndex(x=>x.id === filters.search.unit_category_id[0]);
                    if(index < 0) return;
                    this.categories[index].filterVacant = JSON.parse(JSON.stringify({
                        unit_count: data.length,
                        max_price: data.length > 0 ? Math.max(...data.map(x=>x.unit_price)) : 0,
                        min_price: data.length > 0 ? Math.min(...data.map(x=>x.unit_price)) : 0
                    }));
                }
            },

            resetCategoriesData(){
                for(let i=0;i<this.categories.length;i++){
                    this.categories[i].filterVacant = JSON.parse(JSON.stringify(this.categories[i].Vacant));
                }
            }

        },
        watch:{
            "property.id"(){
                this.property_id = this.property.id;
                if(this.property.id){
                    this.filters.search.property_id = [this.property_id];
                    this.report_key++;
                }
                this.getCategories();
            },
            panel() {
                this.resetCategoriesData();
                this.setSearchParams();
            },
            search: {
              handler(value) {
                this.setSearchParams(value);
              },
              deep : true
            }
        }
    }

</script>


<style scoped>

    .v-expansion-panel{
        border: 1px solid #E1E6EA;
    }
    .v-expansion-panel:before { box-shadow: none !important; }

    .v-expansion-panel-header.v-expansion-panel-header--active{
        border-bottom: 1px solid #E1E6EA;

    }

    .v-expansion-panel:not(:first-child)::after{
        border-top: none;
    }

    .v-expansion-panel.unit-category-panel {
        margin-bottom: 10px;
    }

    .theme--light.v-expansion-panels .v-expansion-panel {
        background-color: #FFFFFF;
        color: rgba(0, 0, 0, 0.87);
        margin-bottom: 10px;
    }
    .v-expansion-panel-content__wrap {
      padding: 0;
      border: none;
    }

    .v-expansion-panel-content.category-holder >>> .v-expansion-panel-content__wrap{
      padding: 0 !important;
    }


</style>
