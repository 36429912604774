<template>
  <div class="intent-section px-7">
    <v-col class="mt-n1 mb-4 pa-0">
      <div class="hb-font-header-2-medium">Intent to Move-Out</div>
      <a class="float-right" @click="generateDocument()" v-if="false"><i aria-hidden="true" class="notranslate mdi mdi-plus theme--light"></i> Generate Intent to Move-Out Document</a>
    </v-col>
    <status :not-dismissable="true" v-if="errors.has('move_out_date')" :message="errors.first('move_out_date')" status="error"></status>
    <v-row v-if="lease.auction_status == 'move_out'" class="px-3 py-4">
      <hb-notification
          type="warning"
          notDismissable
      >
          The following Space has been sold at an auction on {{lease.LeaseAuction.scheduled_date | formatLocalShortDate}}
      </hb-notification>
     </v-row>
    <hb-card
      title="Please schedule a date to Move-Out"
    >
      <v-row>
        <v-col class="pl-4">
          <hb-date-picker
            @click:clear="move_out_date = ''"
            label="MM/DD/YYYY"
            :clearable="true"
            :dense="true"
            :min="minDateAllowed"
            name="move_out_date"
            v-validate="'required'"
            data-vv-as="Move Out Date"
            v-model="move_out_date"
            @change="setMoveOutDate"
          >
          </hb-date-picker>
        </v-col>
      </v-row>

      <hb-bottom-action-bar v-if="isToShowButton" cancel-off>
        <template v-slot:right-actions>
          <hb-btn @click="$emit('showTaskCreater')" color="secondary">Intent to Move-Out</hb-btn>
        </template>
      </hb-bottom-action-bar>
    </hb-card>

    <v-row class="intent-item-header mt-3" v-if="false">
      <v-col>Verify if the below document is accurate </v-col>
    </v-row>

    <v-row class="intent-item-header mt-3" v-if="false">
      <v-col> Intent to Move-Out Form </v-col>
    </v-row>

    <div class="new-tenant-walkthrough-footer" >
      <v-row>
        <v-col class="pt-4">
          <help-juice />
        </v-col>
        <v-col cols="9" class="d-flex align-center justify-end pr-sm-7">
          <hb-link class="mr-3" @click="$emit('close')">Cancel</hb-link>
          <hb-btn :disabled="isToShowButton" color="primary" @click="save" >Next</hb-btn>
        </v-col>
      </v-row>
    </div>
  </div>

</template>
<script type="text/babel">

import Status from '../Messages.vue'
import HbDatePicker from '../../assets/HummingbirdDatepicker'
import moment from 'moment'
import api from '../../../assets/api.js';

export default {
  name: 'IntentMoveOut',
  data: () => ({
    move_out_date: '',
    template: null
  }),
  props: ['close', 'next', 'moveOutDate', 'showLeaseSummary', 'lease', 'property_id'],
  components: {
    HbDatePicker,
    Status
  },
  computed: {
    isToShowButton(){
      return this.move_out_date.trim().split(' ')[0] > moment(this.moveOutDate).format('YYYY-MM-DD') ? true : false;
    },
    minDateAllowed() {
      if(this.template && this.template.allow_back_days) {
        let todaysDate = moment(this.moveOutDate);
        let backDaysAllowedDate = todaysDate.subtract((this.template.allow_back_days), "days");
        backDaysAllowedDate = backDaysAllowedDate.format("YYYY-MM-DD");
        return backDaysAllowedDate; 
      } 

      return 0;
    }
  },
  mounted() {
    this.$emit('showLeaseSummary');
  },
  async created() {
    this.move_out_date = this.moveOutDate || moment().format('YYYY-MM-DD');
    await this.fetchTemplate();
  },
  methods: {
    async fetchTemplate() {
      await api.get(this, api.PROPERTIES + this.property_id  + '/templates?unit_type_id='+this.lease.Unit.unit_type_id).then(r => {
        this.template = r.templates?.Template;
      });
    },
    save() {
      this.validate(this).then(status => {
        if(!status) return;
        this.$emit('setMoveOutDate', this.move_out_date)
        this.$emit('next', true)
      });
    },
    generateDocument(){
      
    },
    setMoveOutDate(){
      this.$emit('setMoveOutDate', this.move_out_date)
    }
  },

}

</script>

<style scoped lang="scss">

  .intent-section{
    padding: 20px 50px 75px 50px;
  }

  .intent-section .new-tenant-walkthrough-footer{
    padding: 3px 0 3px 30px;
    background: #FFFFFF;
    border-top: 1px solid #e2e2e2;
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 100;
    left: 0;
    right: 0;
  }
  
  .intent-item-header {
    background-color: #FFFFFF;
    height: 56px;
    border: 1px solid #E7E7E8;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    font-size: 15px;
    font-weight: 500;
    padding-top: 4px;
    margin: 0;
  }

  .intent-item-body {
    background-color: #FFFFFF;
    height: 64px;
    border: 1px solid #E7E7E8;
    border-width: 0px 1px 1px 1px;
    font-size: 15px;
    font-weight: 500;
    margin: 0;
  }

</style>

