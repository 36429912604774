<template>
   <v-card flat color="#F9FAFB" style="color: rgba(0, 0, 0, 0.87);">

<v-card-title class="pl-8 text-capitalize mb-1 pb-0">Lease Summary</v-card-title>

      <v-card-text style="color: rgba(0, 0, 0, 0.87);" >
        <v-container>
          <HbInfoCard
            v-if="summary_unit.number"
            :type="getUnitTypeLabel(summary_unit)"
            :title="summary_unit.number"
            :status="summary_unit.state ? summary_unit.state : undefined"
            :property-info="computedPropertyInfo"
            :category-one="summary_unit.label ? summary_unit.label : undefined"
            :category-two="summary_unit && summary_unit.Category && summary_unit.Category.name ? summary_unit.Category.name : undefined"
            class="mb-4"
            @click="viewEntity('unit', summary_unit.id)"
          />
    <!-- <v-row no-gutters class="mb-3 mt-3">
      <v-col>
        <v-divider></v-divider>
      </v-col>
    </v-row> -->
    <HbInfoCard
        type="contact"
        :title="checkContactTitle(selectedTenant)"
        :status="selectedTenant.status ? selectedTenant.status : undefined"
        :phone="checkContactPhone(selectedTenant) | formatPhone"
        :email="selectedTenant.email ? selectedTenant.email : undefined"
        :contact-address="checkContactAddress(selectedTenant)"
        :access-code="gateAccessCode && gateAccessCode.contact_id == selectedTenant.id ? gateAccessCode.access_code : undefined"
        :key="selectedTenant.id"
        :contact-is-business="selectedTenant.rent_as_business"
        class="mb-4 hb-text-overflow-wrap"
        @click="viewEntity('contact', selectedTenant.id)"
    />
    <!-- <v-row no-gutters class="mb-3 mt-3">
      <v-col>
        <v-divider></v-divider>
      </v-col>
    </v-row> -->

    <v-row class="mb-1 mx-0">
      <v-col class="font-13">
        <div><strong>Move-In Date:</strong> {{summary_data.start_date | formatLocalShortDate}}</div>
        <div v-if="summary_data.end_date"><strong>Move-Out Date:</strong> {{ summary_data.end_date | formatLocalShortDate }}</div>
        <div><strong>Monthly Rent:</strong> {{ summary_data.rent | formatMoney}}</div>
      </v-col>
    </v-row>

    <v-row no-gutters class="mb-3 mx-3">
      <v-col>
        <v-divider></v-divider>
      </v-col>
    </v-row>

    <template  v-if="summary_invoice && summary_invoice.InvoiceLines">
      <v-row v-for="(line, i) in summary_invoice.InvoiceLines" :key="i" no-gutters class="mx-3 font-13" >
        <template v-if="line.Product.default_type == 'rent'">
          <v-col>
            {{line.Product.name}}
            <small class="d-block" v-if="line.Service && line.Service.recurring">{{line.start_date | formatDate}} - {{line.end_date | formatDate}}</small>
          </v-col>
          <v-col class="align-right">
            <span  v-if="line.qty > 1">
              {{line.qty}} x
            </span>
            {{line.cost | formatMoney}}
            <span class="d-block" v-if="(line.Product.default_type === 'product' || line.Product.default_type === 'late') && step > 2 && !invoices[0].id">
                <small><hb-link @click="removeService(line)">remove</hb-link></small>
            </span>
            </v-col>
        </template>
      </v-row>
    </template>

    <!-- <v-row no-gutters class="mb-1 mx-3 font-13">
      <v-col>
        Coverage
      </v-col>
      <v-col  class="align-right" v-if="summary_data.decline_insurance && summary_data.decline_insurance.decline_insurance">Declined</v-col>
      <v-col  class="align-right" v-if="insurance &&  (!summary_data.decline_insurance || !summary_data.decline_insurance.decline_insurance ) "> {{ insurance.name || 'None' }} </v-col>
    </v-row> -->


    <!-- <v-row no-gutters class="mb-3 mx-3" v-if="lease_discount">
      <v-col>Discount</v-col>
      <v-col  class="align-right">{{ lease_discount.name }} </v-col>
    </v-row>

    <v-row no-gutters class="mb-3 mx-3" v-for="(promotion, i) in lease_promotions" :key="i">
      <v-col>Promotion</v-col>
      <v-col  class="align-right">{{ promotion.name }} </v-col>
    </v-row> -->

    
    <template  v-if="summary_invoice && summary_invoice.InvoiceLines">
      <v-row v-for="(line, i) in summary_invoice.InvoiceLines" :key="i" no-gutters class="mb-1 mx-3 font-13" >
        <template v-if="line.Product.default_type != 'rent'">
          <v-col>
            {{ line.Product.default_type == 'insurance' ? 'Coverage ('+ line.Product.name +')': line.Product.name}}
            <small class="d-block" v-if="line.Service && line.Service.recurring">{{line.start_date | formatDate}} - {{line.end_date | formatDate}}</small>
          </v-col>
          <v-col class="align-right">
            <span  v-if="line.qty > 1">
              {{line.qty}} x
            </span>
            {{line.cost | formatMoney}}
            <span class="d-block" v-if="(line.Product.default_type === 'product' || line.Product.default_type === 'late') && step > 2 && !invoices[0].id">
                <small><hb-link @click="removeService(line)">remove</hb-link></small>
            </span>
          </v-col>
        </template>
      </v-row>
    </template>
    <v-row no-gutters class="mb-3 mt-3 mx-3 font-13">
      <v-col>
        <v-divider></v-divider>
      </v-col>

    </v-row>


          <div class="total-row mb-3 mx-3 font-13"  v-if="summary_invoice && getConfigurationStatus(configuration_unit.unit_type_id,'have_invoices')">

      <v-row no-gutters class="mb-1">
        <v-col>Subtotal</v-col>
        <v-col class="align-right">{{summary_invoice.sub_total | formatMoney}}</v-col>
      </v-row>

      <v-row no-gutters class="mb-1">
        <v-col>Taxes</v-col>
        <v-col class="align-right">{{summary_invoice.total_tax | formatMoney}}</v-col>
      </v-row>

      <v-row no-gutters class="mb-1" v-if="summary_invoice.discounts > 0 ">
        <v-col>Discounts</v-col>
        <v-col class="align-right">-{{ summary_invoice.discounts | formatMoney }}</v-col>
      </v-row>



      <v-row no-gutters class="mb-3 mt-3">
        <v-col>
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <v-row no-gutters class="mb-1 total-payment">
        <v-col>Total</v-col>
        <v-col class="align-right">{{summary_invoice.total_due | formatMoney}}</v-col>
      </v-row>


      <v-row no-gutters class="mb-3 mt-3" v-if="summary_payments && summary_payments.length">
        <v-col>
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <v-row no-gutters class="mb-1" v-if="summary_payments && summary_payments.length" v-for="payment in summary_payments">


        <v-col> {{ payment.method.toUpperCase() }}
          <span v-if="paymentMethod && payment.type === 'ach'"><span v-for="n in paymentMethod.account_number.length - 4">*</span>{{ paymentMethod.account_number.substr(paymentMethod.account_number.length - 4) }}</span>
          <span v-if="paymentMethod && payment.type === 'card'">
          ****-{{ paymentMethod.card_number.substr(paymentMethod.card_number.length - 4) }}
        </span>

        </v-col>
        <v-col class="align-right"><span @click="removePayment()"><v-icon color="danger">mdi-remove</v-icon></span>  ({{ payment.amount | formatMoney }})</v-col>
      </v-row>

      <v-row no-gutters class="mb-1 total-payment" v-show="summary_invoice.balance > 0 && summary_invoice.balance < summary_invoice.total_due ">
        <v-col>Balance Due</v-col>
        <v-col class="align-right">{{summary_invoice.balance | formatMoney}}</v-col>
      </v-row>
    </div>

  </v-container>
</v-card-text>

</v-card>

</template>


<script type="text/babel">

    import Status from '../../includes/Messages.vue';
    import Loader from '../../assets/CircleSpinner.vue';
    import api from '../../../assets/api.js';
    import moment from 'moment';
    import { EventBus } from '../../../EventBus.js';

    import { mapGetters, mapMutations } from 'vuex';

    export default{
        name: "LeaseSummary",
        data(){
            return {
                showAside: true,
                summary_unit:{}
            }
        },

        

        components:{
        },
        async created(){
            this.getLatestContactData()
            this.showAside = this.show;
            await this.$store.dispatch('filterStore/getCategories');
            EventBus.$on('tenant_updated', this.setContactDetail);
        },
        computed:{
            ...mapGetters({
                properties: 'propertiesStore/filtered',
                property: 'onBoardingStore/property',
                lease: 'onBoardingStore/lease',
                configuration_unit: 'onBoardingStore/configuration_unit',
                configuration_lease: 'onBoardingStore/configuration_lease',
                configuration_invoices: 'onBoardingStore/configuration_invoices',
                tenants: 'onBoardingStore/tenants',
                contact: 'onBoardingStore/contact',
                unit: 'onBoardingStore/unit',
                insurance: 'onBoardingStore/insurance',
                promotions: 'onBoardingStore/promotions',
                discounts: 'onBoardingStore/discounts',
                invoices: 'onBoardingStore/invoices',
                payment: 'onBoardingStore/payment',
                payments: 'onBoardingStore/payments',
                paymentMethod: 'onBoardingStore/paymentMethod',
//              categories: 'filterStore/unit_category_id',
                categories: 'unit_category_id',
                unitCategories: 'filterStore/unit_category',
                taxRate: 'onBoardingStore/taxRate',
            }),
            selectedTenant: {
              get() {
                  return this.contact;
              },
              set(value) {
                this.contact = value;
              }
            },
            summary_data(){

                return this.step === 2 ? this.configuration_lease : this.lease;
            },
            summary_invoice(){
              let invoices = []
              if (this.step === 2){
                invoices = this.configuration_invoices;
              } else {
                invoices = this.invoices;
              }

              let summary_invoice;
              //let currentRent;
              if(invoices && invoices.length){
                for(const invoice of invoices){
                  if(!summary_invoice){
                    
                  //   currentRent = invoice.InvoiceLines.filter(line => line.Product.category_type === 'rent')
                  //   currentRent = currentRent[0].cost

                  //   var subtotal = 0;
                  //   var total = 0;
                    

                  // invoice.InvoiceLines.map(line => {
                  //   if(line.Product.amount_type === 'percentage'){
                  //     //calculate cost based on percentage
                  //       line.cost = line.Product.price * currentRent / 100;
                  //     //new subtotal
                  //       line.subtotal = (line.cost * line.qty);
                  //     //new total
                  //       line.total = line.subtotal + line.totalTax - line.totalDiscounts;

                  //       subtotal += line.subtotal;
                  //       total += line.total;
                  //       return line;
                  //   }else{
                  //     subtotal += line.subtotal;
                  //     total += line.total;
                  //   }
                  // });

                  // invoice.sub_total = subtotal;
                  // invoice.total_due = subtotal + invoice.total_tax;

                  summary_invoice = JSON.parse(JSON.stringify(invoice));

                  
                    continue;
                  }

                  for(const line of invoice.InvoiceLines){
                    console.log("line", line)
                    let index = summary_invoice.InvoiceLines.findIndex(il => il.Product.id == line.Product.id);
                    if(index >= 0){
                      if(moment(line.end_date, 'YYYY-MM-DD').isAfter(moment(summary_invoice.InvoiceLines[index].end_date, 'YYYY-MM-DD'))){
                        summary_invoice.InvoiceLines[index].end_date = line.end_date;
                      }
                      summary_invoice.InvoiceLines[index].cost += line.cost;
                      
                    } else {
                      summary_invoice.InvoiceLines.push(JSON.parse(JSON.stringify(line)));
                    }
                  }
                  summary_invoice.sub_total += invoice.sub_total;
                  summary_invoice.total_tax += invoice.total_tax;
                  summary_invoice.discounts += invoice.discounts;
                  summary_invoice.total_due += invoice.total_due;
                  summary_invoice.balance += invoice.balance;
                }

              }

              return summary_invoice || { InvoiceLines : []};

            },

            summary_payments(){
              return this.payments;
            },

            lease_promotions(){
                if(!this.summary_data.promotions || !this.summary_data.promotions.length) return [];
                if(!this.promotions[this.summary_data.unit_id] || !this.promotions[this.summary_data.unit_id].length) return [];
                let promotion_list = JSON.parse(JSON.stringify(this.promotions[this.summary_data.unit_id]));
                return promotion_list.filter(p => this.summary_data.promotions.find(promo =>  promo.promotion_id === p.id ))

            },

            lease_discount(){

                if(!this.summary_data.discount_id) return null;
                if(!this.discounts[this.summary_data.unit_id] || !this.discounts[this.summary_data.unit_id].length) return [];
                let discounts_list = JSON.parse(JSON.stringify(this.discounts[this.summary_data.unit_id]));

                return discounts_list.find(p => this.summary_data.discount_id === p.id);
            },
            promotion(){
               if(!this.lease.promotions || this.lease.promotions.length )  return ;

               return this.lease.promotions.map(promo => {
                   return this.promotions.find( p => p.id === promo.promotion_id )
               });
            },
            unit_category(){
                if(!this.unitCategories) return {};
                return this.unitCategories.find(c => c.id === this.summary_unit.category_id) || {}
            },
            computedPropertyInfo(){
              if(this.property && this.property.Address){
                return (this.property.number ? this.property.number + ' - ' : '') + (this.property.Address && this.$options.filters.formatAddress(this.property.Address, '-', true));
              } else {
                return undefined;
              }
            }
        },
        methods:{
            ...mapMutations({
              setContact: 'onBoardingStore/setContact'
            }),
            editTenant(tenant){
                this.$store.commit('setActiveContactId', tenant.contact_id);
            },
            async removeTenant(tenant){
                let r = await api.delete(this, api.LEASES + this.$store.getters.leaseId + '/tenants/', tenant.id )
                await this.$store.dispatch('findLeaseTenants');

            },
            async removePayment(){
                await this.$store.commit('removePayment');
            },
            async removeService(product){
              let r = await api.delete(this, api.SERVICES, product.Service.id);
              await this.$store.dispatch('onBoardingStore/getServices');
              await this.$store.dispatch('onBoardingStore/getEstimate', {
                updateInvoice: true, generateInvoices: true
              });
              EventBus.$emit('remove_check', product);
              EventBus.$emit('updatePaymentDetails');

            },
            viewEntity(entity, entity_id){
                let params = {};
                params = {
                  component: entity,
                  props: {}
                };
                params.props[entity + '_id'] = entity_id;
                this.$store.dispatch('navigationStore/openSlideOut', params);
            },
            checkContactTitle(tenant) {
              return (tenant.first ? tenant.first : '') + (tenant.middle ? ' ' + tenant.middle : '') + (tenant.last ? ' ' + tenant.last : '');
            },
            checkContactPhone(tenant) {
              if(tenant.Phones && tenant.Phones[0] && tenant.Phones[0].phone){
                  return tenant.Phones[0].phone;
              } else {
                  return undefined;
              }
            },
            checkContactAddress(tenant) {
              if(tenant.Addresses && tenant.Addresses[0] && tenant.Addresses[0].Address){
                return (tenant.Addresses[0].Address.address ? tenant.Addresses[0].Address.address : '') + (tenant.Addresses[0].Address.address2 ? ' ' + tenant.Addresses[0].Address.address2 + ', ' : '') + this.$options.filters.formatAddress(tenant.Addresses[0].Address); 
              } else {
                return undefined;
              }
            },
            setContactDetail(contact){
                this.selectedTenant = contact;
                this.setContact(contact);
            },
            async getLatestContactData(){
              await api.get(this, api.CONTACTS + this.contact.id).then(r => {
                    this.setContactDetail(r.contact)
                  });
                  // await this.$store.dispatch('onBoardingStore/getUnitInfo',{unit_id:this.unit.id});
                let response = await api.get(this.$app, api.UNITS +  this.unit.id);
                this.summary_unit = response.unit
            },
        },
        props:['show', 'step', 'gateAccessCode', 'loading'],
        destroyed() {
              EventBus.$off('tenant_updated');
        },
        watch:{
            show(val){
                this.showAside = val;
            }
        },

    }


</script>
<style scoped>

  .total-row{
    margin-top: 10px;
    /* background-color: #fff;
    padding: 10px; */
    margin-left: -10px;
    margin-right: -10px;

  }

  .card-title-text{
      color: #306FB6;
      font-size: 15px;
      line-height: 20px;
    }
    .reserved-status-badge{
      color: #FFD600;
      border-color: #FFD600;
    }
    .active-status-badge{
      color: #02AD0F;
      border-color: #02AD0F;
    }
    .move-in-label{
      font-size: 12px;
      color:rgba(0, 0, 0, 0.6);
    }
    .b-radius-5px{
      border-radius: 5px;
    }
    .fw-500{
      font-weight: 500;
    }
    .font-14{
      font-size: 14px;
      line-height: 13px;
      overflow-wrap: break-word;
      white-space: normal;
    }
    .font-13{
      font-size: 13px;
      line-height: 18px;
    }
    .move-in-heading{
      color: #637381;
    }
    .total-payment{
      font-weight: 500;
      font-size: 15px;
      line-height: 24px;
    }
    .w-40px{
      width: 70px;
      height: 40px
    }
    .arrow-down{
      position: relative;
      border: 1px solid #919EAB;
      border-radius: 5px;
      margin-right: 20px;
      display: inline-block;
      opacity: 0.7;
      top: 3px;
    }
    .arrow-down select{
      width: 30px;
      padding-left: 5px;
    }
    .arrow-down::after {
      content: "";
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 5px solid #666;
      position: absolute;
      right: 5px;
      top: 7px;
      pointer-events: none;
    }

</style>
