<template>
    <div id="facility-search--property">

        <v-data-table
            :single-select="false"
            :show-select="canSelectMultiple"
            :value="selected"
            @input="updateSelected"
            :headers="getHeaders"
            :items="allProperties ? properties : selected"
            fixed-header
            @click:row="updateLease"
            disable-pagination
            hide-default-footer
            mobile-breakpoint="null"
            class="main-facility-selector-data-table"
            :height="$vuetify.breakpoint.xs && canSelectMultiple ? '61vh' : $vuetify.breakpoint.xs ? '67vh' : 334"
        >
            <template v-slot:[`item.number`]="{ item }">
                <strong>{{ item.number || ' - ' }}</strong>
            </template>

            <template v-slot:[`item.Address.address`]="{ item }">
               {{ item.Address.address }}
            </template>

            <template v-slot:[`item.occupancy`]="{ item }">
                <occupancy :leases="item.lease_count" :units="item.unit_count" />
            </template>
        </v-data-table>
    </div>
</template>
<script>
import ContextMenu from './ContextMenu.vue';
import Occupancy from './Occupancy.vue';
import api from '../../../assets/api.js';
import { EventBus } from '../../../EventBus.js';

import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'FacilitySearchProperty',
    data() {
        return {
            headers: [{
                value: "number",
                text: "Store #",
                width: "110px"
            },{
                value: "Address.city",
                text: "City",
                width: "100px"
            },{
                value: "Address.address",
                text: "Address",
                width: "190px"
            }]
        }
    },
    props: [
        'query',
        'results',
        'properties',
        'selected',
        'property',
        'allProperties',
        'canSelectMultiple'
    ],
    components: {
        ContextMenu,
        Occupancy
    },
    created() {
        EventBus.$off('reset_gps_property');
        EventBus.$on('reset_gps_property', this.resetGPS);
        if(!this.$vuetify.breakpoint.xs) {
            this.headers = [...this.headers, ...[{
                value: "occupancy",
                text: "Occupancy",
                width: "120px",
            },{
                value: "unit_count",
                text: "Spaces",
                width: "80px",
            }]]
        }
    },
    mounted(){
        this.$parent.$emit('update:selected', this.$props.selected)
        
    },
    computed: {
       getHeaders(){
           let headers = JSON.parse(JSON.stringify(this.headers));
           return headers;
       },
       ...mapGetters({
            activeDrawerComponent: 'navigationStore/activeDrawerComponent',
            configuration_unit: 'onBoardingStore/configuration_unit',
            selectedProperties: 'navigationStore/selectedProperties',
            hasPermission: 'authenticationStore/rolePermission',
            loggedInUser: "authenticationStore/getUserData",
            isTakeaPaymentOpen: "paymentStore/getPaymentSource",
            associatedProperty:'onBoardingStore/property',
            charmSubscribedAndFilteredProperties:'charmSubscriptionStore/charmSubscribedAndFilteredProperties',
            charmSubscribedNotSelectedProperties:'charmSubscriptionStore/charmSubscribedNotSelectedProperties'
        }),
    },
    methods: {
        ...mapActions({
            getPropertiesCharm:'charmSubscriptionStore/getProperties',
            setFilteredProperties:'charmSubscriptionStore/setFilteredProperties',
            exchangeToken: 'propertiesStore/exchangeToken',
            setSelectedProperties:'navigationStore/setSelectedProperties',
            resetSelectedPropertyGroup: 'propertiesStore/resetSelectedPropertyGroup',
            setFilteredPropertyGroups: 'propertiesStore/setFilteredPropertyGroups',
            defaultProperty: 'propertiesStore/setDefaultProperty'
        }),
        async updateLease(payload) {
            this.$nextTick(() => {this.$parent.$emit('close');})
            let is_prop_exist = !!(this.associatedProperty?.id && payload.id === this.associatedProperty.id);
            let is_prop_exists_in_drawer = !!(this.activeDrawerComponent?.property_id && payload.id === this.activeDrawerComponent.property_id);
            let is_move_in = !!(this.activeDrawerComponent && this.activeDrawerComponent.type === 'show_lead_intake');
            let is_take_a_payment = !!(this.activeDrawerComponent && this.activeDrawerComponent.type === 'showMakePayment'); 
            let is_move_out = !!(this.activeDrawerComponent && this.activeDrawerComponent.type === 'showMoveOut'); 
            let is_buy_services = !!(this.activeDrawerComponent && this.activeDrawerComponent.type === 'showSellMerchandise');
            let is_transfer = !!(this.activeDrawerComponent && this.activeDrawerComponent.type === 'showTransfer');
            let is_omni_search = !!(this.activeDrawerComponent && this.activeDrawerComponent.type === 'show_omni_Search');
            let is_task_center = !!(this.activeDrawerComponent && this.activeDrawerComponent.type === 'show_task_center');
            let is_review_rent_change = !!(this.activeDrawerComponent && this.activeDrawerComponent.type === 'review_rent_change');
            let is_lead_follow_up = !!(this.activeDrawerComponent && this.activeDrawerComponent.type === 'show_lead_follow_up');
            this.getPropertiesCharm({primaryRoleProperties:[payload.id],refresh:true,userId:this.loggedInUser.id})
            this.setFilteredProperties([payload.id])
            if(!is_prop_exists_in_drawer && is_transfer){
                this.setSelectedProperties([payload]);
                EventBus.$emit('reset_transfer_hb_model');
            }

            if(!is_prop_exists_in_drawer && is_lead_follow_up){
                EventBus.$emit('hide_drawer');
            } 
            
            if(is_take_a_payment) EventBus.$emit('reset_take_a_payment_hb_model');
            if(is_move_out) EventBus.$emit('reset_move_out_hb_model');
            if(is_buy_services) EventBus.$emit('reset_buy_services_hb_model');
            if(is_omni_search || is_task_center || is_review_rent_change) EventBus.$emit('hide_drawer');
            
            this.resetSelectedPropertyGroup();
            await this.exchangeToken({property_ids:[payload.id]});
            this.setFilteredPropertyGroups();
            await this.filter(payload);
            this.defaultProperty(payload);
        },
        async filter(payload){
            payload = payload || this.selectedProperties[0];
            this.$parent.$emit('update:query', '');

            if(this.hasPermission('mini_charm')) {
                let data = {
                    agent_id: this.loggedInUser.id,
                    // availability: 'available',
                    logged_in: true,
                    // availabile_property_ids: [payload.id],
                    // unavailabile_property_ids: this.properties?.filter(p => p.id !== payload.id)?.map(m => m.id),
                    facilities: {
                        available: this.charmSubscribedAndFilteredProperties.filter(p => p.mini_charm_enabled)?.map(m => m.gds_id),
                        unavailable: this.charmSubscribedNotSelectedProperties?.filter(p => p.mini_charm_enabled)?.map(m => m.gds_id)
                    },
                    from: 'single-property-selection'
                }
                if(data.facilities?.available.length || data.facilities?.unavailable.length){
                    this.$store.dispatch('charmSubscriptionStore/setAvailableAgent', data);
                }
                
            }

            this.$store.dispatch('reportStore/clearReportStructure');
            this.$store.dispatch('filterStore/clearSizes');

            const {name,meta} = this.$router.currentRoute;
            const parent_route = this.$route.matched[0].name;

            if(name === 'Dashboard' || parent_route === 'Dashboard') {
                this.$store.dispatch('dashboardsStore/getDashboards');
            }

            if(name === 'RentManagement' || name === 'RateManagement'){
                this.$store.dispatch('revManStore/setProperty',null);
            }

            if(name === 'DynamicReports') this.$router.push('/reports-library').catch(err => {});

            if ("re_fetch" in meta && meta.re_fetch){
                EventBus.$emit('refetch_data')
            }
        },
        menuSelected(item) {
            switch(item.command) {
                case "read":
                    this.$parent.$emit('update:property', item.option)
                    return;
            }
        },
        updateSelected(event) {
            this.$parent.$emit('update:selected', event)
        },
        removeSelected(item){
            let newList = this.selected.filter(s => s.id !== item.id);
            this.$parent.$emit('update:selected', newList);
        },
        buildOptions(option) {
            return [{
                title: "Info",
                command: "read",
                option
            },{
                title: "Cancel",
                command: "cancel"
            }]
        },
        async resetGPS(payload){
            if(payload){
                await this.exchangeToken({property_ids:[payload.id]});
                await this.filter(payload);
            }
        }
    },
    beforeDestroy(){
    }
}
</script>
<style lang="scss">
#facility-search--property {
    table {
        thead {
            height:48px;
            tr {
                th {
                    background: #F5F6F8;
                    border-top: 0.5px solid #E0E3E8;
                    font-weight:normal;
                    color:#474F5A;
                    font-size:14px;
                    padding: 0 10px;
                }
                th:first-of-type{
                    padding-left:27px;
                }
                th:last-of-type{
                    padding-right:0;
                }
            }
        }
    }
    .v-data-table {
        .v-data-table-header {
            background: #eaeaea;
        }
        tbody {
            tr {
                border-bottom: 1px solid #e0e3e8;
                &:hover {
                    background-color:#F9FAFB;
                }
                &.v-data-table__selected {
                    background-color:#E0F5F5;
                }
                td {
                    border-bottom: none;
                    padding: 18px 10px;
                }
                td:first-of-type{
                    padding-left:27px;
                }
                border-bottom: 1px solid #0000001f;
            }
        }
    }
}

.main-facility-selector-data-table {
    .v-simple-checkbox.v-data-table__checkbox {
        .v-icon {
            color: #101318 !important;
        }
    }
}

@media (max-width: 600px) {
    .facility-search--results-header {
        display: flex;
        flex-direction: column;
    }
    #facility-search--property {
        .v-data-table {
            tbody {
                tr {
                    td:first-of-type{
                        padding-left:27px;
                    }
                }
            }
        }
    }
}
</style>
