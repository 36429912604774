import { render, staticRenderFns } from "./TenantList.vue?vue&type=template&id=48c75ade&scoped=true&"
import script from "./TenantList.vue?vue&type=script&lang=js&"
export * from "./TenantList.vue?vue&type=script&lang=js&"
import style0 from "./TenantList.vue?vue&type=style&index=0&id=48c75ade&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48c75ade",
  null
  
)

export default component.exports