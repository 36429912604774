<template>

    <div class="section-content pl-6 pt-0">
        <v-row>
            <v-col style="max-width: 350px;" class="pa-0">

            </v-col>
        </v-row>

        <div style="flex: 1;" :style="{ height: divHeight + 'px'}">
            <hb-report
                :key="report_key"
                :report_type="conditions.report_name"
                :actions_panel="['columns']"
                :bulkCommunication="true"
                :conditions="conditions"
                @selectedRows="setSelectedRows"
                id_field="lease_id"
                show_search
            ></hb-report>
        </div>
    </div>
</template>

<script type="text/babel">

    import HbReport from '../assets/HummingbirdEditableReport.vue'

    export default {
        name: "Tenants",
        data() {
            return {
                report_key: 0,
                property_id: '',
                selctedRows: []
            }
        },
        props: ['conditions'],
        components:{
            HbReport
        },
        computed:{
            divHeight(){
                let docHeight = document.body.clientHeight;
                return (docHeight - 210);
            },
        },
        methods: {
            setSelectedRows(data){
                this.selctedRows = [...data];
                this.$emit('selctedRows', this.selctedRows);
            },
            selectAll(selected){
                this.$emit('selectAll', selected);
            },
            select(data){
                this.$emit('select', data);
            },
            apply(){
                this.$emit('selctedRows', this.selctedRows);
                this.$emit('hideList');
            }
        },

    }
</script>
<style scoped>
    .top-border-gray{
        border-top: 1px solid #e0e3e8;
        left: 0;
    }
</style>
