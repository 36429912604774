<template>
  <div>
    <rent-kebab-view
      :items="filteredItems"
      :parent-event="parentEvent"
      :params-data="params.data"
    />
  </div>
</template>
<script>
import RentKebabView from "./RentKebabView.vue";
import { mapState } from "vuex";

export default {
  name: "ReviewRentKebabFilter",
  props: {
    parentEvent: {
      type: String,
      required: true,
      default: "",
    },
    permissions: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  components: {
    RentKebabView,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      rentPlans: (state) => state.revManStore?.rent?.plans ?? [],
    }),
    hasRentChangeStatus() {
      return this.params?.data?.rentchange_status ?? false;
    },
    isNotDeployed() {
      return this.hasRentChangeStatus
        ? this.params?.data?.rentchange_status.toLowerCase() !== "deployed"
        : false;
    },
    isNotSkipped() {
      return this.hasRentChangeStatus
        ? this.params?.data?.rentchange_status.toLowerCase() !== "skipped"
        : false;
    },
    isNotCancelled() {
      return this.hasRentChangeStatus
        ? this.params?.data?.rentchange_status.toLowerCase() !== "cancelled"
        : false;
    },
    isAuctioned() {
      let auctionStatus = this.params?.data?.rentchange_auction_status ?? "";
      return (['move_out', 'auction_payment'].includes(auctionStatus));
    },
    showSkipCancel() {
      return (
        this.hasRentChangeStatus &&
        this.isNotDeployed &&
        this.params?.data?.rentchange_id &&
        this.skipCancelStatus(
          this.params?.data?.rentchange_status.toLowerCase()
        ) &&
        this.permissions.manage_rent_change_status &&
        !this.isAuctioned
      );
    },
    showRentExempt() {
      return (
        this.permissions.exempt_rent_changes
      );
    },
    showRentTag() {
      return this.permissions.tag_rent_changes;
    },
    getTagText() {
      return this.params?.data?.rentchange_tagged
        ? "Untag Rent Change"
        : "Tag Rent Change";
    },
    showEditRent() {
      return (
        this.params?.data?.rentchange_id &&
        this.isNotDeployed &&
        this.isNotSkipped &&
        this.isNotCancelled &&
        this.permissions.manual_rent_changes &&
        !this.isAuctioned
      );
    },
    showApproveRent() {
      return (
        this.hasRentChangeStatus &&
        this.permissions.approve_rent_changes &&
        this.params?.data?.rentchange_status.toLowerCase() === "scheduled" &&
        !this.isAuctioned
      );
    },
    filteredItems() {
      let paramsData = this.params?.data;
      const rentExemptLabel =
        paramsData?.lease_rent_plan_status &&
        paramsData?.lease_rent_plan_status === "exempt"
          ? "Remove Rent Change Exemption"
          : "Exempt from Rent Change";
      let notesLabel =
        paramsData?.rentchange_recent_note && paramsData?.rentchange_recent_note
          ? "View Notes"
          : "Add Notes";
      let items = [
        ...(this.showRentExempt
          ? [
              {
                text: rentExemptLabel,
                event: "rentChangeExempt",
              },
            ]
          : []),
        ...(this.showEditRent
          ? [
              {
                text: "Edit Rent Change",
                event: "editRentChange",
              },
            ]
          : []),

        ...(this.showSkipCancel
          ? [
              {
                text: "Skip/Cancel Rent Change",
                event: "skipCancelRentChange",
              },
            ]
          : []),
        {
          text: notesLabel,
          event: "viewNotes",
        },
        ...(this.showRentTag
          ? [
              {
                text: this.getTagText,
                event: "tagRentChange",
              },
            ]
          : []),
        ...(this.showApproveRent
          ? [
              {
                text: "Approve Rent Change",
                event: "approveRentChange",
              },
            ]
          : []),
        ...( paramsData?.rentchange_status == "Approved" && ['error', 'resolved', 'unresolved'].includes(paramsData?.rentchange_notification_status)
          ? [
              {
                text: paramsData?.rentchange_notification_status == 'resolved' ? 'Unresolve' :'Resolve',
                event: 'resolveNotificationStatus'
              }
            ]
          : [])
      ];
      return items;
    },
  },
  methods: {
    skipCancelStatus(status) {
      return ["scheduled", "approved"].includes(status);
    },
  },
};
</script>
