<template>
    <div>
      <div v-if="loading">
        <v-row justify="center" align="center">
          <v-col class="text-center ma-12">
            <v-progress-circular width="2" size="32" indeterminate color="primary" ></v-progress-circular>
          </v-col>
        </v-row>
      </div>
      <div v-else>
          <v-container no-gutters class="pa-6">
              <v-row no-gutters class="merchandise-row-search">
                  <!-- <v-col md="5" no-gutters>
                      <div class="hb-font-header-2-medium pt-1">Merchandise</div>
                  </v-col> -->

                  <v-col cols="9">
                    <hb-text-field
                        search
                        v-model="product_search"
                        placeholder="Search"
                    >
                    </hb-text-field>
                  </v-col>
              </v-row>

              <v-row no-gutters justify="center" v-for="product in filtered_products" :key="product.id" class="merchandise-row">
                  <v-col cols="5" lg="5" md="4" class="mt-1">
                      <strong class="hb-font-header-3-medium" >{{product.name}}</strong><br />
                      <p>{{product.description}}</p>
                  </v-col>
                  <v-col cols="2" lg="2" class="mt-1" :class="{'text-right': true, 'py-0 pr-2': !isVariableRate(product.amount_type) }" md="2">
                      <v-text-field v-if="isVariableRate(product.amount_type)"
                              name="price"
                              dense
                              v-model="product.service_price"
                              label="Price"
                              v-validate="'decimal:2|max_value:9999|min_value:.01'"
                              data-vv-name="price"
                              :id="'price_' + product.id"
                              :name="'price_' + product.id"
                              outlined>
                      </v-text-field>
                       <span v-else class="hb-font-header-3-medium mt-1">{{product.service_price | formatMoney}}</span>
                  </v-col>
                  <v-col  cols="3" class="text-right mt-1 px-1" lg="3" md="3">
                      <v-text-field
                              :hide-details="!errors.collect('product_' + product.id).length"
                              dense
                              v-model="product.qty"
                              label="Qty"
                              v-validate="'numeric'"
                              data-vv-name="qty"
                              :error-messages="errors.collect('product_' + product.id)"
                              outlined>
                      </v-text-field>
                  </v-col>
                  <v-col cols="2" class="text-right" style="margin-top:6px;" lg="2" md="3">
                      <hb-btn color="secondary" @click="saveService(product)" v-if="product.Service">Update</hb-btn>
                      <!-- <hb-btn color="tertiary" @click="removeService(product)" v-if="product.Service">Remove</hb-btn> --> 
                      <hb-btn color="primary" @click="saveService(product)" v-if="!product.Service">Add</hb-btn>
                      <hb-link class="remove-service-btn" @click="removeService(product)" v-if="product.Service">Remove</hb-link>
                  </v-col>
              </v-row>

          </v-container>
      </div>
    </div>

</template>

<script type="text/babel">

    import api from '../../../assets/api.js';
    import { EventBus } from '../../../EventBus.js';

    import moment from 'moment';
    import { mapGetters } from 'vuex';
    import { notificationMixin } from  '../../../mixins/notificationMixin.js';
		 
    export default {
        name: "ConfigureMerchandise",
		mixins: [ notificationMixin ],
        data() {
            return {
                products:[],
                product_search: '',
            }
        },
        async created (){
            await this.fetchMerchandise();
            this.$emit('update:loading', false)
        },
        filters:{
            lineTotal(c){
                return c.qty * c.cost;
            },
            getEndOfPeriod(date){
                return moment(date).add(1, 'month').subtract(1, 'day');
            }
        },
        props: ["loading"],
        computed: {
            ...mapGetters({
                property: 'onBoardingStore/property',
                lease: 'onBoardingStore/lease',
                services: 'onBoardingStore/services',
                insurance: 'onBoardingStore/insurance',
                template: 'onBoardingStore/template',
            }),
            lease_services(){
                return this.services;
            },

            filtered_products: {
                get() {
                    return this.products.filter(p => p.default_type === 'product')
                        .filter(p => !this.product_search || p.name.toLowerCase().search(this.product_search.toLowerCase()) >= 0 || (p.description && p.description.toLowerCase().search(this.product_search.toLowerCase()) >= 0) )
                        .map(p => {
                            let service = this.services.find(s => s.product_id === p.id);
                            this.$set(p, 'qty', service ? service.qty: p.qty);  
                            this.$set(p, 'service_price', service ? service.price : p.price);
                            this.$set(p, 'Service', service || null);
                            return p;
                        })
                },
                set(value) {

                },


            }
        },
        methods: {
            async fetchMerchandise(){
                let r = await api.get(this, api.PROPERTIES + this.property.id + '/products', {
                    type: 'product'
                });
                this.products = r.products;
            },
            async saveService(product){

                let status =  await this.validate(this);
                if(!status) return;

                if(!product.qty){
                    product.qty = 1
                    // this.showMessageNotification({ type: 'error', description: 'Please enter a valid Qty.' });
                    // return;
                }else if(!product.service_price){
                    this.showMessageNotification({ type: 'error', description: 'Please enter a valid Price.' });
                    return; 
                }

                var data = {
                    name: product.name,
                    price: product.service_price,
                    qty: parseInt(product.qty),
                    recurring: 0,
                    prorate: 0,
                    prorate_out: 0,
                    description: product.description,
                    start_date: this.lease.start_date,
                    end_date: null,
                    taxable:product.taxable || 0
                };
                let r = {};
                if(!product.Service){
                    data.product_id = product.id;
                    r = await api.post(this, api.LEASES + this.lease.id + '/services', data);
                    await this.$store.dispatch('onBoardingStore/getServices');
                } else {
                    r = await api.put(this, api.LEASES +  this.lease.id  + '/services/' + product.Service.id, data)
                    await this.$store.dispatch('onBoardingStore/getServices');
                }
                this.$emit('update:loading', true);
                await this.$store.dispatch('onBoardingStore/getEstimate', {updateInvoice: true, generateInvoices: true});
                EventBus.$emit('updatePaymentDetails');
                EventBus.$emit('updatePartialPaymentAmount');

            },

            async removeService(product){
                product.qty = 0;
                let r = await api.delete(this, api.SERVICES, product.Service.id);
                await this.$store.dispatch('onBoardingStore/getServices');
                await this.$store.dispatch('onBoardingStore/getEstimate', {updateInvoice: true, generateInvoices: true});
                EventBus.$emit('updatePaymentDetails');
                EventBus.$emit('updatePartialPaymentAmount');

            },

            isVariableRate(rate_type) {
                return rate_type === 'variable';
            }

        }
    }
</script>

<style scoped>

    .insurance-container{
        border: 1px solid #C4CDD5;
        background: white;
        border-radius: 4px;
        padding: 24px 0px 24px 0px;
    }

    .insurance-container .v-input--radio-group--column{
        margin: 0;
    }
    .insurance-container .insurance-row {
        margin-bottom: 10px;

    }
    .insurance-option-row.no-border{
        border-bottom: none;
    }
    .merchandise-row{
        border-bottom: 1px solid #C4CDD5;
        padding: 24px 0px 24px 0px;
    }

    .merchandise-row-search {
        padding: 0px 0px 24px 0px;
        border-bottom: 1px solid #C4CDD5;
    }

    .merchandise-row:last-child{
        border-bottom: none;
    }
    .remove-service-btn {
        display: block;
        padding-top: 10px;
    }

</style>
