<template>
    <div>
      <div class="tab-container">
        <hb-mini-profile class="pt-8 pb-3"
            type="space"
            disable-avatar-click
            disable-click
            :title="unit.number"
            :status="unit.state"
            :space-type="unit.type"
            :space-category="unit.label"
            :space-featured-amenity="unit.Category.name"
            :space-property-info="spacePropertyInfo(unit.Address)"
        >
        </hb-mini-profile>
        <v-divider class="pb-2"></v-divider>
        <v-expansion-panels v-model="accordionGroup" multiple>
            <hb-expansion-panel>
                <template v-slot:title>
                    Select Coverage *
                </template>
                <template v-slot:content>
                    <insurance></insurance>
                </template>
                
            </hb-expansion-panel>

            <hb-expansion-panel>
                <template v-slot:title>
                    Merchandise
                </template>
                <template v-slot:content>
                    <merchandise></merchandise>
                </template>
            </hb-expansion-panel>

            <hb-expansion-panel>
                <template v-slot:title>
                    Fees
                </template>
                <template v-slot:content>
                    <fees></fees>
                </template>
            </hb-expansion-panel>
        </v-expansion-panels>
                        
        <!-- <v-tabs
            v-show="!this.unit_id"
            background-color="#F9FAFB"
            class="hb-text-light"
            color="#101318"
            show-arrows
        >
            <v-tabs-slider color="#00848E"></v-tabs-slider>
            <v-tab class="text-capitalize" :ripple="false" @click="active_tab = 'insurance'">Coverage</v-tab>
            <v-tab class="text-capitalize" :ripple="false" @click="active_tab = 'merchandise'">Merchandise</v-tab>
            <v-tab class="text-capitalize" :ripple="false" @click="active_tab = 'fees'">Fees</v-tab>
        </v-tabs> -->
      </div>


        <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>

        <!-- <div class="services-section scrollable-content">
            <insurance v-if="active_tab === 'insurance'"></insurance>
            <merchandise v-if="active_tab === 'merchandise'"></merchandise>
            <fees v-if="active_tab === 'fees'"></fees>
        </div> -->

        <!-- <div class="new-tenant-walkthrough-footer">
            <hb-bottom-action-bar
                @close="$emit('close')"
                :top-border="false"
            >
                <template v-slot:left-actions>
                    <help-juice />
                </template>
                <template v-slot:right-actions>
                    
                    <hb-btn
                        color="secondary"
                        :disabled="isLoading($options.name) || !canProgress"
                        @click="$emit('retire_lead')"
                      >
                        Retire Lead
                      </hb-btn>
                    <hb-btn
                        :disabled="isLoading($options.name) || !canProgress"
                        :loading="savingInProgress"
                        @click="productsSaved"
                    >
                        Payments
                    </hb-btn>
                </template>
            </hb-bottom-action-bar>
        </div> -->
    </div>

</template>

<script type="text/babel">
    import api from '../../../assets/api.js';
    import Status from './../Messages.vue';
    import Loader from '../../assets/CircleSpinner.vue';
    import moment from 'moment';

    import Insurance from './Insurance';
    import Merchandise from './Merchandise';
    import Fees from './Fees';
    import { EventBus } from '../../../EventBus.js';
    import { mapGetters } from 'vuex';
    export default {
        name: 'ServicesSelection',
        components: {
            Status,
            Insurance,
            Merchandise,
            Fees
        },
        data: () => ({
            active_tab: 'insurance',
            accordionGroup:[0],
            panel: 0,
            confirm: false,
            show_security_deposit: false,
            promotions:[],
            unit_id: '',
            savingInProgress: false
        }),
        props: [],

        created(){

        },
        computed:{
            ...mapGetters({
                lease: 'onBoardingStore/lease',
                insurance: 'onBoardingStore/insurance',
                unit: 'onBoardingStore/unit',
                property: 'onBoardingStore/property',
            }),
            canProgress(){
                if(this.lease.decline_insurance || this.insurance.id){
                    return true;
                }
                return false;
            },
            contact: {
                get() { return this.$store.getters['onBoardingStore/contact'] } ,
                set(val){  return this.$store.commit('onBoardingStore/setContact', val ) }
            },
            lead: {
                get() { return this.$store.getters['onBoardingStore/lead'] } ,
                set(val){  return this.$store.commit('onBoardingStore/setLead', val ) }
            },
        },
        methods:{

            async productsSaved(){
                this.savingInProgress = true;
                this.$emit('saved');
                this.savingInProgress = false;
            },
            spacePropertyInfo(address) {
              if(this.property && this.property.number){
                return this.property.number + ' - ' + this.$options.filters.formatAddress(address, '^', true);
              } else {
                return undefined;
              }
            },

        },
        mounted(){
            this.contact.Lead = this.lead
        }
    }
</script>

<style scoped>
    .tab-container{
      padding: 0 24px;
      background: none;
      border-bottom: 0px solid #E1E6EA
    }
    .new-tenant-walkthrough .v-tabs,
    .new-tenant-walkthrough .v-tabs .v-tabs-bar{
      background: #F9FAFB;
    }
    .new-tenant-walkthrough-footer{
        /* padding: 3px 0 3px 0; */
        background: #FFFFFF;
        border-top: 1px solid #e2e2e2;
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: 100;
        left: 0;
        right: 0;
    }
    .services-section{
        padding: 20px 24px 100px 24px;
    }

    .unit-confirm-icon .v-list-item__icon {
        text-align: center;
        background: white;
        padding: 25px;

    }

    .v-expansion-panel-content .form-label{
        background: #F4F6F8;
        padding: 20px;
        font-weight: bold;
    }

</style>
