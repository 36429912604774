<template>
    <div class="mb-n4">

        <!-- <v-row class="yellow-row px-2" v-for="(activity, index) in activities" :key="index">
            <v-col md="8" class="py-2 d-flex align-center">
                <div class="d-flex align-center">
                    <v-icon class="pr-1" color="primary" size="25">mdi-check</v-icon>
                    <div>{{activity.description}}</div>
                </div>
            </v-col>
            <v-col md="4" class="text-right py-2">
                <div>
                    {{activity.date}} @ {{activity.time}}<br/> 
                    Staff: {{activity.staff}}
                </div>
            </v-col>
        </v-row> -->

        <v-row class="yellow-row px-2">
            <v-col md="8" class="py-2 d-flex align-center">
                <div class="d-flex align-center">
                    <v-icon class="pr-1" color="primary" size="25">mdi-check</v-icon>
                    <div>Credit of $25 issued to to VISA ending in 1111</div>
                </div>
            </v-col>
            <v-col md="4" class="text-right py-2">
                <div>
                    Apr 25, 2020 @ 12:01pm<br/> 
                    Staff: John Doe
                </div>
            </v-col>
        </v-row>
        <v-row class="yellow-row pa-2">
            <v-col md="8" class="py-2 d-flex align-center">
                <div class="d-flex align-center">
                    <v-icon class="pr-1" color="primary" size="25">mdi-check</v-icon>
                    <div>Refund of $25.00 requested. Pending approval</div>
                </div>
            </v-col>
            <v-col md="4" class="text-right py-2">
                <div>
                    Apr 25, 2020 @ 12:01pm<br/> 
                    Staff: John Doe
                </div>
            </v-col>
        </v-row>
        <v-row class="yellow-row pa-2">
            <v-col md="8" class="py-2 d-flex align-center">
                <div class="d-flex align-center">
                    <v-icon class="pr-1" color="primary" size="25">mdi-check</v-icon>
                    <div>Dumpster fee of $25.00 was charged</div>
                </div>
            </v-col>
            <v-col md="4" class="text-right py-2">
                <div>
                    Apr 25, 2020 @ 12:01pm<br/> 
                    Staff: John Doe
                </div>
            </v-col>
        </v-row>
        <v-row class="yellow-row pa-2">
            <v-col md="8" class="py-2 d-flex align-center">
                <div class="d-flex align-center">
                    <v-icon class="pr-1" color="primary" size="25">mdi-check</v-icon>
                    <div>Maintenance Request Scheduled</div>
                </div>
            </v-col>
            <v-col md="4" class="text-right py-2">
                <div>
                    Apr 25, 2020 @ 12:01pm<br/> 
                    Staff: John Doe
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import api from '../../../../assets/api.js';

    export default {
        name: "ActivityLogs",
        data() {
            return {
                activities : []
            }
        },
        created() {
            // this.getActivitiesList();
        },
        methods: {
            getActivitiesList(){
                api.get(this, api.ACTIVITIES).then(r => {
                    this.activities = r;
                    this.$emit('activitesCounter', this.activities.length);
                })
            }
        },
    }
</script>

<style scoped>
    .yellow-row{
        margin-left: -24px;
        margin-right: -24px;
        box-shadow: 0px -1px 0px rgba(11, 18, 29, 0.1);
    }
</style>