import { render, staticRenderFns } from "./CharmNotifications.vue?vue&type=template&id=29e5cd50&scoped=true&"
import script from "./CharmNotifications.vue?vue&type=script&lang=js&"
export * from "./CharmNotifications.vue?vue&type=script&lang=js&"
import style0 from "./CharmNotifications.vue?vue&type=style&index=0&id=29e5cd50&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29e5cd50",
  null
  
)

export default component.exports