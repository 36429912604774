<template>
    <div id="facility-search--company">
        <v-data-table
            v-model="selected"
            :single-select="false"
            :headers="headers"
            :items="results"
            fixed-header
            hide-default-footer
            height="300px"
        >
            <template v-slot:item.Address="{ item }">
                {{ item.Address.address }}
            </template>
            <template v-slot:item.lease_count="{ item }">
                <occupancy :leases="item.property_count" :units="item.unit_count" />
            </template>
            <template v-slot:item.property_count="{ item }">
                {{ item.unit_count }}
            </template>
            <template v-slot:item.command="{ item }">
                <context-menu :options="buildOptions(item)" @option-clicked="menuSelected" />
            </template>
        </v-data-table>
    </div>
</template>
<script>
import ContextMenu from './ContextMenu.vue';
import Occupancy from './Occupancy.vue';
export default {
    name: 'FacilitySearchCompany',
    data() {
        return {
            selected: [],
            headers: [{
                value: "id",
                text: "Store"
            },{
                value: "Address",
                text: "Address",
                width: "200px",
                sortable: true
            },{
                value: "property_count",
                text: "Spaces",
                sortable: true
            },{
                value: "lease_count",
                text: "Occupancy",
                sortable: true
            },{
                value: "command",
                text: ""
            }]
        }
    },
    props: [
        'query',
        'results'
    ],
    components: {
        ContextMenu,
        Occupancy
    },
    methods: {
        menuSelected(ob) {
            console.log("menu", ob)
        },
        buildOptions(option) {
            return [{
                title: "View",
                command: "view",
                id: option.id
            },{
                title: "Edit",
                command: "edit",
                id: option.id
            },{
                title: "Delete",
                command: "delete",
                id: option.id
            },{
                title: "Cancel",
                command: "cancel"
            }]
        }
    }
}
</script>
<style lang="scss">
#facility-search--company {
    table {
        thead {
            tr {
                th {
                    background: #eaeaea;
                }
            }
        }
    }
    .v-data-table {
        .v-data-table-header {
            background: #eaeaea;
        }
        tbody {
            tr {
                td {
                    border-bottom: none;
                }
            }
        }
    }

    tr {
        td:first-child {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
        }
        td:last-child {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
        }
    }
}
</style>
