<script type="text/babel">
import HbStatusGeneral from "../../aviary/HbStatusGeneral.vue";

export default {
  name: "MultiLineStatusRenderer",
  components: { HbStatusGeneral },
  data() {
    return {
      active: false,
    };
  },
  computed: {
    /**
     * @returns {Array}
     * @example [{
     *    text: 'Julies Ceaser',
     *    color: '',
     *    fontSize: '12px',
     *    type: 'status'
     *    seperator: 'dashed',
     *    bold: false,
     *    tooltip: false
     * }]
     */
    contents() {
      return (this.params.fields ?? []).reduce((field_set, field = {}) => {
        if (!field.column) return field_set;

        let text = this.getFormattedValue(
          field.column,
          field.formatter ?? undefined
        );

        if (text)
          field_set.push({
            text,
            color: this.extractColor({
              color: field.color,
              context: this.params.context,
            }),
            type: field.type ?? "",
            fontSize: field.font_size ?? "12px",
            seperator: field.seperator ?? "",
            bold: field.bold ?? false,
            tooltip: field.tooltip ?? false,
          });
        return field_set;
      }, []);
    },
    columnDefs() {
      return this.params.api.getColumnDefs();
    },
    baseParams() {
      return {
        node: this.params.node,
        data: this.params.data,
        type: this.params.type,
        api: this.params.api,
        columnApi: this.params.columnApi,
        context: this.params.$caller,
      };
    },
    getStatus() {
      let value = String(this.params.valueFormatted).toLowerCase();
      switch (value) {
        // Built-in cases where custom icon is not needed
        case "error":
        case "cancelled":
          return {
            type: "error", //-----RED
            icon: null,
          };
        case "caution":
        case "skipped":
        case "pending":
          return {
            type: "caution", //---YELLOW
            icon: null,
          };
        case "approved":
        case "auto approved":
          return {
            type: "success", //---GREEN
            icon: null,
          };
        case "scheduled":
        case "deployed":
          return {
            type: "guidance", //--BLUE
            icon: null,
          };

        // Custom cases where custom icon not needed
        case "active":
          return {
            type: "success", //---GREEN
            icon: "mdi-check-circle",
          };

        default:
          return {
            type: "default", //---GREY
            icon: null,
          };
      }
    },
  },
  methods: {
    extractColor({ color, context }) {
      if (typeof color !== "undefined") {
        return typeof color === "string"
          ? color
          : color.call(context, this.params.data);
      } else return "#000000";
    },
    getFormattedValue(column, customCallback = (val) => val) {
      let value = this.params.data?.[column] ?? "";
      let col = this.params.columnApi.getColumn(column);
      let colDef = col?.colDef ?? {};
      let valueGetter = colDef.valueGetter;
      let valueFormatter = colDef.valueFormatter;

      if (valueGetter) {
        value = this.evaluateAndReturn(
          valueGetter,
          this.getValueGetterParams(column)
        );
      }
      if (valueFormatter) {
        value = this.evaluateAndReturn(
          valueFormatter,
          this.getValueFormatterParams(column, value)
        );
      }

      return customCallback(value);
    },
    evaluateAndReturn(callBack, params) {
      if (typeof callBack === "function") {
        return callBack(params);
      } else return callBack;
    },
    getValueGetterParams(column) {
      let currentIndex = this.params.rowIndex;
      let row = this.params.api.getDisplayedRowAtIndex(currentIndex);
      let AGcolumn = this.params.columnApi.getColumn(column);

      return {
        getValue: (field) => this.params.getValue.bind(null, field, row)(),
        ...this.baseParams,
        column: AGcolumn,
        colDef: AGcolumn.getColDef(),
      };
    },
    getValueFormatterParams(column, value) {
      let AGcolumn = this.params.columnApi.getColumn(column);

      return {
        value,
        ...this.baseParams,
        column: AGcolumn,
        colDef: AGcolumn.getColDef(),
      };
    },
  },
};
</script>

<template>
  <div
    :class="{ 'status-multi-line ': contents.length > 1 }"
    class="multi-line-group"
  >
    <template v-for="(field, index) in contents">
      <hb-tooltip
        :key="index"
        :disabled="!field.tooltip"
        class="multiline-tooltip"
      >
        <template v-slot:item>
          <HbStatusGeneral
            class="mt-1"
            v-if="field.type === 'status'"
            :status="field.text"
            :type="getStatus.type"
            :icon="getStatus.icon"
          />
          <span
            v-else
            :class="[field.seperator, { bold: field.bold }]"
            :style="{ fontSize: field.fontSize, color: field.color }"
            v-text="field.text"
          >
          </span>
        </template>
        <template v-slot:body>
          {{ field.text }}
        </template>
      </hb-tooltip>
    </template>
  </div>
</template>

<style lang="scss">
.multi-line-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 100%;
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    &.dashed {
      border-top: 1px dashed #637381;
    }
    &.dotted {
      border-top: 1px dotted #637381;
    }
    &.solid {
      border-top: 1px solid #637381;
    }
  }
}
.status-multi-line {
  margin-top: -8px;
  :last-child {
    margin-top: -1px;
  }
}
</style>
