<template>
  <div>
    <v-expansion-panels v-model="panel" multiple>
      <hb-expansion-panel :topMargin="false">
        <template v-slot:title>Banking Information</template>
        <template v-slot:content>
          <div class="px-6 py-4">
            <div class="hb-text-night"><strong>Bank Account</strong></div>
            <div class="hb-text-night-light mt-1">
              Enter the bank account your payment processing proceeds will be
              deposited into.
            </div>
          </div>
          <v-divider></v-divider>
          <hb-form label="Name on Account" required extraLarge>
            <HbTextField
              v-model="form.banking_information.bank_account_holder_name"
              v-validate.disable="'required'"
              placeholder="Bank Account Holder Name"
              data-vv-name="bank_account_holder_name"
              data-vv-as="Bank Account Holder Name"
              data-vv-scope="banking_info"
              :error="
                errors.collect('banking_info.bank_account_holder_name').length >
                0
              "
            />
          </hb-form>
          <hb-form label="Account Ownership Type" required extraLarge>
            <HbSelect
              v-model="form.banking_information.bank_ownership_type"
              :items="ownershipTypes"
              item-text="label"
              item-value="value"
              :clearable="false"
              v-validate.disable="'required'"
              data-vv-name="ownership_type"
              data-vv-as="Account Ownership Type"
              data-vv-scope="banking_info"
              :error="errors.collect('banking_info.ownership_type').length > 0"
              placeholder="Select"
            />
          </hb-form>

          <hb-form label="Account Type" required extraLarge>
            <HbSelect
              v-model="form.banking_information.bank_account_type"
              :items="accountTypes"
              item-text="label"
              item-value="value"
              :clearable="false"
              v-validate.disable="'required'"
              data-vv-name="account_type"
              data-vv-as="Account Type"
              data-vv-scope="banking_info"
              :error="errors.collect('banking_info.account_type').length > 0"
              placeholder="Select"
            />
          </hb-form>

          <hb-form label="Bank Name" required extraLarge>
            <HbTextField
              v-model="form.banking_information.bank_name"
              v-validate.disable="'required'"
              placeholder="Bank Name"
              data-vv-name="bank_name"
              data-vv-as="Bank Name"
              data-vv-scope="banking_info"
              :error="errors.collect('banking_info.bank_name').length > 0"
            />
          </hb-form>

          <hb-form label="Bank Routing Number" required extraLarge>
            <HbTextField
              v-model="form.banking_information.bank_routing_num"
              @input="trimRoutingInput"
              v-validate.disable="'required|integer|min_value:0|min:5|max:9'"
              placeholder="Routing Number"
              data-vv-name="routing_number"
              data-vv-as="Routing Number"
              data-vv-scope="banking_info"
              :error="errors.collect('banking_info.routing_number').length > 0"
            />
          </hb-form>
          <hb-form label="Confirm Bank Routing Number" required extraLarge>
            <HbTextField
              v-model="confirmRoutingNumber"
              @input="trimConfirmRoutingInput"
              ref="confirmRoutingNum"
              v-validate.disable="'required|integer|min_value:0|min:5|max:9'"
              placeholder="Confirm Routing Number"
              data-vv-name="confirm_routing_number"
              data-vv-as="Routing Number Confirmation"
              data-vv-scope="banking_info"
              @paste="disablePaste"
              :error="errors.collect('banking_info.confirm_routing_number').length > 0"
            />
            <div v-if="routingNumbersDoNotMatch" class="error-message">
            Routing numbers do not match.
          </div>
          </hb-form>

          <hb-form label="Bank Account Number" required extraLarge>
            <HbTextField
              v-model="form.banking_information.bank_account_num"
              @input="trimAccountInput"
              v-validate.disable="'required|integer|min_value:0|min:5'"
              placeholder="Account Number"
              data-vv-name="account_number"
              data-vv-as="Account Number"
              data-vv-scope="banking_info"
              :error="errors.collect('banking_info.account_number').length > 0"
            />
          </hb-form>

          <hb-form label="Confirm Bank Account Number" required extraLarge>
            <HbTextField
              v-model="confirmAccountNumber"
              @input="trimConfirmAccountInput"
              ref="confirmAccountNum"
              v-validate.disable="'required|integer|min_value:0|min:5'"
              placeholder="Re-Enter Account Number"
              data-vv-name="confirm_account_number"
              data-vv-as="Account Number Confirmation"
              data-vv-scope="banking_info"
              @paste="disablePaste"
              :error="errors.collect('banking_info.confirm_account_number').length > 0"
            />
            <div v-if="accountNumbersDoNotMatch" class="error-message">
              Account numbers do not match.
            </div>
          </hb-form>

          <hb-form label="Country Code" required extraLarge>
            <HbSelect
              v-model="form.banking_information.country_code"
              :items="countryCodes"
              v-validate.disable="'required'"
              data-vv-name="country_code"
              data-vv-as="Country Code"
              data-vv-scope="banking_info"
              :error="errors.collect('banking_info.country_code').length > 0"
              placeholder="Select"
              :clearable="false"
            />
          </hb-form>
        </template>
      </hb-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  name: "BankAccounts",
  props: {
    form: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    'form.banking_information.bank_routing_num'(newVal) {
      this.checkRoutingNumbersMatch();
    },
    confirmRoutingNumber(newVal) {
      this.checkRoutingNumbersMatch();
    },
  'form.banking_information.bank_account_num'(newVal) {
      this.checkAccountNumbersMatch();
    },
    confirmAccountNumber(newVal) {
      this.checkAccountNumbersMatch();
    },
  },
  methods: {
    disablePaste(event) {
      event.preventDefault();
    },
    checkRoutingNumbersMatch() {
      if (this.form.banking_information.bank_routing_num !== this.confirmRoutingNumber) {
        this.routingNumbersDoNotMatch = true;
      } else {
        this.routingNumbersDoNotMatch = false;
      }
    },
    checkAccountNumbersMatch() {
      if (this.form.banking_information.bank_account_num !== this.confirmAccountNumber) {
        this.accountNumbersDoNotMatch = true;
      } else {
        this.accountNumbersDoNotMatch = false;
      }
    },
    trimRoutingInput(){
      this.form.banking_information.bank_routing_num = this.form.banking_information.bank_routing_num.trim();
    },
    trimConfirmRoutingInput(){
      this.confirmRoutingNumber = this.confirmRoutingNumber.trim();
    },
    trimAccountInput(){
      this.form.banking_information.bank_account_num = this.form.banking_information.bank_account_num.trim();
    },
    trimConfirmAccountInput(){
      this.confirmAccountNumber = this.confirmAccountNumber.trim();
    },
  },
  data() {
    return {
      panel: [0],
      ownershipTypes: [
        {
          label: "Personal",
          value: "personal",
        },
        {
          label: "Business",
          value: "business",
        },
      ],
      accountTypes: [
        {
          label: "Checking",
          value: "checking",
        },
        {
          label: "Savings",
          value: "savings",
        },
      ],
      countryCodes: ['USA'],
      confirmRoutingNumber: '',
      confirmAccountNumber: '',
      routingNumbersDoNotMatch: false,
      accountNumbersDoNotMatch: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.error-message {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}
</style>
