<template>
    <div class="setup-transfer">
        <v-row class="ma-0 font-weight-medium text-h5" >Reason for Transfer</v-row>
        <v-card class="my-4 hb-elevation-medium">
            <v-row class="mx-6 pt-3">
                <v-col cols="6" class="py-0 pl-0" :class="errors.collect('transfer.reason').length ? 'pb-4' : ''">
                    <v-select
                        :items="transfer_reasons"
                        ref="reasonSelect"
                        item-text="name"
                        item-value="id"
                        v-validate="'required'"
                        v-model="transferData.reason"
                        @change="setReason"
                        label="Select Reason for Transfer"
                        id="reason"
                        name="reason"
                        flat
                        data-vv-scope="transfer"
                        :hide-details="!errors.collect('transfer.reason').length"
                        :error-messages="errors.collect('transfer.reason')"
                    ></v-select>
                </v-col>
                <v-col cols="6" class="py-0 pr-0">
                    <v-textarea
                    v-model="transferData.notes"
                    name="notes"
                    label="Enter Notes..."
                    rows="1"
                    ></v-textarea>
                </v-col>
            </v-row>
        </v-card>
        <v-row class="ma-0 font-weight-medium text-h5">Transfer Out</v-row>
        <v-row class="ma-0 pb-2" style="color: #637381">This is the space your tenant will transfer out of.</v-row>

            <hb-data-table
                :headers="spaceHeader"
                :items="spaceItem"
            >
                <template v-slot:item.number="{ item }">
                    <span v-html="item.number.toString()"></span>
                </template>
            </hb-data-table>

        <v-row class="ma-0 font-weight-medium text-h5 mt-4">Transfer In</v-row>
        <v-row class="ma-0" style="color: #637381">Select the space you want to transfer the tenant into.</v-row>
        <div>
            <div class="py-2">
                <filter-dropdown-menu
                    :filter_data="filter_data"
                    :search="search"
                    :reset="true"
                    :location="'TRANSFER'"
                    @resetFilters = "resetFilters"
                    @setValues = "setValues"
                    >
                </filter-dropdown-menu>

            </div>
            <unit-selector
                :search="search"
                :condition="{unit_list: selectedUnit}"
                @setUnit="setUnit"
            ></unit-selector>
        </div>
        <div class="new-tenant-walkthrough-footer">
            <v-row class="ml-0">
                <v-col class="pt-4">
                    <help-juice />
                </v-col>
                <v-col cols="9" class="d-flex align-center justify-end pr-sm-7">
                    <hb-link class="mr-2" @click="$emit('close')"
                        >Cancel</hb-link
                    >
                    <hb-btn
                        color="primary"
                        @click="save"
                        :disabled="disableTakePayment"
                        >Take Payment</hb-btn
                    >
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script type="text/babel" >
import UnitSelector from "../LeadIntake/UnitSelector";
import api from '../../../assets/api.js';
import HbUnitIcon from '../../../aviary/HbUnitIcon.vue'
import FilterDropdownMenu from '../../FilterDropdownMenu'
import { mapGetters, mapActions, mapMutations } from 'vuex'; 
import Vue from "vue";
import { EventBus } from "../../../EventBus";
import { notificationMixin } from "@/mixins/notificationMixin.js";


export default {
    name: "SetupTransfer",
    mixins: [notificationMixin],
    components: {
        UnitSelector,
        HbUnitIcon,
        FilterDropdownMenu,
    },
    data() {
        return {
            spaceHeader: [
                {
                    text: "Space #",
                    value: "number",
                    sortable: false,
                },
                {
                    text: "Size",
                    value: "size",
                    sortable: false,
                },
                {
                    text: "Price",
                    value: "price",
                    sortable: false,
                },
                {
                    text: "Active Promo",
                    value: "promo",
                    sortable: false,
                    width: 150
                },
                {
                    text: "Discount",
                    value: "discount",
                    sortable: false,
                    width: 150
                },
                {
                    text: "Coverage",
                    value: "coverage",
                    sortable: false,
                    width: 10,
                },
            ],
            transfer_reasons: [
                { name: "Space is too big", id: "space_too_big" },
                { name: "Space is too small", id: "space_too_small" },
                { name: "Space is too far away", id: "space_too_far" },
                { name: "Rent is too high", id: "rent_too_high" },
                {
                    name: "Space requires maintenance",
                    id: "space_requires_maintenance",
                },
                {
                    name: "Space has deficiencies",
                    id: "space_has_deficiencies",
                },
            ],
            search: {
                search: "",
                property_id: [],
                price: [],
                size: [],
                amenities: [],
                type: [],
                status: ['Available'],
            },
            filter_data: [
                {
                    open: false,
                    label: "Size",
                    items: [],
                    data: [],
                },
                {
                    open: false,
                    label: "Amenities",
                    items: [
                        "Climate Controlled",
                        "Elevator",
                        "Power Outlet",
                        "Outside Access",
                        "Drive-up",
                    ],
                    data: [],
                },
                {
                    open: false,
                    label: "Price",
                    items: [
                        "< $50",
                        "$50 - $100",
                        "$100 - $150",
                        "$150 - $300",
                        "> $300",
                    ],
                    data: "",
                },
            ],
            spaceData: [],
            selectedUnit: []
        };
    },
    props: ["lease", "unitOut", "transferData"],
    async created() {
        if(this.transferData.unit_in) {
            this.selectedUnit.push({unit_id: this.transferData.unit_in});
        }
        await this.getSizes();

        if(this.unitOut){
            this.search.type.push(this.unitOut.unit_type_id);
        }
        EventBus.$on('set_payment_source', this.setPaySource);
    },
    computed: {
        ...mapGetters({
            activeDrawerComponent: 'navigationStore/activeDrawerComponent',
            getTaxExempt: "onBoardingStore/getTaxExempt",
            getTaxExemptDescription: "onBoardingStore/getTaxExemptDescription"
        }),
        spaceItem() {
            let spaceItem = {};

            const componentClass = Vue.extend(HbUnitIcon);
            const instance = new componentClass({propsData: { unit: {type: this.unitOut.type,unit_type_id:this.unitOut.unit_type_id}, small: true, color: '#101318' }});
            const unitIcon = instance.$mount();
            const unitIconHTML = unitIcon.$el.innerHTML;
            spaceItem.number = `<div>${unitIconHTML} <span>#${this.unitOut.number}</span></div>`;
            spaceItem.size = this.unitOut.label;
            spaceItem.price = this.$options.filters.formatMoney(this.unitOut.price);
            spaceItem.promo = this.activePromo.name;
            spaceItem.discount = this.activeDiscount.name;
            spaceItem.coverage = this.getCoverage;

            return this.$props.unitOut ? Array(spaceItem) : [];
        },
        disableTakePayment() {
            if (this.transferData.unit_in) {
                return false;
            } else {
                return true;
            }
        },
        activePromo() {
            if(this.lease.Discounts && this.lease.Discounts.length) {
                let promotion = this.lease.Discounts.find(promotion => promotion.Promotion.label == 'promotion');
                if(promotion) return promotion.Promotion;
            }      
            return {name: 'N/A'};
        },
        activeDiscount() {
            if(this.lease.Discounts && this.lease.Discounts.length) {
                let discount = this.lease.Discounts.find(promotion => promotion.Promotion.label == 'discount');
                if(discount) return discount.Promotion;
            } 
            return {name: 'N/A'};
        },
        getCoverage() {
            if(this.lease.InsuranceService) {
                return "$" + parseInt(this.lease.InsuranceService.Insurance.coverage).toLocaleString();
            } else {
                return 'Has Own';
            }
        }
    },
    methods: {
        ...mapMutations({
           setPaymentSource: 'paymentsStore/setPaymentSource',
           setOnBoardingSource: 'onBoardingStore/setOnBoardingSource'
        }),
        ...mapActions({
            setActiveDrawerComponent:'navigationStore/setActiveDrawerComponent',
        }),
        async setPaySource(){ 
            this.setOnBoardingSource("TRANSFERS");
            await this.setPaymentSource({
                paymentSource: "TRANSFERS"
            });
            this.transferData.unit_in = '';
        },
        async save() {
            let status = await this.$validator.validateAll("transfer");
            if (!status) {
                this.$refs.reasonSelect.focus();
            return;
            }
            if (this.getTaxExempt && !this.getTaxExemptDescription) {
                this.showMessageNotification({ description: 'Please enter reason for tax exempt' });
                return;
            }
            this.$emit("next");
        },
        async getSizes() {
            let r = await api.get(this, api.UNITS + "options", {
                property_id: this.unitOut.property_id,
            });
            this.filter_data[0].items = r.options.map(
                (o) => o.width + "' x " + o.length + "'"
            );
        },
        resetFilters() {
            for (let i = 0; i < this.filter_data.length; i++) {
                this.filter_data[i].data = [];
            }
            this.search.search = "";
            this.search.price = [];
            this.search.size = [];
            this.search.amenities = [];
        },
        async setUnit(unit_id) {
            this.$emit("setUnit", unit_id);
            this.activeDrawerComponent.unit_in = unit_id;
            this.setActiveDrawerComponent({ ...this.activeDrawerComponent })
        },
        setReason() {
            this.$emit("setReason", this.transferData.reason);
            this.activeDrawerComponent.transferData = this.transferData;
            this.setActiveDrawerComponent({ ...this.activeDrawerComponent })
        },
        setValues(mi) {
            this.search[mi.label.toLowerCase()] = mi.data;
            mi.open = false;
        },
    },
};
</script>

<style scoped>
</style>