<template>
  <v-card class="card-height hb-elevation-medium mx-2 mt-1">
    <v-row no-gutters>
      <v-col cols="2" :class="[data.skipped  || data.deleted_at ? 'rate-item-past':'rate-item', 'pl-7 pt-4', 'rate-item-2']">
        <span class="rate-item-label-1">{{data.name}}</span>
        <br>
        <template v-if="data.type === 'manual'">
          <v-icon size="14" color="#637381" >mdi-hand-left</v-icon>
          <span class="rate-item-status">
            Manual
          </span>
        </template>
        <template v-else-if="data.type === 'veritec'">
          <hb-icon  color="#637381" class="rent-mangement-veritec-icon">mdi-custom-veritec</hb-icon>
          <span class="rate-item-status rate-item-status-veritec">
            Veritec
          </span>
        </template>
        <template v-else-if="data.type === 'prorize'">
          <hb-icon  color="#637381">mdi-custom-prorize</hb-icon>
          <span class="rate-item-status rate-item-status-prorize">
            Prorize
          </span>
        </template>
        <template v-else-if="data.type === 'price_monster'">
          <!-- <hb-icon  color="#637381" class="rent-mangement-price-monster-icon">mdi-custom-price-monster</hb-icon> -->
          <span class="rate-item-status">
            Price Monster
          </span>
        </template>
        <template v-else>
          <v-icon  size="14" color="#637381">mdi-state-machine</v-icon>
          <span class="rate-item-status">
            Automated
          </span>
        </template>

        <br>
        <template v-if="data.skipped">
          <v-icon size="14" color="#FB4C4C">mdi-debug-step-over</v-icon>
          <span class="rate-item-status-2">
            Skipped
          </span>
        </template>
        <template v-if="data.deleted_at">
          <v-icon size="14" color="#FB4C4C">mdi-close-circle-outline </v-icon>
          <span class="rate-item-status-2">
            Cancelled
          </span>
        </template>
      </v-col>
      <v-col cols="4" class="pa-0 rate-item-2">
        <v-row align="center" class="px-2" :class="[data.upload_id ? ' rate-complete': '', 'mx-0 py-1']">
          <v-col cols="8">
            <v-row class="mx-0" align="center">
              <span class="material-icons icon-complete" v-if="data.upload_id">check_circle</span>
              <span class="radio-1" v-else></span>
              <span class="rate-item-label-2" v-if="!data.upload_id">Generate Document</span>
              <span class="rate-item-label-2" v-else>
                Generated {{data.uploads}} of {{data.RentChangeLeases.length }} Documents
                <hb-link target="_blank" v-if="data.upload_id && data.src" :href="data.src" >
                  <v-icon size="21" color="primary">mdi-download</v-icon>
                </hb-link>
              </span>
            </v-row>
          </v-col>

          <v-col cols="4">
            <span class="rate-item-label-4 float-right">{{ data.scheduled_date | formatLocalShortDate}}</span>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row align="center" class="px-2" :class="[data.emails > 0 ? ' rate-complete': '', 'mx-0 py-1', !deliveryMethod.name ? 'rate-item-3':'']">
          <v-col cols="8" >
            <v-row class="mx-0" align="center">


              <template v-if="deliveryMethod.name">
                <template v-if="data.emails">
                  <span class="material-icons icon-complete">check_circle</span>
                  <template v-if="deliveryMethod.name == 'Standard Email' || deliveryMethod.name == rpost_delivery_method">
                    <span class="rate-item-label-2">Sent {{data.emails}} of {{data.RentChangeLeases.length }} Emails</span>
                  </template>
                  <span class="rate-item-label-2" v-else>{{deliveryMethod.name}}</span>
                </template>
                <template v-else>
                  <span class="radio-1"></span>
                  <span class="rate-item-label-2">{{deliveryMethod.name}}</span>
                </template>
              </template>
              
              <template v-else>
                <span class="radio-1"></span>
                <span class="rate-item-label-2">No Delivery Requested</span>
              </template>

              
              <!-- <span class="material-icons icon-complete" v-if="data.emails">check_circle</span>
              <span class="radio-1" v-else></span>

              <span class="rate-item-label-2" v-if="!data.emails">Email</span>
              <span class="rate-item-label-2" v-else>
                Sent {{data.emails}} of {{data.RentChangeLeases.length }} Emails
              </span> -->


            </v-row>
          </v-col>
          <v-col cols="4">
            <span class="rate-item-label-4 float-right" v-if="deliveryMethod.name">{{ data.scheduled_date | formatLocalShortDate}}</span>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row align="center" class="px-2" :class="[data.completed ? ' rate-complete': '', 'mx-0 py-1']">
          <v-col cols="8" >
            <v-row class="mx-0" align="center">
              <span class="material-icons icon-complete" v-if="data.completed">check_circle</span>
              <span class="radio-1" v-else></span>
              <span class="rate-item-label-2">Rent Change</span>
            </v-row>
          </v-col>
          <v-col cols="4">
            <span class="rate-item-label-4 float-right">{{ data.target_date | formatLocalShortDate}}</span>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6" class="pa-0">
        <v-row class="mx-0 pt-3">
          <v-col cols="7" class="pl-5 py-1">
            <v-row class="mx-0">
              <v-col md="9" class="pa-0"><span class="rate-item-label-4">Total Rent Changes:</span></v-col>
              <v-col md="3" class="pa-0"><span class="rate-item-label-4 font-weight-medium">{{data.Total ? data.Total : 0}}</span></v-col>
            </v-row>

            <v-row class="mx-0">
              <v-col md="9" class="pa-0"><span class="rate-item-label-4">Rent Change:</span></v-col>
              <v-col md="3" class="pa-0"><span class="rate-item-label-4 font-weight-medium">{{ getCalcValue(data.change_amt, data.change_direction, data.change_type) ? getCalcValue(data.change_amt, data.change_direction, data.change_type) : 0}}</span></v-col>
            </v-row>

            <v-row class="mx-0">
              <v-col md="9" class="pa-0"><span class="rate-item-label-4">Monthly Revenue Change:</span></v-col>
              <v-col md="3" class="pa-0"><span class="rate-item-label-4 font-weight-medium">{{ ( data.monthly_revenue ? data.monthly_revenue : 0 ) | formatMoney }}</span></v-col>
            </v-row>

            <v-row class="mx-0">
              <v-col md="9" class="pa-0"><span class="rate-item-label-4">Property Occupancy:</span></v-col>
              <v-col md="3" class="pa-0"><span class="rate-item-label-4 font-weight-medium">{{data.store_occupancy ? data.store_occupancy : 0}}%</span></v-col>
            </v-row>

            <v-row class="mx-0">
              <v-col md="9" class="pa-0"><span class="rate-item-label-4">Target Group Occupancy:</span></v-col>
              <v-col md="3" class="pa-0"><span class="rate-item-label-4 font-weight-medium">NA</span></v-col>
            </v-row>

            <v-row class="mx-0">
              <v-col md="9" class="pa-0"><span class="rate-item-label-4">Move-Out’s After Raise:</span></v-col>
              <v-col md="3" class="pa-0"><span class="rate-item-label-4 font-weight-medium">{{data.move_out_after_raise ? data.move_out_after_raise : 0}}</span></v-col>
            </v-row>

          </v-col>

          <v-col cols="5" class="pl-0 pr-5 text-right pt-2" v-if="!data.completed && !data.skipped && !data.deleted_at && !$props.finalizeChanges">

            <template v-if="data.reviewed && !$props.editOnly">
              <hb-btn small color="primary" @click="$emit('finalize-changes', data)" >Finalize Rent Change</hb-btn>
              <br>
            </template>

            <template v-else-if="!$props.editOnly">
              <hb-btn small color="primary" @click="$emit('review-changes', data)" >Review Notifications</hb-btn>
              <br>
            </template>

            <hb-btn small color="secondary" v-if="$props.editOnly" @click="editRateChange(data.id, data.type)">Edit</hb-btn>
            <span v-else-if="!data.skipped && !data.deleted_at">
                <hb-link small text class="mt-2 d-inline-block" v-if="!data.skipped && !data.reviewed" @click="$emit('skip-rate', data.id)">Cancel Rent Change</hb-link>
                <hb-link small text class="mt-2 d-inline-block" v-else-if="!data.deleted_at && data.reviewed" @click="$emit('cancel-rate', data.id)">Cancel Rent Change</hb-link>
            </span>

            <br />

            <div class="mt-3">
              <hb-link target="_blank" :href="url + '/companies/' + this.cid + '/rate-management/rate-changes/' + data.id + '/report'">
                <v-icon color="primary" size="21">mdi-download</v-icon> Get Report
              </hb-link>
            </div>

          </v-col>

          <v-col cols="5" class="pl-0 pr-5 text-right pt-2" v-else-if="data.completed">

            <template>
              <br>
              <br>
              <span class="rate-item-label-5">Completed {{ data.completed | formatLocalShortDate}}</span>
              <br />
              <div class="mt-3">
                <hb-link target="_blank" :href="url + '/companies/' + this.cid + '/rate-management/rate-changes/' + data.id + '/report'">
                  <v-icon color="primary" size="21">mdi-download</v-icon> Get Report
                </hb-link>
              </div>
            </template>

          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { EventBus } from '../../EventBus.js';
import { mapGetters, mapActions } from 'vuex';
import api from '../../assets/api.js'
import NOTICE_MANAGER from '@/constants/notice_manager.js'; 

export default {
  name: 'RateChangeItem',
  data() {
    return {
      url: process.env.VUE_APP_API_PROTOCOL + '://' + process.env.VUE_APP_API_SUBDOMAIN + '.' + process.env.VUE_APP_DOMAIN + ':' + process.env.VUE_APP_API_PORT + '/v1',
      rateChangeDetail: [
        {
          label: 'Total Rent Changes:',
          value: '20'
        },
        {
          label: 'Rent Change:',
          value: '15%'
        },
        {
          label: 'Monthly Revenue:',
          value: '$2,000'
        },
        {
          label: 'Store Occupancy:',
          value: '95%'
        },
        {
          label: 'Target Group Occupancy:',
          value: '85%'
        },
        {
          label: 'Move-Out’s After Raise',
          value: '0'
        }
      ],
      deliveryMethod: {},
      rpost_delivery_method: NOTICE_MANAGER.RPOST.RPOST_DELIVERY_METHOD
    }
  },
  props: ['data','reviewChanges', 'skipRateChange', 'cancelRate', 'editOnly', 'finalizeChanges', 'flagTaskCenter'],
  created () {
     //this.url = api.RATE_MANAGEMENT + 'rate-changes/' + this.data.id + '/report';
      // console.log("url", url);
    if(this.data.delivery_methods_id) this.fetchDeliveryMethod(this.data.delivery_methods_id);
  },
  computed:{
    ...mapGetters({
      activeDrawerComponent: 'navigationStore/activeDrawerComponent'
    }),
    cid(){
        return this.$store.getters['authenticationStore/getCid'];
    }
  },
  methods: {
    ...mapActions({
      setActiveDrawerComponent:'navigationStore/setActiveDrawerComponent'
    }),
    async fetchDeliveryMethod(id){
      let r = await api.get(this, api.DELIVERYMETHODS + id);
      this.deliveryMethod = r.delivery_method;
    },
    getCalcValue(amount, direction, type){
      let amnt = amount;
      if (type === 'fixed') {
        amnt = '$'+ amount;
      } else {
        amnt = type === 'percent' ? amount + '%' : '$'+ amount;
        amnt = direction === 'Increase' ? '+' + amnt : '-' + amnt;
      }
        return amnt
    },
    async downloadReport(){
        console.log(this.data);
        let response = await api.get(this, api.RATE_MANAGEMENT + 'rate-changes/' + this.data.id + '/report');
        console.log("response", response.report_buffer);

        let url = api.RATE_MANAGEMENT + '/rate-changes/' + this.data.id + '/report';
        console.log("url", url);
        response = await this.$http.get(url, { responseType: 'arraybuffer' });
        console.log("response", response.body)
        var blob = new Blob([response.body], {type: 'text/csv'});

        // var link = document.createElement('a');
        // link.href = window.URL.createObjectURL(blob);
        // link.download = "Rent Raise Report";
        // link.click();


        // var blob = new Blob([response.report_buffer], {type: 'text/csv' });
        //
        // var link = document.createElement('a');
        // link.href = window.URL.createObjectURL(blob);
        // link.download = "Rent Raise Report";
        // link.click();
        // this.successSet(this.$options.name, "Your download should begin shortly.");



    },
    editRateChange(rateChangeId, rateChangeType) {
      if(this.$props.flagTaskCenter) {
        this.$emit('edit-rate-change-item', {rateChangeID: rateChangeId, type: rateChangeType});
      } else {
        EventBus.$emit('edit-rate-change', {rateChangeID: rateChangeId, type: rateChangeType});
        this.activeDrawerComponent.from_manual_rent = true;
        this.setActiveDrawerComponent({ ...this.activeDrawerComponent });
      }
    }
  },
}
</script>

<style scoped>
.rate-item-status-2 {
    font-size: 12px;
    color: #FB4C4C;
  }
  .rate-item-status {
    font-size: 12px;
    color: #637381;
  }
  .rate-item-status.rate-item-status-veritec {
    position: relative;
    left: -7px;
  }

  .rate-item-status-prorize {
    position: relative;
    left: -6px;
    top: -4px;
  }
  .rate-complete {
    background: #E6F7E7;
  }
  .icon-complete {
    color: #02AD0F;
    font-size: 16px;
    margin-right: 8px;
  }
  .radio-1 {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    background: #FFFFFF;
    border: 1px solid #DFE3E8;
    box-sizing: border-box;
    border-radius: 8px;
  }
  .rate-item-label-6 {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #306FB6;
  }
  .rate-item-label-5 {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: #637381;
  }
  .rate-item-label-4 {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #101318;
  }
  .rate-item-label-3 {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #101318;
  }
  .rate-item-label-2{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #101318;
  }
  .rate-item-label-1 {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #101318;
  }

  .rate-item{
    background: #F4F6F8;
    min-height: 137px;
  }
  .rate-item-2 {
    min-height: 137px;
    border-right: 1px solid;
    border-color: rgba(0, 0, 0, 0.12);
  }
  .rate-item-3{
    background: #F4F6F8;
  }
  .rate-item-past{
    background: #F8E5E1;
    min-height: 137px;
  }
  .card-height {
    /* max-height: 137px; */
  }
  .custom-svg{
    height: 12px;
    width: 12px;
  }
</style>
<style>
  .rent-mangement-veritec-icon span{
    top: 10px !important;
    left: 1px !important;
  }
</style>
