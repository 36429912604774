<template>
  <hb-modal
    size="medium"
    :title="`Approve Rent ${approveByMonth ? 'Changes' : 'Change'}`"
    v-model="dialog"
    confirmation
    @close="$emit('close')"
    show-help-link
  >
    <template v-slot:content>
      <div class="content py-4 px-6 d-flex flex-column">
        <div v-html="popupDescription"></div>
      </div>
    </template>
    <template v-slot:right-actions>
      <hb-btn :loading="isLoading" @click="approveRentChanges">Continue</hb-btn>
    </template>
  </hb-modal>
</template>
<script>
import api from "../../../assets/api.js";

import { EventBus } from "../../../EventBus.js";
import { mapGetters } from "vuex";
import { notificationMixin } from "@/mixins/notificationMixin.js";

import moment from "moment";

export default {
  name: "ApproveRentChange",
  components: {},
  mixins: [notificationMixin],
  props: {},
  data() {
    return {
      dialog: false,
      tenantData: {},
      rentQueueData: {},
      approveByMonth: false,
      popupDescription: "",
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      selectedProperty: "revManStore/getSelectedProperty",
    }),
  },
  methods: {
    show(data) {
      this.dialog = true;
      this.tenantData = data.rowData;
      this.rentQueueData = data.rentQueueData;
      this.approveByMonth = data.approveByMonth;
      this.popupDescription = this.getApprovalConfirmationMessage(
        data.approveByMonth
      );
    },

    getApprovalConfirmationMessage(status) {
      return `Are you sure you want to <span class="font-weight-medium">${
        status ? "Approve All Rent Changes" : "Approve Rent Change"
      }</span> for <span class="font-weight-medium">${
        status
          ? moment(this.rentQueueData.rent_change_deployment_month).format(
              "MMMM YYYY"
            )
          : this.tenantData.rentchange_tenant_name
      }</span>?`;
    },

    async approveRentChanges() {
      this.isLoading = true;
      let body = this.approveByMonth
        ? {
            month: this.rentQueueData.rent_change_deployment_month,
          }
        : {
            rent_change_ids: [this.tenantData.rentchange_id],
          };
      try {
        await api
          .put(
            this,
            api.getPropertyRentManagementUrl(this.selectedProperty) +
              `rent-changes/approve`,
            body
          )
          .then(() => {
            if (this.approveByMonth) {
              this.showMessageNotification({
                type: "success",
                description: `All scheduled Rent changes for ${moment(
                  this.rentQueueData.rent_change_deployment_month
                ).format("MMMM YYYY")} has been approved`,
              });
              EventBus.$emit("reviewRentChangeEvents", {
                event: "refreshTable",
                data: "",
              });
              EventBus.$emit("rentChangeQueueEvents", "refreshTable");
            } else {
              this.showMessageNotification({
                type: "success",
                description: `Rent change for ${this.tenantData.rentchange_tenant_name} has been approved`,
              });
              this.$emit("approved", {
                rentchange_id: this.tenantData?.rentchange_id,
                approved: true,
              });
              EventBus.$emit("rentChangeQueueEvents", "refreshTable");
            }
            this.dialog = false;
          })
          .catch((error) => {
            console.log(error);
            this.showMessageNotification({
              type: "error",
              description: `Rent Change Approval failed ${ error ? `because ${error}` : ''}`,
            });
          });
      } catch (error) {
        console.log(error, " approval API failed");
      }
      this.isLoading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  gap: 16px;
}
</style>
