<template>
    <div id="facility-group--create" class="hb-card v-card">
        <div class="facility-group--create-header">
            <div>
                <span class="icon-circled">{{ group.properties.length || 0}}</span>
            </div>
            <div class="facility-group--create-header-title">
                <input placeholder="Name of Group" v-model="group.name"  v-if="groupMode==='create' || groupMode==='update'"/>
                <span v-if="groupMode==='read'">{{ group.name }}</span>
            </div>
            <div class="facility-group--create-header-actions" v-if="groupMode==='read'">
                <button class="hb-button push-mr-10" @click="$emit('update:groupMode', 'update')">Edit Group</button>
                <span @click="$emit('update:group', { properties: []})">X</span>
            </div>
        </div>
        <div class="facility-group--create-inner">
            <v-data-table
                :single-select="false"
                :show-select="groupMode!=='read'"
                v-model="selected"
                :headers="headers"
                :items="group.properties"
                fixed-header
                disable-pagination
                hide-default-footer
                height="300px"
            >
                <template v-slot:item.Address="{ item }">
                    {{ item.Address.address }}
                </template>
                <template v-slot:item.lease_count="{ item }">
                    <occupancy :leases="item.lease_count" :units="item.unit_count" />
                </template>
                <template v-slot:item.command="{ item }">
                    <context-menu :options="buildOptions(item)" @option-clicked="menuSelected" />
                </template>
            </v-data-table>
        </div>
        <div class="facility-group--create-footer" v-if="groupMode==='create' || groupMode==='update'">
            <button class="hb-button" @click="cancel">Cancel</button>
            <button class="hb-button hb-button__primary" v-bind:disabled="hasName" @click="save">Save Group</button>
        </div>
    </div>
</template>
<script type="text/babel">
import ContextMenu from './ContextMenu.vue';
import Occupancy from './Occupancy.vue';
import { mapActions } from 'vuex';
export default {
    data() {
        return {
            selected: [],
            headers: [{
                value: "address_id",
                text: "Store"
            },{
                value: "Address",
                text: "Address",
                sortable: true
            },{
                value: "lease_count",
                text: "Occupancy",
                sortable: true
            },{
                value: "unit_count",
                text: "Spaces",
                sortable: true
            },{
                value: "command",
                text: ""
            }]
        }
    },
    props: [
        'group',
        'groups',
        'properties',
        'activeTab',
        'view',
        'groupMode'
    ],
    components: {
        ContextMenu,
        Occupancy
    },
    methods: {
        ...mapActions({
            createGroup: 'searchStore/createGroup'
        }),
        cancel(){
            this.$emit('update:groupMode', 'create');
            this.$emit('update:group', { properties: []});
        },
        save() {
            switch(this.groupMode) {
                case 'create':
                    this.groups.push(this.group);
                    this.$emit('update:view', 'FacilitySearchGroups');
                    this.$emit('update:group', { properties: [] }); // Hack because the update:view does not propagate
                    this.createGroup(this.group.properties);
                    return;
                case 'update':
                    const index = this.groups.indexOf(this.group);
                    if(~index) {
                        this.groups[index] = this.group;
                        this.$emit('update:group', { properties: [] });
                        this.$emit('update:groupMode', 'create');
                    }
                    return;
            }
        },
        menuSelected(item) {
            switch(item.command) {
                case "delete":
                    this.removeItem(item);
                    return;
            }
        },
        removeItem(item){
            const index = this.group.properties.indexOf(item);
            this.group.properties.splice(index, 1);
            this.$emit('update:group', this.group);
        },
        updateSelected(event) {
            this.$parent.$emit('update:selected', event)
        },
        buildOptions(option) {
            const options = [{
                title: "View",
                command: "view",
                id: option
            },{
                title: "Edit",
                command: "edit",
                id: option
            },{
                title: "Delete",
                command: "delete",
                id: option
            },{
                title: "Cancel",
                command: "cancel"
            }];
            return options;
        }
    },
    computed: {
        hasName() {
            return !this.group.name;
        }
    }
}
</script>
<style lang="scss">
#facility-group--create {
    position: relative;
    top: 15px;
    background: #fff;
    .facility-group--create-header {
        display: flex;
        padding: 0 10px;
        padding: 10px;
        input:active,
        input:active,
        input:focus,
        input {
            background: none;
            border: none;
            outline: none;
        }
        .facility-group--create-header-title {
            flex: 1 auto;
            display: flex;
            input {
                flex: 1 auto;
            }
        }
    }
    .icon-circled {
        display: inline-block;
        border: 1px solid #101318;
        border-radius: 15px;
        padding: 2px 8px;
        margin-right: 5px;
    }
    .facility-group--create-inner {
        margin: 0 -10px;
    }
    .facility-group--create-footer {
        border-top: 1px solid #d1d1d1;
        padding:10px;
        text-align: right;
    }
    table {
        thead {
            tr {
                th {
                    background: #eaeaea;
                }
            }
        }
    }
    .v-data-table {
        .v-data-table-header {
            background: #eaeaea;
        }
        tbody {
            tr {
                td {
                    border-bottom: none;
                }
            }
        }
    }

    tr {
        td:first-child {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
        }
        td:last-child {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
        }
    }
    .push-mr-10 {
        margin-right: 10px;
    }
}
</style>
