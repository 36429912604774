<template>
    <div class="pb-5 max-container-list mt-7">
        <v-row class="mx-0 pb-3">
            <div class="v-card__title pa-0 pl-1">Rent Change Notifications</div>
        </v-row>

        <v-row class="ma-0 applied-actions px-4 py-1 mb-3 d-flex align-center" v-for="(action, index) in actionsList" :key="index" :class="{'action-active' : ( selectedIndex === index) }" >
            <v-col class="pa-0" cols="12" sm="9">{{action.name}}</v-col>
            <v-col class="pa-0 d-flex align-center justify-end" cols="12" sm="3">
                <v-progress-circular
                    indeterminate
                    color="primary"
                    v-if="loadingDocumentList"
                    size="20"
                    class="pa-0 my-0 mt-0 mr-3"
                ></v-progress-circular>
                <hb-link v-else-if="showAddAction && editingAction && selectedIndex === index" class="mr-3" @click="clearSelected()">Cancel</hb-link>
                <hb-link v-else-if="!showAddAction" class="mr-3" @click="editAction(action, index)">Edit</hb-link>
                <div v-else>&nbsp;</div>
                <hb-btn
                    class="mr-n2"
                    icon
                    tooltip="Remove"
                    active-state-off
                    v-if="(!addingNewAction && !editingAction) || (editingAction && selectedIndex === index)"
                    @click="removeAction(index)"
                >mdi-close</hb-btn>
            </v-col>
        </v-row>

        <v-row class="ma-0 px-4 py-3" v-if="!editingAction" :class="showAddAction ? 'action-active' : 'no-actions'">
            <v-col class="pa-0">
                <span v-if="everyActionAlreadySelected">All available actions have been added. You can edit an existing action or remove an action.</span>
                <span v-else-if="actionsList && actionsList.length === 0 && !showAddAction">No Action added</span>
                <span v-else-if="showAddAction">Complete adding the action in the right hand panel to continue</span>
                <span v-else>Add more actions in the right hand panel</span>

                <hb-link v-if="showAddAction" class="float-right mr-3" @click="clearSelected()">Cancel</hb-link>
                <hb-btn v-if="!showAddAction && !everyActionAlreadySelected" class="float-right" style="margin: -3px 0" color="secondary" small @click="$emit('addAction')">Add Action</hb-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { EventBus } from '../../EventBus.js'

export default {
  data() {
    return {
      selectedIndex: '',
      actionsList: []
    }
  },
  props: ['updatedActions', 'loadingDocumentList', 'showAddAction', 'editingAction','addingNewAction','everyActionAlreadySelected'],
  created () {
    this.actionsList = this.updatedActions;
    EventBus.$on('refresh_rate_actions', this.refreshActionList)
  },
  beforeDestroy () {
       EventBus.$off('refresh_rate_actions', this.refreshActionList)
  },
  methods: {
      refreshActionList(data) {
          this.actionsList = data
          this.selectedIndex = ''
      },
      editAction(action, index){
          this.selectedIndex = index;
          this.$emit('editAction', action);
      },
      removeAction(index){
          this.selectedIndex = null;
          this.$emit('removeAction', index);
          this.$emit('hideAction');
          EventBus.$emit('check_already_selected');
      },
      fetchActions(){
          this.selectedIndex = '';
          console.log('inside menthod');
      },
      clearSelected(){
          this.$emit('hideAction');
      },
  },
}
</script>

<style scoped>

.applied-actions {
    background: #ffffff;
    border: 1px solid #e0e3e8;
    min-height: 50px;
    box-sizing: border-box;
    border-radius: 8px;
}
.action-active {
    background: #E0F5F5;
    border: 1px solid #00848E;
    box-sizing: border-box;
    border-radius: 8px;
}
.no-actions {
    border: 1px dashed #e0e3e8;
    box-sizing: border-box;
    border-radius: 8px;
}
</style>
