<template>
  <hb-modal v-model="showLinkSenderDialog" size="medium" :title="title" :footerOff="false" confirmation
    @close="$emit('close',{linkType})" :footerCancelOption="false">
    <template v-slot:content>
      <div v-if="!showModal" style="height: 200px; display: flex; align-items: center; justify-content: center;" class="px-6 py-4 pb-2">
        <v-progress-circular width="2" size="32" indeterminate color="primary"></v-progress-circular>
      </div>
      <div v-if="showModal" class="px-6 py-4">
        <div class="pb-2">
          {{ content }}
        </div>
        <div class="mt-4" v-show="!!leadEmail">
          <HbCheckbox v-model="checkboxEmail" label="Email" condensed />
          <HbTextField v-model="leadEmail" readonly condensed />
        </div>
        <div class="mt-4" v-show="!!leadText">
          <HbCheckbox v-model="checkboxText" label="Text" condensed />
          <HbTextField v-model="leadText" readonly condensed />
        </div>
      </div>
    </template>

    <template v-slot:right-actions>
      <hb-link @click="closeDialogHandler">Cancel</hb-link>
      <hb-btn @click="sendTheLinks" :disabled="!enableBtn">Send</hb-btn>
    </template>

  </hb-modal>
</template>
  
<script>
import { notificationMixin } from '../../../mixins/notificationMixin';
import { mapGetters, mapActions } from 'vuex';
import api from '../../../assets/api.js'
import { parsePhoneNumber } from 'libphonenumber-js';
export default {
  data() {
    return {
      checkboxEmail: false,
      checkboxText: false,
      leadEmail: "",
      leadText: "",
      enableBtn: false,
      leadData: "",
      preventDialogClose:false,
      showModal:false,
    };
  },
  props: ["title", "content", "contactData", "showLinkSenderDialog",'linkType','form'],
  mixins: [notificationMixin],
  methods: {
    async sendTheLinks() {
      this.enableBtn=false;
      let body = {
        unit: this.configuration_unit,
        send_link_of: this.linkType,
        lead:this.lead,
        reservation: this.reservation,
      }
      if(this.linkType == "rental"){
        body.Addresses = this.contact?.Addresses
        body.license = {
          driver_license: this.contact?.driver_license,
          driver_license_city: this.contact?.driver_license_city,
          driver_license_state: this.contact?.driver_license_state,
          driver_license_country: this.contact?.driver_license_country,
          driver_license_exp: this.contact?.driver_license_exp,
        }
      }

      // check which one is active and send the link based on the bool value
      try {
        this.preventDialogClose=true
        console.log('LinkType: ' + this.linkType);
        console.log('leadText data', this.leadText);

        if (this.checkboxEmail) {
          await this.sendViaEmail(body)
        }
        if (this.checkboxText) {
          await this.sendViaText(body)
        }
        this.showMessageNotification({ type: 'success', description: 'You have successfully sent Link.' });
        this.$emit('closeLinkSender',{linkType:this.linkType})
        this.enableBtn=true;
      
    } catch (error) {
        this.preventDialogClose=false
        this.enableBtn=true;
        console.log("error occurred", error);
        this.showMessageNotification({ type: 'error', description: 'The link could not be sent due to an internal error.', list: error });
      }
    },
    async sendViaEmail(body) {
      try {
          body.type = "email"
          body.to = this.leadEmail

          if (this.linkType == "rental")
            await api.post(this, api.SEND_RENTAL_LINK,body)
          else if (this.linkType == "reservation")
            await api.post(this, api.SEND_RESERVATION_LINK,body)
        console.log("send email");
      } catch (error) {
        // console.log(error);
        throw new Error("in Email: " + error)
      }
    },
    async sendViaText(body) {
      try {
        console.log("send text");
         body.type = "sms"
         body.to = this.leadText
        if (this.linkType == "rental")
          await api.post(this, api.SEND_RENTAL_LINK,body)
        else if (this.linkType == "reservation")
          await api.post(this, api.SEND_RESERVATION_LINK,body)
      } catch (error) {
        throw new Error("in Text: " + error)
      }

    },
    findAndSetLeadEmail() {
      // find email and set leadEmail here
      this.leadEmail = this.leadData?.contact?.email
    },
    findAndSetLeadText() {
      // find text ans set leadText here
      this.leadText = this.formatPhoneNumber(this.leadData?.contact?.Phones.filter(item => item.sms === true).map(item => item.phone)[0])
    },
    async getLeadData() {
      this.showModal = false;
      this.leadData = await api.get(this, api.CONTACTS + this.lead.contact_id)
      if (this.leadData?.contact) {
        this.findAndSetLeadEmail()
        this.findAndSetLeadText()
      }
      this.showModal = true;
      console.log("this.leadData", this.leadData);
    },
    formatPhoneNumber(value) {
      if (!value) return '';
      try {
        var parsedPhoneNumber = parsePhoneNumber('+' + value);

        if (parsedPhoneNumber && parsedPhoneNumber.isValid()) {
          if (parsedPhoneNumber.country === 'US') {
            return '+1 ' + parsedPhoneNumber.formatNational();
          } else {
            return parsedPhoneNumber.formatInternational();
          }
        } else {
          value = value.toString();

          var numbers = value.replace(/\D/g, ''), char = { 0: '(', 3: ') ', 6: '-' };
          value = '';
          for (var i = 0; i < numbers.length; i++) {
            value += (char[i] || '') + numbers[i];
          }
        }
      } catch (err) {
      }
      return value;
      // return formattedPhoneNumber
    },
    setGdsFacilityId() {
      // set gds facility_id
      this.gds_facility_id = this.facilityList.filter(item => item.id === this.property_id).map(item => item.gds_id)[0]
      this.owner_id = '' // remove owner_id
      if (this.gds_facility_id == '' || !this.gds_facility_id)
        this.showMessageNotification({
          type: "error",
          description: `Unable to find GDS config is missing for selected property`,
        });
    },
    closeDialogHandler() {
      if (this.preventDialogClose) {

      } else {
        this.$emit('close', { linkType: this.linkType })
      }
    },
    ...mapActions({
      fetchSpaceMix: "settingsStore/fetchCategories"
    }),

  },
  watch: {
    checkboxEmail() {
      if (this.checkboxEmail || this.checkboxText) {
        this.enableBtn = true
      } else {
        this.enableBtn = false
      }
    },
    checkboxText() {
      if (this.checkboxText || this.checkboxEmail) {
        this.enableBtn = true
      } else {
        this.enableBtn = false
      }
    },
    contactData() {
      if (this.contactData?.length) {
        this.findAndSetLeadEmail()
        this.findAndSetLeadText()
      }
    },
    lead() {
      console.log('lead data', this.lead);
      if (this?.lead?.contact_id) {
        this.getLeadData()
      }
    },
    form(){
      if (this?.lead?.contact_id) {
        this.getLeadData()
      }
    }
  },
  computed: {
    ...mapGetters({
      configuration_unit: 'onBoardingStore/configuration_unit',
      lead: 'onBoardingStore/lead',
      unit: 'onBoardingStore/unit',
      spaceMix: "settingsStore/unitMixes",
      categories: 'filterStore/unit_category',
      reservation: 'onBoardingStore/reservation',
      lease: 'onBoardingStore/lease',
    }),
    contact: {
      get() { return this.$store.getters['onBoardingStore/contact'] },
      set(val) { return this.$store.commit('onBoardingStore/setContact', val) }
    },
  },
  beforeDestroy() {
    // console.log('accha to mai chalta hu');
  },
  async mounted() {
    await this.fetchSpaceMix()
    if (this?.lead?.contact_id) {
      this.getLeadData()
    }

  }
};
</script>
  
<style>
.dialog-lead-intake {}
</style>