<template>

    <div :class="[isMini? 'mx-2':'mr-4']">
        <v-tooltip bottom v-if="showLiveSupport">
            <template v-slot:activator="{ on, attrs }">
                <v-btn 
                    v-if="!isMini"
                    rounded
                    color="#FFFFFF"
                    @click="joinMeeting"
                    v-bind="attrs"
                    v-on="on">
                    <v-icon size="24" left color="primary" >mdi-face-agent</v-icon>
                    <span class="hb-link subtitle-2"> Live Video Support</span>
                </v-btn>
                <v-btn 
                    v-else
                    color="#FFFFFF"
                    fab
                    small
                    @click="joinMeeting" 
                    v-bind="attrs"
                    v-on="on">
                        <v-icon color="primary">mdi-face-agent</v-icon>
                </v-btn>
            </template>
            <span>Live Video Support</span>
        </v-tooltip>

        <v-tooltip bottom v-else>
            <template v-slot:activator="{ on, attrs }">
                <v-btn 
                    v-if="!isMini"
                    rounded
                    color="#004247"
                    v-bind="attrs"
                    v-on="on">
                    <v-icon size="24" left color="#FFFFFF" >mdi-face-agent</v-icon>
                    <span class="icon-off"></span>
                    <span class="subtitle-2 white--text"> Live Support Hours: M-F 9-12 PST</span>
                </v-btn>
                <v-btn 
                    v-else
                    color="#004247"
                    fab
                    small
                    v-bind="attrs"
                    v-on="on">
                        <v-icon color="#FFFFFF">mdi-face-agent</v-icon>
                        <span class="icon-off-1"></span>
                </v-btn>
            </template>
            <span>Please come back during the classroom hours</span>
        </v-tooltip>
    </div>
    
</template>
<script>

import moment from 'moment-timezone';
import { mapGetters } from 'vuex';

export default {
    name: 'LiveVideoSupport',
    data() {
        return {
            showLiveSupport: true,
            startTime: null,
            endTime: null,
            format: 'hh:mm a'
        }
    },
    created() {  
        this.startTime = moment('08:59 am', this.format);
        this.endTime = moment('5:01 pm', this.format);
        this.displayLiveSupportOrNot();

        window.setInterval(() => {
          this.displayLiveSupportOrNot()
        }, 30000);
    },

    methods:{
        joinMeeting() {
            if(this.zoomURL) window.open(this.zoomURL, "_blank");
        },
        displayLiveSupportOrNot() {
            let currentPST = moment.tz('America/Los_Angeles').format(this.format);
            let currentTime = moment(currentPST, this.format);        
            this.showLiveSupport = currentTime.isBetween(this.startTime, this.endTime);
        }
    },

    computed:{
        ...mapGetters({
          zoomURL: 'authenticationStore/getZoomURL'
        }),
        isMini() {
            switch (this.$vuetify.breakpoint.name) {
              case 'xs': return true
              case 'sm': return true
              case 'md': return false
              case 'lg': return false
              case 'xl': return false
            }
        }
    },
}
</script>
<style lang="scss">
    .icon-off-1 {
        position: absolute;
        background: white;
        left: 7px;
        right: 7px;
        top: 12px;
        bottom: 9px;

        transform: rotate(45deg);
        border-top: 0.5px solid #004247;
    }
    .icon-off{
        position: absolute;
        background: white;
        left: -3.33%;
        right: 92.33%;
        top: 47.5%;
        bottom: 39.5%;

        transform: rotate(45deg);
        border-top: 0.5px solid #004247;
    }  
</style>
