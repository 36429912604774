<template>
  <div class="know-your-customer-wrapper">
    <div
      v-if="showSSNInfo"
      class="d-flex align-center hb-notification-caution mb-5"
    >
      <hb-btn icon tooltip="Info" mdi-code="mdi-information"></hb-btn>
      <span class="hb-default-font-size">
        We DO NOT save social security numbers (SSNs). Please re-enter all
        missing SSNs.
      </span>
    </div>
    <v-row no-gutters class="mb-2" justify="center">
      <v-expansion-panels v-model="panel" multiple>
        <hb-expansion-panel :topMargin="false">
          <template v-slot:title> {{ setTitle }} </template>
          <template v-slot:content>
            <div class="top-header">
              <div class="pb-4">
                Payment processing is regulated in the same manner as banking.
                In order to comply with Anti-Money Laundering regulations, our
                payment service provider must perform KYC (Know Your Customer) on a
                Director and the owners of your Business. KYC is a standard
                process used by financial institutions for verifying a
                customer’s identity before and while doing business with them.
                KYC regulations are a tool to prevent money laundering,
                terrorist financing, and some fraud schemes.
              </div>
              <div>
                Providing incorrect or false information will delay activation
                of your payment processing account by several days and may
                require you to provide photos of government issued ID and SSN
                card to verify identity before your payment processing account
                can be activated.
              </div>
            </div>
            <div v-if="!isSoleProprietorship" class="company-director-desc">
              <div class="hb-text-night"><strong>Company Director</strong></div>
              <div class="hb-font-caption hb-text-night-light mt-1">
                We need to collect some information about a Director of your
                company. If you can, provide information for a Director who can be tied to your business using public sources. E.g. Secretary of State Business Registry, public webpages, your webpage, or other public business records. Our payment processor needs to verify the link between the provided Director and your business. If they are unable to verify this on their own, they will require you provide documentation proving the link.
              </div>
            </div>
            <hb-form
              label="Full Name"
              description="Enter your name as shown on your government issued identification."
              multiple
              required
              extraLarge
            >
              <div class="fields-wrapper">
                <v-row>
                  <v-col cols="12" sm="6" class="py-0 my-0">
                    <HbTextField
                      v-model="form.account_owner.first_name"
                      v-validate.disable="'required'"
                      hide-details
                      placeholder="First"
                      data-vv-name="account_owner_first_name"
                      data-vv-as="First Name"
                      data-vv-scope="account_owner_info"
                      :error="
                        errors.collect(
                          'account_owner_info.account_owner_first_name'
                        ).length > 0
                      "
                      full
                      @input="bindDirectorInfo"
                    />
                  </v-col>
                  <v-col cols="12" sm="6" class="py-0 my-0">
                    <HbTextField
                      v-model="form.account_owner.last_name"
                      v-validate.disable="'required'"
                      hide-details
                      placeholder="Last"
                      data-vv-name="account_owner_last_name"
                      data-vv-as="Last Name"
                      data-vv-scope="account_owner_info"
                      :error="
                        errors.collect(
                          'account_owner_info.account_owner_last_name'
                        ).length > 0
                      "
                      full
                      @input="bindDirectorInfo"
                    />
                  </v-col>
                </v-row>
              </div>
            </hb-form>
            <hb-form
              label="Personal Address"
              description="Provide your address as shown on your government issued identification."
              required
              full
              extraLarge
            >
              <div class="fields-wrapper">
                <HbTextField
                  v-model="form.account_owner.address"
                  v-validate.disable="{ required: true, customAddressValidation: true }"
                  hide-details
                  placeholder="Address (Line 1)"
                  data-vv-name="legal_address1"
                  data-vv-as="Address 1"
                  data-vv-scope="account_owner_info"
                  :error="
                    errors.collect('account_owner_info.legal_address1').length >
                    0
                  "
                  full
                  @input="bindDirectorInfo"
                />
                <HbTextField
                  v-model="form.account_owner.address2"
                  v-validate.disable="{ customAddressValidation: true }"
                  hide-details
                  placeholder="Address (Line 2)"
                  data-vv-name="legal_address2"
                  data-vv-as="Address 2"
                  data-vv-scope="account_owner_info"
                  :error="
                    errors.collect('account_owner_info.legal_address2').length >
                    0
                  "
                  full
                  @input="bindDirectorInfo"
                />
                <v-row>
                  <v-col cols="6" class="py-0 my-0">
                    <HbTextField
                      v-model="form.account_owner.city"
                      v-validate.disable="'required'"
                      hide-details
                      placeholder="City"
                      data-vv-name="legal_city"
                      data-vv-as="City"
                      data-vv-scope="account_owner_info"
                      :error="
                        errors.collect('account_owner_info.legal_city').length >
                        0
                      "
                      @input="bindDirectorInfo"
                    />
                  </v-col>
                  <v-col cols="6" class="py-0 my-0">
                    <HbSelect
                      v-model="form.account_owner.state"
                      :items="states"
                      item-text="value"
                      item-value="value"
                      v-validate.disable="'required'"
                      hide-details
                      placeholder="State/Province"
                      :clearable="false"
                      data-vv-name="legal_state"
                      data-vv-as="State"
                      data-vv-scope="account_owner_info"
                      :error="
                        errors.collect('account_owner_info.legal_state')
                          .length > 0
                      "
                      @change="bindDirectorInfo"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" class="py-0 my-0">
                    <HbAutocomplete
                      v-model="form.account_owner.country"
                      :items="countryCodes"
                      v-validate.disable="'required'"
                      hide-details
                      placeholder="Country"
                      :clearable="false"
                      data-vv-name="legal_country"
                      data-vv-as="Country"
                      data-vv-scope="account_owner_info"
                      :error="
                        errors.collect('account_owner_info.legal_country')
                          .length > 0
                      "
                      @change="bindDirectorInfo"
                    />
                  </v-col>
                  <v-col cols="6" class="py-0 my-0">
                    <HbTextField
                      v-model="form.account_owner.zip"
                      v-validate.disable="'required|max:5'"
                      hide-details
                      placeholder="Zip/Postal Code"
                      v-mask="'#####'"
                      data-vv-name="legal_zip"
                      data-vv-as="Zip"
                      data-vv-scope="account_owner_info"
                      :error="
                        errors.collect('account_owner_info.legal_zip').length >
                        0
                      "
                      @input="bindDirectorInfo"
                    />
                  </v-col>
                </v-row>
              </div>
            </hb-form>
            <hb-form label="Date of Birth" required full extraLarge>
              <div class="fields-wrapper">
                <HbDatePicker
                  v-model="form.account_owner.dob"
                  v-validate.disable="'required'"
                  hide-details
                  label="MM/DD/YYYY"
                  :solo="false"
                  :max="currentDate"
                  :single-line="true"
                  :removePaddingMargin="true"
                  data-vv-name="account_owner_dob"
                  data-vv-as="DOB"
                  data-vv-scope="account_owner_info"
                  :error="
                    errors.collect('account_owner_info.account_owner_dob')
                      .length > 0
                  "
                  full
                  @change="bindDirectorInfo"
                />
              </div>
            </hb-form>
            <hb-form
              label="Social Security Number (SSN)"
              required
              full
              extraLarge
            >
              <div class="fields-wrapper">
                <HbTextField
                  v-model="form.account_owner.ssn"
                  v-validate="'required|length:11'"
                  placeholder="Enter full SSN"
                  v-mask="'###-##-####'"
                  data-vv-name="account_owner_ssn"
                  data-vv-as="SSN"
                  data-vv-scope="account_owner_info"
                  :error="
                    errors.collect('account_owner_info.account_owner_ssn')
                      .length > 0
                  "
                  full
                  @input="bindDirectorInfo"
                />
              </div>
            </hb-form>
            <!-- <hb-form label="Ownership Percentage" required extraLarge>
              <div class="fields-wrapper">
                <v-row>
                  <v-col class="py-0 my-0">
                    <HbTextField
                      v-model="form.account_owner.ownership"
                      v-validate="'required|min_value:1|max_value:100'"
                      placeholder="Amount"
                      :data-vv-name="'ownership_percentage'"
                      data-vv-as="Ownership Percentage"
                      data-vv-scope="account_owner_info"
                      :error="
                        errors.collect(
                          `account_owner_info.ownership_percentage`
                        ).length > 0
                      "
                      full
                      @input="bindDirectorInfo"
                    />
                  </v-col>
                </v-row>
              </div>
            </hb-form> -->
            <!-- Beneficiaries -->
            <template v-if="!isSoleProprietorship">
              <hb-form
                label="Beneficiary Owners"
                description="You must provide name, birth date, and address for each ultimate beneficiary owner that controls 25% or more of your business. Ultimate Beneficiary Owners must be individual persons. If your company is owned by one or more companies you must follow the ownership tree until you reach individual persons. If no single person controls 25% of your business, you must provide required information for a beneficial owner of your choosing."
                required
                full
                extraLarge
              >
                <p class="hb-text-night-light hb-font-caption">
                  How many ultimate beneficiary owners own 25% or more of your
                  business?*
                </p>
                <HbSelect
                  v-model="form.general.beneficiary_owners"
                  :items="[0, 1, 2, 3, 4]"
                  v-validate.disable="'required'"
                  data-vv-name="beneficiary_owners_count"
                  data-vv-as="Beneficiary Owners"
                  data-vv-scope="account_owner_info"
                  :error="
                    errors.collect(
                      'account_owner_info.beneficiary_owners_count'
                    ).length > 0
                  "
                  @change="onBeneficiaryChange"
                  :clearable="false"
                />
              </hb-form>
              <div v-for="i in getLoopCount" :key="i">
                <div class="beneficiary-header">
                  <p
                    v-if="isCountZero"
                    class="hb-text-night-light hb-font-caption mb-1"
                  >
                    Provide the following information for one of the owners of
                    your company.
                  </p>
                  <div class="checkbox-wrapper">
                    <div class="font-weight-medium mr-3">
                      Beneficiary {{ isCountZero ? "Owner" : `#${i}` }}
                    </div>
                    <div v-if="i <= 1">
                      <HbCheckbox
                        label="Same as Director Information"
                        v-model="form.beneficiaries[i - 1].same_as_director"
                        @change="bindDirectorInfo"
                      />
                    </div>
                  </div>
                </div>
                <hb-form
                  label="Full Name"
                  description="Enter your name as shown on your government issued identification."
                  multiple
                  required
                  full
                  extraLarge
                >
                  <div class="fields-wrapper">
                    <v-row>
                      <v-col cols="12" sm="6" class="py-0 my-0">
                        <HbTextField
                          v-model="form.beneficiaries[i - 1].first_name"
                          v-validate.disable="'required'"
                          placeholder="First"
                          :disabled="form.beneficiaries[i - 1].same_as_director"
                          :data-vv-name="`beneficiary_${i}_first_name`"
                          data-vv-as="First Name"
                          data-vv-scope="account_owner_info"
                          :error="
                            errors.collect(
                              `account_owner_info.beneficiary_${i}_first_name`
                            ).length > 0
                          "
                        />
                      </v-col>
                      <v-col cols="12" sm="6" class="py-0 my-0">
                        <HbTextField
                          v-model="form.beneficiaries[i - 1].last_name"
                          v-validate.disable="'required'"
                          placeholder="Last"
                          :disabled="form.beneficiaries[i - 1].same_as_director"
                          :data-vv-name="`beneficiary_${i}_last_name`"
                          data-vv-as="Last Name"
                          data-vv-scope="account_owner_info"
                          :error="
                            errors.collect(
                              `account_owner_info.beneficiary_${i}_last_name`
                            ).length > 0
                          "
                        />
                      </v-col>
                    </v-row>
                  </div>
                </hb-form>
                <hb-form
                  label="Personal Address"
                  description="Provide your address as shown on your government issued identification."
                  required
                  full
                  extraLarge
                >
                  <div class="fields-wrapper">
                    <v-row>
                      <v-col class="py-0 my-0">
                        <HbTextField
                          v-model="form.beneficiaries[i - 1].address"
                          v-validate.disable="{ required: true, customAddressValidation: true }"
                          placeholder="Address (Line 1)"
                          :disabled="form.beneficiaries[i - 1].same_as_director"
                          data-vv-name="legal_address1"
                          :data-vv-name="`beneficiary_${i}_legal_address1`"
                          data-vv-as="Address 1"
                          data-vv-scope="account_owner_info"
                          :error="
                            errors.collect(
                              `account_owner_info.beneficiary_${i}_legal_address1`
                            ).length > 0
                          "
                          full
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="py-0 my-0">
                        <HbTextField
                          v-model="form.beneficiaries[i - 1].address2"
                          v-validate.disable="{ customAddressValidation: true }"
                          placeholder="Address (Line 2)"
                          :disabled="form.beneficiaries[i - 1].same_as_director"
                          :data-vv-name="`beneficiary_${i}_legal_address2`"
                          data-vv-as="Address 2"
                          data-vv-scope="account_owner_info"
                          :error="
                            errors.collect(
                              `account_owner_info.beneficiary_${i}_legal_address2`
                            ).length > 0
                          "
                          full
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6" class="py-0 my-0">
                        <HbTextField
                          v-model="form.beneficiaries[i - 1].city"
                          v-validate.disable="'required'"
                          placeholder="City"
                          :disabled="form.beneficiaries[i - 1].same_as_director"
                          :data-vv-name="`beneficiary_${i}_legal_city`"
                          data-vv-as="City"
                          data-vv-scope="account_owner_info"
                          :error="
                            errors.collect(
                              `account_owner_info.beneficiary_${i}_legal_city`
                            ).length > 0
                          "
                        />
                      </v-col>
                      <v-col cols="6" class="py-0 my-0">
                        <HbSelect
                          v-model="form.beneficiaries[i - 1].state"
                          :items="states"
                          item-text="value"
                          item-value="value"
                          v-validate.disable="'required'"
                          placeholder="State/Province"
                          :disabled="form.beneficiaries[i - 1].same_as_director"
                          :clearable="false"
                          :data-vv-name="`beneficiary_${i}_legal_state`"
                          data-vv-as="State"
                          data-vv-scope="account_owner_info"
                          :error="
                            errors.collect(
                              `account_owner_info.beneficiary_${i}_legal_state`
                            ).length > 0
                          "
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6" class="py-0 my-0">
                        <HbAutocomplete
                          v-model="form.beneficiaries[i - 1].country"
                          :items="countryCodes"
                          v-validate.disable="'required'"
                          placeholder="Country"
                          :clearable="false"
                          :disabled="form.beneficiaries[i - 1].same_as_director"
                          :data-vv-name="`beneficiary_${i}_legal_country`"
                          data-vv-as="Country"
                          data-vv-scope="account_owner_info"
                          :error="
                            errors.collect(
                              `account_owner_info.beneficiary_${i}_legal_country`
                            ).length > 0
                          "
                        />
                      </v-col>
                      <v-col cols="6" class="py-0 my-0">
                        <HbTextField
                          v-model="form.beneficiaries[i - 1].zip"
                          v-validate.disable="'required|max:5'"
                          placeholder="Zip/Postal Code"
                          :disabled="form.beneficiaries[i - 1].same_as_director"
                          v-mask="'#####'"
                          :data-vv-name="`beneficiary_${i}_legal_zip`"
                          data-vv-as="Zip"
                          data-vv-scope="account_owner_info"
                          :error="
                            errors.collect(
                              `account_owner_info.beneficiary_${i}_legal_zip`
                            ).length > 0
                          "
                        />
                      </v-col>
                    </v-row>
                  </div>
                </hb-form>
                <hb-form label="Date of Birth" required extraLarge>
                  <div class="fields-wrapper">
                    <v-row>
                      <v-col class="py-0 my-0">
                        <HbDatePicker
                          v-model="form.beneficiaries[i - 1].dob"
                          v-validate.disable="'required'"
                          label="MM/DD/YYYY"
                          :disabled="form.beneficiaries[i - 1].same_as_director"
                          :max="currentDate"
                          :solo="false"
                          :single-line="true"
                          :removePaddingMargin="true"
                          :data-vv-name="`beneficiary_${i}_dob`"
                          data-vv-as="DOB"
                          data-vv-scope="account_owner_info"
                          :error="
                            errors.collect(
                              `account_owner_info.beneficiary_${i}_dob`
                            ).length > 0
                          "
                        />
                      </v-col>
                    </v-row>
                  </div>
                </hb-form>
                <hb-form
                  label="Social Security Number (SSN)"
                  required
                  extraLarge
                >
                  <div class="fields-wrapper">
                    <v-row>
                      <v-col class="py-0 my-0">
                        <HbTextField
                          v-model="form.beneficiaries[i - 1].ssn"
                          v-validate="'required|length:11'"
                          placeholder="Enter full SSN"
                          v-mask="'###-##-####'"
                          :disabled="form.beneficiaries[i - 1].same_as_director"
                          :data-vv-name="`beneficiary_${i}_ssn`"
                          data-vv-as="SSN"
                          data-vv-scope="account_owner_info"
                          :error="
                            errors.collect(
                              `account_owner_info.beneficiary_${i}_ssn`
                            ).length > 0
                          "
                        />
                      </v-col>
                    </v-row>
                  </div>
                </hb-form>
                <hb-form label="Ownership Percentage" required extraLarge>
                  <div class="fields-wrapper">
                    <v-row>
                      <v-col class="py-0 my-0">
                        <HbTextField
                          v-model="
                            form.beneficiaries[i - 1].ownership
                          "
                          v-validate="'required|min_value:1|max_value:100'"
                          placeholder="Amount"
                          :data-vv-name="`beneficiary_${i}_ownership_percentage`"
                          data-vv-as="Ownership Percentage"
                          data-vv-scope="account_owner_info"
                          :error="
                            errors.collect(
                              `account_owner_info.beneficiary_${i}_ownership_percentage`
                            ).length > 0
                          "
                        />
                      </v-col>
                    </v-row>
                  </div>
                </hb-form>
              </div>
            </template>
          </template>
        </hb-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </div>
</template>

<script type="text/babel">
import HbDatePicker from "../../../assets/HummingbirdDatepicker";
import ADDRESS from "@/constants/address.js";
import moment from "moment";
export default {
  name: "KnowYourCustomer",
  components: {
    HbDatePicker,
  },
  props: {
    form: {
      type: Object,
      default: () => {},
    },
    showSSNInfo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      panel: [0],
      countryCodes: ["USA"],
      states: ADDRESS.STATES,
      currentDate: "",
    };
  },
  computed: {
    getLoopCount() {
      return this.form.general.beneficiary_owners === 0
        ? 1
        : this.form.general.beneficiary_owners;
    },
    isCountZero() {
      return this.form.general.beneficiary_owners === 0;
    },
    isSoleProprietorship() {
      return this.form.general.entity_type === "sole_proprietorship";
    },
    setTitle() {
      return this.form.general.entity_type === "sole_proprietorship"
        ? "Sole Proprietor Information"
        : "Director & Beneficiary Owner Information";
    },
  },
  mounted() {
    this.setCurrentDates();
  },
  methods: {
    onBeneficiaryChange(value) {
      this.$emit("countChange", value);
    },
    bindDirectorInfo() {
      if (this.form.beneficiaries[0].same_as_director) {
        for (const property in this.form.beneficiaries[0]) {
          if (this.form.account_owner.hasOwnProperty(property)) {
            this.form.beneficiaries[0][property] =
              this.form.account_owner[property];
          }
        }
      }
    },
    setCurrentDates() {
      this.currentDate = moment().subtract(18, "years").format("YYYY-MM-DD");
      this.form.account_owner.dob = this.form.account_owner.dob
        ? this.form.account_owner.dob
        : this.currentDate;
      this.form.beneficiaries.forEach((element) => {
        if (!element.dob) {
          element.dob = this.currentDate;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.know-your-customer-wrapper {
  .top-header {
    font-size: 14px;
    color: #101318;
    line-height: 24px;
    margin: 0px;
    padding: 16px 24px;
    box-shadow: inset 0px -1px 0px rgba(142, 142, 143, 0.15);
  }
  .company-director-desc {
    padding: 16px 24px;
    box-shadow: inset 0px -1px 0px rgba(142, 142, 143, 0.15);
  }
  .fields-wrapper {
    width: 538px;
    @media only screen and (max-width: 960px) {
      width: 100%;
    }
  }
  .beneficiary-header {
    display: flex;
    flex-direction: column;
    padding: 16px 0px 16px 24px;
    .checkbox-wrapper {
      display: flex;
    }
  }
}
</style>
