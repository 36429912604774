<template>
    <div class="bg-colo-light" ref="firstContent">
        <hb-blade-header class="hb-blade-header-wrapper-custom"
            :title="toTitleCase(currentItem?.item?.name) || 'New Template'" backButtonOff @close="$emit('close')">
        </hb-blade-header>
        <div v-if="!currentItem.isNew">
            <div>
                <template-edit ref="templateEditRef" :currentItem="currentItem"
                    class="custom-compo-element-002 bg-colo-light" @scrollIntoView="scrollIntoView()"
                    @content-changed="contentChanged" />
            </div>
            <div class="footer-wrapper">
                <div class="d-flex justify-end">
                    <div>
                        <hb-link class="mr-5" @click="$emit('close')">Cancel</hb-link>
                        <hb-btn color="primary" v-if="!checkIfCreate" :disabled="disableButton" :loading="loading"
                            @click="check()">Save Template</hb-btn>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="mb-13 bg-colo-light">
            <template-edit :currentItem="currentItem" ref="templateEditRef" @scrollIntoView="scrollIntoView()"
                @content-changed="contentChanged" />

            <div class="footer-wrapper">
                <div class="d-flex justify-end">
                    <div>
                        <hb-link class="mr-5" @click="$emit('close')">Cancel</hb-link>
                        <hb-btn color="primary" @click="createTemplate(false)" :loading="loading">Create
                            Template</hb-btn>
                    </div>
                </div>
            </div>
        </div>
        <hb-modal size="medium" title="Warning" v-model="warningModal" confirmation>
            <template v-slot:content>
                <div class="py-4 px-6">
                    This may impact the Corporate level & Assigned properties.
                    <br /><br />
                    Would you like to continue?
                </div>
            </template>
            <template v-slot:left-actions>
            </template>
            <template v-slot:right-actions>
                <hb-btn color="primary" @click="createTemplate(true)" :loading="loading">Confirm</hb-btn>
            </template>
        </hb-modal>
    </div>
</template>
<script type="text/babel">
import { mapGetters } from "vuex";
import api from '../../../../assets/api.js';
import TabSection from '../../../includes/Tabs/Index.vue'
import { notificationMixin } from '../../../../mixins/notificationMixin.js';
import { EventBus } from '../../../../EventBus';
import TemplateConfig from './templateConfig.vue';
import TemplateEdit from './templateEdit.vue';
export default {
    name: "TemplateManager",
    props: ['currentItem'],
    mixins: [notificationMixin],
    data() {
        return {
            tabOptions: [
                { label: 'Configuration', key: 'template-config' },
                { label: 'Edit/Preview', key: 'template-edit' }

            ],
            component: 'template-config',
            templateConfig: {},
            templateEdit: {},
            loading: false,
            disableButton: true,
            warningModal: false
        };
    },
    components: {
        TabSection,
        'template-config': TemplateConfig,
        'template-edit': TemplateEdit,
    },
    computed: {
        checkIfCreate() {
            if (this.currentItem) {
                return false
            }
            else return true
        },
    },
    methods: {
        contentChanged(val) {
            this.disableButton = !val
        },
        toTitleCase(str) {
            if (!str) return 'New Template'; // Default value if str is falsy
            return str.replace(/\w\S*/g, (txt) => {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            });
        },
        scrollIntoView() {
            this.$refs.firstContent.scrollIntoView({ behavior: 'smooth', block: 'start' });
        },
        async check() {
            let general_status_edit = await this.$refs.templateEditRef.$validator.validateAll("general");
            if (!general_status_edit) {
                this.showMessageNotification({ type: 'error', description: 'There are errors in your form, correct them before continuing.', list: this.errors.items });
                return;
            }
            else {
                if (!this.currentItem.duplicate) this.warningModal = true
                else this.createTemplate(true)
            }
        },
        // checkDisable(val) {
        //     if (val) this.disableButton = true
        //     else this.disableButton = false
        // },
        tabChange(tabName) {
            this.component = tabName
        },
        handleMessageTypeChange(newMessageType) {
            this.templateConfig = newMessageType // getting from component via event
        },
        async createTemplate(update) {
            if (update == true) {
                this.loading = true
                let general_status_edit = await this.$refs.templateEditRef.$validator.validateAll("general");
                if (!general_status_edit) {
                    this.loading = false
                    this.showMessageNotification({ type: 'error', description: 'There are errors in your form, correct them before continuing.', list: this.errors.items });
                    return;
                }
                else {
                    try {
                        let data = {
                            name: this.$refs.templateEditRef.getConfigData().name,
                            type: this.$refs.templateEditRef.getConfigData().type,
                            category: this.$refs.templateEditRef.getConfigData().category,
                            properties: this.$refs.templateEditRef.getConfigData().properties,
                            template: this.$refs.templateEditRef.getData(),
                        }
                        if (!this.currentItem.duplicate) {
                            data.template_id = this.currentItem.item.template_id;
                        }
                        if (this.currentItem.duplicate && this.currentItem.item.is_base_template == 1) {
                            data.base_template_id = this.currentItem.item.template_id
                            await api.post(this, api.TEMPLATE_MANAGER + `template`, data);
                        }
                        else if (this.currentItem?.propertyId) {
                            delete data.properties
                            await api.post(this, api.TEMPLATE_MANAGER + `template` + `?property_id=${this.currentItem?.propertyId}`, data);
                        }
                        else {
                            await api.post(this, api.TEMPLATE_MANAGER + `template`, data);
                        }
                        this.$emit('close')
                        EventBus.$emit('CommunicationTemplate:refreshList')
                        this.loading = false
                        if (data.template_id) this.showMessageNotification({ type: 'success', description: "Template edited successfully" });
                        else this.showMessageNotification({ type: 'success', description: "Template duplicated successfully" });
                    }
                    catch {
                        this.loading = false
                        this.showMessageNotification({ type: 'error', description: "Something went wrong" });
                        console.error(error);
                    }
                }
            } else {
                this.loading = true
                // api call for saving template
                let general_status = await this.$refs.templateEditRef.$validator.validateAll("general");
                if (!general_status) {
                    this.showMessageNotification({ type: 'error', description: 'There are errors in your form, correct them before continuing.', list: this.errors.items });
                    this.loading = false
                    return;
                }
                else {
                    try {
                        let data = {
                            name: this.$refs.templateEditRef.getConfigData().name,
                            type: this.$refs.templateEditRef.getConfigData().type,
                            category: this.$refs.templateEditRef.getConfigData().category,
                            properties: this.$refs.templateEditRef.getConfigData().properties, // dont need property arrays in body
                            template: this.$refs.templateEditRef.getData(),
                            //is_default_editor: true
                        }
                        if (data.template.body || data.template.smsContent) {
                            await api.post(this, api.TEMPLATE_MANAGER + `template`, data);
                            this.loading = false
                            this.$emit('close')
                            EventBus.$emit('CommunicationTemplate:refreshList')
                            this.showMessageNotification({ type: 'success', description: "Template created successfully" });
                        }
                        else {
                            this.showMessageNotification({ type: 'error', description: "Content cannot be empty" });
                            this.loading = false
                        }
                    }
                    catch (error) {
                        this.loading = false
                        this.showMessageNotification({ type: 'error', description: "Something went wrong" });
                        console.error(error);
                    }
                }
            }
        }
    }
}

</script>
<style scoped>
.bg-colo-light {
    background-color: #F9FAFB;
    min-height: 100vh;
}

.footer-wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #ffffff;
    padding: 12px 16px;
    box-shadow: inset 0px 1px 0px rgba(142, 142, 143, 0.15);
    z-index: 1;
}

.custom-compo-element {
    height: calc(100vh - 30px);
}

.hb-blade-header-wrapper-custom {
    position: sticky;
    top: 0;
    z-index: 1000;
}
</style>