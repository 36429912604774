<template>
    <HbAutocomplete
        search
        v-model="addressSelection"
        :items="autocompleteList"
        placeholder="Enter Address"
        :search-input.sync="addressInput"
        hide-no-data
        no-filter
        return-object
        item-text="address"
        @click:clear="clearData"
    />
</template>


<script type="text/babel">

import { notificationMixin } from  '../../mixins/notificationMixin.js';
import api from '../../assets/api.js';
import { v4 as uuidv4 } from 'uuid';

export default{
        name: "AddressAutofill",
        mixins: [ notificationMixin ],
        data(){
            return {
                addressSelection: {},
                addressInput: '',
                autocompleteList: [],
                sessionToken: '',
                timeout: null,
                fetchingPlace: false
            }
        },
        props: {
            value:{
                type: Object,
                default: {}
            }
        },
        created(){
            this.sessionToken = this.generateSessionToken();
        },
        computed:{
            autofillData: {
                get () {
                    return this.value;
                },
                set (value) {
                    this.$emit('input', value);
                }
            }
        },
        methods:{
            generateSessionToken() {
                return uuidv4()
            },
            waitToAutoComplete() {
                if (this.timeout) {
                    clearTimeout(this.timeout);
                }
                this.timeout = setTimeout(this.getAutocomplete, 200);
            },
            getAutocomplete() {
                if (this.sessionToken && this.addressInput && !this.fetchingPlace) {
                    let params = {
                        input: this.addressInput, 
                        session_token: this.sessionToken
                    }
                    api.get(this, api.AUTOCOMPLETE, params).then(r => {
                        this.autocompleteList = r ?? []
                    }).catch(err => {
                        this.autocompleteList = []
                        console.log(err)
                    });
                }
            },
            getPlaceDetails() {
                if (this.sessionToken && this.addressSelection?.place_id) {
                    if (this.timeout) {
                        clearTimeout(this.timeout);
                    }
                    let params = {
                        place_id: this.addressSelection.place_id,
                        session_token: this.sessionToken
                    }
                    this.fetchingPlace = true
                    api.get(this, api.AUTOCOMPLETE + "/place-details", params).then(r => {
                        this.autofillData = r ?? {}
                    }).catch(err => {
                        this.autofillData = {}
                    }).finally(r => {
                        this.sessionToken = this.generateSessionToken();
                        this.fetchingPlace = false;
                    });
                }
            },
            clearData() {
                this.autofillData = {};
                this.autocompleteList = [];
            }
        },
        watch:{
            addressInput() {
                this.getAutocomplete() 
            },
            addressSelection() {
                this.getPlaceDetails()
            }
        }
}


</script>
