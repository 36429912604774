<template>
  <div :style="'margin-top: 1px'">
    <span v-if="getComponentProps.position !== 'left'">
      #{{ fieldData.text }}
    </span>
    <hb-unit-icon
      v-if="getComponentProps.position === 'left'"
      v-bind="getComponentProps"
    />
    <span v-if="getComponentProps.position === 'left'">
      #{{ fieldData.text }}
    </span>
  </div>
</template>
<script>
export default {
  name: "UnitIconField",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    fieldData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  computed: {
    getComponentProps() {
      return {
        ...this.$attrs,
        unit: this.data,
      };
    },
  },
};
</script>
<style lang="scss" scoped></style>
