<template>
  <div>
    <div class="rent-plan-defaults">
      <hb-blade-header :back-button-off="true" @close="onClose">
        <template v-slot:left>
          <span class="rent-default-title pl-1">
            Rent Plan Defaults for the Selected Space Group
          </span>
        </template>
      </hb-blade-header>
      <div class="rent-plan-default-sub-header hb-text-night-light px-4 py-3">
        The Rent Plans below will be applied as a default to the selected space
        group when a space is or becomes vacant.
      </div>
      <div class="pa-0 ma-0" id="">
        <v-divider />
        <div class="rent-space-initials px-4 py-3">
          <div
            v-if="getSpaceDataInfo.category && getSpaceDataInfo.size"
            class="storage-info"
          >
            {{ getSpaceDataInfo.category }}: {{ getSpaceDataInfo.size }}
          </div>
          <div v-if="getSpaceDataInfo.amenity" class="amenity-info">
            {{ getSpaceDataInfo.amenity }}
          </div>
        </div>
        <v-divider />
        <span 
          v-if="loading"
          class="rent-card-wrap pa-4" 
          :style="{ height: rentCardsHeight + 'px' }"
          v-resize="setHeight"
        >
          <span v-for="n in 3" :key="n" class="rent-card pa-4">
            <v-skeleton-loader type="text, heading" />
            <v-skeleton-loader class="py-5" type="text, text, text" />
            <v-skeleton-loader class="" type="image, image" />
          </span>
        </span>
        <span
          v-else-if="isValueTierEnabled && !loading"
          class="rent-card-wrap pa-4"
          :style="{ height: rentCardsHeight + 'px' }"
          v-resize="setHeight"
        >
          <span
            v-for="(tier, index) of tierTypes"
            :key="index"
            class="rent-card"
          >
            <div class="pa-4">
              <v-row class="plan-card-head">
                <v-col
                  v-if="getTierDetails[tier]"
                  class="d-flex flex-row tier"
                  cols="8"
                  >Value Tier: {{ getTierDetails[tier] }}
                </v-col>
                <v-col class="d-flex flex-row-reverse" cols="4">
                  <hb-link
                    v-if="getPlanTier(tier).rent_plan_id"
                    class="change-link"
                    @click="selectDefaultRentPlan(tier, getTierDetails[tier])"
                    >Change Plan</hb-link
                  >
                </v-col>
              </v-row>
              <div v-if="getPlanTier(tier).rent_plan_id">
                <v-row class="default-headings ml-0">
                  <descriptive-tags
                    :plan="descriptiveTagProperties(tier)"
                    :conf="{ class: 'mr-3' }"
                  />
                  <span
                    v-if="getPlanDetails(getPlanTier(tier).rent_plan_id).name"
                    class="pt-2"
                    >{{ getPlanDetails(getPlanTier(tier).rent_plan_id).name }}
                  </span>
                </v-row>
                <v-row class="default-description pt-2 pb-4 ml-0">
                  <span
                    v-if="
                      getPlanDetails(getPlanTier(tier).rent_plan_id).description
                    "
                    >{{
                      getPlanDetails(getPlanTier(tier).rent_plan_id).description
                    }}
                  </span>
                </v-row>
                <v-row 
                  class="rent-plan-timeline" 
                  :style="`max-height: ${ rentCardsHeight - 225 }px;`"
                >
                  <rent-increase-timeline
                    :stages="
                      getPlanDetails(getPlanTier(tier).rent_plan_id).settings
                    "
                  />
                </v-row>
              </div>
              <empty-state-container
                v-if="!getPlanTier(tier).rent_plan_id"
                class="empty-state-container mt-3"
                message="No Plan Selected"
                showBtn
                btnTxt="Select"
                btnColor="secondary"
                @clicked="selectDefaultRentPlan(tier, getTierDetails[tier])"
              />
            </div>
          </span>
        </span>
        <span
          class="rent-card-wrap pa-4"
          v-if="!isValueTierEnabled && !loading"
          :style="{ height: rentCardsHeight + 'px' }"
          v-resize="setHeight"
        >
          <span class="rent-card py-6">
            <div class="px-4">
              <v-row class="plan-card-head">
                <v-col class="" cols="4">
                  <hb-link
                    v-if="getPlanTier('good').rent_plan_id"
                    class="change-link"
                    @click="selectDefaultRentPlan()"
                  >
                    Change Plan
                  </hb-link>
                </v-col>
              </v-row>
              <div v-if="getPlanTier('good').rent_plan_id">
                <v-row class="default-headings ml-0">
                  <descriptive-tags
                    :plan="descriptiveTagProperties()"
                    :conf="{ class: 'mr-3' }"
                    class="mr-3"
                  />
                  <span
                    v-if="getPlanDetails(getPlanTier(`good`).rent_plan_id).name"
                    class="pt-2"
                    >{{
                      getPlanDetails(getPlanTier("good").rent_plan_id).name
                    }}</span
                  >
                </v-row>
                <v-row
                  v-if="
                    getPlanDetails(getPlanTier(`good`).rent_plan_id).description
                  "
                  class="default-description pt-2 ml-0"
                >
                  <span
                    >{{
                      getPlanDetails(getPlanTier("good").rent_plan_id)
                        .description
                    }}
                  </span>
                </v-row>
                <v-row 
                  class="rent-plan-timeline" 
                  :style="`max-height: ${ rentCardsHeight - 225 }px;`"
                >
                  <rent-increase-timeline
                    :stages="
                      getPlanDetails(getPlanTier('good').rent_plan_id).settings
                    "
                  />
                </v-row>
              </div>
              <empty-state-container
                v-if="!getPlanTier('good').rent_plan_id"
                class="empty-state-container"
                message="No Plan Selected test"
                showBtn
                btnTxt="Click"
                btnColor="secondary"
                @clicked="selectDefaultRentPlan()"
              />
            </div>
          </span>
        </span>
      </div>
    </div>
    <AssignRentPlan
      ref="rentPlansList"
      @selected="setSelectedPlan($event)"
      :is-value-tiered="true"
    />
    <ConfirmationPopup v-once ref="confirmationPopup" />
    <hb-bottom-action-bar class="bottom-action-bar" @close="onClose">
      <template v-slot:left-actions>
        <help-juice />
      </template>
      <template v-slot:right-actions>
        <hb-btn
          :loading="saveLoader"
          :disabled="!hasPlansChanged"
          @click="showConfirmationPopup()"
        >
          Save
        </hb-btn>
      </template>
    </hb-bottom-action-bar>
    <hb-modal
      v-if="noRentPlans"
      class="z-300"
      v-model="noRentPlans"
      size="medium"
      title="No Rent Plans"
      confirmation
      @close="noRentPlans = false"
      :footer-off="true"
      show-help-link
    >
      <template v-slot:content>
        <div class="pa-4">
          There are no rent plans created at the
          corporate level. Please create a
          rent plan before assigning to a space group.
        </div>
      </template>
    </hb-modal>
  </div>
</template>

<script>
import { EventBus } from "../../../EventBus.js";
import { capitalizeFirstLetter } from "../../../utils/common";
import api from "../../../assets/api.js";
import { cloneDeep } from "lodash";
import { notificationMixin } from "@/mixins/notificationMixin.js";
import { mapState } from "vuex";

import DescriptiveTags from "../utils/DescriptiveTags.vue";
import EmptyStateContainer from "../../assets/EmptyStateContainer.vue";
import RentIncreaseTimeline from "./RentIncreaseTimeline.vue";
import AssignRentPlan from "./AssignRentPlan.vue";
import ConfirmationPopup from "../utils/ConfirmationPopup.vue";

export default {
  name: "SelectedRentPlanDefaults",
  mixins: [notificationMixin],
  components: {
    DescriptiveTags,
    EmptyStateContainer,
    RentIncreaseTimeline,
    AssignRentPlan,
    ConfirmationPopup,
  },
  props: {
    spaceData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      showRentPlanSelection: false,
      noRentPlans: false,
      rentCardsHeight: 0,
      spaceGroupPlans: null,
      initalPlans: null,
      loading: false,
      saveLoader: false,
      tierTypes: ["good", "better", "best"],
      confirmationPopup: {
        title: "Save Rent Plan Defaults!",
        description: `You are about to set Default Rent Plans to the selected Space Group.<br/><br/>Are you sure you want to save changes?`,
      },
    };
  },
  mounted() {
    this.getPlanData();
  },
  destroyed() {
    EventBus.$off("hide_drawer", this.onClose());
  },
  computed: {
    ...mapState({
      rentPlans: (state) => state.revManStore.rent.plans ?? [],
    }),
    getSpaceData() {
      return this.spaceData ?? {};
    },
    getSpaceDataInfo() {
      return {
        category: capitalizeFirstLetter(this.getSpaceData.spacegroup_category),
        size: this.getSpaceData.spacegroup_size,
        amenity: this.getSpaceData.spacegroup_amenities,
      };
    },
    getTierDetails() {
      return this.spaceGroupPlans?.value_pricing_config?.labels ?? {};
    },
    getGroupPlans() {
      return this.spaceGroupPlans?.group?.plans ?? [];
    },
    isValueTierEnabled() {
      return (
        this.spaceGroupPlans?.value_pricing_config?.value_pricing_active ??
        false
      );
    },
    hasPlansChanged() {
      return JSON.stringify(this.spaceGroupPlans?.group?.plans) !=
        JSON.stringify(this.initalPlans?.group?.plans)
        ? true
        : false;
    },
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
    setHeight() {
      let headings = 100;
      let footer = 74;
      let padding = 50;
      let extraSizes = 58;
      let aminites = this.getSpaceDataInfo?.amenity
        ? this.getSpaceDataInfo?.amenity.length > 218
          ? 50
          : 25
        : 0;
      this.rentCardsHeight =
        window.innerHeight -
        headings -
        footer -
        padding -
        extraSizes -
        aminites;
    },
    getPlanTier(tierType) {
      return (
        this.getGroupPlans.find((plan) => plan.value_tier_type == tierType) ??
        {}
      );
    },
    getPlanDetails(planId) {
      return this.rentPlans.find((plan) => plan.id === planId) ?? {};
    },
    async getSpaceGroupPlans() {
      this.loading = true;
      try {
        let response = await api.get(
          this,
          api.getPropertySpaceGroupRentPlansUrl(
            this.spaceData?.property,
            this.getSpaceData?.spacegroup_id_hash
          )
        );
        this.spaceGroupPlans = response.space_group_settings;
        this.initalPlans = cloneDeep(this.spaceGroupPlans);
      } catch (err) {
        console.error(err);
        this.showMessageNotification({
          description: `Rent Plans fetch failed.`,
        });
      }
      this.loading = false;
    },
    async setSpaceGroupPlans() {
      this.saveLoader = true;
      let payload = this.spaceGroupPlans?.group?.plans ?? [];
      try {
        let response = await api.put(
          this,
          api.getPropertySpaceGroupRentPlansUrl(
            this.spaceData?.property,
            this.getSpaceData?.spacegroup_id_hash
          ),
          { plans: payload }
        );
        this.showMessageNotification({
          type: "success",
          description: `Rent Plans updated successfully.`,
        });
        await this.getSpaceGroupPlans();
      } catch (err) {
        console.error(err);
        this.showMessageNotification({
          description: `Rent Plans update failed.`,
        });
      }
      this.saveLoader = false;
    },
    selectDefaultRentPlan(tier = "good", tierName = "") {
      if (this.rentPlans.length) {
        this.noRentPlans = false;
        this.$refs.rentPlansList.show({
          rentPlans: this.rentPlans,
          currentPlanId: this.getPlanTier(tier).rent_plan_id,
          rowData: {
            ...this.getSpaceDataInfo,
            tier: {
              name: tierName,
              type: tier,
            },
          },
        });
      } else {
        this.noRentPlans = true;
      }
    },
    async setSelectedPlan(selectedPlanData) {
      if (
        this.spaceGroupPlans?.group?.plans &&
        this.spaceGroupPlans?.group?.plans.length
      ) {
        let hasPlan = false;
        for (let plan of this.spaceGroupPlans?.group?.plans) {
          if (plan.value_tier_type == selectedPlanData.tierType) {
            hasPlan = true;
            plan["rent_plan_id"] = selectedPlanData.selectedPlanId;
          }
        }
        if (!hasPlan) {
          this.spaceGroupPlans.group.plans.push({
            rent_plan_id: selectedPlanData.selectedPlanId,
            value_tier_type: selectedPlanData.tierType,
          });
        }
      } else {
        this.spaceGroupPlans.group = {
          plans: [
            {
              rent_plan_id: selectedPlanData.selectedPlanId,
              value_tier_type: selectedPlanData.tierType,
            },
          ],
        };
      }
    },
    async getPlanData() {
      this.loading = true;
      await this.getSpaceGroupPlans();
      this.loading = false;
    },
    descriptiveTagProperties(tier = "good") {
      return {
        tags:
          this.getPlanDetails(this.getPlanTier(tier).rent_plan_id).tags ?? [],
        price_delta_type: this.RentValueTypes ?? [],
      };
    },
    /**
     * Function to display the confirmation modal.
     * @param {String} type Type of the confirmation modal ( default/delete)
     */
    async showConfirmationPopup(type = "active") {
      this.confirmationType = type;
      let confirmed = await this.$refs.confirmationPopup
        .show({
          title: this.confirmationPopup.title,
          message: this.confirmationPopup.description,
          buttonType: "primary",
          resolver: `Continue`,
        })
        .catch(() => false);
      if (confirmed) await this.setSpaceGroupPlans();
    },
  },
};
</script>

<style lang="scss" scoped>
.rent-plan-defaults {
  background-color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  max-height: 95vh;
  overflow-y: auto;
}
.rent-plan-timeline {
  // max-height: 40vh;
  overflow-y: auto;
}
.rent-plan-default-sub-header {
  font-weight: 400;
  font-size: 14px;
}
.bottom-action-bar {
  font-weight: 400;
  position: fixed;
  width: 100%;
  bottom: 0px;
}
.rent-space-initials {
  font-weight: 400;
  background: #f4f6f8;
  .storage-info {
    font-size: 14px;
    color: black;
  }
  .amenity-info {
    font-size: 12px;
    color: #637381;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
}
.default-headings {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: black;
}
.empty-state-container {
  font-size: 14px;
  font-weight: 400;
}
.default-description {
  font-size: 12px;
  font-weight: 400;
}
.rent-card-wrap {
  overflow-y: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;
  .plan-card-head {
    font-size: 14px;
    font-weight: 500;
    .tier {
      color: black;
    }
    .change-link {
      font-weight: 400;
    }
  }
  .rent-card {
    border-style: groove;
    border-radius: 6px;
    border-width: 0px;
    box-shadow: 0px 1px 5px #d9d9d9;
  }
}
</style>
<style lang="scss">
.default-headings {
  div {
    .descriptive-icons {
      &.medium {
        span {
          svg {
            margin-top: 5px !important;
          }
          i {
            margin-top: 4px !important;
          }
        }
      }
    }
  }
}
</style>
