<template>
  <div>
    <v-row no-gutters class="mt-n2 mr-n6" v-if="facilityList.length > 1">
      <HbPropertySelector
        id="facility"
        :items="facilityList"
        item-text="name"
        item-value="id"
        v-model="form.property_id"
        @change="resetTenantList"
        data-vv-name="facility"
        data-vv-as="Property"
        v-validate="'required'"
        :error="errors.has('facility')"
      />
    </v-row>

    <span v-else-if="facilityList.length === 1" class="v-card__title hb-text-night pt-0 pl-0">
      <div class="truncate">
         {{facilityList[0].number}} - {{facilityList[0].name}} - {{facilityList[0].Address.address}}
      </div>
    </span>


    <v-card>
      <v-card-title>
        <v-icon class="mr-2 mt-n1" color="#101318">mdi-gesture-tap</v-icon> Rent Change Trigger and Configuration
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="interaction-section-body pa-0">
        <v-row class="hb-table-row ma-0 pa-0">
          <v-col class="hb-table-label py-4 pl-6" cols="4">Name</v-col>
          <v-col cols="6" class="pa-4 pt-2  pl-6 hb-table-value">
              <v-text-field
                  single-line
                  dense
                  flat
                  class="input-font-size"
                  label="Enter Rent Change Name"
                  v-model="form.name"
                  data-vv-name="rate_change_name"
                  data-vv-as="rent change name"
                  v-validate="'required'"
                  :error-messages="errors.collect('rate_change_name')"
                  hide-details="auto"
              ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="hb-table-row ma-0 pa-0">
          <v-col class="hb-table-label py-4 pl-6" cols="4">Notification Period</v-col>
          <v-col cols="2" class="pa-4 pt-2 pr-2 pl-6 hb-table-value">
              <v-text-field
                  label="0"
                  single-line
                  dense
                  flat
                  class="input-font-size"
                  v-model="form.notification_period"
                  data-vv-name="notification_period"
                  data-vv-as="notification period"
                  v-validate="'required|integer|min_value:1'"
                  :error-messages="errors.collect('notification_period')"
                  hide-details="auto"
              ></v-text-field>
          </v-col>
          <v-col cols="4" class="pa-4 pt-5 pl-0 hb-table-value">
            <span class="input-font-size input-desc-text">Days before the rent change</span>
          </v-col>
        </v-row>
        <v-row class="hb-table-row ma-0 pa-0">
          <v-col class="hb-table-label py-4 pl-6" cols="4">Change Rent For</v-col>
          <v-col cols="4" class="pa-4 pt-1 pb-2 pl-6 hb-table-value" >
            <hb-date-picker
                placeholder="Select Date"
                clearable
                dense
                name="target_date"
                :solo="false"
                :min="new Date().toISOString()"
                :prepend_icon="true"
                v-model="form.target_date"
                data-vv-as="date"
                data-vv-name="target_date"
                v-validate="'required'"
                :message="errors.collect('target_date')"
              />
          </v-col>
        </v-row>
        <v-row class="hb-table-row ma-0 pa-0">
          <v-col class="hb-table-label py-4 pl-6" cols="4">Rent Change Detail</v-col>
          <v-col cols="8" class="pa-4 pt-2 pl-6 pb-0 hb-table-value">
            <v-radio-group v-model="rateInc" :column="false" dense hide-details>
              <v-radio label="Increment" :value="1" ></v-radio>
              <v-radio label="Set Rent" :value="2" class="ml-3" ></v-radio>
            </v-radio-group>
            <v-row>
              <v-col cols="4" class="pa-0 pl-1" style="max-width:125px;" v-if="rateInc === 1">
                <v-select
                  :items="changeDirections"
                  v-model="form.change_direction"
                  solo
                  flat
                  hide-details
                >
                </v-select>
              </v-col>
              <v-col cols="4" class="pa-0" style="max-width:125px;">
                <v-select
                  :items="rateInc === 1 ? changeType : changeTypeFixed"
                  :disabled="rateInc == 2"
                  v-model="form.change_type"
                  solo
                  flat
                  hide-details
                >
                </v-select>
              </v-col>
              <v-col cols="3" class="pa-0 pb-2" style="max-width:80px;">
                <v-text-field
                    label="Amount"
                    single-line
                    dense
                    flat
                    class="input-font-size"
                    v-model="form.change_amt"
                    data-vv-name="change_amt"
                    data-vv-as="amount"
                    v-validate="'required|decimal|min_value:0'"
                    :error-messages="errors.collect('change_amt')"
                    hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <Rounding from="rentManagement" :selected="rate_data"></Rounding>
            <v-row class="hb-table-row ma-0 pa-0">
              <v-col class="hb-table-label py-4 pl-6" cols="4">Tenant List</v-col>
              <v-col cols="8" class="pr-4 pt-3 pb-12 pl-6 hb-table-value">
                <div>{{ selectedTenants.length }} Tenants</div>
                <a @click="showTenantList"> {{ selectedTenants.length > 0 ? 'Edit': 'Create'}} Tenant List</a>
              </v-col>
            </v-row>
      </v-card-text>
    </v-card>

  </div>

</template>

<script>
import HummingbirdDatepicker from '../assets/HummingbirdDatepicker.vue';
import Rounding from "../assets/Rounding.vue";
import { mapGetters, mapActions } from 'vuex';
import { EventBus } from '../../EventBus.js'

export default {
  name: "ManualForm",
  data() {
    return {
      form: {
        property_id: '',
        name: null,
        type: 'manual',
        change_amt: null,
        notification_period: null,
        change_direction: 'Increase',
        change_type: 'percent',
        document_id: '1234567891',
        leases: [],
        target_date: null,
        trigger: 'start_of_lease',
        rounding: null
      },
      rateInc: 1,
      changeDirections: [
        { text: 'Increase', value: 'Increase' },
        { text: 'Decrease', value: 'decrease' }
      ],
      changeType: [
        { text: 'Percent', value: 'percent' },
        { text: 'Dollar', value: 'dollar' }
      ],
      changeTypeFixed: [
        { text: 'Dollar', value: 'fixed' }
      ],
      roundingData: null,
      change_counter: 0
    }
  },
  props: ['rate_data','selectedTenants', 'saveRateChange'],
  components: {
    'hb-date-picker': HummingbirdDatepicker,
    Rounding
  },
  computed: {
    ...mapGetters({
      facilityList: 'propertiesStore/filtered',
      activeDrawerComponent: 'navigationStore/activeDrawerComponent'
    })
  },
  watch: {
    rateInc () {
      if (this.rateInc === 1) {
        this.form.change_type = 'percent'
      } else {
        this.form.change_type = 'fixed'
      }
    },
    form:{
      handler(value){
        if(!this.activeDrawerComponent.change_counter && !this.change_counter){
          this.change_counter++;
          this.setActiveDrawerComponent({...this.activeDrawerComponent, change_counter: this.change_counter })
        } else if (this.change_counter || this.activeDrawerComponent.change_counter){
          this.change_counter++;
          this.$emit('update:rate_data', value);
        } 
      },
      deep: true
    },
    rate_data(value) {
      if(value) {
        const keys =  Object.keys(this.form)
        const self = this
        keys.forEach(key => {
          self.form[key] = self.rate_data[key]
        })
        this.form.id = this.rate_data.id
        if(this.rate_data.change_type === 'fixed') {
          this.rateInc = 2
        }
      }
    }
  },
  created () {
    this.form.property_id = this.facilityList.length === 1 ? this.facilityList[0].id : null
    EventBus.$on('fetch_rate_change',this.fetchAutomatedFormData)
    EventBus.$on("saveRoundingData", this.saveRounding);
  },
  mounted () {
    if(this.rate_data) {
      const keys =  Object.keys(this.form)
      const self = this
      keys.forEach(key => {
        self.form[key] = self.rate_data[key]
      })
      this.form.id = this.rate_data.id
      if(this.rate_data.change_type === 'fixed') {
        this.rateInc = 2
      }
    }
  },
  beforeDestroy () {
    EventBus.$off('fetch_rate_change', this.fetchAutomatedFormData)
    EventBus.$off("saveRoundingData", this.saveRounding);
  },
  methods: {
    ...mapActions({
      setActiveDrawerComponent:'navigationStore/setActiveDrawerComponent',
    }),
    saveRounding(rounding) {
      this.roundingData = rounding;
      this.form.rounding = rounding;
    },
    resetTenantList() {
      if(this.form.property_id) {
        this.$emit('resetList', this.form.property_id);
      }
    },
    async showTenantList() {
      if(this.facilityList.length == 1) {
        this.form.property_id = this.facilityList[0].id;
      }
      if(this.form.property_id) {
        let params = {};
        params.property_id = this.form.property_id;
        params.tenantList = this.selectedTenants;
        this.$emit('showList', params);
      } else {
        const status = await this.validate(this);
      }
    },
    async fetchAutomatedFormData() {
      const status = await this.validate(this)
      if(status) {
        this.form.leases = this.selectedTenants.map(x=> {
            return {
              id: x.lease_id
            }
          })
        if(this.facilityList.length == 1) {
          this.form.property_id = this.facilityList[0].id;
        }
        this.form.rounding = this.roundingData;
        this.$emit('saveRateChange', this.form)
      }
    }
  }
}
</script>

<style scoped>
.hb-table-label{
    color: #101318;
    font-weight: 500;
}
.input-desc-text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #101318;
}
.truncate {
  max-width: 320px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
