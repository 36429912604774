<template>
    <div>
        <v-container class="pa-0 ma-0">
          <v-row class="hb-table-row ma-0 pa-0">
            <v-col class="hb-table-label py-4 pl-4" cols="3">
              Auction Type
            </v-col>
            <v-col class="ma-0 pa-4" cols="6">
              <v-row
                class="pt-0 pl-4"
                v-if="scheduled_auction.id"
              >
                <v-col v-if="scheduled_auction.id" cols="11" class="text-capitalize pa-0 ma-0">
                  {{ scheduled_auction.auction_type }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="hb-table-row ma-0 pa-0">
            <v-col class="hb-table-label py-4 pl-4" cols="3">
              Date and Time
            </v-col>
            <v-col class="ma-0 py-4 pt-0" :class="{'hb-form-editable' : scheduled_auction.id && fieldEditing != 'date_time' && !isAuctionDay && !isMoveOut}" cols="9">
              <v-row
                class="pt-4 pl-5"
                v-if="isAuctionDay || isMoveOut"
              >
                <v-col v-if="scheduled_auction.id" cols="11" class="pa-0 ma-0">
                  {{ scheduled_auction.date | formatLocalShortDate }} @
                  {{ scheduled_auction.scheduled_date | formatTime12Hour }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="hb-table-row ma-0 pa-0" v-if="isAuctionDay || isMoveOut">
            <v-col class="hb-table-label py-4 pl-4" cols="3"> Winning Bid </v-col>
            <v-col class="ma-0 pl-4" cols="9"  v-if="isMoveOut">
              <v-row
                class="pt-0 pl-4"
                v-if="scheduled_auction.id"
              >
                <v-col v-if="scheduled_auction.id" cols="11" class="pa-0 ma-0">
                  {{ scheduled_auction.amount | formatMoney }}
                  <hb-status-general>Paid</hb-status-general>
                </v-col>
              </v-row>
              <v-row
                class="pt-3 pl-4"
                v-if="scheduled_auction.id"
              >
                <v-col v-if="scheduled_auction.id" cols="11" class="pa-0 ma-0">
                  Cleaning Deposit: {{ scheduled_auction.cleaning_deposit | formatMoney }}
                  <hb-btn small color="secondary" @click="refundFlag = true" v-if="!scheduled_auction.refund_id && !auction_refunded"
                    >Refund</hb-btn>
                  <hb-status color="#919EAB" v-else>Refunded</hb-status>
                </v-col>
              </v-row>
              <v-row
                class="pt-3 pl-4"
                v-if="scheduled_auction.id"
              >
                <v-col v-if="scheduled_auction.id" cols="11" class="pa-0 ma-0">
                  Cleaning Up Period: {{ scheduled_auction.cleaning_period }} Days
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="hb-table-row ma-0 pa-0" v-if="isAuctionDay || isMoveOut">
            <v-col class="hb-table-label py-4 pl-4" cols="3">Auction Winner</v-col>
            <v-col class="ma-0 pl-4" cols="9" v-if="isMoveOut">
              <v-row
                class="pt-0 pl-4"
                v-if="scheduled_auction.id"
              >
                <v-col v-if="scheduled_auction.id" cols="11" class="pa-0 ma-0">
                  {{ scheduled_auction.Contact.first }} {{ scheduled_auction.Contact.last }}
                </v-col>
              </v-row>
              <v-row
                class="pt-1 pl-4"
                v-if="scheduled_auction.id"
              >
                <v-col v-if="scheduled_auction.id" cols="11" class="pa-0 ma-0">
                  {{ scheduled_auction.Contact.email }}
                </v-col>
              </v-row>
              <v-row
                class="pt-1 pl-4"
                v-if="scheduled_auction.id"
              >
                <v-col v-if="scheduled_auction.Contact.Phones.length && scheduled_auction.Contact.Phones[0].phone" cols="11" class="pa-0 ma-0">
                  {{ 1 + scheduled_auction.Contact.Phones[0].phone | formatPhone }}
                </v-col>
              </v-row>
              <v-row
                class="pt-1 pl-4"
                v-if="scheduled_auction.id && scheduled_auction.tax_exempt"
              >
                <v-col v-if="scheduled_auction.id" cols="11" class="pa-0 ma-0">
                  Tax Exempt (Reseller License)
                </v-col>
              </v-row>
              <v-row
                class="pt-1 pl-4"
                v-if="scheduled_auction.id && scheduled_auction.tax_exempt"
              >
                <v-col v-if="scheduled_auction.id" cols="11" class="pa-0 ma-0">
                  {{ scheduled_auction.license_number }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="hb-table-row ma-0 pa-0">
            <v-col class="hb-table-label py-4 pl-4" cols="3"> Notes </v-col>
            <v-col class="ma-0 pa-4" :class="{'hb-form-editable' : scheduled_auction.id && fieldEditing != 'notes' && !isAuctionDay && !isMoveOut}" cols="9">
              <v-row
                class="pl-4"
                v-if="isMoveOut"
              >
                <v-col v-if="scheduled_auction.id" cols="11" class="pa-0 ma-0">
                  {{ scheduled_auction.notes }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!-- Refund Modal -->
          <hb-modal
            size="medium"
            title="Refund Cleaning Deposit"
            v-model="refundFlag"
            v-if="refundFlag"
            show-help-link
          >
            <template v-slot:content>
              <status
                  @resetStatus="errorClear($options.name)"
                  v-if="errorHas($options.name)"
                  :message="errorGet($options.name)"
                  status="error"
                  class="pa-3"
              ></status>
              <v-row class="pa-0 ma-0">
                  <v-col cols="4" class="py-4 px-6 ma-0 hb-cloud-light font-weight-medium">
                      Refund From
                  </v-col>
                  <v-col cols="8" class="ma-0 pt-4 pl-6">
                    <v-select
                      v-model="refund.paymentFrom"
                      :items="paymentTypes"
                      item-value="value"
                      item-text="text"
                      name="payment_type"
                      label="Select a payment"
                      hide-details
                    ></v-select>
                  </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row class="pa-0 ma-0">
                  <v-col cols="4" class="py-4 px-6 ma-0 hb-cloud-light font-weight-medium">
                      Amount
                  </v-col>
                  <v-col cols="8" class="ma-0 pt-4 pl-6">
                    <v-text-field
                      :disabled="true"
                      :error-messages="errors.collect('refund_amount')"
                      :hide-details="!errors.collect('refund_amount').length"
                      label="Refund Amount"
                      flat
                      id="refund_amount"
                      name="refund_amount"
                      data-vv-as="refund amount"
                      v-model="refund.amount"
                      v-validate="'required|integer'"
                    ></v-text-field>
                    <span class="caption" style="color: #637381;">(Total Available: {{scheduled_auction.cleaning_deposit | formatMoney}})</span>
                  </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row class="pa-0 ma-0">
                  <v-col cols="4" class="py-4 px-6 ma-0 hb-cloud-light font-weight-medium">
                      Refund To
                  </v-col>
                  <v-col cols="8" class="ma-0 pt-4 pl-6 text-capitalize">
                    {{ refund_option.text }}
                  </v-col>
              </v-row>
            </template>
            <template v-slot:actions>
                <hb-btn color="primary" @click="processRefund" :disabled="isRefundAmount || disableRefund">Refund</hb-btn>
            </template>
          </hb-modal>
          <!-- Refund Modal -->
        </v-container>
    </div>
</template>
<script type="text/babel">
import Status from '../../../includes/Messages.vue';
import { EventBus } from "../../../../EventBus.js";
import api from "../../../../assets/api.js";
import Loader from "../../../assets/CircleSpinner.vue";
import HbDatePicker from "../../../assets/HummingbirdDatepicker";
import HbTimePicker from "../../../assets/HummingbirdTimepicker";
import VuePhoneNumberInput from 'vue-phone-number-input';
import moment from "moment";

export default {
  name: "AuctionSold",
  data() {
    return {
      paymentTypes: [
        { text: "Cleaning Deposit", value: "cleaning_deposit" }
      ],
      refund: {
        paymentFrom: 'cleaning_deposit',
        amount: 0,
        paymentTo: ''
      },
      refundFlag: false,
      refund_option: {
        text: '',
        value: 0
      },
      auction_invoice_amount: 0,
      scheduled_auction: {
        Contact: {
          Phones: [{phone: "", type: ""}],
          country_code: "",
          email: "",
          first: "",
          last: ""
        },
        id: "",
        auction_type: "",
        scheduled_date: "",
        date: "",
        time: "",
        notes: "",
        cleaning_deposit: 100,
        cleaning_period: 3,
        amount: 100,
        tax_exempt: false,
        license_number: '',
        country_code: '',
        mobile_no: ''
      },
      fieldEditing: "",
      payment: {},
      auction_refunded: false,
      disableRefund: false
    };
  },
  components: {
    Status,
    Loader,
    HbDatePicker,
    HbTimePicker,
    VuePhoneNumberInput
  },
  computed: {
    isRefundAmount() {
      return this.refund.amount ? false : true;
    },
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    isAuctionDay() {
      return (this.auctionLease.auction_status == 'auction_day' || this.auctionLease.auction_status == 'auction_payment') ? true : false;
    },
    isMoveOut() {
      return (this.auctionLease.auction_status == 'move_out') ? true : false;
    }
  },
  async created() {
    const { LeaseAuction } = this.auctionLease;

    if (LeaseAuction && LeaseAuction != {}) {
      this.scheduled_auction = JSON.parse(JSON.stringify(LeaseAuction));
      if(!this.scheduled_auction.contact_id){
        this.scheduled_auction.Contact = {
          Phones: [{phone: "", type: ""}],
          country_code: "",
          email: "",
          first: "",
          last: ""
        };
      } else {
        if(!this.scheduled_auction.Contact.Phones.length){
          this.scheduled_auction.Contact.Phones = [{phone: "", type: ""}];
        } else {
          this.scheduled_auction.Contact.Phones[0].phone = this.scheduled_auction.Contact.Phones[0].phone.slice(1);
        }
        if(this.scheduled_auction && this.scheduled_auction.payment_id) {
          this.fetchPayment();
        }
        this.refund.amount = this.scheduled_auction.cleaning_deposit;
      }
      let localScheduleDateTime = moment(
        this.scheduled_auction.scheduled_date
      ).format("YYYY-MM-DD HH:mm:ss");

      this.scheduled_auction.scheduled_date = localScheduleDateTime;
      this.scheduled_auction.date = moment(localScheduleDateTime).format(
        "YYYY-MM-DD"
      );
      this.scheduled_auction.time = moment(localScheduleDateTime).format(
        "HH:mm"
      );
    }
  },
  props: [
    "auctionLease",
    "value"
  ],
  methods: {
    async processRefund(){
      this.disableRefund = true;
      const status = await this.$validator.validateAll();
      if(!status) return
      // refund
      var data = {
        amount: parseFloat(this.refund.amount)
      }
      if (this.refund_option.value === 0) {
        data.is_prepay = true
      }
      await api.post(this, api.LEASE_AUCTION + this.scheduled_auction.id + '/refund', data).then(r => {
        this.auction_refunded = true;
        EventBus.$emit('contact_updated',  this.contact_id);
        this.refundFlag = false;
      });
      this.disableRefund = false;
    },
    fetchPayment(){
      api.get(this, api.GET_PAYMENT + this.scheduled_auction.payment_id).then(r => {
        this.payment = r.payment;
        this.getRefundOptions();
      });
    },
    getRefundOptions() {
      // Handling Credit payment refund option
      if(this.payment.method && this.payment.method === 'credit') {
        this.refund_option = {
          text: 'Credit Balance',
          value: 0
        }
        return;
      }
      let text = ''
      if (['card', 'ach'].indexOf(this.payment.method) >= 0) {
        text = this.payment.PaymentMethod.card_type + '****' +  this.payment.PaymentMethod.card_end
      } else {
        text = this.payment.method
      }
      this.refund_option = {
        text,
        value: this.payment.PaymentMethod.id  || 1
      }
    },
    close() {
      this.errorClear(this.$options.name);
      this.successClear(this.$options.name);
      this.$emit("close");
    },
  },
};
</script>
<style scoped>
  .fw-500 {
    font-weight: 500;
  }
  .payment-data{
    background: white;
    margin-bottom: 10px;
    border-radius: 1px;
  }
</style>
