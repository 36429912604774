<template>
  <div>
    <v-container class="payment-method-container">
      <v-row>
        <v-col><strong>Check Payment</strong></v-col>
      </v-row>

      <v-row justify="space-between">
        <v-col cols="7" >Total Payment</v-col>
        <v-col cols="5" class="align-right">
          <strong>{{payment.amount | formatMoney}}</strong>
          <!--            <v-text-field-->
          <!--              :hide-details="!errors.collect('amount').length"-->
          <!--              v-model="payment.amount"-->
          <!--              v-validate="'required'"-->
          <!--              data-vv-name="amount"-->
          <!--              :error-messages="errors.collect('amount')"-->

          <!--              prepend-inner-icon="mdi-currency-usd"-->
          <!--              outlined>-->
          <!--          </v-text-field>-->
        </v-col>
      </v-row>


      <v-row>
        <v-col>
          <hb-date-picker
            label="Payment Date"
            v-model="payment.date"
          ></hb-date-picker>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            :class="{'custom-field-error' : errors.first('ref_name')}"
            :hide-details="!errors.collect('ref_name').length"
            v-model="payment.ref_name"
            v-validate="'required'"
            data-vv-name="ref_name"
            data-vv-as="Reference Name"
            :error-messages="errors.collect('ref_name')"
            label="Reference Name"
            outlined>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            :class="{'custom-field-error' : errors.first('number')}"
            :hide-details="!errors.collect('number').length"
            v-model="payment.number"
            v-validate="'required'"
            data-vv-name="number"
            data-vv-as="Check Number"
            :error-messages="errors.collect('number')"
            label="Check Number"
            outlined>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-textarea
            :class="{'custom-field-error' : errors.first('notes')}"
            rows="4"
            :hide-details="!errors.collect('notes').length"
            v-model="payment.notes"
            data-vv-name="notes"
            :error-messages="errors.collect('notes')"
            label="Notes"
            outlined>
          </v-textarea>
        </v-col>
      </v-row>


    </v-container>


  </div>
</template>


<script type="text/babel">
    import HbDatePicker from '../../assets/HummingbirdDatepicker';
    import PaymentMethodBase from './PaymentMethodBase.vue';
    export default {
        extends: PaymentMethodBase,
        data() {
            return {
                type: '',
            }
        },
        components:{
            HbDatePicker
        },
        computed:{
        },
        created(){
            this.type = this.payment_type;
            this.payment.source = 'In-store';
            this.payment.type = this.payment_type;
            this.payment.payment_method_id = null

        },
        props: ['payment_type', 'payment_method', 'payment'],
        watch:{
            payment_type(val){
                this.type = val;
                this.payment.type =  'check';
                this.payment_method.type = 'check';
            }
        }


    }
</script>
<style scoped>

</style>
