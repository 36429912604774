<template>
  <div class="col-12 pa-0">
    <div class="v-card__title px-0 pt-0">
      {{ title }} Rent Change Instructions
    </div>
    <p>
        Create and manage rent changes for existing tenants. Setup triggers to automatically send notifications to the applied tenants. Notifications must be timestamped no less than 30 days before a tenant's rent is changed.
    </p>
  </div>
</template>

<script>
export default {
  name: "Instructions",
  props: ['title']
}
</script>