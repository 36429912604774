<template>
  <hb-modal
    size="medium"
    :title="isTag ? 'Tag Rent Change' : 'Untag Rent Change'"
    v-model="dialog"
    confirmation
    @close="$emit('close')"
    show-help-link
  >
    <template v-slot:content>
      <div class="content py-4 px-6 d-flex flex-column">
        <div v-html="popupDescription"></div>
        <div>Describe the reason for the {{ isTag ? "tag" : "untag" }}.</div>
        <div>
          <span class="reason-text hb-text-night-light hb-font-caption"
            >Notes:</span
          >
          <HbTextarea
            v-model.trim="reason"
            placeholder="Enter Reason"
            v-validate="'max:255'"
            data-vv-name="reason"
            data-vv-as="Reason"
            :error="errors.collect('name').length > 0"
          />
        </div>
      </div>
    </template>
    <template v-slot:right-actions>
      <hb-btn :loading="isLoading" @click="tagRentChange">Save</hb-btn>
    </template>
  </hb-modal>
</template>
<script>
import api from "../../../assets/api.js";

import { mapGetters } from "vuex";
import { notificationMixin } from "@/mixins/notificationMixin.js";

export default {
  name: "TagRentChange",
  components: {},
  mixins: [notificationMixin],
  props: {},
  data() {
    return {
      dialog: false,
      tenantData: {},
      reason: "",
      popupDescription: "",
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      selectedProperty: "revManStore/getSelectedProperty",
    }),
    isTag() {
      return !this.tenantData?.rentchange_tagged;
    },
  },
  methods: {
    show(data) {
      this.dialog = true;
      this.tenantData = data;
      this.popupDescription = `You are ${
        this.isTag ? "tagging" : "untagging"
      }  <span class="font-weight-medium">${
        this.tenantData?.rentchange_tenant_name
      }’s</span> upcoming rent change.`;
    },

    async tagRentChange() {
      const valid = await this.$validator.validateAll();
      if (valid) {
        this.isLoading = true;
        let payload = {
          rent_change_ids: [this.tenantData?.rentchange_id],
          tag: this.isTag ? true : false,
          note: this.reason,
        };
        try {
          await api
            .put(
              this,
              api.getPropertyRentManagementUrl(this.selectedProperty) +
                "rent-changes/tag",
              payload
            )
            .then(() => {
              this.showMessageNotification({
                type: "success",
                description: `Rent Change ${
                  this.isTag ? "tagged" : "untagged"
                } successfully.`,
              });
              this.$emit("tagged", {
                status: true,
              });
              this.dialog = false;
            })
            .catch((error) => {
              console.log(error);
              this.showMessageNotification({
                type: "error",
                description: `Unable to ${
                  this.isTag ? "Tag" : "Untag"
                } Rent Change.`,
              });
            });
        } catch (error) {
          console.log(error);
        }
        this.reason = "";
        this.isLoading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  gap: 16px;
}
</style>
