<template>
    <div id="facility-search--groups">
        <v-data-table
            :headers="headers"
            :items="groups"
            :value="[selectedGroup]"
            :no-data-text="'No Property Groups have been created.'"
            @click:row="onGroupSelection"
            fixed-header
            disable-pagination
            hide-default-footer
            height="334px"
        >

            <template v-slot:item.pg_created_by="{ item }">
              {{ item.pg_created_by }}
              <br />
              <span class="hb-text-light">{{ item.created_at | formatDate("MMM DD, YYYY") }}</span>
            </template>

            <template v-slot:item.properties="{ item }">
                    <span>{{ item.properties.length }}</span>
            </template>

            <template v-slot:[`item.occupancy`]="{ item }">
                <occupancy :leases="item.lease_count" :units="item.unit_count" />
            </template>
        </v-data-table>
    </div>
</template>
<script>
import Occupancy from './Occupancy.vue';
import { mapActions } from 'vuex';
export default {
    data() {
        return {
            headers: [{
                value: "name",
                text: "Group Name",
                sortable: true,
                width: "170"
            },{
                value: "pg_created_by",
                text: "Created By",
                sortable: true,
                width: "136"
            },{
                value: "properties",
                text: "# of Properties",
                width: "110px",
                sortable: true
            },{
                value: "occupancy",
                text: "Occupancy",
                width: "104px",
                sortable: true
            },{
                value: "unit_count",
                text: "Spaces",
                sortable: true,
                width: "80"
            }
            ],
        }
    },
    props: [
        'properties', 
        'propertyGroups', 
        'selectedGroup'
    ],
    components: {
        Occupancy
    },
    computed: {
        groups() {
            this.propertyGroups.forEach(function(pg) {
                pg.occupancy = pg.lease_count / pg.unit_count * 100;
            })
            return this.propertyGroups;
        }
    },
    methods: {
        ...mapActions({
            setSelectedPropertyGroup: 'propertiesStore/setSelectedPropertyGroup'
        }),
        onGroupSelection(group) {
            let group_properties = group.properties.map(p => p.id);
            let selectedProperties = this.properties.filter(p => group_properties.includes(p.id))
            this.setSelectedPropertyGroup(group);
            this.$emit("handleGroupSelection", selectedProperties);
        }
    }
}
</script>
<style lang="scss">
#facility-search--groups {
    table {
        thead {
            height:56px;
            tr {
                th {
                    background: #F5F6F8;
                    border-top: 0.5px solid #E0E3E8;
                    font-weight:normal;
                    color:#474F5A;
                    font-size:14px;
                    padding: 0 10px;
                }
                th:first-of-type{
                    padding-left:27px;
                }
                th:last-of-type{
                    padding-right:0;
                }
            }
        }
    }
    .v-data-table {
        .v-data-table-header {
            background: #eaeaea;
        }
        tbody {
            tr {
                &:hover {
                    background-color:#F9FAFB;
                }
                &.v-data-table__selected {
                    background-color:#E0F5F5;
                }
                td {
                    border-bottom: none;
                    padding: 18px 10px;
                }
                td:first-of-type{
                    padding-left:27px;
                }
                border-bottom: 1px solid #0000001f;
            }
        }
    }
}
</style>