<template>
    <hb-modal
        size="large"
        title="Send Document"
        v-model="dialog"
        @close="$emit('close')"
        show-help-link
    >
        <template v-slot:content>
  <div class="w-form add-interaction-form">

    <div class="form-row">

      <status @resetStatus="successClear($options.name)" v-if="successHas($options.name)" :message="successGet($options.name)" status="success"></status>
      <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
    </div>


    <div class="form-row">
      <div class="form-label"><label>Subject</label></div>
      <div class="form-input-container" :class="{'has-error': errors.has('subject') }">
          <input type="text" class="w-input" v-model="form.subject" name="subject" v-validate="'required|max:78'" />
      </div>
      <span v-show="errors.has('subject')" class="status-block error-block field-error">{{ errors.first('subject') }}</span>
    </div>

    <div class="form-row">
      <div class="form-label">
        <label>Message</label>
      </div>
      <div class="form-input-container" :class="{'has-error': errors.has('message') }">
        <textarea class="w-input" name="message" v-model="form.message" v-validate="'required|max:5000'" rows="8" />
        <span v-show="errors.has('message')" class="status-block error-block field-error">{{ errors.first('message') }}</span>
      </div>
    </div>

    <div class="form-row">
      <div class="form-label">
        <label>Recipients</label>
      </div>

      <div  v-for="contact in contacts">

        <div class="form-input-container" v-if="contact.email">
          <div class="checkbox">
            <input
              @click.stop=""
              type="checkbox"
              v-model="form.emails"
              :value="{contact_id: contact.id}"
              :id="'checkbox-email_' + contact.id + '_' + upload_id"
              :name="'checkbox-email_' + contact.id + '_' + upload_id"
              :data-name="'checkbox-email_' + contact.id + '_' + upload_id"
              class="w-checkbox-input">

            <label
              :for="'checkbox-email_' + contact.id + '_' + upload_id"
              class="w-form-label">Send email to {{contact.first}} {{contact.last}}
            </label>

          </div>

        </div>

        <div v-for="relation in contact.Relationships">
          <div class="form-input-container inset" v-if="relation.Contact.email">
            <div class="checkbox">
              <input
                type="checkbox"
                v-model="form.emails"
                :value="{contact_id: relation.Contact.id}"
                :id="'checkbox-email-relation_' + relation.Contact.id + '_' + upload_id"
                :name="'checkbox-email-relation_' + relation.Contact.id + '_' + upload_id"
                :data-name="'checkbox-email-relation_' + relation.Contact.id + '_' + upload_id"
                class="w-checkbox-input">

              <label
                :for="'checkbox-email-relation_' + relation.Contact.id + '_' + upload_id"
                class="w-form-label">
                Send email to {{relation.Contact.first}} {{relation.Contact.last}}<br />
                <span class="subdued" v-show="relation.is_emergency"> Emergency,</span>
                <span class="subdued" v-show="relation.is_cosigner">Cosigner, </span>
                <span class="subdued" v-show="relation.is_military">Military, </span>
                <span class="subdued" v-show="relation.is_lien_holder">Lien Holder, </span>
                <span class="subdued" v-show="relation.is_authorized">Authorized, </span>
              </label>

            </div>

          </div>
        </div>


      </div>

    </div>



    </div>
    </template>
        <template v-slot:actions>
            <hb-btn class="mr-2" color="primary" :disabled="isLoading($options.name)" @click="sendMessage">Send Message</hb-btn>
        <loader  v-show="isLoading($options.name)" color="#00b2ce" size="20px" class="inline-loader"></loader>
        </template>
    </hb-modal>
</template>


<script type="text/babel">
    import api from '../../assets/api.js';
    import Status from '../includes/Messages.vue';
    import Loader from '../assets/CircleSpinner.vue';
    import { EventBus } from '../../EventBus.js';
    export default {
        name: "SendDocument",
        data() {
            return {
                showWindow: false,
                form:{
                    message: '',
                    subject: '',
                    upload_id: '',
                    lease_id: '',
                    emails: []
                },
                contacts: []
            }
        },
        props: ['lease_id','upload_id','value'],
        components:{
            Loader,
            Status
        },
        created(){
            this.resetForm();
            this.getContacts();


        },
        computed:{
          dialog: {
                get () {
                    return this.value
                },
                set (value) {
                    this.$emit('input', value)
                }
            },
        },
        filters: {

            formatDate(date){
                return moment(date).format('MM/DD/YYYY');
            }


        },
        methods:{
            getContacts(){
                api.get(this, api.LEASES + this.lease_id + '/contacts').then(r => {
                    this.contacts = r.contacts;
                })
            },
            resetForm(){
                this.form = {
                    message: '',
                    upload_id: this.upload_id,
                    subject: '',
                    emails: [],
                    lease_id: this.lease_id
                }
            },
            sendMessage(){
                var data = this.form;
                data.upload_id = this.upload_id;

                if(this.form.emails.length < 1){
                  this.errorSet(this.$options.name, "Please choose a recipient for this message");
                  return;
                }

                this.validate(this).then(status => {
                  if(!status) return;
                  return api.post(this, api.LEASES + this.lease_id + '/send-message', data).then(r => {
                      this.resetForm();
                      this.successSet(this.$options.name, "Message Sent!");
                  })
                })
            }
        },
    }
</script>
<style>
  .form-input-container.inset {
    margin-left: 20px;
  }
</style>
