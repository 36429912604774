<template>
<div>
  <hb-modal v-model="dialog" size="medium" title="Discard Setup" show-help-link confirmation>
    <template v-slot:content>
        <div class="px-6 py-4">
            Are you sure you want to discard this setup?
        </div>
        <div class="px-6 pb-4">
            If you do, you will lose all the information you’ve entered. Continue the setup process to ensure Hummingbird saves your information.   
        </div>
    </template> 
    <template v-slot:actions>
        <hb-btn color="primary"  @click="$emit('close')">Discard</hb-btn>
    </template>
  </hb-modal>
</div>
</template>

<script type="text/babel">
  export default {
    name: "DiscardSetup",
    data() {
      return {};
    },
    props: {
      value:{
        type: Boolean,
        default: false
      },
    },
    computed: {
      dialog: {
          get () {
              return this.value
          },
          set (value) {
              this.$emit('input', value)
          }
      },
    }
  }

</script>

<style type="scss" scoped></style>