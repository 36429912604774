<template>
    <div class="searchbar-margin-top">
        <div class="facility-search--input only-one-property" style="background:#004247">
            <v-row class="white--text" style="margin-top: 2px; margin-left: 3px;" no-gutters>
                <div style="margin-right: 2px;">
                    <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
                </div>
                <div class="ml-3 hb-font-header-3">
                    <span>Loading Facilities</span>
                </div>
            </v-row>
            <div style="float:right;padding-top:2px;margin-top: 2px;">
                <hb-icon color="#ffffff">mdi-magnify</hb-icon>
            </div>
        </div>
    </div>
</template>

<script>
import Loader from '../../assets/CircleSpinner.vue';
export default {
    name:'FacilitySearchBarSkeleton',
    data() {
        return {
        }
    },
    components: {
        Loader,
    }
}
</script>
