<template>

  <div class="msgbox-area">

    <transition-group name="slide-fade">
    <v-card
      class="notification pb-0 mb-0"
      elevation="10"
      :color="colors[n.status]"
      @click="n.type === 'message_center' && handleEventClick(n)"
      :dark="n.status !== 'open' && (!isFormError(n) && !isFormSuccess(n))"
      v-for="(n, i) in notifications"
      :key="i"
    >
      <v-toolbar 
        :color="colors[n.status]"
        elevation="0"
        :class="{'mb-3 success-border': isFormSuccess(n) , 'mb-3 error-border': isFormError(n)  }"
        height="58"
      >
        <!-- updated icon component by BCT -->
        <div class="d-flex align-top">
          <hb-icon
            :color="icon_color[n.status]"
          class="mr-2"
          >{{n.icon}}</hb-icon>
          <v-toolbar-title class="store-notification-success-error-title" style="position:relative;bottom:1px;" :style="'color:' + (n.title_color ? n.title_color : '#101318')">{{n.title}}</v-toolbar-title>
        </div>
        <v-spacer></v-spacer>
        <span class="font-weight-medium hb-font-caption truncate" v-if="n.time && n.time != 'undefined'">{{n.time | formatLocalDateTimeDays}}</span>

        <hb-btn
          v-if="n.dismissible"
          class="mr-n1"
          icon
          tooltip="Close"
          @click="removeNotification(n.id)"
          active-state-off
          :color="(isFormSuccess(n) || isFormError(n)) ? '#637381' : icon_color[n.status]"
          hover-color="#101318"
          :hover-background-color="isFormSuccess(n) ? '#C5EDC9' : isFormError(n) ? '#EFCFCC' : '#DFE3E8'"
        >
          mdi-close
        </hb-btn>
      </v-toolbar>

      <div class="py-1">
        <div v-if="n.status == 'formError'">
            <v-card-text class="pa-0 pl-4 font-weight-regular" v-for="(e, index) in n.errors" :key="index">
              <span class="hb-text-night hb-font-caption" v-if="e.msg.includes('Issuer Rejection')" style="text-align: justify;">The card holders bank has rejected the transaction. Rejection reason below. Try another payment method or have the card holder contact their issuing bank to resolve the issue.</span>
              <span class="hb-text-night hb-font-caption" v-else-if="e.msg.includes('GatewayError')" style="text-align: justify;">An Error has occurred. The transaction was not successful. You can try the transaction again. If the issue persists, please report this to technical support by copying this error text or taking a screenshot.</span>
              <span class="hb-text-night hb-font-caption" v-else-if="index==0">There are errors in your form, correct them before continuing.</span>
            </v-card-text>
        </div>

        <v-card-text  v-if="n.subject && n.subject != 'undefined'" class="pt-0 pb-0 px-5 font-weight-medium hb-text-night truncate" color="white">
          {{n.subject}}
        </v-card-text>

        <v-card-text v-if="n.text" class="pt-0 px-5 font-weight-regular hb-text-night" :class="{'pb-0' : n.status == 'formError'}" color="white">
          <div class="line-clamp" v-if="n.type == 'message_center'">{{n.text}}</div>
          <div v-else :style="'color:' + n.text_color">{{n.text}}</div>
        </v-card-text>

        <v-card-text class="pt-0 px-5 font-weight-regular" color="white"  v-if="n.status == 'formError'">
          <ul style="list-style-type:disc;">
            <li
                v-for="(e, index) in n.errors"
                :key="index"
                class="pt-1 hb-text-night hb-font-caption"
                >
              {{e.msg}}
            </li>
          </ul>
        </v-card-text>
        <v-progress-linear
                           :active="n.loading"
                           :indeterminate="true"
                           color="primary"
                           height="2"
                           ></v-progress-linear>
        <v-card-text class="pt-2 px-5 font-weight-regular hb-text-night" v-if="n.validationText">
          {{n.validationText}}
        </v-card-text>
      </div>

      <v-divider v-if="!isFormSuccess(n) && !isFormError(n)"></v-divider>
      <v-card-actions style="background-color: white" v-if="n.action">
        <v-spacer></v-spacer>
        <!-- <v-btn
          v-if="n.type === 'link'"
          :to="n.action"
          small
          color="primary"
        >
            {{n.btn_text}}
        </v-btn> -->
        <hb-btn
          small
          color="secondary"
          @click="handleEventClick(n)"
        >
            {{n.btn_text}}
        </hb-btn>
      </v-card-actions>
    </v-card>

    </transition-group>

  </div>

</template>

<script>

    import { mapGetters, mapActions } from 'vuex';
    import { EventBus } from '../../EventBus.js';
    import moment from 'moment';

    export default {
        name: 'AssetsNotificationBanner',
        components: {},
        created(){},
        computed:{
            ...mapGetters({
                notifications: 'notificationsStore/notifications',
                // added by BCT
                links: "onboardingTabsStore/getOnboardingTabs",
                activeDrawerComponent: 'navigationStore/activeDrawerComponent'
            }),
            filteredNotifications() {
              if(this.notifications.length) {
                return this.notifications.filter(n => (n.status == 'formWarning' || n.status == 'formSuccess' || (n.status == 'formError' && n.errors.length > 0)));
              }
            }
        },
        filters: {
          formatLocalDateTimeDays(value){
            if (!value) return '';
            if (moment.utc(value).local().isSame(new Date(), "day")) {
              //check if date is today
              return `Today, ${moment.utc(value).local().format('h:mma')}`
            } else if (moment.utc(value).local().isSame(moment().utc().local().subtract(1, 'day').startOf('day'), 'day')) {
              //Check if Date was Yesterday
              return `Yesterday, ${moment.utc(value).local().format('h:mma')}`
            } else if (moment.utc(value).local().isAfter(moment().utc().local().subtract(7, 'day').startOf('day'))) {
              //Check if Date within Week
              return moment.utc(value).local().format('dddd, h:mma');
            }
            return moment.utc(value).local().format('MMM DD, YYYY h:mma')
          }
        },
        data: () => ({
            colors:{
               open: '#FFFFFF',
               success: 'primary',
                error: '#FB4C4C',
                formSuccess: '#e1fae3',
                formError: '#f8e5e1',
                formWarning: '#f8e5e1'
            },
            icon_color:{
                open: '#101318',
                success: '#FFFFFF',
                error: '#FFFFFF',
                formSuccess: '#02ad0f',
                formError: '#fb4c4c',
                formWarning: '#fb4c4c'
            }
        }),
        methods: {
          removeNotification(id){
            this.$store.dispatch('notificationsStore/getMessageCount');
              this.$store.commit('notificationsStore/removeNotification', id);

          },
          handleEventClick(notification){
            switch(notification.type){
              case 'document_download':
                var buffer = Buffer.from( notification.file.data);
                var blob = new Blob([buffer], {type: notification.content_type });
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download =  notification.filename;
                link.click();
                break;
              case 'link':
                this.$router.push(notification.action);
                this.removeNotification(notification.id);
                break;
              case 'show_reservation':
                //await this.$store.dispatch('onBoardingStore/getPropertyInfo', this.properties.find(p => p.id === r.Lease.Unit.property_id));

                var contact = this.contact;
                var data = {
                    contact_id: notification.payload.contact_id,
                    lead_first: notification.payload.first,
                    lead_last: notification.payload.last,
                    expires: notification.payload.expires,
                    lease_id: notification.payload.lease_id,
                    reservation_id: notification.payload.reservation_id,
                    reservation_time: notification.payload.reservation_time,
                    unit_id: notification.payload.unit_id
                }

                EventBus.$emit('HB-Navigation:LeadFollowUp', data);
                
                this.removeNotification(notification.id);

                break;
 
              // added by BCT
                case 'completed_corporate_setting':
                  if (this.$route.name !== 'property') {
                    this.$router.push('/onboarding/property')
                  }
                this.$store.dispatch('onboardingTabsStore/setActiveTab',this.links.property);
                this.removeNotification(notification.id);
                break;

                case 'share_report':
                this.removeNotification(notification.id);
                break;

                case 'completed_property_setting':
                  this.$router.push('/dashboard')
                  this.removeNotification(notification.id);
                break;

                case 'message_center':
                  let div_collection = document.getElementsByClassName('v-overlay v-overlay--active') || [];
                  if(!this.activeDrawerComponent.type && !this.activeDrawerComponent.rent_raise_drawer && div_collection.length === 0) {
                    this.removeNotification(notification.id);
                    EventBus.$emit('HB-Navigation:CommunicationCenter', notification);
                  }
              default:
                break;
            } 
          },
          isFormError(n) {
            return (n.status === 'formError' || n.status === 'formWarning') ? true : false;
          },
          isFormSuccess(n) {
            return (n.status === 'formSuccess') ? true : false;
          }
        }
    }

</script>

<style scoped>

  .line-clamp {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .notification{
    transition: opacity 300ms ease-in;
    -webkit-transition: opacity 300ms ease-in;
    margin: 0 0 15px;
    -webkit-box-align: center;
    align-items: center;
  }

  .msgbox-area {
    max-height: 100%;
    position: fixed;
    bottom: 15px;
    left: 20px;
    right: 20px;
    z-index: 999999;
  }

  .success-border{
    border-bottom: 2px solid #02ad0f !important;
  }

  .error-border {
    border-bottom: 2px solid #fb4c4c !important;
  }



  .msgbox-area .notification:last-child {
    margin: 0;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    .msgbox-area {
      left: 80px;
      right: 80px;
    }
  }
  @media (min-width: 768px) {
    .msgbox-area {
      width: 343px;
      height: 0;
      top: 88px;
      left: auto;
      right: 15px;
    }
  }
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .15s ease;
}
.slide-fade-leave-active {
  transition: all .15s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(20px);
  opacity: 0;
}

.store-notification-success-error-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

</style>
