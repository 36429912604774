<template>
    <hb-modal
        v-model="dialog"
        size="medium"
        title="Send Payment Link"
        @close="$emit('close')"
        show-help-link
    >
        <template v-slot:subheader>
            Send a link to the tenant so they can make payment.
        </template>
        <template v-slot:content>
            <div class="pa-6">
            
                <v-row class="ma-0 pa-0 " v-if="mobileNumber">
                    <v-col cols="9" class="mr-0 py-0">
                            <div>
                                <span class="label">Mobile Number</span>
                            </div>

                            <div>
                                <input 
                                    name="mobileNumber"
                                    type="text"
                                    readonly style="width:100%;"
                                    class="input-field" 
                                    v-model="mobileNumber" 
                                    v-mask="'(NNN) NNN-NNNN'"
                                    v-validate.immediate="'required'"
                                    :class="{'has-error': errors.has('mobileNumber') }"
                                >
                                <span v-show="errors.has('mobileNumber')" class="status-block error-block">{{ errors.first('mobileNumber') ? 'Mobile number is not available' : null }}</span>
                            </div>
                    </v-col>

                    <v-col cols="3" class="pt-4 pl-1">
                        <hb-btn color="primary" @click="$emit('sendText')">Send Text</hb-btn>
                    </v-col>
                </v-row>

                <v-row class="ma-0 pa-0" :class="mobileNumber ? '': 'mt-2'" v-if="email">
                    <v-col cols="9" class="mr-0 pt-0">
                        <div>
                            <span class="label">Email</span>
                        </div>

                        <div>
                            <input
                                name="email" 
                                type="text" 
                                readonly 
                                style="width:100%;" 
                                class="input-field" 
                                v-model="email"
                                v-validate.immediate="'required|email|'"
                                :class="{'has-error': errors.has('email') }" 
                            >
                            <span v-show="errors.has('email')" class="status-block error-block">{{ errors.first('email')}}</span>
                        </div>
                    </v-col>

                    <v-col cols="3" class="pt-4 pl-1">
                        <hb-btn color="primary" @click="$emit('sendEmail')">Send Email</hb-btn>
                    </v-col>
                </v-row>
            </div>
        </template>
    </hb-modal>
</template>

<script>

export default {
    name: 'SendInformation',
    data(){
        return {
        }
    },
    components:{
    },
    created(){
    },
    computed: {
        dialog: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            }
        },
    },
    props:['mobileNumber', 'email', 'value'],
}
</script>


<style scoped>
.description {
    color:#637381;
    font-weight: medium;
}
.input-field {
  height: 32px;
  color: #101318;
  border-bottom: 1px solid #C4CDD5;
  width: 275px;
  font-weight: 500;
}
.label {
  background-color: #F9FAFB;
  height: 48px;
  color: #637381;
  font-weight: medium;
}
</style>