<template>
  <v-row justify="center" align="center" v-if="loading">
    <v-col class="text-center ma-12">
        <v-progress-circular indeterminate color="primary" ></v-progress-circular>
    </v-col>
  </v-row>
  <div class="scrollable-content mb-10" v-else>
    <v-row class="mx-0">
      <v-col cols="12" class="align-end d-flex px-0">
        <span class="hb-header-font-size font-weight-medium ml-3">Customer Receipt</span>
      </v-col>
    </v-row>
    <view-payment-content
      v-if="payment.id"
      :isInterProperty="InterPropertyInvoices && InterPropertyInvoices.length ? true : false"
      :invoices="InterPropertyInvoices.length ? InterPropertyInvoices : invoices"
      :payment="payment"
      class="px-0"
    ></view-payment-content>
    <div class="new-tenant-walkthrough-footer">
      <v-row>
        <v-col class="pt-4 pl-0">
          <help-juice />
        </v-col>
        <v-col cols="9" class="d-flex align-center justify-end pr-sm-7">
          <hb-btn class="mr-2" color="secondary" @click="download" :disabled="buttonLoading" :loading="buttonLoading"
            >Print / Download Receipt</hb-btn
          >
          <hb-btn
            class="mr-2"
            color="secondary"
            :disabled="buttonLoading"
            @click="sendReceiptModal = true,$refs?.sendReceipt?.fetchDataOnEvent()">
            Send Receipt
          </hb-btn>
          <hb-btn
            class="mr-2"
            color="secondary"
            :disabled="buttonLoading"
            @click="$emit('makeAnotherPayment')"
            >Make Another Payment</hb-btn
          >
          <hb-btn class="mr-2" color="primary" @click="$emit('close')"
            >Finish and Close</hb-btn
          >
        </v-col>
      </v-row>
    </div>

    <hb-modal
      confirmation
      footerOff
      v-model="sendReceiptModal"
      size="medium"
      title="Send Receipt"
      title-icon="mdi-user-actions-custom-1"
      show-help-link
       @close="$refs.sendReceipt.closeModal()"
    >
      <template v-slot:content>
        <send-receipt :payment_id="payment.id"  ref="sendReceipt" :contact="contact" @close="sendReceiptModal = false"></send-receipt>
      </template>
    </hb-modal>
    
  </div>
</template>

<script type="text/babel">
import api from "../../../assets/api.js";
import ViewPaymentContent from "../../payments/ViewPaymentContent.vue";
import SendReceipt from "../../assets/SendReceipt.vue";
import { mapGetters } from "vuex";
import { EventBus } from "../../../EventBus.js";
import { notificationMixin } from  '../../../mixins/notificationMixin.js'; 
		 
export default {
  name: "PaymentReceipt",
  mixins: [ notificationMixin ],
  data() {
    return {
      payment: {},
      invoices: [],
      sendReceiptModal: false,
      loading: false,
      buttonLoading: false,
      InterPropertyInvoices: [],
      receipt: {
        phone: null,
        email: null
      }
    };
  },
  components: {
    ViewPaymentContent,
    SendReceipt
  },
  props: ["payment_id", "contact"],
  computed: {
    ...mapGetters({
      payments: "onBoardingStore/payments"
    }),
    /*
    formatPhoneLocal(){
      return this.receipt.phone ? this.$options.filters.formatPhone(this.receipt.phone) : null;
    }
    */
  },
  async created() {
    this.loading = true;
    await this.fetchData();
    this.loading = false;
  },
  methods: {
    callChildFunction(){
      console.log("hitttt");
      // this.$refs.sendReceiptRef.closeModal()
    },
    async download() {
      let url = api.REPORTS + "download-pdf";
      var data = {
        type: "receipt",
        format: "pdf",
        request_id: this.payment_id,
        browser_time: this.$options.filters.formatDateTimeCustom(this.payment.created, 'MMM DD, YYYY @ h:mma')
      };
      try {
        this.buttonLoading = true;
        let response = await api.post(this, url, data);
        const arr = new Uint8Array(response.data);
        var blob = new Blob([arr], { type: "application/pdf" });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        window.open(link.href, "_blank");
      } catch (err) {
        let r = { error: err };
        EventBus.$emit("paymentReader", r);
      } finally {
        this.buttonLoading = false;
      }
    },
    async fetchData() {
      let payment_id = this.payments.length
        ? this.payments[0].id
        : this.payment_id || null;
      if (!payment_id) return;
      let r = await api.get(this, api.PAYMENTS + payment_id + "/receipt");
      this.payment = r.payment;
      this.invoices = r.invoices;
      this.InterPropertyInvoices = r.InterPropertyInvoices;
    },
    /*
    sendText(){},
    async sendEmail(){
      let payment_id = this.payments.length
        ? this.payments[0].id
        : this.payment_id || null;
      if (!payment_id) return;
      var data = {
          email: this.receipt.email
      };

      try {
        let response = await api.post(this, api.PAYMENTS + payment_id + "/email", data);
        if(response && response.msg){ 
		  this.showMessageNotification({ id: this.$options.name, type: "success", description: response.msg });
        }
      } catch (err) {
        let r = { error: err };
        EventBus.$emit("paymentReader", r);
      }

    },
    */
  },
};
</script>

<style scoped>
.new-tenant-walkthrough-footer {
  padding: 3px 0 3px 30px;
  background: #ffffff;
  border-top: 1px solid #e2e2e2;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 100;
  left: 0;
  right: 0;
}
</style>
