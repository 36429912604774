import moment from 'moment';
import api from '../assets/api.js';


export const charges = {
	data() {
		return {
			taxRate: 0,
			rentProduct: {},
			securityProduct: {},
			bill_end: '',
		}
	},
	filters:{
		dayEnding(i){
			var days = [];
			var v;
			var s= ["th","st","nd","rd"];
			v=i%100;
			var label = i + (s[(v-20)%10]||s[v]||s[0]);
			return label;
		}
	},
	created(){
		this.bill_end = moment();
		this.getTaxRate();
		this.getRentProduct();
		this.getSecurityProduct();
	},

	computed:{
		generatedRows(){

			var lease = this.$store.getters.lease;
			var services = this.$store.getters.leaseServices;
			var insurance = this.$store.getters.leaseInsurance;
			var template = this.$store.getters.leaseTemplate;

			var lease_start = moment(lease.start_date).startOf('day');
			//var billing_start_date = lease_start.clone();

			var billing_start_date = this.getNextBillDate(lease_start.clone()).subtract(1,'month');

			if(this.$store.getters.billed_months > 0) {
				this.bill_end = this.getNextBillDate(moment()).add(this.$store.getters.billed_months, 'months').subtract(1, 'day');
			} else {
				this.bill_end = this.getNextBillDate(moment()).subtract(1,'day');
			}

			var lease_end = lease.type !== "Month to Month" && lease.end_date ?  moment(lease.end_date).startOf('day'): null;
			var billedProducts = [];
			var product = {
				type: "rent",
				label: "Rent",
				product_type: "rent",
				product_id: this.rentProduct.id,
				taxable: template.tax_rent,
				defaultPrice: lease.rent,
				showDetail : false,
				canDelete: false,
				recurring: true,
				lines:[],
			}

			var line = {};
			while (billing_start_date.format('x') <= this.bill_end.startOf('day').format('x') ){

				line = {
					start_date: billing_start_date.format('x') < lease_start.format('x')? lease_start.clone(): billing_start_date.clone(),
					end_date: this.getNextBillDate(billing_start_date.clone()).subtract(1, 'day'),
				}

				if(!template.prorate_rent) {
					line.amount = lease.rent;
				} else {
					line.amount = this.prorateAmt(lease.rent, billing_start_date.clone(), line.end_date.clone(), lease_start, lease_end);
				}


				product.lines.push(line);
				billing_start_date = this.getNextBillDate(billing_start_date.clone().add(1, 'day'));

			}

			billedProducts.push(product);

			if(lease.security_deposit > 0){
				product = {
					type: "security",
					label: "Security Deposit",
					product_type: "deposit",
					product_id: this.securityProduct.id,
					taxable: this.securityProduct.taxable,
					defaultPrice: lease.security_deposit,
					showDetail : false,
					canDelete: false,
					recurring: false,
					lines:[],
				}

				product.lines.push({
					start_date: billing_start_date.clone(),
					end_date: this.bill_end.clone(),
					amount: lease.security_deposit
				});

				billedProducts.push(product);
			}



			if(insurance.name){

				lease_start = moment(lease.start_date).startOf('day');
				billing_start_date = this.getNextBillDate(lease_start.clone()).subtract(1,'month');
				lease_end = lease.type !== "Month To Month" && lease.end_date != null ?  moment(lease.end_date).startOf('day'): null;

				product = {
					type: "insurance",
					qty: 1,
					product_type: "insurance",
					product_id: insurance.product_id,
					label: insurance.name,
					taxable: insurance.taxable,
					defaultPrice: 0,
					showDetail: true,
					canDelete: false,
					recurring: true,
					lines:[],
				}

				var insurance_amount = insurance.price;

				if(insurance.premium_type == '$') insurance_amount = insurance.premium_value;
				if(insurance.premium_type == '%') insurance_amount = Math.round(insurance.premium_value * lease.rent) / 1e2;


				var line = {};
				while (billing_start_date.startOf('day').format('x') <= this.bill_end.startOf('day').format('x') ){
					line = {
						start_date: billing_start_date.format('x') < lease_start.format('x')? lease_start.clone(): billing_start_date.clone(),
						end_date: this.getNextBillDate(billing_start_date.clone()).subtract(1, 'day'),
					};

					if(!insurance.prorate) {
						line.amount = insurance_amount;
					} else {
						line.amount = this.prorateAmt(insurance_amount, billing_start_date.clone(), line.end_date, lease_start, lease_end)
					}
					product.lines.push(line);
					billing_start_date = this.getNextBillDate(billing_start_date.add(1, 'day'))
				}
				billedProducts.push(product);
			}
			var product = {};
			if(!services) return [];




			services.forEach(s => {

				if(s.Product.default_type !== 'product' &&  s.Product.default_type !== 'late') return;

				lease_start = moment(lease.start_date).startOf('day');
				billing_start_date = this.getNextBillDate(lease_start.clone()).subtract(1,'month');
				lease_end = lease.type !== "Month To Month" && lease.end_date != null ?  moment(lease.end_date).startOf('day'): null;

				product = {
					type: 'service',
					product_type: s.Product.default_type,
					qty: s.qty,
					time: s.time,
					label: s.Product.name,
					product_id: s.Product.id,
					taxable: s.Product.taxable,
					defaultPrice: s.price,
					showDetail : false,
					canDelete: true,
					recurring: s.recurring,
					lines:[],
				}

				if(!s.recurring){
					product.lines.push({
						start_date: billing_start_date.format('x') < lease_start.format('x')? lease_start.clone(): billing_start_date.clone(),
						end_date: moment(),
						amount: s.price * s.qty
					})
					billedProducts.push(product);
					return;
				} else{

					// line.amount = this.prorateAmt(lease.rent, billing_start_date.clone(), line.end_date, lease_start, lease_end);

					var line = {};
					while (billing_start_date.startOf('day').format('x') <= this.bill_end.startOf('day').format('x') ){
						line = {
							start_date: billing_start_date.format('x') < lease_start.format('x')? lease_start.clone(): billing_start_date.clone(),
							end_date: this.getNextBillDate(billing_start_date.clone()).subtract(1, 'day'),

						};
						if(!s.prorate) {
							line.amount = s.price;
						} else {
							line.amount = this.prorateAmt(s.price, billing_start_date.clone(), line.end_date, lease_start, lease_end)
						}
						product.lines.push(line);
						billing_start_date = this.getNextBillDate(billing_start_date.add(1, 'day'))
					}

					billedProducts.push(product);

				}
			});

			return billedProducts;
		},
		subTotal(){
			var subtotal =  this.generatedRows.reduce((a,b)=> {
				return a + +b.lines.reduce((c,d)=> {

						return c + +d.amount;
					}, 0)
			}, 0);
			return Math.round(subtotal * 1e2) / 1e2;

		},
		taxSum(){

			var taxableAmt =  this.generatedRows.reduce((a,b)=> {
				return a + +b.lines.reduce((c,d)=> {
					return c + (b.taxable ? d.amount: 0);
					}, 0);
			}, 0);

			var preTaxPromotions = this.promotionDiscount(true);


			return ((taxableAmt - preTaxPromotions) * this.taxRate) / 1e2;
		},
		totalSum(){
			var total = this.subTotal + this.taxSum  - this.promotionDiscount();
			//var total = this.subTotal + this.taxSum - this.promotionDiscount();
			return (total > 0)? Math.round(total * 1e2) /1e2 : 0;
		},
	},

	methods:{
		promotionDiscount(pretax){
			var discount = 0;

			if(!this.$store.getters.leasePromotion) return 0;
			var promotion = this.$store.getters.leasePromotion;
			this.generatedRows.map( row => {

				if(row.type !== 'rent') return;

				var discountedLines = row.lines.slice(promotion.offset, promotion.offset + promotion.months);

				discount =  discountedLines.reduce((a,b) => {
					if(pretax && !promotion.pretax) return 0;

					switch(promotion.type){
						case 'percent':
							return a + +Math.round(b.amount * promotion.value) / 1e2;
							break;
						case 'dollar':
							return a + +Math.round(promotion.value * 1e2) / 1e2;
							break;
            case 'fixed':
              return a + +Math.round( (b.amount - promotion.value) * 1e2) / 1e2
              break;
					}
				}, 0);
			});
			return (discount * 1e2) / 1e2;
		},
		prorateAmt(amt, period_start, period_end, lease_start, lease_end ){


			var activeServiceStart = (lease_start > period_start)? lease_start: period_start;
			var activeServiceEnd = (!lease_end || lease_end > period_end)? period_end: lease_end;
			var totalDaysInBillingPeriod = period_end.diff(period_start, 'days') + 1; // this bills for the current day
			var totalDaysActiveService = activeServiceEnd.diff(activeServiceStart, 'days') + 1;
			var prorateMultiplier = totalDaysActiveService / totalDaysInBillingPeriod;
			prorateMultiplier = prorateMultiplier || 1;
			var proratedRent = Math.round((amt * prorateMultiplier)* 1e2) / 1e2;
			return (proratedRent > 0) ? proratedRent : 0;
		},
		getNextBillDate(date, billToday){

			var d = date || moment();
			var today = d.clone().format("D");

			var billday = this.$store.getters.lease.bill_day;
			if((parseInt(today) < parseInt(billday) ) || (billToday && parseInt(today) == parseInt(billday))   ){

				return d.date(billday).startOf('day');
			} else {
				return d.add(1, 'month').date(billday).startOf('day');
			}
		},
		proratedRent() {
			var lease_start = moment(this.$store.getters.lease.start_date, 'YYYY-MM-DD');
			var rent = this.$store.getters.lease.rent;


			var billing_cycle_end = this.getNextBillDate(lease_start).startOf('day').subtract(1, 'day');
			var billing_cycle_start = this.getNextBillDate(lease_start).startOf('day').subtract(1, 'Month');
			if(lease_start.isAfter(parseInt(billing_cycle_end), 'day')){
				return 0;
			}
			if(lease_start.isBefore(parseInt(billing_cycle_start), 'day')){
				return +rent;
			}
			var period_start = lease_start;
			var totalDaysInTimePeriod = billing_cycle_end.diff(billing_cycle_start, 'days') + 1;
			var totalDaysActiveService = billing_cycle_end.diff(period_start, 'days') + 1;
			var prorateMultiplier = totalDaysActiveService / totalDaysInTimePeriod;
			var proratedRent = Math.round((rent * prorateMultiplier)* 1e2) /1e2;
			return (proratedRent>0)? proratedRent : 0;
		},
		getTaxRate(){
			api.get(this, api.SETTINGS_BILLING ).then(r => {
				this.taxRate = r.settings.taxRate;
			});
		},
		getRentProduct(){
			api.get(this, api.PRODUCTS + 'rent').then(r => {
				this.rentProduct = r.product;
			});

		},
		getSecurityProduct(){
			api.get(this, api.PRODUCTS + 'security').then(r => {

				this.securityProduct = r.product;
			});

		},
	},
};
