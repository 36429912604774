<template>
    <hb-modal size="medium" title="Attention" v-model="dialog" confirmation @close="$emit('close')">
        <template v-slot:content>
            <div class="py-4 px-6">
                The information you provided is currently marked as {{ currentContactType == 'Business' ? 'a' : 'an' }} {{ currentContactType }} user. If you change the
                selection to {{ newContactType }}, the form will be updated accordingly.
                <br /><br />
                Click 'OK' to proceed with the {{ newContactType }} designation or 'Cancel' to maintain the current {{
                    currentContactType }} status.
            </div>
        </template>
        <template v-slot:right-actions>
            <hb-btn color="primary" @click="$emit('ok')">OK</hb-btn>
        </template>
    </hb-modal>
</template>
  
<script type="text/babel">
export default {
    name: 'RentAsBusiness',
    data: () => ({
    }),
    async created() {
    },
    computed: {
        dialog: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
    },
    props: ['currentContactType', 'newContactType','value'],
}
</script>
  
<style scoped></style>
  