<template>

    <v-expansion-panel class="new-tenant-form-data elevation-0" v-if="lease">
      <v-expansion-panel-header style="border-bottom: 1px solid #DFE3E8;">
        <strong>SPACE #{{ lease.Unit.number }}</strong>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="no-padding">
        <v-row no-gutters style="border-bottom: 1px solid #DFE3E8; margin-bottom: 0;">
          <v-col cols="5" class="form-label">
            <v-checkbox dense hide-details v-model="auto_debit" label="Enroll In AutoPay"></v-checkbox>
          </v-col>
          <v-col cols="7" class="invoice_display">

<!--            <v-row v-for="charge in generatedRows" :key="charge.id"  @click="showCharges(charge.product_id)"  no-gutters >-->
<!--              <v-col style="flex: 1">-->
<!--                <v-icon v-show="show_charge != charge.product_id">mdi-chevron-down</v-icon>-->
<!--                <v-icon v-show="show_charge == charge.product_id">mdi-chevron-up</v-icon>-->
<!--                {{charge.label}}</v-col>-->
<!--              <v-col style="flex: 0 1 100px" class="text-right">-->
<!--                <span v-show="charge.product_type === 'product'">{{charge.qty}}</span>-->
<!--              </v-col>-->
<!--              <v-col style="flex: 0 1 100px" class="text-right">{{charge.defaultPrice | formatMoney}}</v-col>-->
<!--              <v-col class="breakdown" v-show="show_charge === charge.product_id">-->
<!--                <v-row v-for="(line, i) in charge.lines" :key="'line_' + i" no-gutters>-->
<!--                  <v-col>{{line.start_date | formatDate}} - {{line.end_date | formatDate}}</v-col>-->
<!--                  <v-col class="text-right">{{line.amount | formatMoney}}</v-col>-->
<!--                </v-row>-->
<!--              </v-col>-->
<!--            </v-row>-->

          </v-col>
        </v-row>
<!--        <v-row style="padding: 0 20px">-->
<!--          <v-col cols="5" style="display:flex; align-items: center;">-->
<!--            <div style="margin-right: auto">-->
<!--              Prepay Additional-->
<!--            </div>-->
<!--            <div style="flex: 0 1 60px" class="text-right">-->
<!--              <v-text-field-->
<!--                hide-details-->
<!--                dense-->
<!--                v-validate="'integer|min_value:0'"-->
<!--                v-model.lazy="billed_months"-->
<!--                singleLine-->
<!--                outlined-->
<!--              ></v-text-field>-->
<!--            </div>-->
<!--            <div style="flex: 0 1 60px;" class="text-right">-->
<!--              Months-->
<!--            </div>-->
<!--          </v-col>-->
<!--          <v-col cols="7" style="padding: 20px;" class="text-right">-->
<!--            Total Due Today <span style="margin-left: 20px;"><strong>{{totalSum | formatMoney }}</strong></span>-->
<!--          </v-col>-->
<!--        </v-row>-->
      </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script type="text/babel">

    import api from '../../assets/api.js';
    import Status from '../includes/Messages.vue';
    import moment from 'moment';

    export default {
        name: "Charges",
        data() {
            return {
                panel: 0,
                auto_debit:false,
                prepay: null,
                show_charge: null,
            }
        },
        components:{
            Status,
        },
        props: ['lease'],
        created (){


        },
        filters:{
        },
        computed: {

        },
        methods: {

        },
        watch:{

        },
    }
</script>

<style scoped>

  .new-tenant-walkthrough-footer{
    padding: 3px 0 3px 30px;
    background: #FFFFFF;
    border-top: 1px solid #e2e2e2;
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 100;
    left: 0;
    right: 0;
  }
  .services-section{
    padding: 20px 50px 100px 50px;
  }

  .unit-confirm-icon .v-list-item__icon {
    text-align: center;
    background: white;
    padding: 25px;

  }

  .v-expansion-panel-content .form-label{
    background: #F4F6F8;
    padding: 20px;
    font-weight: bold;
  }
  .row {
    margin-bottom: 10px;
  }

  .payments-container{
    border: 1px solid #C4CDD5;
    background: white;
    border-radius: 4px;
    padding: 30px 50px;
  }
  .v-expansion-panel-content__wrap,
  .v-expansion-panel-content.no-padding .v-expansion-panel-content__wrap{
    padding: 0;
  }
  .v-expansion-panel-content__wrap {
    padding: 0px;
  }

  .invoice_display{
    padding: 20px;
    font-size: 14px;
  }
  .breakdown{
    display:inline-block;
    flex:0 0 100%;
    padding: 10px;
    width: 100%;
    background:#f2f9fd;
    border: 1px solid #dce8ef;
  }
</style>
