<!-- BCT: MINI-CHARM  -->
<template>
  <div v-if="isCharmNotificationToastOpen">
    <v-slide-x-reverse-transition>
      <hb-toast
        :addAsAdditionalContactButton="false"
        :active-call-id="''"
        :id="charmNotification.id"
        :type="charmNotificationType"
        :view="charmNotificationView"
        :count="charmNotificationCount"
        :title="charmNotificationTitle"
        :status="charmNotification.status"
        :loading.sync="charmNotification.loading"
        :contact-id="charmNotification.contact_id"
        :playback-url="charmNotification.playback_url"
        :contact-name="charmNotification.contact_name"
        :contact-email="charmNotification.contact_email"
        :contact-units="charmNotification.contact_units"
        :voicemail-url="charmNotification.voicemail_url"
        :source="communicationSource"
        :reservation-id="charmNotification.reservation_id"
        :contact-status="charmNotification.contact_status"
        :call-back-time="charmNotification.call_back_time"
        :contact-address="charmNotification.contact_address"
        :property="charmNotification.communication_property"
        :contact-unknown="charmNotification.contact_unknown"
        :reservation-code="charmNotification.reservation_code"
        :communication-type="charmNotification.communication_type"
        :selected-contact-id="charmNotification.selected_contact_id"
        :phone-number="charmNotification.phone_number | formatPhone"
        :contact-phone="charmNotification.contact_phone | formatPhone"
        :contact-access-codes="charmNotification.contact_access_codes"
        :switching-in-progress="charmNotification.switching_in_progress"
        :selected-contact-name="charmNotification.selected_contact_name"
        :selected-contact-email="charmNotification.selected_contact_email"
        :selected-contact-units="charmNotification.selected_contact_units"
        :selected-contact-status="charmNotification.selected_contact_status"
        :selected-contact-address="charmNotification.selected_contact_address"
        :reservation-in-store-price="charmNotification.reservation_in_store_price"
        :reservation-web-only-price="charmNotification.reservation_web_only_price"
        :selected-contact-phone="charmNotification.selected_contact_phone | formatPhone"
        :selected-contact-access-codes="charmNotification.selected_contact_access_codes"
        :reservation-space-information=" charmNotification.reservation_space_information"
        :add-as-additional-contact-button="!charmNotification.contact_added_to_selected_contact"
        :add-as-additional-phone-number-button="!charmNotification.phone_number_added_to_selected_contact "
        :reservation-move-in-date=" charmNotification.reservation_move_in_date | formatDateTimeCustom('MMM DD, YYYY') "
        :communication-time="charmNotification.communication_time | formatCharmDateTimeCustom('MMM DD, YYYY, hh:mma', 'hh:mma')"
        :profile-button-disabled="profileButtonDisabled"
        @transfer-button-clicked="handleActionPre(charmNotification, 'Transfer')"
        @move-out-button-clicked="handleActionPre(charmNotification, 'MoveOut')"
        @profile-button-clicked="handleActionPre(charmNotification, 'Profile')"
        @payment-button-clicked="handleActionPre(charmNotification, 'Payment')"
        @move-in-button-clicked="handleActionPre(charmNotification, 'MoveIn')"
        @add-as-additional-phone-number="addAsAdditionalPhoneNumber()"
        @lead-button-clicked="handleActionPre(charmNotification, 'Lead')"
        @update:view="changeToListView(charmNotification.status)"
        @add-as-additional-contact="addAsAdditionalContact()"
        @remove-selected-contact="removeContact()"
        @dismiss="readNotification(), handleDismiss($event)"
        @call-back-button-clicked="callBackClicked()"
        @close="handleClose($event)"
        @update:loading="updateLoading"
        class="charm-notifications-toast"
      >
        <template v-slot:listItems>
          <hb-toast-item
            v-show="
              listViewItems &&
                listViewItems.length &&
                (charmNotificationType == 'missed calls' ||
                  charmNotificationType == 'reservations' ||
                  charmNotificationType == 'voicemails')
            "
            v-for="(item, i) in listViewItems"
            :key="'toast-item-' + charmNotificationType + '-' + i"
            :id="item.id"
            :status="item.status"
            :contact-unknown="item.contact_unknown"
            :phone-number="item.phone_number"
            :contact-id="item.contact_id"
            :contact-name="item.contact_name"
            :contact-status="item.contact_status"
            :time="item.follow_up_time"
            @click="changeToDefaultView(item)"
          >
          </hb-toast-item>
        </template>
        <template v-slot:searchContacts>
          <call-toast-auto-search :contact_address ="charmNotification.contact_address"> </call-toast-auto-search>
        </template>
      </hb-toast>
    </v-slide-x-reverse-transition>
    <space-selector
      v-if="showSpaceSelector"
      :action="path"
      :contactId="contactId"
      :properties="properties"
      :tenantContact="tenantContact"
      :onModalClose="onSpaceSelectorClosed"
      :showSpaceSelector="showSpaceSelector"
      :propertyId="charmNotification.property_id"
      :isUnknownCaller="isUnknownCaller"
      :contactUnits="path == 'MoveIn' ? reservedContactUnits : leasedContactUnits"
      :isActiveLeadWithoutContactUnits="isActiveLeadWithoutContactUnits"
      :unknownCallerNumber="isUnknownCaller && charmNotification.phone_number || ''"
     >
    </space-selector>
  </div>
</template>

<script>
import { notificationMixin } from "@/mixins/notificationMixin.js";
import CallToastAutoSearch from './CallToastAutoSearch.vue';
import SpaceSelector from './SpaceSelectorModal.vue';
import { mapGetters, mapActions } from "vuex";
import { EventBus } from "../../EventBus.js";
import api from '../../assets/api.js';

export default {
  name: "CharmNotifications",
  data() {
    return {
      contactId:"",
      tenantContact:null,
      isSelectedContactFetched: false,
      showSpaceSelector:false,
      path:""
    };
  },
  components: {
    SpaceSelector,
    CallToastAutoSearch
  },
  mixins: [notificationMixin],
  computed: {
    ...mapGetters({
      charmSubscribedAndFilteredProperties: 'charmSubscriptionStore/charmSubscribedAndFilteredProperties',
      isCharmNotificationToastOpen: "charmNotificationStore/isCharmNotificationToastOpen",
      charmNotificationCount: "charmNotificationStore/charmNotificationCount",
      charmNotificationTitle: "charmNotificationStore/charmNotificationTitle",
      charmNotificationType: "charmNotificationStore/charmNotificationType",
      charmNotificationView: "charmNotificationStore/charmNotificationView",
      charmNotification: "charmNotificationStore/charmNotification",
      outgoingPhoneNumber: 'charmCallStore/outgoingPhoneNumber',
      searchedContact: "charmNotificationStore/searchedContact",
      listViewItems: "charmNotificationStore/listViewItems",
      loggedInUser: "authenticationStore/getUserData",
      properties: 'propertiesStore/filtered',
      searchedContactDetails:"charmNotificationStore/searchedContactDetails"
    }),
    isUnknownCaller(){
      if(this.searchedContact?.id){
        return false;
      }
      return this.charmNotification.contact_unknown;
    },
    communicationSource(){
      if(this.charmNotification.communication_source == "ppc_ad_campaign")
       return "Google Ad";
      return this.charmNotification.communication_source;
    },
    leasedContactUnits(){
      if(this.searchedContact?.id){
        return this.searchedContactDetails?.contact_units?.filter(unit => unit.status !== "reserved") || [];
      }
      if (this.charmNotification?.contact_units?.length) {
        return this.charmNotification?.contact_units?.filter(unit => unit.status !== "reserved") || [];
      }
      return [];
    },
    reservedContactUnits() {
      if(this.searchedContact?.id){
        return this.searchedContactDetails?.contact_units?.filter(unit => unit.status === "reserved") || [];
      }
      if (this.charmNotification?.contact_units?.length) {
        return this.charmNotification?.contact_units?.filter(unit => unit.status === "reserved") || [];
      }
      return [];
    },
    profileButtonDisabled() {
      if(this.charmNotification?.selected_contact_id){
        return !this.charmNotification?.selected_contact_status;
      }
      return !this.charmNotification.contact_status;
    }
  },

  methods: {
    ...mapActions({
      addAdditionalContactForNewLead: 'charmNotificationStore/addAdditionalContactForNewLead',
      readReservationNotifications: "charmNotificationStore/readReservationNotifications",
      readMissedCallsNotifications: "charmNotificationStore/readMissedCallsNotifications",
      showCharmNotificationToast: "charmNotificationStore/showCharmNotificationToast",
      readVoicemailNotifications: "charmNotificationStore/readVoicemailNotifications",
      removeSelectedContact: "charmNotificationStore/removeSelectedContact",
      stopLoadingAnimation: 'charmNotificationStore/stopLoadingAnimation',
      setToDefaultView: "charmNotificationStore/setToDefaultView",
      setCallBackTimer: "charmNotificationStore/setCallBackTimer",
      setCharmNotification: 'charmCallStore/setCharmNotification',
      addAdditionalContact: 'charmCallStore/addAdditionalContact',
      setLoadingAction: 'charmNotificationStore/setLoadingAction',
      setOutgoingCallData: "charmCallStore/setOutgoingCallData",
      showCharmCallToast: "charmCallStore/showCharmCallToast",
      addAdditionalPhone: 'charmCallStore/addAdditionalPhone',
      setToListView: "charmNotificationStore/setToListView",
      makeOutgoingCall: 'charmCallStore/makeOutgoingCall',
    }),
    async removeContact(){
      try{
       await this.removeSelectedContact();
       this.isSelectedContactFetched = false;
       this.tenantContact = null;
      }catch(error){
        this.showMessageNotification({ type: 'error', description: error });
      }
    },
    isActiveLeadWithoutContactUnits() {
      if(this.searchedContact?.id){
        return (['active', 'active lead', 'retired', 'retired lead'].includes(this.searchedContactDetails?.contact_status?.toLowerCase()) && 
          (!this.searchedContactDetails.contact_units || this.searchedContactDetails.contact_units.length === 0));
      }
     return (['active', 'active lead', 'retired', 'retired lead'].includes(this.charmNotification?.contact_status?.toLowerCase()) && 
          (!this.charmNotification.contact_units || this.charmNotification.contact_units.length === 0));
    },
    changeToDefaultView(item) {
      let notify = this.setToDefaultView({id: item.id, type: this.charmNotificationType,properties:this.charmSubscribedAndFilteredProperties});
      if (notify.type === 'error') {
        this.showMessageNotification({type: 'error', description: notify.msg});
      }
      setInterval(() => this.setCallBackTimer(), 1000);
    },

    changeToListView(status) {
      let title = "";
      switch (status) {
        case "reservation":
          title = "Reservations";
          break;
        case "missed":
          title = "Missed calls";
          break;
      }
      this.setToListView(title);
    },

    handleDismiss() {
      this.showCharmNotificationToast({ value: false });
    },

    handleClose() {
      this.showCharmNotificationToast({ value: false });
    },
    readNotification() {
      if (this.charmNotification.status == "reservation") {
        this.readReservationNotifications({ notification: this.charmNotification, properties: this.charmSubscribedAndFilteredProperties });
      } else if (this.charmNotification.status  == "missed") {
        this.readMissedCallsNotifications({ notification: this.charmNotification, properties: this.charmSubscribedAndFilteredProperties });
      } else if (this.charmNotification.status  == "voicemail") {
        this.readVoicemailNotifications({ notification: this.charmNotification, properties: this.charmSubscribedAndFilteredProperties });
      }
    },
    onSpaceSelectorClosed() {
      this.showSpaceSelector = false;
      this.path = "";
    },
    async setSelectSpaceModal() {
      if(this.searchedContact?.id && !this.isSelectedContactFetched){
            try{
             const { contact } = await api.get(this,`${api.CONTACTS}${this.searchedContact.id}`);
             this.tenantContact = contact;
             this.isSelectedContactFetched = true;
            }catch(error){
              this.showMessageNotification({ type: 'error', description: error });
            }
          }
      else{
        if(this.contactId && !this.tenantContact) {
          try {
          const { contact } = await api.get(this,`${api.CONTACTS}${this.contactId}`);
          this.tenantContact = contact;
          } catch (error) {
            this.showMessageNotification({ type: 'error', description: error });
          }
        }
      }
      this.showSpaceSelector = this.tenantContact ? true : false;
    },
    closeBlades(){
      EventBus.$emit("closeMoveOut");
      EventBus.$emit("closeTransfer");
      EventBus.$emit("closeMakePayment");
      EventBus.$emit("closeLeadIntake");
      EventBus.$emit("closeCreateTask");
    },
    async handleActionPre(callDetails, path){
      await this.closeBlades();
      if(this.searchedContact?.id){
        this.handleAction(this.searchedContactDetails,path); 
      }else{
        this.handleAction(callDetails,path);
      }
    },
    handleAction(callDetails, path) {
      this.path = path;
      this.callDetails = callDetails;
      let routePath = "";
      const { contact_id } = callDetails;
      this.contactId = contact_id;
      switch (path) {
        case 'Lead':
         this.openLead();
         break;
        case 'Profile':
         routePath = `/contacts/${this.contactId}`;
         if (this.$route.path !== routePath) {
            this.$router.push(routePath);
         }else {
            this.setLoadingAction({"status": false, "eventStatus": 'profile-button-clicked'});
          }
        break;
        case "Payment":
          EventBus.$emit("make_payment", { property_id: this.charmNotification.property_id, contact_id: this.contactId });
        break;
        default:
          this.setSelectSpaceModal();
          break;
      }
    },
    openLead(){
      if(this.contactId){
      this.$store.dispatch('onBoardingStore/getContactInfo', {contact_id: this.contactId});
      let data={
          'charm_property_id':this.charmNotification.property_id
        }
      EventBus.$emit('new_lead',data);
      }
    },
   
    async callBackClicked(){
      this.readNotification();
      this.showCharmNotificationToast({ value: false });
      this.setCharmNotification(this.charmNotification);
      this.showCharmCallToast(true);
      await this.setOutgoingCallData();
      if (this.outgoingPhoneNumber && this.charmNotification?.property_id) {
        let data = {
          To: this.outgoingPhoneNumber,
          agent_id: this.loggedInUser?.id,
          property_id: this.charmNotification?.property_id
        }
        this.makeOutgoingCall(data);
      }
    },
    async addAsAdditionalContact() {
      let payload = {
        layout: 'charmNotification',
        caller: this.charmNotification,
        searchedContact: this.searchedContact
      };
      let notify = this.charmNotification?.contact_name === 'New Lead' ? await this.addAdditionalContactForNewLead(payload) : await this.addAdditionalContact(payload);
      this.showMessageNotification({ type: notify.type, description: notify.msg });
    },
    async addAsAdditionalPhoneNumber() {
      let payload = {
        layout: 'charmNotification',
        caller: this.charmNotification,
        searchedContact: this.searchedContact,
        formatted_phone_number: this.$options.filters.formatPhone(this.charmNotification.phone_number)
      };
      let notify = await this.addAdditionalPhone(payload);
      this.showMessageNotification({ type: notify.type, description: notify.msg });
    },
    updateLoading(event) {
      this.setLoadingAction({"status": true, "eventStatus": event});
    },
  },
  watch: {
    'charmNotification.loading'() {
      if (this.charmNotification.loading) {
        setTimeout(() => {
          this.stopLoadingAnimation()
        }, 15000);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.charm-notifications-toast {
  height: min-content !important;
}
</style>
