<template>
    <div class="pt-3">
        <v-row>
            <v-col md="5" class="pr-0">
                <v-text-field
                    label="Security Deposit amount to be refunded to the tenant"
                    v-model="refund_amount"
                    v-validate="'required|decimal:2|min_value:0.00|max_value:'+security_deposit"
                    :name="'refund_'"
                    :id="'refund_'"
                    data-vv-scope="payments"
                    data-vv-as="Amount"
                    :hide-details="!errors.collect('payments.refund_').length"
                    :error-messages="errors.collect('payments.refund_')"
                    prefix="$"
                >
                </v-text-field>
            </v-col>
            <v-col md="7">
                <div class="pt-5">( max {{security_deposit | formatMoney}})</div>
            </v-col>
        </v-row>

        <v-row class="pt-2 pb-3 mrl--24px">
            <v-divider></v-divider>
        </v-row>

        <div class="ref-amount">
            <div class="font-big">Refund Amount will be issued to the tenant.</div>
        </div>

        <v-row class="pt-4 pb-3 mrl--24px">
            <v-divider></v-divider>
        </v-row>

        <v-row>
            <v-col cols="8" class="pa-0 mt-n3">
            </v-col>
            <v-col class="text-right">
                <hb-btn :disabled="refund_amount <= 0 || isLoading('refund')" @click="processRefund" color="primary" >Process Refund</hb-btn>
                <span v-show="isLoading('refund')">
                    <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
                </span>
            </v-col>
        </v-row>

    </div>
</template>

<script>
    
    import Status from '../../../includes/Messages.vue'
    import Loader from '../../../assets/CircleSpinner.vue'
    import moment from 'moment';
    import api from '../../../../assets/api.js';
    import { notificationMixin } from  '../../../../mixins/notificationMixin.js';
    
    export default {
        name: "SecurityDepositRefund",
        props: ['security_deposit', 'lease_id'],
        components: { Status, Loader },
        mixins: [ notificationMixin ],
        data() {
            return {
                refund_amount : 0
            }
        },
        created() {
        },
        methods: {
            processRefund(){

                this.$validator.validateAll().then((status) => {
                    if(!status) throw "error";
                    
                    let data = {
                        amount: this.refund_amount,
                    }

                    api.post(this, api.LEASES + this.lease_id + '/security-deposit/refund', data).then(r => {
                        this.showMessageNotification({ type: 'success', description: "Security Deposit Refund Processed Successfully" });
                        this.$emit('fetchSecurityDeposit');
                        this.$emit('securityRefunded',true);
                        this.refund_amount = 0;
                    });
                })

            }
        }
    }
</script>

<style scoped>
    .font-big{
        font-size: 15px;
        font-weight: 500;
    }
    .mrl--24px{
        margin-left: -24px;
        margin-right: -24px;
    }
    .btn-update-req{
        background: linear-gradient(180deg, #FFFFFF 0%, #F9FAFB 100%);
        border: 1px solid #C4CDD5;
        box-sizing: border-box;
        box-shadow: 0px 1px 0px rgba(22, 29, 37, 0.05);
        border-radius: 4px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #101318;
    }
    .approve-ref-btn{
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: #919EAB;
        background: #F9FAFB;
        border: 1px solid #DFE3E8;
        box-sizing: border-box;
        border-radius: 4px;
    }
    .font-15px{
        font-size: 15px;
    }
    .width-25px{
        width:25px;
    }
</style>