<template>
    <v-row class="ma-0">
      <v-col md="3" class="text-center px-2 pl-0">
        <div height="100%" width="100%" class="move-out-btn px-2 pt-5 pb-3">
          <v-icon size="5rem" color="#00848e">mdi-file</v-icon>
          <p class="mb-0">Intent To Move-Out</p>
        </div>
      </v-col>

      <v-col md="3" class="text-center px-2">
        <div height="100%" width="100%" class="move-out-btn px-2 pt-5 pb-3">
          <span class="relative-box d-inline-block">
            <span class="counter-absolute">12</span>
            <svg width="5rem" height="4.7rem" viewBox="0 0 40 38" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.4393 25.5998L4.4393 37.5998L0.219299 33.2798L12.2193 21.2798L16.4393 25.5998ZM8.9993 15.1998C9.7793 15.1998 10.6193 15.0998 11.2793 14.8198L34.0593 37.4998L38.2793 33.2798L23.4593 18.4798L26.9993 14.9198L28.4393 16.3198L31.3393 13.4998V19.2198L32.7393 20.6198L39.7793 13.4998L38.3793 12.0998H32.7593L35.5593 9.27979L28.4393 2.15979C26.0993 -0.180215 22.3393 -0.180215 19.9993 2.15979L24.2193 6.47978L21.3993 9.27979L22.8193 10.6998L19.2393 14.2598L15.5993 10.4998C15.8793 9.83979 15.9993 8.99979 15.9993 8.25979C15.9993 4.31979 12.8793 1.21979 8.9993 1.21979C7.8193 1.21979 6.7793 1.49979 5.8393 2.05979L11.1793 7.39979L8.1793 10.3998L2.8393 5.05979C2.2793 5.99979 1.9993 6.99979 1.9993 8.25979C1.9993 12.0998 5.0993 15.1998 8.9993 15.1998Z" fill="#637381"/>
            </svg>
          </span>
          <p class="mb-0">Maintenance Tickets</p>
        </div>
      </v-col>

      <v-col md="3" class="text-center px-2">
        <div height="100%" width="100%" class="move-out-btn px-2 pt-5 pb-3">
          <svg width="5rem" height="4.7rem" viewBox="0 0 40 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.4393 25.5998L4.4393 37.5998L0.219299 33.2798L12.2193 21.2798L16.4393 25.5998ZM8.9993 15.1998C9.7793 15.1998 10.6193 15.0998 11.2793 14.8198L34.0593 37.4998L38.2793 33.2798L23.4593 18.4798L26.9993 14.9198L28.4393 16.3198L31.3393 13.4998V19.2198L32.7393 20.6198L39.7793 13.4998L38.3793 12.0998H32.7593L35.5593 9.27979L28.4393 2.15979C26.0993 -0.180215 22.3393 -0.180215 19.9993 2.15979L24.2193 6.47978L21.3993 9.27979L22.8193 10.6998L19.2393 14.2598L15.5993 10.4998C15.8793 9.83979 15.9993 8.99979 15.9993 8.25979C15.9993 4.31979 12.8793 1.21979 8.9993 1.21979C7.8193 1.21979 6.7793 1.49979 5.8393 2.05979L11.1793 7.39979L8.1793 10.3998L2.8393 5.05979C2.2793 5.99979 1.9993 6.99979 1.9993 8.25979C1.9993 12.0998 5.0993 15.1998 8.9993 15.1998Z" fill="#637381"/>
          </svg>
          <p class="mb-0">Invoice</p>
        </div>
      </v-col>

      <v-col md="3" class="text-center px-2 pr-0">
        <div height="100%" width="100%" class="move-out-btn px-2 pt-5 pb-3">
          <span class="relative-box d-inline-block">
            <span class="counter-absolute">8</span>
            <v-icon size="5rem" color="#00848e">mdi-panorama</v-icon>
          </span>
          <p class="mb-0">Photos</p>
        </div>
      </v-col>
    </v-row>
</template>

<script>
export default {
    name: "Buttons"
}
</script>

<style scoped lang="scss">

  .intent-section{
    padding: 20px 50px 75px 50px;
  }

  .intent-section .new-tenant-walkthrough-footer{
    padding: 3px 0 3px 30px;
    background: #FFFFFF;
    border-top: 1px solid #e2e2e2;
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 100;
    left: 0;
    right: 0;
  }
  .light-text{
    color: rgba(0, 0, 0, 0.6);
  }
  .panel-header-custom-setting{
    background-color: rgb(251, 228, 224);
    color: rgba(0, 0, 0, 1);
  }
  .move-out-btn{
    border-radius: 4px;
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(0,132,142,.05) 100%);
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);

    p {
      color: rgb(0, 132, 142);
      font-size: 15px;
      font-weight: 500;
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
  .relative-box{
    position: relative;

    span.counter-absolute{
      position: absolute;
      right: -20px;
      background: #F26500;
      color: #fff;
      font-weight: 500;
      padding: 5px 11px;
      border-radius: 50%;
      border: 5px solid #fff;
      top: -10px;
      z-index: 1;
    }
  }
  .move-up-text{
    position: relative;
    top: -4px;
  }
  
</style>