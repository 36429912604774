<template>
    <v-dialog v-model="showDialog" max-width="350">
        <v-card>
                <v-card-title>
                    <v-row class="ma-0">
                        <v-col cols="10 pa-0" >
                            <span> <v-icon @click="closeWindow()">mdi-calendar</v-icon> Date Range</span>
                        </v-col>
                        <v-col cols="2" class="pa-0 text-right">
                            <v-icon @click="closeWindow()">mdi-close</v-icon>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="pb-0 px-0">
                    <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>                    
                    <v-row class="hb-table-row">
                        <v-col class="hb-table-label py-4 pl-6" cols="4">
                            Start Date
                        </v-col>
                        <v-col cols="8" class="hb-table-value pa-0 px-3 py-2">
                            <hb-date-picker
                                @click:clear="start_date = ''"
                                label="MM/DD/YYYY"
                                clearable
                                dense
                                id="dob"
                                data-vv-name="dob"
                                data-vv-scope="general"
                                name="dob"
                                data-vv-as="date of birth"
                                v-model="start_date">
                            </hb-date-picker>
                        </v-col>
                    </v-row>

                    <v-row class="hb-table-row">
                        <v-col class="hb-table-label py-4 pl-6" cols="4">
                            End Date
                        </v-col>
                        <v-col cols="8" class="hb-table-value pa-0 px-3 py-2">
                            <hb-date-picker
                                @click:clear="end_date = ''"
                                label="MM/DD/YYYY"
                                clearable
                                dense
                                id="dob"
                                data-vv-name="dob"
                                data-vv-scope="general"
                                name="dob"
                                data-vv-as="date of birth"
                                v-model="end_date">
                            </hb-date-picker>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="pa-2 text-right">
                    <v-col class="pt-1 pb-1">
                        <a class="close-link" @click="closeWindow">Cancel</a>
                        <v-btn color="primary" :disabled="isLoading($options.name)" large @click="save">Apply</v-btn>
                        <span v-show="isLoading($options.name)">
                            <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
                        </span>
                    </v-col>
                </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

    import Status from '../../includes/Messages.vue';
    import HbDatePicker from '../../assets/HummingbirdDatepicker.vue';
    import Loader from '../../assets/CircleSpinner.vue';


    export default {
        name: "DateRange",
        components: {
            Status,
            HbDatePicker,
            Loader
        },
        data() {
            return {
                showDialog: true,
                start_date: '',
                end_date: ''
            }
        },
        methods: {
            closeWindow(){
                this.showDialog = false;
                setTimeout(() => {
                    this.$emit('close');
                }, 220);
            },
            save(){

            }
        },
    }
</script>