<template>
  <div>
    <v-expansion-panels v-model="panel" multiple>
      <hb-expansion-panel :topMargin="false">
        <template v-slot:title>Default COA</template>
        <template v-slot:content>
          <div class="px-2 pb-13 default-coa-wrapper">
            <v-row class="pa-0 ma-0">
              <v-col cols="12" class="pt-1">
                <hb-data-table-header>
                  <template v-slot:description>
                    Set Default Chart of Accounts
                  </template>
                  <template v-slot:actions v-if="!editCOA">
                    <hb-btn small color="secondary" @click="editCOA = true">Edit Default COA</hb-btn>
                  </template>
                </hb-data-table-header>
                <hb-data-table :headers="headers" :items="defaultCOAData">
                  <template v-slot:item.subtype="{ item }">{{
                    item.gl_subtype_name
                  }}<span v-if="isCOARequired(item.gl_subtype_key)">*</span></template>
                  <template v-slot:item.code="{ item }">
                    <span v-if="editCOA">
                      <v-autocomplete
                        :items="getChartOfAccountsByCategory(item.gl_category_name)"
                        hide-details
                        placeholder="Choose Account"
                        item-value="id"
                        item-text="name"
                        class="pa-0"
                        :id="`${item.gl_subtype_key}`"
                        :name="`${item.gl_subtype_name}`"
                        data-vv-scope="default_coa"
                        v-model="item.gl_account_id"
                        v-validate.disable="isCOARequired(item.gl_subtype_key) ? 'required' : ''"
                        :error-messages="errors.collect('default_coa.'+item.gl_subtype_name)"
                        single-line
                      >
                        <template v-slot:selection="{ item }">
                          <span>{{ item.code }} : {{ item.name }}</span>
                        </template>
                        <template v-slot:item="{ item }">
                          <span>{{ item.code }} : {{ item.name }}</span>
                        </template>
                      </v-autocomplete>
                    </span>
                    <span v-else-if="item.gl_account_code"
                      >{{ item.gl_account_code }} :
                      {{ item.gl_account_name }}</span
                    >
                    <span v-else>--</span>
                  </template>
                  <template v-slot:body.append v-if="editCOA">
                    <tr>
                      <td />
                      <td class="pa-0 ma-0">
                        <v-row class="ma-0 pa-0 float-right">
                          <div
                            class="mx-4 my-2 d-flex justify-center align-center"
                          >
                            <hb-link class="mr-3" @click="cancelDefaultCOA"
                              >Cancel</hb-link
                            >
                            <hb-btn color="primary" @click="saveDefaultCOA"
                              >Save</hb-btn
                            >
                          </div>
                        </v-row>
                      </td>
                    </tr>
                  </template>
                </hb-data-table>
              </v-col>
            </v-row>
          </div>
        </template>
      </hb-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script type="text/babel">
import { ACCOUNTING } from "@/constants/accounting.js";
import { mapActions, mapGetters } from "vuex";
import api from "../../../assets/api.js";
export default {
  name: "DefaultCOA",
  data() {
    return {
      panel: [0],
      headers: [
        { text: "Default Subtype", value: "subtype" },
        { text: "Default Account", value: "code", width: 200 },
      ],
      editCOA: false,
      defaultCOAData: [],
    };
  },
  async created() {
    this.ACCOUNTING = ACCOUNTING;
    if(this.selectedTemplate?.id && !this.selectedTemplate?.DefaultSubTypeAccounts) {
      await this.getDefaultCOA({
        id: this.selectedTemplate.id,
      });
      this.defaultCOAData = JSON.parse(JSON.stringify(this.defaultCOA));
    }
    if(!this.chartOfAccounts?.length) {
      await this.getChartOfAccounts();
    }
  },
  computed: {
    ...mapGetters({
      defaultCOA: "accountingStore/defaultCOA",
      chartOfAccounts: "accountingStore/chartOfAccounts",
      selectedTemplate: "accountingStore/selectedTemplate",
    }),
    isEditAccountingTemplate() {
      return this.selectedTemplate && this.selectedTemplate.id ? true : false;
    },
  },
  methods: {
    ...mapActions({
      getDefaultCOA: "accountingStore/getDefaultCOA",
      getChartOfAccounts: "accountingStore/getChartOfAccounts",
    }),
    getChartOfAccountsByCategory(category) {
      return this.chartOfAccounts.filter(i => i.category == category);
    },
    isCOARequired(subtype) {
      return this.ACCOUNTING?.requiredDefaultCOATypes.indexOf(subtype) == -1 ? false : true;
    },
    async cancelDefaultCOA() {
      this.defaultCOAData = JSON.parse(JSON.stringify(this.defaultCOA));
      this.editCOA = false;
    },
    setEditMode() {
      this.editCOA = true;
    },
    async saveDefaultCOA() {
      const valid = await this.$validator.validateAll("default_coa");
      if(!valid) return;
      let formData = {
        default_subtype_accounts: this.defaultCOAData,
      };
      await api.put(
        this,
        api.ACCOUNTING + "/template/" + this.selectedTemplate.id + "/default-subtypes",
        formData
      );
      await this.getDefaultCOA({
        id: this.selectedTemplate.id,
      });
      this.defaultCOAData = JSON.parse(JSON.stringify(this.defaultCOA));
      this.editCOA = false;
    },
  },
  watch: {
    async "selectedTemplate"(val) {
      if(val?.DefaultSubTypeAccounts?.length) {
        this.defaultCOAData = JSON.parse(JSON.stringify(val.DefaultSubTypeAccounts));
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.default-coa-wrapper {
  .v-input {
    ::v-deep .v-select__slot {
      font-size: 12px !important;
    }
    ::v-deep .v-select__slot .v-select__selections > input {
      font-size: 12px !important;
    }
  }
}
</style>
