<template>
    <hb-modal
        size="large"
        title="Retire Lead"
        v-model="dialog"
        show-help-link
    >
        <template v-slot:subheader>
            Would you like to retire this lead?
        </template>

        <template v-slot:content>
            <hb-form label="Reason" required>
                <hb-select
                    v-model="lead.reason"
                    :items="reasons"
                    placeholder="Select Reason"
                    v-validate="'required'"
                    id="lead_reason"
                    name="lead_reason"
                    data-vv-as="Reason"
                    :clearable="false"
                    :error="errors.has('lead_reason')"
                >
                </hb-select>
            </hb-form>

            <hb-form label="Email">
                {{contact.email}}
            </hb-form>

            <hb-form label="Phone">
                <div v-if="contact.Phones && contact.Phones.length">
                  {{contact.Phones[0].phone | formatPhone}}
                </div>
            </hb-form>

            <hb-form label="Name">
                {{contact.first}} {{contact.last}}
            </hb-form>

            <hb-form label="Opt-Out">
                <hb-checkbox
                    label="Remove tenant from all future communication."
                    v-model="lead.opt_out"
                    id="lead_OptOut"
                    name="lead_OptOut"
                    data-vv-as="opt"
                ></hb-checkbox>
            </hb-form>

            <hb-form label="Notes for Retiring" full last required>
                <hb-textarea
                    v-model="lead.notes"
                    v-validate="'max:1000|required'"
                    placeholder="Why are you retiring this lead"
                    id="lead_notes"
                    name="lead_notes"
                    data-vv-as="Notes"
                    :error="errors.has('lead_notes')"
                >
                </hb-textarea>
            </hb-form>

        </template>

        <template v-slot:actions>
            <hb-btn v-if="hasPermission('retire_lead')" color="primary" :disabled="isLoading($options.name)" :loading="isLoading($options.name)" @click="save">Retire Lead</hb-btn>
        </template>
    </hb-modal>
</template>

<script>
    import api from '../../assets/api.js';
    import { EventBus } from '../../EventBus.js';
	import { notificationMixin } from  '../../mixins/notificationMixin.js';
    import { mapGetters } from 'vuex';
    import LEASE from '@/constants/lease.js'; 

    export default {
        name: "RetireLead",
		mixins: [ notificationMixin ],
        data () {
            return {
                showDialog: true,
                reasons: [],
                lead : {
                    reason : "Can't afford storage",
                    opt_out: false,
                    notes  : ''
                }
            }
        },
        props:['contact', 'value', 'lead_id'],
        computed:{
            dialog: {
                get () {
                    return this.value
                }, 
                set (value) {
                    this.$emit('input', value)
                },
            },
            ...mapGetters({
                hasPermission: 'authenticationStore/rolePermission',          
            }),
        },
        methods: {
            async save(){

                let status = await this.validate(this);
                if(!status){
					this.showMessageNotification({ type: 'error', description: 'There are errors in your form, correct them before continuing.', list: this.errors.items });
                    return;
                } 

                await api.put(this, api.LEADS + this.lead_id + '/retire', this.lead);


                EventBus.$emit('lead_saved');
                EventBus.$emit('reset_profile_tasks');
                this.$emit('retired');

            },
            closeWindow(){
                this.showDialog = false;
                setTimeout(() => {
                    this.$emit('close');
                }, 200);
            },
            getRetireReasons(){
                api.get(this, api.LEADS + 'retire-reasons').then(r => {
                    // this.retireReasons = r;
                    this.reasons = r
                });
            }

        },
        created() {
             this.getRetireReasons()
        },
        
    }
</script>

<style scoped>
    .mrl--25px{
        margin-left: -25px;
        margin-right: -24px;
    }
    .key-label{
      background: #F4F6F8;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      color: #101318;
    }
    .top-border{
        border-top: 1px solid #DFE3E8;
    }
</style>
