<template>
    <v-card id="facility-property-crud" v-if="property" class="hb-card">
        <span class="icon-close" @click="$emit('update:property', null)">X</span>
        <v-form>
            <v-container>
                <v-row>
                    <h1>{{ property.name}}</h1>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                            v-model="property.name"
                            label="Name"
                            readonly
                        />
                        <v-textarea
                            v-model="property.description"
                            label="Description"
                            readonly
                        />
                    </v-col>
                    <v-col>
                        <v-text-field
                            v-model="property.unit_count"
                            label="Space Count"
                            readonly
                        />
                        <v-text-field
                            v-model="property.lease_count"
                            label="Lease Count"
                            readonly
                        />
                    </v-col>
                </v-row>
                <v-row>

                </v-row>
            </v-container>
        </v-form>
    </v-card>
</template>
<script>
export default {
    data() {
        return {

        }
    },
    props: [
        'property'
    ]
}
</script>
<style lang="scss">
#facility-property-crud {
    position: relative;
    top: 15px;
    .icon-close {
        text-align: right;
    }
}
</style>