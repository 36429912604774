<template>
  <div v-if="this.contactUnits && this.contactUnits.length > 1">
    <hb-modal
      size="medium"
      title="Select a space"
      v-model="showModal"
      @close="closeModal()"
      show-help-link
    >
      <template v-slot:content>
        <v-row class="pa-0 ma-0">
          <v-col>
            <hb-select
              :items="contactUnits"
              v-model="selected_space"
              label="Select a space"
              dense
              return-object
              v-validate="'required'"
              data-vv-scope="refund"
              hide-details="auto"
              class="py-5 px-2"
              full
            >
              <template v-slot:item="{ item }">
                <span class="text-capitalize">
                  <div
                    class="hb-toast-overflow-handler"
                    :style="item.type !== 'storage' ? 'padding-top:2px;' : ''"
                  >
                    {{ item.number ? "#" + item.number : ""
                    }}<span class="px-1" v-if="item.number && item.address"
                      >|</span
                    >{{ item.address }}
                  </div></span
                >
              </template>
              <template v-slot:selection="{ item }">
                <span class="text-capitalize">
                  <div
                    class="hb-toast-overflow-handler"
                    :style="item.type !== 'storage' ? 'padding-top:2px;' : ''"
                  >
                    {{ item.number ? "#" + item.number : ""
                    }}<span class="px-1" v-if="item.number && item.address"
                      >|</span
                    >{{ item.address }}
                  </div></span
                >
              </template>
            </hb-select>
          </v-col>
        </v-row>
      </template>
      <template v-slot:right-actions>
        <hb-btn
          color="primary"
          @click="voidConfirm"
          :disabled="selected_space == null"
          >Confirm</hb-btn
        >
      </template>
    </hb-modal>
  </div>
</template>

<script type="text/babel">
import api from '../../assets/api.js'
import { EventBus } from "../../EventBus.js";

export default {
  name: "SpaceSelector",
  data() {
    return {
      selected_space: null,
      showModal: false,
      reservation: {},
      has_reservation: false,
    };
  },
  created() {
    this.showModal = this.showSpaceSelector;
    if (this.contactUnits && this.contactUnits.length === 1) {
          this.selected_space = this.contactUnits[0];
          this.voidConfirm();
    }else if(!this.contactUnits || this.contactUnits.length === 0){
          this.selected_space = null;
          this.voidConfirm(true);
    }
  },
  props: {
    contactUnits: {
      type: Array,
      default: null
    },
    showSpaceSelector: {
      type: Boolean,
      default: false
    },
    action: {
      type: String,
      default: ""
    },
    contactId: {
      type: String,
      default: ""
    },
    propertyId: {
      type: String,
      default: ""
    },
    tenantContact: {
      type: Object,
      default: null
    },
    onModalClose: {
      type: Function
    },
    isActiveLeadWithoutContactUnits:{
    type: Function
    },
    properties:{
    type: Array
    },
    isUnknownCaller:{
    type: Boolean,
    default: false
    },
    unknownCallerNumber:{
    type: String,
    default: ""
    }
  },
 
  methods: {
    closeModal() {
      this.selected_space = null;
      this.onModalClose();
    },
    voidConfirm(noReservations) {
      switch (this.action) {
        case "MoveIn":
          this.moveIn(noReservations);
          break;
        case "MoveOut":
          this.moveOut();
          break;
        case "Transfer":
          this.setTransfer();
          break;
      }
      this.onModalClose();
      this.selected_space = null;
    },
   async  moveIn(hasNoReservation) {
        if(this.isUnknownCaller){
          if(this.unknownCallerNumber && this.unknownCallerNumber.length > 0){
            const numberString= this.unknownCallerNumber.match(/\d+/g);
           this.navigateToMoveIn({unKnownNumber: `+${numberString.join("")}`});
          }else{
            this.navigateToMoveIn();
          }
      }else if(this.contactId && this.isActiveLeadWithoutContactUnits()) {
        await this.$store.dispatch('onBoardingStore/getContactInfo', {contact_id: this.contactId});
         this.navigateToMoveIn({charm_property_id:this.propertyId});
        return;
     }else if(this.contactId && hasNoReservation){
        await this.$store.dispatch('onBoardingStore/getContactInfo', {contact_id: this.contactId});
        this.navigateToMoveIn({ showSpacesList: true,charm_property_id:this.propertyId});
        return;
        }else{
          this.handleUserReservation();
        }
    },
    async handleUserReservation(){
         for(let res of this.tenantContact.Reservations) {
           if(res.Lease.unit_id == this.selected_space.id) {
            this.has_reservation = true;
            const{expires, lease_id, id, time, Lease} = res || {};
            const{first, last} = this.tenantContact || {};
            const reservation = {
              contact_id: this.contactId,
              expires,
              id,
              lead_first: first,
              lead_last: last,
              lease_id,
              reservation_id: id,
              reservation_time: time,
              unit_id: Lease.unit_id,
              Contact: this.tenantContact,
              Lease,
            }
            const requestObj = {
                    reservation_id: reservation.reservation_id,
                    start_date: reservation.start_date || reservation.Lease.start_date
            }
            let setup = await api.get(this, api.UNITS + reservation.unit_id + '/lease-set-up', requestObj);
            reservation.Setup = setup.details;
            this.reservation = reservation;
            break;
           }
         }
        if(this.has_reservation && Object.keys(this.reservation).length) {
        await this.$store.dispatch('onBoardingStore/getContactInfo', {contact_id: this.contactId});
        await this.$store.dispatch('onBoardingStore/getPropertyInfo',this.properties.find(p => p.id === this.reservation.Lease.Unit.property_id));
        let {  Contact, ...reserved } = this.reservation;
        await this.$store.dispatch('onBoardingStore/getReservation', {reservation: reserved, contact_id: this.contactId});
        this.navigateToMoveIn({ location: "move_in",charm_property_id:this.propertyId});
      }
    },
    navigateToMoveIn(data = {}){
      EventBus.$emit('new_lead',data);
      this.$emit('close');
    },
    setTransfer() {
      if (this.contactId) {
        try {
          if (this.selected_space) {
            const data = {
              property_id: this.propertyId,
              contact: this.tenantContact,
              lease_id: this.selected_space.lease_id,
              unit_id: this.selected_space.id
            };
            EventBus.$emit("transfer", data);
          }
        } catch (exception) {
          console.log(exception);
        }
      }
    },
    moveOut() {
      if (this.contactId) {
        try {
          let data = {
            property_id: this.propertyId,
            contact: this.tenantContact,
            lease_id: this.selected_space.lease_id,
            unit_id: this.selected_space.id
          };
          EventBus.$emit("move_out", data);
        } catch (exception) {
          console.log(exception);
        }
      }
    }
  }
};
</script>
