<template>
    <hb-autocomplete 
    v-model="searchContactsModel" 
    :search-input.sync="searchContactsInput" 
    :items="searchContactsList"
    return-object placeholder="Search Contacts" 
    prepend-inner-icon="mdi-magnify" 
    append-icon=""
    background-color="white"
    :no-data-text="'No Results Found'"
    :hide-no-data="!isFinished"
    :loading="!isFinished"
    box 
    no-filter 
    @change="onContactSelected()">
        <template v-slot:item="{ item }">
            <div class="template-wrapper">
                <div class="pt-1 pb-4">
                    <v-row no-gutters>
                        <v-col cols="12" no-gutters class="hb-overflow-handler py-1 d-flex align-center">
                            <hb-icon small color="#101318">mdi-account</hb-icon>
                            <div class="font-weight-medium pl-2 hb-overflow-handler name-container">{{
                                    getName(item)
                            }}</div>
                            <hb-status class="pl-2 status-container" :status="item.status">
                                {{ item.status }}
                            </hb-status>
                        </v-col>
                    </v-row>

                    <v-row no-gutters v-if="(item.Phones && item.Phones.length) || item.email">
                        <v-col cols="12" no-gutters class="hb-font-caption hb-text-night-light hb-overflow-handler">
                            {{ getPhone(item.Phones) | formatPhone}}<span v-if="(item.Phones && item.Phones.length) || item.email"
                                class="px-1">|</span> {{ item.email }}
                        </v-col>
                    </v-row>

                    <v-row no-gutters v-if="item.contact_address">
                        <v-col cols="12" no-gutters class="hb-font-caption hb-text-night-light hb-overflow-handler">
                            {{ item.contact_address }}
                        </v-col>
                    </v-row>

                    <v-row no-gutters v-if="item.contactUnits && item.contactUnits.length">
                        <v-col cols="12" v-for="(unit, i) in item.contactUnits"
                            :key="item.first + ' ' + item.last + '_search_contact_units_' + i" no-gutters
                            class="hb-font-caption hb-text-night-light d-flex align-center"
                             :class="unit.type == 'storage' ? 'storage-margin' : 'non-storage-margin'">
                            <div class="toast-icon-wrapper">
                                <hb-icon small class="pr-1 storage" v-if="unit.type == 'storage'"
                                    mdi-icon="mdi-facility-custom-3"></hb-icon>
                                <hb-icon small class="pr-1 parking" v-else-if="unit.type == 'parking'">mdi-truck</hb-icon>
                                <hb-icon small class="pr-1 company" v-else-if="unit.type == 'company'">mdi-domain</hb-icon>
                                <hb-icon small class="pr-1 company" v-else-if="unit.type == 'residential'">mdi-home</hb-icon>
                            </div>
                            <div class="hb-overflow-handler" 
                             :class="unit.type !== 'storage' ? 'unit-number-padding' : ''">
                                {{ unit.number ? '#' + unit.number : '' }}<span class="px-1"
                                    v-if="unit.number && unit.address">|</span>{{ unit.address }}</div>
                        </v-col>
                    </v-row>
                </div>

                <v-divider v-if="item !== searchContactsList[searchContactsList.length - 1]"></v-divider>
            </div>
        </template>

        <template v-slot:selection="{ item }">
            {{ getName(item) }}
        </template>
    </hb-autocomplete>
</template>

<script type="text/babel">

import { mapActions } from "vuex";
import api from '../../assets/api.js';

export default {
    name: "CallToastAutoSearch",
    data() {
        return {
            params: {
                search: '',
                limit: 10,
                offset: 0
            },
            contactParams: {
                search: '',
                limit: 20,
                offset: 0,
                result_count: 0
            },
            resultParams: {
                search: '',
                limit: 20,
                offset: 0,
                result_count: 0
            },
            defaultOffset: 20,
            isSearchResults: true,
            results: [],
            isContactResults: true,
            isFinished: true,
            searchContactsModel: {},
            searchContactsInput: '',
            searchContactsList: [],
        }
    },
    watch: {
        searchContactsInput() {
            this.search();
        }
    },
    props: {
      contact_address: {
      type: String,
      default: ""
      },
      isActiveCall: {
      type: Boolean,
      default: false
      }
    },
    methods: {
        ...mapActions({
            commitSelectedContact: "charmCallStore/commitSelectedContact",
            commitNotificationContacts:"charmNotificationStore/saveSelectedContact"
        }),
        onContactSelected() {
            if(this.isActiveCall){
             this.commitSelectedContact(this.searchContactsModel);
            }else{
             this.commitNotificationContacts(this.searchContactsModel);
            }
            this.searchContactsModel = {};
            this.searchContactsInput = '';
        },
        getPhone(PhonesArry) {
            return (PhonesArry.length && PhonesArry[0].phone);
        },
        getName(contact) {
            return `${contact.first} ${contact.last}`;
        },
        search: _.debounce(async function () {
            this.isFinished = false;
            this.results = [];
            this.searchContactsList = [];
            this.isSearchResults = true;
            this.isContactResults = true;
            this.resultParams = {
                search: this.params.search,
                limit: 20,
                offset: 0,
                result_count: 0
            };
            this.contactParams = {
                search: this.searchContactsInput,
                limit: 20,
                offset: 0,
                result_count: 0
            };
            if (this.contactParams.search && this.contactParams.search.length) {
                await this.searchContacts();
            }else {
                this.isFinished = true;  
            }
        }, 500),
        async searchContacts() {
            const d = new Date();
            this.contact_time = d.getTime();
            if (this.searchContactsList.length > 0) {
                this.contactParams.offset += this.defaultOffset
            }
            let data = JSON.parse(JSON.stringify(this.contactParams));
            data.t = this.contact_time;
            if (data.result_count >= data.offset && this.isContactResults) {
                this.isFinished = false;
                await api.post(this, api.UNITS_OMNI + '?type=contact', data).then(r => {
                    let {count, t, results} = r;
                    if (results && results.length) {
                    results.map((contact) => {
                        contact.contactUnits = this.getContactUnits(contact.Leases);
                        contact.contact_address = this.contact_address
                    });
                    }
                    this.contactParams.result_count = count
                    this.isContactResults = false;
                    if (this.contact_time === t) {
                        if (this.searchContactsList.length === 0) {
                            this.searchContactsList = results || []
                        } else {
                            this.searchContactsList = this.searchContactsList.concat(r.results)
                        }
                        this.isFinished = true;
                    }
                }).catch(e => {
                    this.isFinished = true;
                    console.log(e)
                })
            }
        },
        getContactUnits(leases) {
            const contactUnits = leases.map(lease => {
                const { Unit: { Address: {address, address2, city, state, zip} = {}, number, type } = {}, unit_id: id, } = lease;
                const addressString = (address ? address + ',' : ' ' ) + (address2 ? address2 + ',' : ' ') + (city ? city + ',' : ' ') + (state ? state + ',' : ' ') + (zip ? zip + ',' : ' ');
                return {
                    address: addressString,
                    id,
                    number,
                    type,
                }
            });
            return contactUnits
        }
    },
}

</script>

<style lang="scss" scoped>
  .name-container {
    margin-top: 3px;
  }
  .status-container {
    position:relative;
    top:1.5px;
  }
  .toast-icon-wrapper {
    min-height: 18px;
  }
  .company {
        position:relative;
        top:.5px;
    }
  .parking {
        position:relative;
        top:1px;
    }
  .storage {
        position:relative;
        top:1px;
    }
    .storage-margin {
        margin-top:-.5px; 
        margin-bottom:-.5px;
    }
    .non-storage-margin {
        margin-top:-1.5px;
    }
    .unit-number-padding {
        padding-top:2px;
    }


</style>

<style lang="scss">
 .template-wrapper {
    max-width: 272px;
  }
</style>