<template>
  <div>
    <v-row class="mb-4 mx-0" v-if="facilityList.length > 1">
      <v-col style="max-width: 350px;" class="pa-0 pt-2">
        <HbPropertySelector
          :wrapper="false"
          :items="facilityList"
          item-text="name"
          item-value="id"
          v-model="form.property_id"
          data-vv-name="facility"
          data-vv-as="Property"
          v-validate="'required'"
          :error="errors.has('facility')"
        />
      </v-col>
    </v-row>

    <span v-else-if="facilityList.length === 1" class="v-card__title hb-text-night pt-0 pl-0">
      <div class="truncate">
        {{facilityList[0].number}} - {{facilityList[0].name}} - {{facilityList[0].Address.address}}
      </div>
    </span>

    <v-card>
      <v-card-title>
        <v-icon class="mr-2 mt-n1" color="#101318">mdi-gesture-tap</v-icon> Rent Change Trigger and Configuration
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="interaction-section-body pa-0">
        <v-row class="hb-table-row ma-0 pa-0">
          <v-col class="hb-table-label py-4 pl-6" cols="4">Name</v-col>
          <v-col cols="6" class="pa-4 pt-2  pl-6 hb-table-value">
              <v-text-field
                  label="Enter Rent Change Name"                 
                  single-line
                  dense
                  flat
                  class="input-font-size"
                  v-model="form.name"
                  data-vv-name="rate_change_name"
                  data-vv-as="rent change name"
                  v-validate="'required'"
                  :error-messages="errors.collect('rate_change_name')"
                  hide-details="auto"
              ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="hb-table-row ma-0 pa-0">
          <v-col class="hb-table-label py-4 pl-6" cols="4">Target Rent Change</v-col>
          <v-col cols="2" class="pb-2 pt-2 pr-2 pl-6 hb-table-value">
              <v-text-field
                  label="0"
                  single-line
                  dense
                  flat
                  class="input-font-size"
                  v-model="form.change_length"
                  data-vv-name="change_length"
                  data-vv-as="target rent change "
                  v-validate="'required|integer|min_value:1'"
                  :error-messages="errors.collect('change_length')"
                  hide-details="auto"
              ></v-text-field>
          </v-col>
          <v-col cols="3" class="pb-2 pt-2 px-0 hb-table-value"  style="max-width: 117px;">
            <v-select
              :items="changePeriod"
              v-model="form.change_period"
              solo
              flat
              hide-details="auto"
            >
            </v-select>
          </v-col>
          <v-col cols="4" class="pb-2 pt-2 pl-0 hb-table-value">
            
            <v-select
              :items="triggerList"
              v-model="form.trigger"
              solo
              flat
              hide-details="auto"
            >
            </v-select>
          </v-col>
        </v-row>

        <v-row class="hb-table-row ma-0 pa-0">
          <v-col class="hb-table-label py-4 pl-6" cols="4">Notification Period</v-col>
          <v-col cols="2" class="pa-4 pt-2 pr-2 pl-6 hb-table-value">
              <v-text-field
                  label="0"
                  single-line
                  dense
                  flat
                  class="input-font-size"
                  v-model="form.notification_period"
                  data-vv-name="notification_period"
                  data-vv-as="notification period"
                  v-validate="'required|integer|min_value:1'"
                  :error-messages="errors.collect('notification_period')"
              ></v-text-field>
          </v-col>
          <v-col cols="4" class="pa-4 pt-5 pl-0 hb-table-value">
            <span class="input-font-size input-desc-text">Days before the rent change</span>
          </v-col>
        </v-row>
        <v-row class="hb-table-row ma-0 pa-0">
          <v-col class="hb-table-label py-4 pl-6" cols="4">Rent Change Frequency</v-col>
          <v-col cols="3" class="pa-4 pt-1 pb-0 hb-table-value" style="max-width:145px;">
            <v-select
              :items="changeFrequency"
              v-model="form.frequency"
              solo
              flat
              hide-details
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row class="hb-table-row ma-0 pa-0">
          <v-col class="hb-table-label py-4 pl-6" cols="4">Rent Change Detail</v-col>
          <v-col cols="8" class="pa-4 pt-2 pl-6 pb-0 hb-table-value">
            <v-radio-group v-model="rateInc" :column="false" dense hide-details class="pb-1">
              <v-radio label="Increment" :value="1" ></v-radio>
              <v-radio label="Set Rent" :value="2" class="ml-3" ></v-radio>
            </v-radio-group>
            <v-row>
              <v-col cols="4" class="pa-0 pl-1" style="max-width:125px;" v-if="rateInc === 1">
                <v-select
                  :items="changeDirections"
                  v-model="form.change_direction"
                  solo
                  flat
                  hide-details
                >
                </v-select>
              </v-col>
              <v-col cols="4" class="pa-0" style="max-width:125px;">
                <v-select
                  :items="rateInc === 1 ? changeType : changeTypeFixed"
                  :disabled="rateInc == 2"
                  v-validate="'required'"
                  v-model="form.change_type"
                  solo
                  flat
                  hide-details
                >
                </v-select>
              </v-col>
              <v-col cols="3" class="pa-0 pb-2" style="max-width:80px;">
                <v-text-field
                    label="Amount"
                    single-line
                    dense
                    flat
                    class="input-font-size"
                    v-model="form.change_amt"
                    data-vv-name="change_amt"
                    data-vv-as="amount"
                    v-validate="'required|decimal|min_value:0'"
                    :error-messages="errors.collect('change_amt')"
                    hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>

        </v-row>
        <Rounding from="rentManagement" :selected="rate_data"></Rounding>
      </v-card-text>
    </v-card>

  </div>

</template>

<script>
import Rounding from "../assets/Rounding.vue";
import { mapGetters } from 'vuex';
import { EventBus } from '../../EventBus.js'
import api from '../../assets/api.js'

export default {
  name: "AutomatedForm",
  data() {
    return {
      form: {
        property_id: '',
        name: null,
        type: 'auto',
        change_length: null,
        change_period:'months',
        frequency: 'monthly',
        change_amt: null,
        notification_period: null,
        change_direction: 'Increase',
        change_type: 'percent',
        trigger: 'start_of_lease',
        document_id: 'QZxjP5nBlA',
        // target_date: '2020-05-25'
        rounding: null
      },

      rateInc: 1,
      changePeriod:[
        { text: 'Day(s)', value: 'days'},
        { text: 'Month(s)', value: 'months'},
        { text: 'Year(s)', value: 'years'},
      ],
      changeFrequency: [
        { text: 'Monthly', value: 'monthly' },
        { text: 'Quarterly', value: 'quarterly' },
        { text: 'Yearly', value: 'yearly' }
      ],
      changeDirections: [
        { text: 'Increase', value: 'Increase' },
        { text: 'Decrease', value: 'decrease' }
      ],
      changeType: [
        { text: 'Percent', value: 'percent' },
        { text: 'Dollar', value: 'dollar' }
      ],
      changeTypeFixed: [
        { text: 'Dollar', value: 'fixed' }
      ],
      triggerList: [
        { text: 'After start of lease', value: 'start_of_lease' },
        { text: 'After last rent change', value: 'last_rent_change' }
      ],
      roundingData: null,
      change_counter: 0
    }
  },
  components: {
    Rounding
  },
  props: ['rate_data', 'saveRateChange'],
  computed: {
    ...mapGetters({
      facilityList: 'propertiesStore/filtered',
      activeDrawerComponent: 'navigationStore/activeDrawerComponent'
    }),
  },
  watch: {
    rateInc () {
      if (this.rateInc === 1) {
        this.form.change_type = 'percent'
      } else {
        this.form.change_type = 'fixed'
      }
    },
    rate_data() {
      if(this.rate_data) {
        const keys =  Object.keys(this.form)
        const self = this
        keys.forEach(key => {
          self.form[key] = self.rate_data[key]
        })
        this.form.id = this.rate_data.id
        if(this.rate_data.change_type === 'fixed') {
          this.rateInc = 2
        }
      } else {
        this.form = { ...defualtForm }
      }
    },
    form:{
      handler(value){
        if(!this.activeDrawerComponent.change_counter && !this.change_counter){
          this.change_counter++;
          this.setActiveDrawerComponent({...this.activeDrawerComponent, change_counter: this.change_counter })
        } else if (this.change_counter || this.activeDrawerComponent.change_counter){
          this.change_counter++;
          this.$emit('update:rate_data', value);
        } 
      },
      deep: true
    }
  },
  created () {
    this.form.property_id = this.facilityList.length === 1 ? this.facilityList[0].id : null
    EventBus.$on('fetch_rate_change',this.fetchAutomatedFormData)
    EventBus.$on("saveRoundingData", this.saveRounding);
  },
  mounted() {
    if(this.rate_data) {
      const keys =  Object.keys(this.form)
      const self = this
      keys.forEach(key => {
        self.form[key] = self.rate_data[key]
      })
      this.form.id = this.rate_data.id
      if(this.rate_data.change_type === 'fixed') {
        this.rateInc = 2
      }
    }
  },
  beforeDestroy () {
    EventBus.$off('fetch_rate_change', this.fetchAutomatedFormData)
    EventBus.$off("saveRoundingData", this.saveRounding);
  },
  methods: {
    saveRounding(rounding) {
      this.roundingData = rounding;
      this.form.rounding = rounding;
    },
    async fetchAutomatedFormData() {
      const status = await this.validate(this)
      if(status) {
        if(this.facilityList.length == 1) {
          this.form.property_id = this.facilityList[0].id;
        }
        this.form.rounding = this.roundingData;
        this.$emit('saveRateChange', this.form)
      }
    }
  },
}
</script>

<style scoped>
.hb-table-label{
    color: #101318;
    font-weight: 500;
}
.input-desc-text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #101318;
}
.truncate {
  max-width: 320px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>