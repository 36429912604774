<template>
  <div class="new-tenant-walkthrough">
      <div class="new-tenant-walkthrough-container">
        <hb-blade-header
          :title="title"
          :title-icon="title_icon"
          @back="handleBackButton"
          @close="$emit('close')"
          more-title-space
        >
        </hb-blade-header>



<!--        <v-list-item dense class="nav-drawer-header">-->
<!--          <v-btn v-if="showTenantsList" icon @click="hideTenantList" >-->
<!--            <v-icon>mdi-chevron-left</v-icon>-->
<!--          </v-btn>-->
<!--          <v-btn v-else icon @click="$emit('close')" >-->
<!--            <v-icon>mdi-chevron-left</v-icon>-->
<!--          </v-btn>-->
<!--          <v-list-item-title class="section-header title py-0" style="height: 20px;">-->
<!--            <v-icon v-if="type === MANUAL && !showTenantsList" color="#101318" >mdi-hand-left</v-icon>-->
<!--            <v-icon v-if="type === AUTOMATED && !showTenantsList" color="#101318">mdi-state-machine</v-icon>-->
<!--            <span v-if="type === MANUAL && !showTenantsList">  <span v-if="!rate_config_id">Create</span><span v-else>Edit</span> Manual Rent Change</span>-->
<!--            <span v-if="type === AUTOMATED  && !showTenantsList"> <span v-if="!rate_config_id">Create</span><span v-else>Edit</span> Rent Change Automation</span>-->
<!--            <span v-if="type === MANUAL && showTenantsList"> Tenant List</span>-->
<!--          </v-list-item-title>-->
<!--          <v-btn class="ml-2" icon @click.stop="$emit('close')">-->
<!--              <v-icon>mdi-close</v-icon>-->
<!--          </v-btn>-->
<!--        </v-list-item>-->


        <div class="new-tenant-walkthrough-content-section">
          <div class="onboarding-container">
            <div class="walkthrough-body">
              <div class="pt-2 px-6 pb-0">

                <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>

                <v-row class="mx-0">
                  <tenant-list
                    v-show="false"
                    @selectedRows="selectedTenant"
                    @hideList="showTenantsList = false"
                    :conditions="conditions"
                  ></tenant-list>
                  <tenant-list
                  v-if="showTenantsList"
                  @selectedRows="selectedTenant"
                  @hideList="showTenantsList = false"
                  :conditions="conditions"
                  ></tenant-list>
                </v-row>
                <div v-show="!showTenantsList">
                  <automated-form
                    v-if="type === AUTOMATED && edit_configuration"
                    @saveRateChange="save"
                    :rate_data.sync="rateConfigObject" />
                  <manual-form
                    :selectedTenants="selectedTenantList"
                    v-if="type === MANUAL || !edit_configuration"
                    @saveRateChange="save"
                    @showList="showTenantList"
                    @resetList="resetTenantList"
                    :rate_data.sync="rateConfigObject"/>
                  <actions-list v-show="false" @editAction="setAction" @removeAction="removeAction" @addAction="createNewAction" @hideAction="hideActions" :updatedActions="actionList" :loadingDocumentList="loadingDocumentList" :showAddAction="showAddAction" :editingAction="editingAction" :addingNewAction="addingNewAction" :everyActionAlreadySelected="everyActionAlreadySelected" ref="actionsList" />
                  <!-- <div class="pb-5 max-container-list mt-7" >
                      <v-row class="mx-0 pb-3">
                          <div class="v-card__title pa-0 pl-1">Rent Change Notifications</div>
                      </v-row>

                      <v-row class="pa-0 ma-0 applied-actions px-4 py-3 mb-3" v-for="(action, index) in actionList" :key="index" :class="{'action-active' : ( selectedAction && selectedAction.type === action.type) }" >
                          <v-col class="pa-0 mt-1" cols="12" sm="9">{{action.name}}</v-col>
                          <v-col class="pa-0 text-right" cols="12" sm="3">
                              <v-btn
                                  color="primary"
                                  class="mt-n2"
                                  text
                                  @click="setAction(action, index)"
                              >Edit</v-btn>
                              <i
                                  aria-hidden="true"
                                  class="notranslate mdi mdi-close mdi-18px theme--light"
                                  style="cursor: pointer;"
                              ></i>
                          </v-col>
                      </v-row>

                      <v-row class="ma-0 px-4 py-3 no-actions">
                          <v-col class="pa-0">
                              <span v-if="actionList && actionList.length === 0">No Action added</span>
                              <span v-else>Add more actions in the right hand panel</span>
                              <a class="float-right" @click="showAddAction = true"><i aria-hidden="true" class="notranslate mdi mdi-plus theme--light"></i> Add Rent Change Notification</a>
                          </v-col>
                      </v-row>
                  </div> -->

                </div>
              </div>
              <div class="new-tenant-walkthrough-footer">
                <hb-bottom-action-bar
                  @close="$emit('close')"
                  :cancel-off="showTenantsList"
                  :top-border="false"
                >
                  <template v-slot:right-actions>
                    <div v-if="!showTenantsList" class="d-flex align-center">
                      <hb-btn color="primary" v-if="type === AUTOMATED && !edit_rate_change" @click="fetchData">Save Rent Change Automation</hb-btn>
                      <hb-btn color="secondary" v-if="type === MANUAL && !edit_rate_change" @click="fetchData(true)">Send Notification Now</hb-btn>
                      <hb-btn color="primary" v-if="type === MANUAL && !edit_rate_change" @click="fetchData">Create Rent Change</hb-btn>
                      <hb-btn color="primary" v-if="(type === MANUAL || type === AUTOMATED) && edit_rate_change" @click="fetchData">Save</hb-btn>
                    </div>

                    <div v-if="showTenantsList" class="d-flex align-center">
                      <hb-link @click="hideTenantList">Back</hb-link>
                      <hb-btn color="primary" @click="setTenantList">Create</hb-btn>
                    </div>

                  </template>
                </hb-bottom-action-bar>
              </div>
            </div>
          </div>
          <div class="new-tenant-info-panel align-stretch walkthrough-body" v-show="!showTenantsList">
            <add-action
              :actionSelected="selectedAction"
              @fetchData="addAction"
              @hideAction="hideActions"
              :documents.sync="documentList"
              :property_id="conditions.property_id"
              :actionList="actionList" 
              ref="addActionRef"/>
            <!-- <instructions  :title="type === AUTOMATED ? 'Automated':'Manual'" v-else /> -->
          </div>
        </div>
      </div>
  </div>

</template>

<script>

import Instructions  from './Instructions.vue'
import AutomatedForm from './AutomatedForm.vue'
import ActionsList from './ActionsList.vue'
import AddAction from './AddAction.vue'
import ManualForm from './ManualForm.vue'
import TenantList from './TenantList.vue'

import Status from '../includes/Messages.vue'

import { EventBus } from '../../EventBus.js'
import api from '../../assets/api.js'
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { notificationMixin } from  '@/mixins/notificationMixin.js';

export default {
  name: 'AddRateChange',
  data() {
    return {
      showAddAction: false,
      selectedAction: null,
      showTenantsList: false,
      selectedTenantList: [],
      MANUAL: 'manual',
      AUTOMATED: 'auto',
      rateConfigObject: null,
      actionList: [],
      documentList: [],
      conditions: {},
      notify_now: false,
      loadingDocumentList: false,
      editingAction: false,
      addingNewAction: false,
      everyActionAlreadySelected: false,
      deliveryMethod: {}
    }
  },
  components: {
    Instructions,
    AutomatedForm,
    ActionsList,
    AddAction,
    ManualForm,
    TenantList,
    Status
  },
  props: ['type','rate_config_id', 'edit_configuration', 'edit_rate_change', 'rate_change_property_id'],
  mixins: [ notificationMixin ],
  created () {
    if(this.properties.length === 1) this.conditions.property_id = this.properties[0].id
    if (this.rate_config_id) {
      this.conditions.filters = {};
      this.conditions.filters.property_id = this.rate_change_property_id;
      this.conditions.show_count_tenant = true;
      this.conditions.report_name = 'rate_change_tenants';
      this.fetchDataByConfigId()
    } else {
      this.rateConfigObject = null
    }
    this.checkAlreadySelected();
    EventBus.$on('check_already_selected', this.checkAlreadySelected)
    let drawer_type = this.activeDrawerComponent.rent_raise_drawer;
    if(drawer_type === 'add-manual' || drawer_type === 'edit-manual' || drawer_type === 'add-auto' || drawer_type === 'edit-auto'){
      let filtered_properties = Object.entries(this.activeDrawerComponent).filter(([key, value]) => ['actionList','selectedTenantList'].indexOf(key) === -1 )
      this.rateConfigObject = Object.fromEntries(filtered_properties);
      this.actionList = this.activeDrawerComponent.actionList ? [...this.activeDrawerComponent.actionList]: this.actionList;
      this.selectedTenantList = this.activeDrawerComponent.selectedTenantList ? [...this.activeDrawerComponent.selectedTenantList] : this.selectedTenantList;
      if(this.actionList.length) this.hideActions();
    } else {
      this.activeDrawerComponent.rent_raise_drawer = `${this.edit_rate_change || this.rate_config_id ? 'edit-':'add-'}${this.type}`;
      this.setActiveDrawerComponent({ ...this.activeDrawerComponent });
    }

  },
  destroyed(){
    this.clearTenantList();
    EventBus.$off('check_already_selected', this.checkAlreadySelected)
  },
  computed:{
      ...mapGetters({
          getSelectedEntries: 'reportStore/get_selected_entries',
          getDocuments: 'documentsStore/getDocuments',
          activeDrawerComponent: 'navigationStore/activeDrawerComponent',
          properties: 'propertiesStore/filtered'
      }),

      tenantList(){
          return this.getSelectedEntries({
              report_id: null,
              report_template: "rate_change_tenants",
          });
      },
      title_icon(){
          let icon = '';
          if(this.type.toUpperCase() === "MANUAL" && !this.showTenantsList ) icon = "mdi-hand-left";
          if((this.type.toUpperCase() === "AUTOMATED" || this.type.toUpperCase() === "AUTO") && !this.showTenantsList ) icon = "mdi-state-machine";
          return icon;
      },
      title(){

          let title = '';
          if(this.type.toUpperCase() === "MANUAL" && !this.showTenantsList ) {
            if(this.rate_config_id){
                title += 'Edit '
            } else {
                title += 'Create '
            }
              title += ' Manual Rent Change'

          } else if((this.type.toUpperCase() === "AUTOMATED" | this.type.toUpperCase() === "AUTO") && !this.showTenantsList ) {
              if(this.rate_config_id){
                  title += 'Edit '
              } else {
                  title += 'Create '
              }
              title += 'Rent Change Automation'

          } else if(this.type.toUpperCase() === "MANUAL" && this.showTenantsList ) {
              title += 'Tenant List';
          }
          return title;
      }
  },
  methods: {
    ...mapActions({
      addRateChange: 'rateManagementStore/addRateChange',
      editRateChange: 'rateManagementStore/editRateChange',
      fetchDocumentsData: 'documentsStore/fetchDocuments',
      setActiveDrawerComponent:'navigationStore/setActiveDrawerComponent'
    }),
    ...mapMutations({
      setDocuments: 'documentsStore/setDocuments',
    }),
      handleBackButton(){
          if(this.showTenantsList){
              this.hideTenantList();
          } else {
              this.$emit('close')
          }
      },
    setAction(action, index){
      this.editingAction = true;
      this.showAddAction = true;
      this.selectedAction = {
        key: action.type,
        ...action
      }
    },
    removeAction(index ){
      this.actionList.splice(index, 1);
    },
    fetchActionsList(data){
      var d = this.$refs.actionsList.fetchActions();
    },
    createNewAction(){
      this.addingNewAction = true;
      this.showAddAction = true;
    },
    addAction (newAction) {
      console.log("ADDACTION", newAction)
      if(newAction.deliveryMethod) this.deliveryMethod = newAction.deliveryMethod;
      let index = this.actionList.findIndex(x=>x.type === newAction.type)
      if(index === -1 && !newAction.isEdit && !this.selectedAction) {
        if (this.actionList.length < 2) {
          this.actionList.push(newAction)
          EventBus.$emit('refresh_rate_actions', this.actionList)
        } else {
          this.errorSet(this.$options.name, "No more than 2 notifications allowed.");
        }
      } else if(newAction.isEdit) {
        index = this.actionList.findIndex(x=>x.type === this.selectedAction.type)
        this.actionList[index] = newAction
        this.selectedAction = null
        EventBus.$emit('refresh_rate_actions', this.actionList)

      } else {
        this.errorSet(this.$options.name, "This notification already added.");
      }

      this.showAddAction = false

    },
    clearTenantList(){
        this.$store.commit('reportStore/resetReport', {
            report_id: null,
            report_template: "rate_change_tenants",
        })
    },
    hideActions() {
      this.selectedAction = null;
      this.editingAction = false;
      this.addingNewAction = false;
      this.showAddAction = false;
      EventBus.$emit('refresh_rate_actions', this.actionList);
    },
    selectedTenant(data) {

      this.selectedTenantList = data;
      this.conditions.tenant_list = this.selectedTenantList;
    },

    hideTenantList() {
      this.selectedTenantList = this.tenantList;
      this.showTenantsList = false
    },

    setTenantList(){
      this.selectedTenantList = this.tenantList;
      this.showTenantsList = false
    },
    showTenantList(obj) {
      this.conditions.filters = {};
      this.conditions.filters.property_id =  obj.property_id;
      this.conditions.show_count_tenant = true;
      this.conditions.report_name = 'rate_change_tenants';
      // this.conditions.tenant_list = obj.tenantList;


      if(this.selectedTenantList && this.selectedTenantList.length){
          this.$store.commit('reportStore/setSelectedEntries', {
              report_id: null,
              report_template: "rate_change_tenants",
              entries: this.selectedTenantList
          });
      }
        //this.conditions.tenant_list = this.rateConfigObject.RentChangeLeases;
      this.showTenantsList = true;
    },
    resetTenantList(property_id) {
      this.$set(this.conditions, 'property_id', property_id)
      this.hideTenantList();
    },
    fetchData(notify = false) {
      //this.autoSave = true;
      this.$refs.addActionRef.save();
      this.notify_now = notify;
      EventBus.$emit('fetch_rate_change')
    },
    async save(data) {

      if(data?.dryrun) {
        delete data.dryrun;
        this.rateConfigObject = {...data};
        return;
      }
      const actionData = this.setAndValidateActions(data);
      if(!actionData) return;

      data.document_id = actionData.document_id;
      data.email_text = this.deliveryMethod.message;
      data.email_subject = this.deliveryMethod.subject;
      data.notify_now = this.notify_now;
      data.editRateChange = this.$props.edit_rate_change ? this.$props.edit_rate_change : false;
      //data.deliveryMethod = this.deliveryMethod;
      data.delivery_methods_id = this.deliveryMethod.id
      if(data.id) {
        let id = data.id;
        await this.editRateChange(data);
        delete data.id
      } else {
        await this.addRateChange(data);
      }

      this.$emit('close')
    },
    async fetchDataByConfigId () {
      this.selectedTenantList = [];
      let rateConfig = {};
      if(this.$props.type == this.AUTOMATED && this.$props.edit_configuration) {
        rateConfig = await api.get(this, api.RATE_MANAGEMENT + 'rate-change-configurations/' + this.rate_config_id);
        this.rateConfigObject = rateConfig.rate_change_configuration;
      } else {
        rateConfig = await api.get(this, api.RATE_MANAGEMENT + 'rate-changes/' + this.rate_config_id);
        this.rateConfigObject = rateConfig.rate_change
      }
      if(this.rateConfigObject && this.rateConfigObject.RentChangeLeases) {
        this.selectedTenantList = this.rateConfigObject.RentChangeLeases;
      }

      if(this.rateConfigObject.document_id){
        this.actionList.push({
          document_id: this.rateConfigObject.document_id,
          isEdit:false,
          name:"Generate Document",
          type:"generate_document"
        })
        this.fetchDocuments();
      }

      if(this.rateConfigObject.email_text ||  this.rateConfigObject.email_subject) {
        this.actionList.push({
          isEdit:false,
          message: this.rateConfigObject.email_text,
          name:"Standard Email",
          subject: this.rateConfigObject.email_subject,
          type:"standard_email"
        })
      }
      EventBus.$emit('refresh_rate_actions', this.actionList)
    },
    setAndValidateActions(data) {

      let doc = null
      let email = null

      if(data.type === 'manual') {
        if (data.leases && data.leases.length === 0) {
          this.errorSet(this.$options.name, "Please select some tenants.");
          return null
        }
      }

      // if(this.actionList.length === 0) {
      //   this.errorSet(this.$options.name, "Add notifications");
      //   return null
      // } else {

      doc = this.actionList.find(x=>x.type === 'generate_document');
      email = this.actionList.find(x=>x.type === 'standard_email');
      if(!doc) {
        this.errorSet(this.$options.name, "No document notification found");
        return null
      }


        // if(!email) {
        //   this.errorSet(this.$options.name, "No email notification found");
        //   return null
        // }
      // }

      return {
        document_id: doc? doc.document_id:  null,
        subject: email ? email.subject : null,
        message: email ? email.message : null
      }
    },
    async fetchDocuments() {
      this.loadingDocumentList = true;
      await this.setDocuments([]);
      let limit = 200;
      let offset = this.getDocuments.length == 0 ? 1 : Math.ceil(this.getDocuments.length / limit) + 1 ;
      let path = (this.rateConfigObject.property_id) ? `&property_ids[]=${this.rateConfigObject.property_id}` : '';
      let data = {
        component: this,
        params: api.DOCUMENT.slice(0, -1) + `?type[]=rent-change&limit=${limit}&offset=${offset}` + path
      }
      try {
        await this.fetchDocumentsData(data);
        this.documentList = this.getDocuments;
      } catch(err) {
        this.showMessageNotification({ description: err });
      }

      try {
        if (this.rate_config_id) {
          let index = this.actionList.findIndex(x=>x.type === 'generate_document')
          if(index !== -1) {
            let doc = this.documentList.find(x=>x.id === this.rateConfigObject.document_id)
            if(doc) {
              this.actionList[index].name = 'Generate Document > ' + doc.name
              EventBus.$emit('refresh_rate_actions', this.actionList)
            }
          }
        }
      } catch (err) {

      }
      this.loadingDocumentList = false;
    },
    checkAlreadySelected(){
      this.everyActionAlreadySelected = false;
      var generateDocumentAlreadySelected = false;
      var sendEmailAlreadySelected = false;

      if(this.actionList){
        for(var i = 0; i < this.actionList.length; i++) {
            if( this.actionList[ i ].type === 'generate_document' ) {
                var generateDocumentAlreadySelected = true;
            }
        }
        for(var i = 0, len = this.actionList.length; i < len; i++) {
            if( this.actionList[ i ].type === 'standard_email' ) {
                var sendEmailAlreadySelected = true;
            }
        }
      }

      if(generateDocumentAlreadySelected && sendEmailAlreadySelected){
        this.everyActionAlreadySelected = true;
      }
    }
  },
  watch:{
    actionList(){
      this.checkAlreadySelected();
    },
    rateConfigObject:{
      handler (value) {
        if(!value) return;
        this.setActiveDrawerComponent({...this.activeDrawerComponent, ...value});
      },
      deep: true
    },
    selectedTenantList:{
      handler(value){
        this.setActiveDrawerComponent({...this.activeDrawerComponent, selectedTenantList: value || []});
      },
      deep: true
    },
    actionList: {
      handler(value){
        this.setActiveDrawerComponent({...this.activeDrawerComponent, actionList: value || []});
      },
      deep: true
    }
  }
}
</script>

<style scoped>

  .new-tenant-walkthrough-footer{
    padding: 3px 0 3px 0;
    background: #FFFFFF;
    border-top: 1px solid #e2e2e2;
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 100;
    left: 0;
    right: 0;
    max-height: 60px;
  }
  span{
    color: #101318;
  }

  .new-tenant-walkthrough-container{
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: stretch;
      height: 100%;
      flex: 1 1;
      position: relative;
      overflow: hidden;
  }
  .new-tenant-walkthrough-content-section{
      align-items:stretch;
      display: flex;
      overflow: hidden;
      padding-bottom: 50px;
      height: 100%;
  }
  .new-tenant-walkthrough{
      background: #F9FAFB;
      width: 100%;
      overflow: hidden;
  }
  .v-stepper__header {
      max-width: 525px;
      border: none;
      box-shadow:none;
      margin: 0 auto;
  }
  .new-tenant-stepper{
      border-bottom: 1px solid #E1E6EA;
      text-align: center;
      box-shadow:none;
      background: white;
  }
  .new-tenant-info-panel{
      flex: 0 0 385px;
      height: 100%;
      padding: 16px;
      background-color: #F9FAFB;
      border-left: 1px solid #E1E6EA;
  }
  .walkthrough-body{
      /* flex: 1; */
      overflow-y: auto;
      height: 82vh;
  }

  .nav-drawer-header{
      flex: 0 1 auto;
      min-height:62px;
      background: #FFFFFF;
  }
  .onboarding-container{
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: stretch;
      flex: 1;
  }

  @media (min-height: 700px) {
    .walkthrough-body{
      height: 75vh;
    }
  }

  @media (min-height: 800px) {
    .walkthrough-body{
      height: 78vh;
    }
  }

  @media (min-height: 900px) {
    .walkthrough-body{
      height: 80vh;
    }
  }

  @media (min-height: 1025px) {
    .walkthrough-body{
      height: 83vh;
    }
  }
</style>
