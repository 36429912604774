<template>
  <div class="pt-3 pb-2">
        <v-row class="ma-0">
            <v-col md="8" class="pl-0 pb-0">
                Bob's Electric 
            </v-col>
            <v-col md="4" class="text-right pr-0 pb-0">
                Apr 25, 2020 @ 12:01pm<br/> 
                Staff: John Doe
            </v-col>
        </v-row>
        <div class="pb-2">
            <span class="font-big">Task/Issue:</span> Lighting fixture is broken
        </div>
        <div class="pb-2">
            <span class="font-big">Lighting fixture is broken</span>
        </div>
        <button class="btn-update-req px-8 py-3">Update Maintenance Request</button>
  </div>
</template>

<script>
export default {
    name: "MaintenanceScheduled"
}
</script>

<style scoped>
    .font-big{
        font-size: 15px;
        font-weight: 500;
    }
    .btn-update-req{
        background: linear-gradient(180deg, #FFFFFF 0%, #F9FAFB 100%);
        border: 1px solid #C4CDD5;
        box-sizing: border-box;
        box-shadow: 0px 1px 0px rgba(22, 29, 37, 0.05);
        border-radius: 4px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #101318;
    }
</style>