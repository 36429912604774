<template>

    <div class="slide-out-window">
        <slot name="body">
            <div class="loading-spinner">
                <loading color="#00b2ce" size="24px"></loading>
            </div>
        </slot>
    </div>

</template>
<script type="text/babel">
    import loading from "../assets/CircleSpinner.vue";
    export default {
        data() {
            return {

            }
        },
        components:{
            loading
        }
    }
</script>

<style scoped>

    .loading-spinner{
        position: absolute;
        margin-top: -60px;
        left: 50%;
        height: 120px;
        width: 400px;
        text-align: center;
        margin-left: -200px;
        top: 50%;
    }
</style>