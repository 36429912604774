<template>
  <div>
    <span v-if="getRendererType === 'button'">
      <hb-btn
        small
        :color="getRendererColor"
        @click="btnClickedHandler(params)"
      >
        {{ getRendererText }}
      </hb-btn>
    </span>
    <span v-else-if="getRendererType === 'link'">
      <hb-link
        :color="getRendererColor"
        @click="btnClickedHandler(params, 'event')"
      >
        {{ getRendererText }}
      </hb-link>
    </span>
    <span v-else-if="getRendererType === 'icon'">
      <hb-btn
        icon
        :tooltip="params.tooltip"
        :color="getRendererColor"
        :mdi-code="params.icon"
        @click="btnClickedHandler(params, 'event')"
      />
    </span>
  </div>
</template>
<script>
import HbBtn from "@/aviary/HbBtn.vue";
import HbLink from "@/aviary/HbLink.vue";

import { EventBus } from "../../EventBus.js";

export default {
  name: "ClickCellRenderer",
  components: { HbBtn, HbLink },
  data() {
    return {};
  },
  computed: {
    getRendererType() {
      return this.params?.type ?? "";
    },
    getRendererText() {
      return this.params?.text
        ? this.params?.text
        : this.getFormattedValue(this.params.formatter ?? undefined) ?? "";
    },
    getRendererColor() {
      return this.params?.color ?? "";
    },
  },
  methods: {
    btnClickedHandler(paramData, type = "") {
      if (type === "event" && this.params?.event) {
        EventBus.$emit(this.params?.event?.name, {
          event: this.params?.event?.method,
          data: this.params?.data,
        });
      } else paramData.context.parent.$parent.$parent.parentMethod(paramData);
    },
    getFormattedValue(customCallback = (val) => val) {
      let colId = this.params.column.colId;
      let value = this.params.data?.[colId] ?? "";
      let col = this.params.columnApi.getColumn(colId);
      let colDef = col?.colDef ?? {};
      let valueGetter = colDef.valueGetter;
      let valueFormatter = colDef.valueFormatter;

      if (valueGetter) {
        value = this.evaluateAndReturn(
          valueGetter,
          this.getValueGetterParams(colId)
        );
      }
      if (valueFormatter) {
        value = this.evaluateAndReturn(
          valueFormatter,
          this.getValueFormatterParams(colId, value)
        );
      }

      return customCallback(value);
    },
    evaluateAndReturn(callBack, params) {
      if (typeof callBack === "function") {
        return callBack(params);
      } else return callBack;
    },
    getValueGetterParams(column) {
      let currentIndex = this.params.rowIndex;
      let row = this.params.api.getDisplayedRowAtIndex(currentIndex);
      let AGcolumn = this.params.columnApi.getColumn(column);

      return {
        getValue: (field) => this.params.getValue.bind(null, field, row)(),
        ...this.baseParams,
        column: AGcolumn,
        colDef: AGcolumn.getColDef(),
      };
    },
    getValueFormatterParams(column, value) {
      let AGcolumn = this.params.columnApi.getColumn(column);

      return {
        value,
        ...this.baseParams,
        column: AGcolumn,
        colDef: AGcolumn.getColDef(),
      };
    },
  },
};
</script>
