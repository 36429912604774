<template>
    <div>
        <h2 class="headline mb-1">Select Insurance Coverage</h2><br />
        <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>

        <v-card class="mb-5">
          <v-card-text>
            <v-radio-group v-model="insurance" @change="selectInsurance" hide-details class="mt-0">
              <v-container class="pt-0">
                <v-row>
                  <v-col cols="3" style="background-color: #F4F6F8;">
                    <v-container>
                      <v-row no-gutters>
                        <v-col><h2 class="subtitle-2 font-weight-medium">Coverage</h2></v-col>
                      </v-row>
                    </v-container>
                  </v-col>

                  <v-col cols="9" class="py-0">
                      <v-container>
                        <div v-for="(o, i) in optionalInsurance" :key="o.id">
                          <v-row>
                            <v-col class="row-checkbox-col">
                              <v-radio class="ma-0 pa-0" :value="o" :name="o.id" :id="o.id"></v-radio>
                            </v-col>
                            <v-col :label-for="o.id">
                              <strong>{{o.Insurance.name}}</strong><br />
                              {{o.Insurance.description}}
                            </v-col>
                          </v-row>
                          <v-divider></v-divider>
                        </div>

                        <div v-if="!optionalInsurance.length" >
                          <v-row>
                            <v-col>
                              No Insurance Options Found
                            </v-col>
                          </v-row>
                          <v-divider></v-divider>
                        </div>
                      </v-container>

                  </v-col>
                </v-row>
              </v-container>
              <v-container class="pt-0">
                <v-row >
                  <v-col cols="3" style="background-color: #F4F6F8;">
                    <v-container>
                      <v-row no-gutters>
                        <v-col><h2 class="subtitle-2 font-weight-medium">Decline Insurance</h2></v-col>
                      </v-row>
                    </v-container>
                  </v-col>
                  <v-col cols="9">
                    <v-container>
                      <div>
                        <v-row>
                          <v-col class="row-checkbox-col">
                            <v-radio class="ma-0 pa-0" :value="0" name="decline" id="decline"></v-radio>
                          </v-col>
                          <v-col >
                            <label for="decline"><strong>Tenant has their own insurance</strong></label>
                          </v-col>
                        </v-row>
                      </div>
                    </v-container>
                  </v-col>
                </v-row>
              </v-container>
            </v-radio-group>

<!--              <v-container class="pt-0">-->
<!--                <v-row >-->
<!--                  <v-col cols="3" style="background-color: #F4F6F8;">-->
<!--                    <v-container>-->
<!--                      <v-row no-gutters>-->
<!--                        <v-col><h2 class="subtitle-2 font-weight-medium">Insurance Brochure</h2></v-col>-->
<!--                      </v-row>-->
<!--                    </v-container>-->
<!--                  </v-col>-->
<!--                  <v-col cols="9">-->
<!--                    <v-container>-->
<!--                      <div>-->
<!--                        <v-row>-->
<!--                          <v-col align-self="center" class="row-checkbox-col">-->
<!--                            <v-checkbox class="ma-0 pa-0"  hide-details v-model="email_brochure" name="email_brochure" id="email_brochure"></v-checkbox>-->
<!--                          </v-col>-->
<!--                          <v-col align-self="center" style="flex: 0 1 160px">-->
<!--                            <label for="email_brochure"><strong>Email Brochure</strong></label>-->
<!--                          </v-col>-->
<!--                          <v-col align-self="center" v-show="email_brochure">-->
<!--                            <v-text-field-->
<!--                              v-model="email_brochure_to"-->
<!--                              dense-->
<!--                              hide-details-->
<!--                              label="Email Address"-->
<!--                            >-->
<!--                              <template slot="append"><v-btn text color="primary">Send</v-btn></template>-->
<!--                            </v-text-field>-->
<!--                          </v-col>-->
<!--                        </v-row>-->
<!--                        <v-row>-->
<!--                          <v-col align-self="center" class="row-checkbox-col">-->
<!--                            <v-checkbox class="mt-0 pt-0"  hide-details v-model="text_brochure" name="text_brochure" id="text_brochure"></v-checkbox>-->
<!--                          </v-col>-->
<!--                          <v-col align-self="center" style="flex: 0 1 160px" >-->
<!--                            <label for="text_brochure"><strong>Text Brochure</strong></label>-->
<!--                          </v-col>-->
<!--                          <v-col align-self="center" v-show="text_brochure">-->
<!--                            <v-text-field-->
<!--                              v-model="text_brochure_to"-->
<!--                              dense-->
<!--                              hide-details-->
<!--                              label="Phone Number"-->
<!--                            >-->
<!--                              <template slot="append"><v-btn text color="primary">Send</v-btn></template>-->
<!--                            </v-text-field>-->
<!--                          </v-col>-->
<!--                        </v-row>-->
<!--                        <v-row>-->
<!--                          <v-col align-self="center" class="row-checkbox-col">-->
<!--                            <v-checkbox class="mt-0 pt-0"  hide-details v-model="print_brochure" name="print_brochure" id="print_brochure"></v-checkbox>-->
<!--                          </v-col>-->
<!--                          <v-col align-self="center">-->
<!--                            <label for="print_brochure"><strong>Print Brochure</strong></label>-->
<!--                          </v-col>-->
<!--                          <v-col align-self="center" v-show="print_brochure" style="flex: 1" class="align-right">-->
<!--                            <v-btn color="primary" text>Print</v-btn>-->
<!--                          </v-col>-->
<!--                        </v-row>-->
<!--                      </div>-->
<!--                    </v-container>-->
<!--                  </v-col>-->
<!--                </v-row>-->
<!--              </v-container>-->

          </v-card-text>
        </v-card>

    </div>
</template>

<script type="text/babel">

    import api from '../../../assets/api.js';
    import Status from '../../includes/Messages.vue';
    import Loader from '../../assets/CircleSpinner.vue';
    import moment from 'moment';

    export default {
        name: "ConfigureInsurance",
        data() {
            return {
                services:[],
                taxRate: 0,
                showAddService:false,
                showDeleteService:false,
                showAddProduct:false,
                selectedService: null,
                offset: 0,
                service_updated: false,
                insurance: {},
                email_brochure: false,
                email_brochure_to: '',
                text_brochure: false,
                text_brochure_to: '',
                print_brochure: '',

            }
        },
        components:{
            Status,
            Loader
        },
        created (){
            console.log("this.$store.getters.lease", this.$store.getters.lease)
            console.log("this.$store.getters.leaseInsurance", this.$store.getters.leaseInsurance)

          if(this.$store.getters.lease.decline_insurance){
              this.insurance = 0;
          } else {
              this.insurance = this.optionalInsurance.find(i => i.product_id === this.$store.getters.leaseInsurance.product_id ) ;

          }

        },
        filters:{
            lineTotal(c){
                return c.qty * c.cost;
            },
            getEndOfPeriod(date){
                return moment(date).add(1, 'month').subtract(1, 'day');
            }
        },
        computed: {
            canProgress(){
                if(this.declineInsurance || this.$store.getters.leaseInsurance.id){
                    return true;
                }
                return false;
            },
            declineInsurance: {
                get() { return this.$store.getters.lease.decline_insurance; },
                set(value) { this.$store.commit('updateDeclineInsurance', value); },
            },

            optionalInsurance(){
                if(!this.$store.getters.leaseTemplate) return [];
                return this.$store.getters.leaseTemplate.Services.filter(s => s.service_type === 'insurance' && s.optional);
            }

        },
        methods: {
            insurancePrice(insurance){
                var premium;
                if(insurance.Insurance.premium_type == "%"){
                    premium = this.$store.getters.leaseRent * (insurance.Insurance.premium_value / 100);
                } else {
                    premium = insurance.Insurance.premium_value;
                }
                return premium; //(Math.round(premium * 1e2) / 1e2).toFixed(2);
            },
            async selectInsurance(o){
                if(o === 0){
                    this.$store.commit('updateDeclineInsurance', true);
                    this.$store.commit('setInsurance', null);
                    await api.put(this, api.LEASES + this.$store.getters.lease.id + '/decline-insurance/', {
                        decline_insurance: this.$store.getters.lease.decline_insurance
                    });
                    // return return await this.updateInsurance();
                }
                if(o.product_id !== this.$store.getters.leaseInsurance.product_id){
                    this.service_updated = true;
                    await this.saveInsurance(o);
                    this.$store.commit('updateDeclineInsurance', false);
                    await api.put(this, api.LEASES + this.$store.getters.lease.id + '/decline-insurance/', {
                        decline_insurance: false
                    });
                    // this.updateInsurance();
                } else {
                    await this.deleteInsurance();
                }
            },
            deleteInsurance(){
                this.$store.commit('setInsurance', null);
                api.delete(this, api.LEASES + this.$store.getters.lease.id + '/template-insurance/');
            },
            saveInsurance(insurance){
                if(!insurance) return;
                this.$store.commit('updateDeclineInsurance', false);
                insurance.Insurance.prorate = insurance.prorate;
                insurance.Insurance.recurring = insurance.recurring;
                this.$store.commit('setInsurance', insurance.Insurance);

                var data = {
                    insurance_id: insurance.id
                };
                api.post(this, api.LEASES + this.$store.getters.lease.id + '/template-insurance/', data);
            },
            updateInsurance(){
               this.$store.commit('updateDeclineInsurance', !this.$store.getters.lease.decline_insurance);
                if(this.$store.getters.lease.decline_insurance) {
                    this.deleteInsurance();
                }
                api.put(this, api.LEASES + this.$store.getters.lease.id + '/decline-insurance/', {
                    decline_insurance: this.$store.getters.lease.decline_insurance
                });
            }
        }
    }
</script>

<style scoped>

    .insurance-container{
        border: 1px solid #C4CDD5;
        background: white;
        padding: 30px;
        border-radius: 4px;
    }
    .policy-container{
        padding-top: 0;
        padding-bottom: 0;
    }
    .form-label{
        background: #F4F6F8;
        border-radius: 4px;
    }
    .insurance-option-row{
        border-bottom: 1px solid #C4CDD5;
        padding: 10px 20px;
    }
    .insurance-section-label{
        padding: 30px;
        font-weight: bold;
    }
    .insurance-container{
        padding: 30px 50px;
    }

    .insurance-container .v-input--radio-group--column{
        margin: 0;
    }
    .insurance-container .insurance-row {
        margin-bottom: 10px;

    }
    .insurance-option-row.no-border{
        border-bottom: none;
    }


</style>
