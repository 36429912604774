<template>
  <div  style="overflow: hidden;" class="new-tenant-walkthrough">

    <div class="new-tenant-walkthrough-container">
      <hb-blade-header
        :title="'Sell ' + (activeTab === 'fees' ? 'Services' : 'Merchandise')"
        title-icon="mdi-tag"
        @back="goBack"
        :back-button-off="!canNavigate"
        @close="close"
      >
          <template v-slot:right> 
              <!-- <hb-btn class="mr-1" icon tooltip="Property Info" :active="summary_panel === 'summary'" @click="summary_panel = summary_panel === 'summary' ? null : 'summary'" v-show="step < 7" hover-background-color="#CAEAEA">mdi-information-outline</hb-btn> -->

              <hb-btn class="mr-1" icon tooltip="Similar" :active="summary_panel === 'found'" v-show="found.length && step < 7" @click="summary_panel = summary_panel === 'found' ? null : 'found'" hover-background-color="#CAEAEA">mdi-account-tie-voice</hb-btn>
          </template>
      </hb-blade-header>

      <div class="new-tenant-walkthrough-content-section">
        <div class="onboarding-container" :class="{'mr-3 elevation-9': step !== 4}">
          <hb-stepper :value="step">
            <hb-stepper-header :force-resize="resizeStepper">
              <hb-stepper-step
                step="1"
                :complete="step > 1"
                :cursor="false"
              >
                {{(activeTab === 'fees' ? 'Services' : 'Merchandise')}}
              </hb-stepper-step>
              
              <v-divider></v-divider>

              <hb-stepper-step
                step="2"
                :complete="step > 2"
                :cursor="false"
              >
                Checkout
              </hb-stepper-step>
            </hb-stepper-header>
          </hb-stepper>

          <div class="walkthrough-body pl-4 mt-3">
            <HbPropertySelector
              class="mt-1 mb-1" 
              v-model="property.id"
              id="property" 
              :items="properties" 
              item-text="name" 
              item-value="id"
              v-validate="'required'"
              data-vv-scope="gate" 
              data-vv-name="property"
              data-vv-as="Property" 
              :error="errors.has('gate.property')"
              @change="handlePropertyChangeEvent"
              v-if="step === 1"
            />

            <services
              :lease_id="lease_id"
              :services.sync="services"
              @setServices="setServices"
              @calculateTotal="calculateTotal"
              :property="property"
              :contact="contact"
              :quick_action=" activeDrawerComponent.quick_action"
              @close="close"
              :activeTab="activeTab"
              ref="services"
              v-if="step === 1"></services>

            <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>

            <payment
              @processPayment="processPayment"
              @close="close"
              @isGuestUserEvent="isGuestUserEvent"
              @saveInvoice="saveInvoice"
              :services.sync="services"
              v-if="step === 2"
              :property="property"
              :contact="contact"
              :invoice.sync="invoice"
              :disable_payment="disable_payment"
              :quick_action=" activeDrawerComponent.quick_action"
              :canProgress="canProgress"
              ref="processPayment"
              @selected="selectContact"
              @resetContact="resetContact"
            ></payment>

            <receipt :payment_id="payment.id" @close="close" :is-guest-user="isGuestUser" :merchandise="true" v-if="step === 4"></receipt>
          </div>
        </div>

        <div class="new-tenant-info-panel align-stretch" v-if="step !== 4">
          <transition name="slide-fade">
            <found v-if="summary_panel === 'found'" @selected="selectContact" :contacts.sync="found" @hide="summary_panel = null"></found>
          </transition>
          <transition name="slide-fade" v-if="showSummaryPanel">
            <div>
              <summary-panel @calculateTotal="calculateTotal" :step="step" :is-guest-user="isGuestUser" :contact="contact" :property="property" :services="services" @removeServices="removeService" :invoice="invoice"></summary-panel>
            </div>
        </transition>
        </div>
      </div>
    </div>


  </div>

</template>
<script type="text/babel">

    import api from '../../../assets/api.js';
    import Contact from './Contact.vue';
    import Services from './Services.vue';
    import Payment from './Payment.vue';
    import SummaryPanel from './Summary.vue';
    import Receipt from '../LeadIntake/Receipt.vue';
    import Found from '../LeadIntake/Found.vue';
    import { mapGetters, mapActions, mapMutations } from 'vuex';
    import { EventBus } from '../../../EventBus.js';
    import Status from '../../includes/Messages.vue';
    import { notificationMixin } from  '../../../mixins/notificationMixin.js';
    import moment from 'moment';
    import axios from 'axios';
    import https from 'https';
    export default {
        name: 'SellMerchandise',
        mixins: [ notificationMixin ],
        components: {
            Contact,
            Services,
            Payment,
            Found,
            SummaryPanel,
            Receipt,
            Status
        },
        data: () => ({
            step: 1,
            panel: [0],
            invoice: {},
            contact: {
                id: '',
                first: '',
                last: '',
                email: '',
                company:'',
                rent_as_business:false,
                Phones:[ {
                    type: 'Cell',
                    phone: '',
                    sms: true
                }],
                Addresses: [{
                    id: null,
                    type: 'Home',
                    address_id: '',
                    Address:{
                        address: '',
                        address2: '',
                        city: '',
                        neighborhood: '',
                        lat: '',
                        lng: '',
                        state: '',
                        zip: ''
                    }
                }]
            },
            property: {},
            services: {},
            valid: false,
            drawer: false,
            model: null,
            search: null,
            summary_panel: 'summary',
            unit_id:'',
            payment: {},
            found: [],
            disable_payment: false,
            resizeStepper: 0,
            guestUser: {},
            isGuestUser: true
        }),

        props: ["showLeadIntake",  "selected_contact",  "unit", 'lease_id', 'property_id', 'activeTab'],
        filters: {
            truncateText(value) {
                if (!value) return '';
                return value.length > 150 ? value.slice(0, 150) + '...' : value;
            }
        },
        async created(){

            if(this.property_id && this.properties){
                this.property = this.properties.find(p => p.id === this.property_id);
            } else if(this.properties.length === 1){
                this.property = this.properties[0];
            }

            if(this.selected_contact){
                this.selectContact(JSON.parse(JSON.stringify(this.selected_contact)));
                this.isGuestUser = false;
            } else {
              this.fetchGuestContact();
            }


          EventBus.$on('paymentReader', this.enablePayment);
          EventBus.$on('reset_buy_services_hb_model', this.close);
          EventBus.$on('HB-Merchandise:found-contact', this.showFoundOptions);
          EventBus.$on('HB-Merchandise:contact-info', this.setContactInfo);
        },
        mounted () {
          if(this.activeDrawerComponent.type === 'showSellMerchandise') {
            this.contact = this.activeDrawerComponent.contact || this.contact;
            this.invoice = this.activeDrawerComponent.invoice || this.invoice;
            this.services =  this.activeDrawerComponent.services || this.services;
            this.step = this.activeDrawerComponent.step || this.step;
            
            if(this.activeDrawerComponent.payment_id && this.activeDrawerComponent.step === 4) {
              this.payment = {
                id: this.activeDrawerComponent.payment_id
              }
              this.step = 4;
            }
          }
        },
        destroyed(){
            EventBus.$off('paymentReader', this.enablePayment);
            EventBus.$off('reset_buy_services_hb_model', this.close);
            EventBus.$off('HB-Merchandise:found-contact', this.showFoundOptions);
            EventBus.$off('HB-Merchandise:contact-info', this.setContactInfo);
            this.setPaymentConfigProperty({property: 'SHOW_SAVE_PAYMENT', propertyValue: false});

        },
        computed:{
            ...mapGetters({
                properties: 'propertiesStore/filtered',
                activeDrawerComponent: 'navigationStore/activeDrawerComponent'
            }),
            canNavigate(){
                if(this.step > 1 && this.step < 4) return true;
                return false;
            },
            generatedServices(){
                let s = [];
                for(let key in this.services){
                    if(this.services[key].product_id){
                        this.services[key].cost = this.services[key].price;
                        s.push(this.services[key]);
                    }
                }
                return s;
            },
            showSummaryPanel() {
              let tempServices = this.services;
              let hasSkip = false;
              hasSkip = Object.keys(tempServices).length === 1 && tempServices.hasOwnProperty('skip') ? true : false;
              return this.summary_panel === 'summary' && Object.keys(tempServices).length && !hasSkip && (this.step === 1 || this.step === 2)
            },
            canProgress(){
                for(let s in this.services){
                  if(this.services[s] && this.services[s].qty && this.services[s].price  ) return true;
                }
                return false;
            }
        },
        methods:{
          ...mapActions({
            setActiveDrawerComponent:'navigationStore/setActiveDrawerComponent',
            resetContactEvent: 'paymentsStore/resetContactEvent',
            setActiveMerchandiseTab: 'navigationStore/SET_ACTIVE_MERCHANDISE_TAB'
          }),
          ...mapMutations({
            setPaymentConfigProperty: 'paymentsStore/setPaymentConfigProperty'
        }),
          async enablePayment(response) {
            this.disable_payment = false;
            if(response && response.error) {
              this.showMessageNotification({ list: [response.error] });
            } else if(response && response.data && response.data.msg) {
              this.showMessageNotification({ type: 'success', description: response.data.msg.text });
              this.step = 4;
              this.setActiveDrawerComponent({ ...this.activeDrawerComponent, step: 4, payment_id:response.data.payment.id})
            }
          },
            async calculateTotal(){

                let r = await api.post(this, api.PROPERTIES + this.property.id + '/invoices', { lease_id: this.lease_id, InvoiceLines: this.generatedServices, dryrun : true});
                this.invoice = r.invoice;
            },
            async fetchPropertyConnections(device_id) {
              let r = await api.get(this.$app, api.PROPERTIES + this.property.id + "/connections/address?device_id=" + device_id);
              this.IPaddress = r.IPaddress;
              this.lan = r.lan;
            },

            async processPayment(payment_data){
              let response = {};
              let payloadResponse;
              let lanResponseData;
              let payment_id;
              let responseData;
              try {
                this.disable_payment = true;
                //Fetch Device IP and Port for Lan Connection
                if(payment_data?.paymentMethod?.device_id)
                  await this.fetchPropertyConnections(payment_data.paymentMethod.device_id);
                if(payment_data.paymentMethod && payment_data.paymentMethod.device_id && this.lan == 1){

                // Create an instance of the HTTPS agent with certificate verification enabled.
                      const certData = ``;

                      const httpsAgent = new https.Agent({
                      ca: [certData],
                      });

                      // Configure Axios to use the custom HTTPS agent.
                      const instance = axios.create({
                      httpsAgent
                      });
                      const endpoint = 'https://' + this.IPaddress + '?';
                      //Get PAX Request Payload from HB Backend server
                      payloadResponse = await api.post(this, api.PAYMENTS + "getLanSwipePayload",payment_data);
                      payment_id = payloadResponse.payment.id;
                      payment_data.payment.id = payment_id;
                      // Make the GET request using the custom Axios instance.
                      lanResponseData = await instance.get(endpoint + payloadResponse.payment.status_desc , {headers: {'Accept': 'text/plain',},});
                      payment_data.payment.PAXResponse = lanResponseData;
                      //console.log("lanResponseData :::",lanResponseData);
                      responseData = await api.post(this, api.PROPERTIES + this.property.id + '/invoices', {
                            lease_id: this.lease_id,
                            contact: this.contact,
                            payment: payment_data.payment,
                            paymentMethod: payment_data.paymentMethod,
                            InvoiceLines: this.generatedServices,
                            use_credits: payment_data.use_credits,
                            Invoices: payment_data.Invoices,
                        });
                      } else {
                        responseData = await api.post(this, api.PROPERTIES + this.property.id + '/invoices', {
                            lease_id: this.lease_id,
                            contact: this.contact,
                            payment: payment_data.payment,
                            paymentMethod: payment_data.paymentMethod,
                            InvoiceLines: this.generatedServices,
                            use_credits: payment_data.use_credits,
                            Invoices: payment_data.Invoices,
                        });
                      }
                        this.payment.id = responseData.payment.id || responseData.payment_id;
                          response = {
                            data: {
                              msg: {
                                id: this.$options.name,
                                text: 'Your Payments have been processed successfully',
                              },
                              payment: responseData.payment
                            }
                          };
                  } catch(err){
                  response = {
                    error: err,
                    contact_id: payment_data.contact_id
                  };
                  if(payment_data.paymentMethod && payment_data.paymentMethod.device_id && this.lan == 1)
                  {
                    responseData = await api.post(this, api.PAYMENTS + payment_id + "/lanSwipeResponse", response);
                  }
                } finally {
                  this.enablePayment(response);
                }
            },

            selectContact(contact){                
                this.contact = contact;
                if(this.property.id){
                  this.summary_panel = 'summary';
                }
                this.setActiveDrawerComponent({
                  ...this.activeDrawerComponent,
                  contact: this.contact,
                  step: this.step,
                  property_id: this.property.id
                })
            },
            showFoundOptions(found){
              console.log('showFoundOptions :>> ', found);
                if(found.length){
                    this.found = found;
                    this.summary_panel = 'found';
                }
            },

            resetContact() {
              this.contact = {
                  id: '',
                  first: '',
                  last: '',
                  email: '',
                  company:'',
                  rent_as_business:false,
                  Phones:[ {
                      type: 'Cell',
                      phone: '',
                      sms: true
                  }],
                  Addresses: [{
                      id: null,
                      type: 'Home',
                      address_id: '',
                      Address:{
                          address: '',
                          address2: '',
                          city: '',
                          neighborhood: '',
                          lat: '',
                          lng: '',
                          state: '',
                          zip: ''
                      }
                  }]
              };
              this.setActiveDrawerComponent({
                  ...this.activeDrawerComponent,
                  contact: this.contact
              });
              this.resetContactEvent();
            },

            setContactInfo(data){
                // if(this.property_id && this.properties){
                //     this.property = this.properties.find(p => p.id === this.property_id);
                // }

                this.summary_panel = 'summary';
                // this.step = 2;
                this.setActiveDrawerComponent({
                  ...this.activeDrawerComponent,
                  contact: this.contact,
                  step: this.step,
                  property_id: this.property.id
                })

                // this.setPropertyDetails(data.property_id)
            },
            async setServices(){
                await this.calculateTotal();
                if(this.services.skip === true){
                  
                  if(this.invoice.InvoiceLines.length && (this.invoice.InvoiceLines.reduce((inital, line) => inital + line.cost, 0) > 0)){
                    try {
                      let data = {
                        dryrun: false,
                        lease_id: this.invoice.lease_id,
                        Contact: this.contact,
                        date: this.invoice.date,
                        due: this.invoice.due,
                        period_start: this.invoice.period_start,
                        period_end: this.invoice.period_end,
                        InvoiceLines: this.invoice.InvoiceLines,
                        Property: this.property,
                      }
                      if(this.contact.NationalAccount.id){
                        const nextMonthLastDate = moment(this.invoice.due).add(1, 'month').endOf('month');
                        data.due = nextMonthLastDate.format('YYYY-MM-DD') 
                      }
                      data.InvoiceLines.property_id=this.Property
                      let r = await api.post(this, api.LEASES + this.invoice.lease_id + '/create-invoice',data );

                      this.showMessageNotification({ type: 'success', description: 'A new invoice has been created.' });
                      EventBus.$emit('payment_saved');
                      await EventBus.$emit('refetch_overview_payments');
                      this.$emit('close');
                      return;
                    } catch(err) {
                      this.showMessageNotification({ list: [{ msg: err }] });
                    }
                  }else{
                      this.showEmptyInvoiceLineWarning =  true
                  
                }
                this.show_invoice = true;
              } else {
                this.step = 2;
              }
              this.setActiveDrawerComponent({
                ...this.activeDrawerComponent,
                invoice: this.invoice,
                services: this.services,
                step: this.step
              })
            },

            close(){
                this.setActiveMerchandiseTab('merchandise');
                this.$emit('close');
            },

            goBack(){
                this.step--;
                this.setActiveDrawerComponent({
                  ...this.activeDrawerComponent,
                  step: this.step
                })
            },
            async getPropertyInfo(){
                let result = await api.get(this, api.PROPERTIES + this.property.id);
                this.property_detail = result.property;
            },
            removeService(params) {
              if (params) {
                let temp = JSON.parse(JSON.stringify(this.services))
                delete temp[params];
                this.services = temp;
                if (this.services.length) {
                  this.calculateTotal();
                } else {
                  this.invoice = {};
                }
                this.setActiveDrawerComponent({
                  ...this.activeDrawerComponent,
                  invoice: this.invoice,
                  services: this.services
                })
              }
            },
            async fetchGuestContact() {
              let r = await api.get(this, api.CONTACTS + 'check', { contact_id: this.contact.id, email: 'posguestuser@tenantinc.com' });
              if (r.contacts?.length) {
                let contactInfo = r.contacts[0];
                contactInfo.first = 'Guest';
                contactInfo.last = 'Checkout';
                this.selectContact(contactInfo);
              }
            },
            async isGuestUserEvent() {
              this.isGuestUser =! this.isGuestUser;
              if (this.isGuestUser) {
                await this.fetchGuestContact();
                this.setPaymentConfigProperty({property: 'SHOW_SAVE_PAYMENT', propertyValue: false});
              } else {
                await this.resetContact(false);
                this.setPaymentConfigProperty({property: 'SHOW_SAVE_PAYMENT', propertyValue: true});
              }
            },
            async handlePropertyChangeEvent() {
              this.invoice = {};
              this.services = {};
              this.setActiveDrawerComponent({
                ...this.activeDrawerComponent,
                invoice: this.invoice,
                services: this.services
              })
              await this.$refs.services.fetchProducts();
            },
            async saveInvoice() {
                this.services.skip = true
                 this.setServices();
            },
        },
        watch:{
            "property.id"(value){
                // console.log("value", value);
                this.property = JSON.parse(JSON.stringify(this.properties.find(p => p.id === value)));
                this.setActiveDrawerComponent({
                  ...this.activeDrawerComponent,
                  property_id: this.property.id
                })
                // this.getPropertyInfo();
            },
            summary_panel(){
                this.resizeStepper++;
            }
        }
    }

</script>

<style scoped>
  .new-tenant-walkthrough-container{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100%;
    position: relative;
    overflow: hidden;
  }
  .new-tenant-walkthrough-content-section{
    align-items:stretch;
    display: flex;
    overflow: hidden;
    height: 100%;

  }
  .new-tenant-walkthrough{
    background: #F9FAFB;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .new-tenant-info-panel{
    flex: 0 0 510px;
    height: 100%;
    background-color: #F9FAFB;
    overflow: scroll;
    padding-bottom: 60px;
    /* border-left: 1px solid #E1E6EA; */
  }
  .walkthrough-body{
    padding: 0px;
    flex: 1;
  }

  .nav-drawer-header{
    flex: 0 1 auto
  }
  .onboarding-container{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    flex: 1;
  }

  .facility-text{
    font-size:14px;
  }
</style>



<style>
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>