export const webNotificationMixin = {
    data() {
        return {
            isSupported: false,
        };
    },
    mounted() {
        this.checkNotificationSupport();
    },
    methods: {
        checkNotificationSupport() {
            if ("Notification" in window) {
                this.isSupported = true;
            }
        },
        showNotification(options) {
            if (this.isSupported) {
                this.show(options);
            }
        },
        show(options) {
            if ("Notification" in window) {
                Notification.requestPermission().then((permission) => {
                    if (permission === "granted") {
                        new Notification(options.title, {
                            dir: options.dir,
                            lang: options.lang,
                            renotify: options.renotify,
                            tag: options.tag,
                            icon:options.icon
                        });
                    }
                });
            }
        },
    },
  };