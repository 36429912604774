<template>
  <hb-modal
    size="medium"
    :title="`Generate Notices`"
    v-model="dialog"
    confirmation
    @close="$emit('close')"
    show-help-link
  >
    <template v-slot:content>
      <div class="content py-4 px-6 d-flex flex-column">
        <div v-html="popupDescription"></div>
      </div>
    </template>
    <template v-slot:right-actions>
      <hb-btn :loading="isLoading" @click="generateNotices">Continue</hb-btn>
    </template>
  </hb-modal>
</template>
<script>
import api from "../../../assets/api.js";

import { EventBus } from "../../../EventBus.js";
import { mapGetters } from "vuex";
import { notificationMixin } from "@/mixins/notificationMixin.js";

import moment from "moment";

export default {
  name: "GenerateNotices",
  components: {},
  mixins: [notificationMixin],
  props: {},
  data() {
    return {
      dialog: false,
      rentQueueData: {},
      popupDescription: "",
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      selectedProperty: "revManStore/getSelectedProperty",
    }),
  },
  methods: {
    show(data) {
      this.dialog = true;
      this.rentQueueData = data.rentQueueData;
      this.popupDescription = this.getGenerateNoticesConfirmationMessage(
        data.approveByMonth
      );
    },

    getGenerateNoticesConfirmationMessage() {
      const action = "Generate Notices and Approve all rent changes";
      const detail = moment(this.rentQueueData.rent_change_deployment_month).format("MMMM YYYY");
      return `Are you sure you want to <span class="font-weight-medium">${action}</span> for <span class="font-weight-medium">${detail}</span>?`;
    },

    async generateNotices() {
      this.isLoading = true;
      let body = {
        month: this.rentQueueData.rent_change_deployment_month,
      };
      try {
        await api
          .put(
            this,
            api.getPropertyRentManagementUrl(this.selectedProperty) +
              `rent-changes/generate`,
            body
          )
          .then(() => {
              this.showMessageNotification({
                type: "success",
                description: `All notices for ${moment(
                  this.rentQueueData.rent_change_deployment_month
                ).format("MMMM YYYY")} are being generated and sent. Please check back after few minutes`,
              });
              new Promise(resolve => setTimeout(resolve, 10000)).then(() => {
                EventBus.$emit("rentChangeQueueEvents", "refreshTable");
              });
              this.dialog = false;
          })
          .catch((error) => {
            console.log(error);
            this.showMessageNotification({
              type: "error",
              description: `Generate Notices failed ${ error ? `because ${error}` : ''}`,
            });
          });
      } catch (error) {
        console.log(error, " Generate API failed");
      }
      this.isLoading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  gap: 16px;
}
</style>
