<template>
    <div>
        {{ percentage.toFixed(2) }}%
    </div>
    <!--
    <div class="facility-search--occupancy">
        <span class="filled-circle-label" :class="getClass()">
            <span class="filled-circle--circle">
                <span class="icon">&nbsp;</span>
            </span>
            <span class="filled-circle--label">{{ percentage.toFixed(2) }}%</span>
        </span>
    </div>
    -->
</template>
<script>
export default {
    data() {
        return {

        }
    },
    props: [
        'leases',
        'units'
    ],
    computed: {
        percentage() {
            return this.$props.leases && this.$props.units ? (this.$props.leases / this.$props.units) * 100 : 0;
        }
    },
    methods: {
        getClass() {
            if (this.percentage >= 0 && this.percentage <= 33){
                return "hb-red";
            }
            if (this.percentage >= 34 && this.percentage <= 66){
                return "hb-gray";
            }
            if (this.percentage >= 67 && this.percentage <= 100){
                return "hb-green";
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.facility-search--occupancy {
    .filled-circle-label {
        padding: 3px 5px;
        border-radius: 15px;
        vertical-align: middle;
        display: inline-block;
        .icon {
            display:inline-block;
            width: 100%;
            height: 100%;
        }
        &.hb-green {
            border-color:#414F3E;
            background-color: #E6F0E1;
            .icon {
                background-color: #414F3E;
                border: 2px solid #fff;
                border-radius: 15px;
            }
        }

        &.hb-gray {
            background-color: #F5F6F8;
            border-color: #232B35;
            .icon {
                background-color: #232B35;
                border: 2px solid #fff;
                border-radius: 15px;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                border-top: none;
                height: 50%;
                top:10%; 
                position: relative;
            }
        }

        &.hb-red {
            border-color:#583C35;
            background-color: #F8EBE6;
            color: #583C35;
            .icon {
                background-color: #F8EBE6;
                border: 2px solid #F8EBE6;
                border-radius: 15px;
            }
        }

        .filled-circle--circle {
            display: inline-block;
            overflow: hidden;
            border-radius: 15px;
            width: 20px;
            height: 20px;
            border: 2px solid;
            vertical-align: middle;
        }

        .filled-circle--label {
            padding: 0 5px;
            display: inline-block;
            vertical-align: middle;
        }
    }
}
</style>