<template>
  <div>
    <hb-modal 
        v-model="dialog"
        size="large" 
        :title="isEdit ? 'Edit Journal Template' : 'Add Journal Template'"
        @close="$emit('close')"
        :footerCancelOption="isEdit ? false : true"
        show-help-link
    >
        <template v-slot:subheader>
           <span v-if="isEdit">Hover over and click on any field to edit.</span> 
           <span v-else>Fill out the fields below to add a journal template.</span>
        </template>
        <template v-slot:content>
            <v-row class="mr-0 pl-3">
                <v-col class="field-label pl-4" cols="3">
                    <div>
                        <span class="font-weight-medium">Event Name</span>
                    </div>
                </v-col>
                <v-col class="px-4 py-3" cols="9">
                  <span>{{journal_event.event_name}}</span>
                </v-col>
                <v-col cols="12" class="pa-0">
                    <v-divider></v-divider>
                </v-col>
            </v-row>
            <v-row class="mr-0 pl-3">
                <v-col class="field-label pl-4" cols="3">
                    <div>
                    <span class="font-weight-medium">Credit*</span>
                    </div>
                </v-col>
                <v-col class="pa-0" cols="9">
                  <hoverable-edit-field
                    :activeEditing="activelyEditing == 'credit'"
                    name="credit"
                    :customPadding="!isEdit ? 'pt-1 pb-3 px-4' : null"
                  >
                  <!-- Editable mode -->
                  <template slot="editingMode">
                    <v-row class="pa-0 ma-0">
                      <v-col cols="12" class="pa-0 pb-3">
                        <span class="hb-text-light">An amount received, listed on the right column of your journal template.</span>
                      </v-col>
                      <v-col class="pa-0 ma-0 d-flex align-center" cols="9">
                        <span class="font-weight-medium">GL Account*:</span>
                        <v-select
                          :error-messages="errors.first('credit_account_id')"
                          :items="gl_accounts"
                          hide-details
                          single-line
                          label="Select GL Account"
                          v-validate="'required'"
                          id="credit_account_id"
                          item-value="id"
                          name="credit_account_id"
                          data-vv-as="credit gl account"
                          v-model="journal_event.credit_account_id"
                          clearable
                          attach
                          class="pt-0 mt-0 pl-2 hb-text-night"
                        >
                        <template v-slot:selection="{ item }">
                            <span class="text-capitalize">{{item.code}}</span>
                        </template>
                        <template v-slot:item="{ item }">
                            <span class="text-capitalize">{{item.code}} - {{item.name}}</span>
                        </template>
                        </v-select>
                      </v-col>
                      <v-col cols="12" class="pa-0 py-3">
                        <span class="hb-text-light">Use overrides to create GL account related exceptions.</span>
                      </v-col>

                      <v-col cols="12" class="pa-0 pb-3">
                        <!-- <a class="hb-link"> + Add Override</a> -->
                        <v-card
                          v-if="journal_event.creditOverrides && journal_event.creditOverrides.length > 0"
                          v-for="(override, i) in journal_event.creditOverrides" :key="'credit_override' + i"
                          class="pb-5 mb-4 rounded-card"
                          outlined
                        >
                          <v-row class="pa-0 ma-0">
                            <span class="font-weight-medium pt-4 pl-4">Override {{i+1}}</span>
                            <span class="clsbtn"><hb-btn icon tooltip="Delete Override" @click="removeOverride('credit',i)" active-state-off>mdi-close</hb-btn></span>
                          </v-row>
                          <v-row class="ma-0" no-gutters>
                            <v-col cols="11" class="pt-2 pl-4 d-flex align-center">
                              <span class="font-weight-medium">Product Type*:</span>
                              <v-select
                                :error-messages="errors.first('general.credit_product_type_' + i)"
                                :items="product_types"
                                item-text="text"
                                item-value="value"
                                label="Product Type"
                                :id="'credit_product_type_' + i"
                                :name="'credit_product_type_' + i"
                                data-vv-as="credit product type"
                                data-vv-scope="general"
                                single-line
                                attach
                                v-validate="'required'"
                                class="pa-0 ma-0 pl-2 hb-text-night"
                                hide-details
                                v-model="journal_event.creditOverrides[i].product_type"
                                @change="updateProducts(journal_event.creditOverrides[i].product_type,'credit',i)"
                              ></v-select>
                            </v-col>
                          </v-row>
                          <v-row class="ma-0" no-gutters>
                            <v-col cols="11" class="pt-2 pl-4 d-flex align-center">
                              <span class="font-weight-medium">Product Name*:</span>
                              <v-select
                                :error-messages="errors.first('general.credit_product_name_' + i)"
                                :items="journal_event.creditOverrides[i].products"
                                item-value="id"
                                item-text="name"
                                hide-details
                                attach
                                v-validate="'required'"
                                single-line
                                label="Select Name"
                                :id="'credit_product_name_' + i"
                                :name="'credit_product_name_' + i"
                                data-vv-as="credit product name"
                                data-vv-scope="general"
                                v-model="journal_event.creditOverrides[i].product_id"
                                @change="updateAccounts(journal_event.creditOverrides[i].product_id,i,'credit')"
                                class="pa-0 ma-0 pl-2 hb-text-night"
                              >
                              </v-select>
                            </v-col>
                          </v-row>
                          <v-row class="ma-0" no-gutters>
                            <v-col cols="11" class="pt-2 pl-4 d-flex align-center">
                              <span class="font-weight-medium">GL Account*:</span>
                                <v-select
                                  :error-messages="errors.first('general.override_credit_account_id_' + i)"
                                  :items="gl_accounts"
                                  label="Gl Account"
                                  item-value="id"
                                  v-validate="'required'"
                                  :id="'override_credit_account_id_' + i"
                                  :name="'override_credit_account_id_' + i"
                                  data-vv-as="override credit gl account"
                                  data-vv-scope="general"
                                  single-line
                                  attach
                                  class="pa-0 ma-0 pl-2 hb-text-night"
                                  hide-details
                                  v-model="journal_event.creditOverrides[i].override_gl_account_id"
                                >
                                  <template v-slot:selection="{ item }">
                                    <span class="text-capitalize">{{item.code}}</span>
                                  </template>
                                  <template v-slot:item="{ item }">
                                    <span class="text-capitalize">{{item.code}} - {{item.name}}</span>
                                  </template>
                                </v-select>
                            </v-col>
                          </v-row>
                        </v-card>
                        <hb-link class="pt-4 mt-6" @click="addoverride('credit')">+ Add Override</hb-link> 
                      </v-col>

                    </v-row>
                  </template>
                  <!-- Read mode -->
                  <template slot="readMode" v-if="isEdit">
                    <span class="font-weight-medium">GL Account*:</span>
                      <span v-if="journal_event.credit_account_active == 0" class="px-1">
                        <hb-icon color="#fb4c4c" small>mdi-alert</hb-icon>
                      </span>
                      <span class="text-capitalize" :class="{'hb-text-error' : journal_event.credit_account_active == 0}">{{ journal_event.credit_account_code }}</span>
                    <v-col cols="10" class="pa-0 pb-3 pt-5">
                      <v-card
                        v-if="journal_event.creditOverrides && journal_event.creditOverrides.length > 0"
                        v-for="(override, i) in journal_event.creditOverrides" :key="'override' + i"
                        class="pb-5 mb-2 rounded-card"
                        outlined
                      >
                        <v-row class="pa-0 ma-0">
                          <span class="font-weight-medium pt-4 pl-4">Override {{i+1}}</span>
                        </v-row>
                        <v-row class="pl-3 pt-1">
                          <span class="pl-4 font-weight-medium">
                            {{ "Product Type*:" }}
                          </span>
                          <span class="pl-2 text-capitalize">
                            {{ getProductType(journal_event.creditOverrides[i].product_type) }}
                          </span>
                        </v-row>
                        <v-row class="pl-3 pt-1">
                          <span class="pl-4 font-weight-medium hb-text-lighter">
                            {{ "Product Name*:" }}
                          </span>
                          <span class="pl-2">
                            {{ journal_event.creditOverrides[i].product_name }}
                          </span>
                        </v-row>
                        <v-row class="pl-3 pt-1">
                          <span class="pl-4 font-weight-medium">
                            {{ "GL Account*:" }}
                          </span>
                          <span v-if="journal_event.creditOverrides[i].override_account_active == 0" class="pl-1">
                            <hb-icon color="#fb4c4c" small>mdi-alert</hb-icon>
                          </span>
                          <span class="pl-2" :class="{'hb-text-error' : journal_event.creditOverrides[i].override_account_active == 0}">{{ journal_event.creditOverrides[i].override_gl_account_code }}</span>
                        </v-row>
                      </v-card>
                    </v-col>
                  </template>
                  </hoverable-edit-field>
                </v-col>
                <v-col cols="12" class="pa-0">
                    <v-divider></v-divider>
                </v-col>
            </v-row>
            <v-row class="mr-0 pl-3">
                <v-col class="field-label pl-4" cols="3" style="min-height: 22em">
                    <div>
                        <span class="font-weight-medium">Debit*</span>
                    </div>
                </v-col>
                <v-col class="pa-0" cols="9">
                    <hoverable-edit-field
                        :activeEditing="activelyEditing == 'debit'"
                        name="debit"
                        :customPadding="!isEdit ? 'pt-1 pb-3 px-4' : null"
                    >
                      <!-- Editable mode -->
                      <template slot="editingMode">
                        <v-row class="pa-0 ma-0">
                          <v-col cols="12" class="pa-0 pb-3">
                            <span class="hb-text-light">An amount owed, listed on the left column of your journal template.</span>
                          </v-col>
                          <v-col class="pa-0 ma-0 d-flex align-center" cols="9">
                            <span class="font-weight-medium">GL Account*:</span>
                            <v-select
                              :error-messages="errors.first('debit_account_id')"
                              :items="gl_accounts"
                              hide-details
                              single-line
                              label="Select GL Account"
                              v-validate="'required'"
                              id="debit_account_id"
                              item-value="id"
                              attach
                              name="debit_account_id"
                              data-vv-as="debit gl account"
                              data-vv-scope="general"
                              v-model="journal_event.debit_account_id"
                              clearable
                              class="pt-0 mt-0 pl-2 hb-text-night"
                            >
                            <template v-slot:selection="{ item }">
                                <span class="text-capitalize">{{item.code}}</span>
                            </template>
                            <template v-slot:item="{ item }">
                                <span class="text-capitalize">{{item.code}} - {{item.name}}</span>
                            </template>
                            </v-select>
                          </v-col>
                          <v-col cols="12" class="pa-0 py-3">
                            <span class="hb-text-light">Use overrides to create GL account related exceptions.</span>
                          </v-col>

                          <v-col cols="12" class="pa-0 pb-3">
                        <!-- <a class="hb-link"> + Add Override</a> -->
                            <v-card
                              v-if="journal_event.debitOverrides && journal_event.debitOverrides.length > 0"
                              v-for="(override, i) in journal_event.debitOverrides" :key="'override' + i"
                              class="pb-5 mb-4 rounded-card"
                              outlined
                            >
                              <v-row class="pa-0 ma-0">
                                <span class="font-weight-medium pt-4 pl-4">Override {{i+1}}</span>
                                <span class="clsbtn"><hb-btn icon tooltip="Delete Override" @click="removeOverride('debit',i)" active-state-off>mdi-close</hb-btn></span>
                              </v-row>
                              <v-row class="ma-0" no-gutters>
                                <v-col cols="11" class="pt-2 pl-4 d-flex align-center">
                                <span class="font-weight-medium">Product Type*:</span>
                                <v-select
                                  :error-messages="errors.first('general.debit_product_type_' + i)"
                                  :items="product_types"
                                  item-text="text"
                                  item-value="value"
                                  label="Product Type"
                                  v-validate="'required'"
                                  :id="'debit_product_type_' + i"
                                  :name="'debit_product_type_' + i"
                                  data-vv-as="debit product type"
                                  data-vv-scope="general"
                                  single-line
                                  attach
                                  class="pa-0 ma-0 pl-2 hb-text-night"
                                  hide-details
                                  v-model="journal_event.debitOverrides[i].product_type"
                                  @change="updateProducts(journal_event.debitOverrides[i].product_type,'debit',i)"
                                ></v-select>
                                </v-col>
                              </v-row>
                              <v-row class="ma-0" no-gutters>
                                <v-col cols="11" class="pt-2 pl-4 d-flex align-center">
                                  <span class="font-weight-medium">Product Name*:</span>
                                  <v-select
                                    :error-messages="errors.first('general.debit_product_name_' + i)"
                                    :items="journal_event.debitOverrides[i].products"
                                    item-value="id"
                                    item-text="name"
                                    hide-details
                                    single-line
                                    attach
                                    v-validate="'required'"
                                    label="Select Name"
                                    :id="'debit_product_name_' + i"
                                    :name="'debit_product_name_' + i"
                                    data-vv-as="debit product name"
                                    data-vv-scope="general"
                                    v-model="journal_event.debitOverrides[i].product_id"
                                    @change="updateAccounts(journal_event.debitOverrides[i].product_id,i,'debit')"
                                    class="pa-0 ma-0 pl-2 hb-text-night"
                                  >
                                  </v-select>
                                </v-col>
                              </v-row>
                              <v-row class="ma-0" no-gutters>
                                <v-col cols="11" class="pt-2 pl-4 d-flex align-center">
                                  <span class="font-weight-medium">Gl Account*:</span>
                                  <v-select
                                    :error-messages="errors.first('general.override_debit_account_id_' + i)"
                                    :items="gl_accounts"
                                    label="Gl Account"
                                    v-validate="'required'"
                                    item-value="id"
                                    :id="'override_debit_account_id_' + i"
                                    :name="'override_debit_account_id_' + i"
                                    data-vv-as="override debit gl account"
                                    data-vv-scope="general"
                                    single-line
                                    attach
                                    class="pa-0 ma-0 pl-2 hb-text-night"
                                    hide-details
                                    v-model="journal_event.debitOverrides[i].override_gl_account_id"
                                  >
                                    <template v-slot:selection="{ item }">
                                      <span class="text-capitalize">{{item.code}}</span>
                                    </template>
                                    <template v-slot:item="{ item }">
                                      <span class="text-capitalize">{{item.code}} - {{item.name}}</span>
                                    </template>
                                  </v-select>
                                </v-col>
                              </v-row>
                            </v-card>
                          <hb-link class="pt-4 mt-6" @click="addoverride('debit')">+ Add Override</hb-link> 
                        </v-col>

                        </v-row>
                      </template>
                      <!-- Read mode -->
                      <template slot="readMode" v-if="isEdit">
                        <span class="font-weight-medium">GL Account*:</span>
                          <span v-if="journal_event.debit_account_active == 0" class="px-1">
                            <hb-icon color="#fb4c4c" small>mdi-alert</hb-icon>
                          </span>
                          <span class="text-capitalize" :class="{'hb-text-error' : journal_event.debit_account_active == 0}">{{ journal_event.debit_account_code }}</span>
                        <v-col cols="10" class="pa-0 pb-3 pt-5">
                            <v-card
                              v-if="journal_event.debitOverrides && journal_event.debitOverrides.length > 0"
                              v-for="(override, i) in journal_event.debitOverrides" :key="'override' + i"
                              class="pb-5 mb-2 rounded-card"
                              outlined
                            >
                            <v-row class="pa-0 ma-0">
                                <span class="font-weight-medium pt-4 pl-4">Override {{i+1}}</span>
                            </v-row>
                            <v-row class="pl-3 pt-1">
                              <span class="pl-4 font-weight-medium  ">
                              {{ "Product Type*:" }}
                              </span>
                              <span class="pl-2 text-capitalize">
                                 {{ getProductType(journal_event.debitOverrides[i].product_type) }}
                              </span>
                            </v-row>
                            <v-row class="pl-3 pt-1">
                              <span class="pl-4 font-weight-medium hb-text-lighter">
                              {{ "Product Name*:" }}
                              </span>
                              <span class="pl-2">
                                 {{ journal_event.debitOverrides[i].product_name }}
                              </span>
                            </v-row>
                            <v-row class="pl-3 pt-1">
                              <span class="pl-4 font-weight-medium">
                              {{ "GL Account*:" }}
                              </span>
                              <span v-if="journal_event.debitOverrides[i].override_account_active == 0" class="pl-1">
                                <hb-icon color="#fb4c4c" small>mdi-alert</hb-icon>
                              </span>
                              <span class="pl-2" :class="{'hb-text-error' : journal_event.debitOverrides[i].override_account_active == 0}">{{ journal_event.debitOverrides[i].override_gl_account_code }}</span>
                            </v-row>
                            </v-card>
                        </v-col>
                      </template>
                    </hoverable-edit-field>
                </v-col>
            </v-row>

        </template>
        <template v-slot:actions>
            <hb-btn v-if="!isEdit" color="primary" @click="showConfirmDialog" :disabled="isLoading($options.name)" >Save</hb-btn>
            <hb-btn v-else color="secondary" @click="dialog=false">Close</hb-btn>
            <span v-show="isLoading($options.name)">
                <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
            </span>       
        </template>
    </hb-modal>
    <hb-modal v-model="showConfirmation" show-help-link v-if="showConfirmation" size="medium" title="Confirm Save" confirmation>
        <template v-slot:content>
          <div class="py-4 px-6">
            You are about to save new journal event details:
            <br /><br />
            Are you sure you want to save these details?
          </div>
        </template>
        <template v-slot:actions>
            <hb-btn color="primary" :disabled="isLoading('generate')" @click="saveEvent">Continue</hb-btn>
            <span v-show="isLoading('generate')" >
                <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
            </span>
        </template>
      </hb-modal>
  </div>
</template>

<script>

  import Loader from '../../assets/CircleSpinner.vue';
  import api from '../../../assets/api.js';
  import HoverableEditField from "../../assets/HoverableEditField.vue";
  import { EventBus } from '../../../EventBus.js';
  import { notificationMixin } from "../../../mixins/notificationMixin.js";

  export default {
    name : 'EditJournalEvent',
    components : {
      Loader,
      HoverableEditField
    },
    mixins:[notificationMixin],
    props : {
      selected : {
        type : Object,
        default : null
      },
      isEdit: {
        type: Boolean,
        default: false
      },
      value: {
        type: Boolean
      },
      selectedTemplate : {
        type: Object
      }
    },
    data() {
      return {
        journal_event: {
          id: null,
          event_name: '',
          credit_account_code: '',
          debit_account_code: '',
          credit_account_id: null,
          debit_account_id: null,
          Overrides: [],
          debitOverrides: [],
          creditOverrides:[]
        },
        previous_state:{},
        gl_accounts: [],
        product_types: [
          { text: 'Fee', value: 'late' },
          { text: 'Insurance', value: 'insurance' },
          { text: 'Merchandise', value: 'product' }
        ],
        products: [],
        activelyEditing:'',
        showConfirmation: false,
      }
    },
    async created() {
        EventBus.$on('saveData', this.showConfirmDialog);
        EventBus.$on('activeEdit', this.setActiveEditName);
        await this.prepareData();
    },
    destroyed () {
      EventBus.$off('activeEdit', this.setActiveEditName);
      EventBus.$off('saveData', this.showConfirmDialog);
      this.closeMessageNotification();
    },
    computed: {
      dialog: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        }
      },
    },
    methods: {
      emitClose(){
          setTimeout(() => {
              this.$emit('close');
          }, 250);
      },
      getProductType(type) {
        switch(type) {
          case 'product':
            return "Merchandise";
          break;
          case 'late':
            return 'Fee';
          break;
          case 'insurance':
            return type;
          break;
        }
      },
      async prepareData(){
        await this.fetchGLAccounts();
        this.journal_event = JSON.parse(JSON.stringify(this.selected));
        this.$set(this.journal_event, 'creditOverrides', this.journal_event.Overrides.filter(e => e.credit_debit_type ==='credit'));
        this.$set(this.journal_event, 'debitOverrides', this.journal_event.Overrides.filter(e => e.credit_debit_type ==='debit'));
        await this.setOverrideProducts();
        this.previous_state = JSON.parse(JSON.stringify(this.journal_event));
      },
      async fetchGLAccounts(){
        let p = await api.get(this, api.ACCOUNTING + 'accounts?all=true');
        this.gl_accounts = p.accounts
      },

      setActiveEditName(name) {
        if(!name) {
          this.journal_event = JSON.parse(JSON.stringify(this.previous_state));
        }
        if(name === 'credit'){
          this.journal_event.debitOverrides = this.previous_state.debitOverrides;
        }
        if(name === 'debit'){
          this.journal_event.creditOverrides = this.previous_state.creditOverrides;
        }
        this.activelyEditing = name;
      },

      displayMessageNotification(message, isError) {
          if(!isError) {
              this.showMessageNotification({ id: this.$options.name, type: "success", description: message });
          } else {
              this.showMessageNotification({ id: this.$options.name, type: "error", list: [{ msg: message }], });
          }
      },
      addoverride(type){
        if(type === 'credit'){
          this.journal_event.creditOverrides.push({
            product_type : '',
            product_id : '',
            override_gl_account_id: '',
            credit_debit_type: 'credit',
            actual_gl_account_id: this.journal_event.credit_account_id,
            products:[],
            product_name: '',
            override_gl_account_code: '',
          })
        }else{
          this.journal_event.debitOverrides.push({
            product_type : '',
            product_id : '',
            override_gl_account_id: '',
            credit_debit_type: 'debit',
            actual_gl_account_id: this.journal_event.debit_account_id,
            products: [],
            product_name: '',
            override_gl_account_code: '',
          })
        }
      },
      removeOverride(type,index){
        if(type === 'credit'){
          this.journal_event.creditOverrides.splice(index,1);
        }
        else{
          this.journal_event.debitOverrides.splice(index,1);
        }
      },
      async showConfirmDialog(){
        let general_status = await this.$validator.validateAll('general');
        if(!general_status){
          return;
        }
        this.showConfirmation = true;
      },
      async saveEvent(){
            let general_status = await this.$validator.validateAll('general');
            if(!general_status){
              this.showConfirmation = false;
              return;
            }
            this.validate(this).then(status => {

                if(!status) return;

                if(!this.isLoading(this.$options.name)){

                    this.errorClear(this.$options.name);
                    this.startLoading(this.$options.name);
                    this.journal_event.creditOverrides.forEach(e => {
                      if(!e.actual_gl_account_id) e.actual_gl_account_id = this.journal_event.credit_account_id;
                      if(e.product_id === -1) e.product_id = null;
                    });
                    this.journal_event.debitOverrides.forEach(e => {
                      if(!e.actual_gl_account_id) e.actual_gl_account_id = this.journal_event.debit_account_id;
                      if(e.product_id === -1) e.product_id = null;
                    });
                    let overrides = [];
                    this.journal_event.creditOverrides.forEach(x => {
                      overrides.push(Object.assign({},x));
                    });
                    this.journal_event.debitOverrides.forEach(x => {
                      overrides.push(Object.assign({},x));
                    });
                    overrides.forEach(e => delete e.products);
                    let data = {
                      gl_account_credit_id: this.journal_event.credit_account_id,
                      gl_account_debit_id: this.journal_event.debit_account_id,
                      overrides: overrides
                    }
                    
                    try {
                        api.put(this, api.ACCOUNTING + 'template/' + this.selectedTemplate.id + '/events/' + this.journal_event.id, data).then(r => {
                          let msg = '';
                          this.showConfirmation = false;
                          this.activelyEditing = '';
                          this.$emit('refetch');
                          this.updateData();
                          msg = 'You’ve edited your journal template.'
                          if(!this.isEdit){
                            msg = 'You’ve added a journal template.' 
                            this.emitClose()
                          };
                          this.displayMessageNotification(msg, false);
                        });
                        
                    } catch (error) {
                        this.displayMessageNotification(error, true);
                    }
                }
            })

        },
        async updateData(){
          await this.updateAccountCodes();
          this.updateProductNames();
          this.journal_event.Overrides = [...this.journal_event.creditOverrides, ...this.journal_event.debitOverrides];
          this.previous_state = JSON.parse(JSON.stringify(this.journal_event));
        },
        updateProducts(prod_type,type,index){
          api.post(this, api.PRODUCTS + 'search', {types:[prod_type]}).then(r =>{
            if(type === 'credit'){
              this.journal_event.creditOverrides[index].products = r.products;
              this.journal_event.creditOverrides[index].products.unshift({id: -1, name: 'All Products'});
              this.journal_event.creditOverrides[index].product_id = '';
              this.journal_event.creditOverrides[index].override_gl_account_id = '';
            }
            else{
              this.journal_event.debitOverrides[index].products = r.products;
              this.journal_event.debitOverrides[index].products.unshift({id: -1, name: 'All Products'});
              this.journal_event.debitOverrides[index].product_id = '';
              this.journal_event.debitOverrides[index].override_gl_account_id = '';
            }
          })
        },
        updateAccounts(id,index,type){
          if(type === 'credit'){
            if(this.previous_state.creditOverrides[index] && this.previous_state.creditOverrides[index].product_id === id ){
                this.journal_event.creditOverrides[index].override_gl_account_id = this.previous_state.creditOverrides[index].override_gl_account_id;
                return;
            }
            let p = (id != -1) ? this.journal_event.creditOverrides[index].products.find(e => e.id === id) : {};
            let glAccountExist = p && p.income_account_id ? this.gl_accounts.find(a => a.id === p.income_account_id) : {};
            this.journal_event.creditOverrides[index].override_gl_account_id = glAccountExist && glAccountExist.id? glAccountExist.id : '';
          }else{
            if(this.previous_state.debitOverrides[index] && this.previous_state.debitOverrides[index].product_id === id ){
                this.journal_event.debitOverrides[index].override_gl_account_id = this.previous_state.debitOverrides[index].override_gl_account_id;
                return;
            }
            let p = (id != -1) ? this.journal_event.debitOverrides[index].products.find(e => e.id === id) : {};
            let glAccountExist = p && p.income_account_id ? this.gl_accounts.find(a => a.id === p.income_account_id) : {};
            this.journal_event.debitOverrides[index].override_gl_account_id = glAccountExist && glAccountExist.id? glAccountExist.id : ''; 
          }
        },
        async fetchProducts(type){
          let p = await api.post(this, api.PRODUCTS + 'search', {types:[type]})
          return p.products;
        },
        async setOverrideProducts(){
          if(this.journal_event.creditOverrides && this.journal_event.creditOverrides.length > 0){
            for(const e of this.journal_event.creditOverrides){
              this.$set(e, 'override_gl_account_code', this.gl_accounts.find(a => a.id === e.override_gl_account_id).code);
              let products = await this.fetchProducts(e.product_type);
              let selected_product = products.find(p => p.id === e.product_id);
              products.unshift(Object.assign({},{id: -1, name: 'All products'}));
              if(!selected_product && !e.product_id){
                selected_product = Object.assign({},{id: -1, name: 'All products'});
                e.product_id = -1;
              }
              this.$set(e, 'products', products);
              this.$set(e, 'product_name', selected_product.name);
            }
          }
          if(this.journal_event.debitOverrides && this.journal_event.debitOverrides.length > 0){
            for(const e of this.journal_event.debitOverrides){
              this.$set(e, 'override_gl_account_code', this.gl_accounts.find(a => a.id === e.override_gl_account_id).code);
              let products = await this.fetchProducts(e.product_type);
              let selected_product = products.find(p => p.id === e.product_id);
              products.unshift(Object.assign({},{id: -1, name: 'All products'}));
              if(!selected_product && !e.product_id){
                selected_product = Object.assign({},{id: -1, name: 'All products'});
                e.product_id = -1;
              }
              this.$set(e, 'products', products);
              this.$set(e, 'product_name', selected_product.name);
            }
          }
        },
        updateProductNames(){
          this.journal_event.creditOverrides.forEach(e => {
            if(e.product_id){
              e.product_name = e.products.find(p => p.id === e.product_id).name;
            }
            else{
              e.product_id = -1;
              e.product_name = "All Products";
            }
          });
          this.journal_event.debitOverrides.forEach(e => {
            if(e.product_id){
              e.product_name = e.products.find(p => p.id === e.product_id).name;
            }
            else{
              e.product_id = -1;
              e.product_name = "All Products";
            }
          })
        },
        async updateAccountCodes(){
          this.journal_event.credit_account_code = this.gl_accounts.find(e => e.id === this.journal_event.credit_account_id).code;
          this.journal_event.debit_account_code = this.gl_accounts.find(e => e.id === this.journal_event.debit_account_id).code;
          this.journal_event.creditOverrides.forEach(e => {
            e.override_gl_account_code = this.gl_accounts.find(a => a.id === e.override_gl_account_id).code;
          });
          this.journal_event.debitOverrides.forEach(e => {
            e.override_gl_account_code = this.gl_accounts.find(a => a.id === e.override_gl_account_id).code;
          });
        },
    },
  }

</script>

<style scoped>
.rounded-card{
    border-radius:8px;
}
.clsbtn{
  position: absolute;
  padding-top: 6px;
  right: 10px;
}
</style>
