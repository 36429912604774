<template>
  <div>
    <p class="hb-text-night-light">
      We need to collect some information about you and your business to create
      an account with our payment service provider.
    </p>
    <v-expansion-panels v-model="panel" multiple>
      <hb-expansion-panel :topMargin="false">
        <template v-slot:title>Entity Information</template>
        <template v-slot:content>
          <hb-form
            label="Entity Type"
            description="Please select the entity type that best describes your business."
            required
            full
            extraLarge
            @change="isIndexEqual(index)"
          >
            <hb-radio-group
              v-model="form.general.entity_type"
              v-validate="'required'"
              data-vv-name="entity_selection"
              data-vv-as="Entity"
              data-vv-scope="default"
              :errors="errors.has('default.entity_selection')"
            >
              <HbRadio
                v-for="(type, index) in entityTypes"
                :key="index"
                :value="type.value"
                @change="isIndexEqual(index)"
              >
                <template v-slot:label>
                  <div>
                    <strong>{{ type.label }}</strong>
                  </div>
                </template>
                <template v-slot:description>
                  <div class="hb-font-body hb-text-night">
                    {{ type.description }}
                  </div>
                  <div class="hb-text-night-light hb-font-caption mt-1">
                    {{ type.exampleText }}
                  </div>
                  <hb-radio-group v-model="form.general.entity_sub_type"
                    v-validate="'required'"
                    data-vv-name="entity_public_selection"
                    data-vv-as="Entity Subtype"
                    data-vv-scope="entity_public"
                    :errors="errors.has('entity_public.entity_public_selection')"
                  >
                    <div
                      v-if="type.value === 'public_registered_business' && ((entityTypes.findIndex(entity => entity.value === 'public_registered_business')) == selectedIndex)">
                      <HbRadio  class="mt-4"
                        v-for="(item, subIndexOne) in type.buttons"
                        :key="subIndexOne" 
                        :value="item.value"
                        >
                        <template v-slot:label>
                          {{ item.label }}
                        </template>
                        <template v-slot:description>
                          <div class="hb-font-body hb-text-night">
                            {{ item.description }}
                          </div>
                        </template>
                      </HbRadio>
                    </div>
                  </hb-radio-group>
                  <hb-radio-group v-model="form.general.entity_sub_type" 
                    v-validate="'required'"
                    data-vv-name="entity_private_selection"
                    data-vv-as="Entity Subtype"
                    data-vv-scope="entity_private"
                    :errors="errors.has('entity_private.entity_private_selection')"
                  >
                    <div
                      v-if="type.value === 'private_registered_business' && ((entityTypes.findIndex(entity => entity.value === 'private_registered_business')) == selectedIndex)">
                      <HbRadio 
                        v-for="(item, subIndexTwo) in type.buttons"
                        :key="subIndexTwo" 
                        :value="item.value" 
                        >
                        <template v-slot:label>
                          {{ item.label }}
                        </template>
                        <template v-slot:description>
                          <div class="hb-font-body hb-text-night">
                            {{ item.description }}
                          </div>
                        </template>
                      </HbRadio>
                    </div>
                  </hb-radio-group>
                </template>
              </HbRadio>
            </hb-radio-group>
          </hb-form>
        </template>
      </hb-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script type="text/babel">
export default {
  name: "EntitySelection",
  props: {
    form: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      panel: [0],
      entityTypes: [
        {
          label: "Sole Proprietorship",
          value: "sole_proprietorship",
          exampleText: "e.g. Sole proprietorship",
          description:
            "A sole proprietorship is a business that is owned by one individual. There is no legal distinction between the person and the business.",
        },
        {
          label: "General Partnership",
          value: "partnership",
          exampleText: "e.g. General Partnership",
          description:
            "A general partnership is a business owned by more than one person where the profits and losses are passed directly on to the partners. If your business has more than one owner and you have not incorporated your business, you should choose this option.",
        },
        {
          label: "Publicly Traded",
          value: "public_registered_business",
          description: " A publicly traded company is a corporation that offers its ownership shares for trading on stock exchanges or over-the-counter markets.",
            buttons: [
              {
              label: "LLC",
              value: "llc-publicly_traded",
              description:
                "A publicly traded company that offers flexibility in management and taxation."
            },
            {
              label: "C - Corp",
              value: "c-corp-publicly_traded",
              description:
                "A publicly traded company that provides distinct ownership through shares, with structured governance and tax implications."
            }
          ]
        },
        {
          label: "Non-Publicly Traded",
          value: "private_registered_business",
          description: "A non-publicly traded company is a privately held corporation whose ownership shares are not available for trading on public stock exchanges or over-the-counter markets.",
          buttons: [
            {
            label: "LLC",
            value: "llc-non_publicly_traded",
            description:
                "A non-publicly traded company that offers flexibility in management and taxation."
            },
            {
              label: "C - Corp",
              value: "c-corp-non_publicly_traded",
              description:
                "A non-publicly traded company that provides distinct ownership through shares, with structured governance and tax implications."
            }
          ]
        },
      ],
      sub_entity_type: '',
      selectedPublicBusiness: '',
      selectedPrivateBusiness: '',
      selectedIndex: ''
    }
  },
  expose: ['call'],

  methods: {
    call(){
      if(this.form.general.entity_type === 'public_registered_business') {
      this.selectedIndex = 2;
    } else if(this.form.general.entity_type === 'private_registered_business') {
      this.selectedIndex = 3;
    }
  },
    isIndexEqual(indexNum) {      
      this.selectedIndex = indexNum;
      if (![2, 3].includes(indexNum)) {
        this.form.general.entity_sub_type = '';
      }
    },
  }

};
</script>

<style lang="scss" scoped></style>
