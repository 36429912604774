<template>
  <div class="misc-wrapper">
    <div class="registration-code-wrapper">
      <hb-form
        label="Registration code"
        description="Tenant Inc. will provide this to you. Please contact Tenant if you don't have code."
        required
        extraLarge
      >
        <HbTextField
          v-model="form.misc.registration_code"
          v-validate.disable="'required'"
          placeholder="Enter Code"
          data-vv-name="registration_code"
          data-vv-as="Registration Code"
          data-vv-scope="misc_info"
          :error="errors.collect('misc_info.registration_code').length > 0"
        />
      </hb-form>
    </div>
  </div>
</template>

<script>
export default {
  name: "Misc",
  props: {
    form: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.misc-wrapper {
  .registration-code-wrapper {
    margin-bottom: 16px;
    background-color: #ffffff;
    border: 1px solid #dfe3e8;
  }
}
</style>
